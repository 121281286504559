import React from 'react';
import { Redirect } from 'react-router-dom';

import { BlockProps, Redirect as RedirectConfig } from '../../../core/block';

export type Config = RedirectConfig;
export type Props = BlockProps & Config;

const RedirectBlock = (props: Props) => {
    if (props.to.startsWith('http')) {
        window.location.replace(props.to);
        return null;
    }

    return <Redirect to={{ pathname: props.to }} />;
};
export default RedirectBlock;
