import { Streamer, Events, Event } from '../../../../core/stream';
import { SchoolPb, Target } from '../../../../core/action';
import { Action, Tools as ActionTools } from '../../../../core/action';

export default function (payload: SchoolPb.storedState.Payload) {
    return async (dispatch: any) => {
        const event: Event<any> = Events.getSPBState(payload);
        const result = await Streamer.stream(event);

        const updateStateAction: Action<any> = {
            type: '@SCHOOLPB:update_state',
            target: Target.CLIENT,
            payload: { stateUpdate: '{{value}}' },
        };

        ActionTools.processAction(dispatch, updateStateAction, result);
    };
}
