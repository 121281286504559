import { isNil as _isNil } from 'lodash';
import React from 'react';

import { BlockProps, Tools, FlexibleImage } from '../../../core/block';
import { useOutlets } from '../../hooks';
import * as Styled from './styled';

export type Config = FlexibleImage;
export type Props = BlockProps &
    Config & {
        onClick?: () => void;
    };

const Image = (props: Props) => {
    const outlet = useOutlets();

    const processClickOutlet = async () => {
        if (_isNil(props.outlets?.click)) {
            return;
        }

        await outlet(props.outlets?.click);
    };

    const onClick = () => {
        processClickOutlet();

        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return <Styled.Image onClick={onClick} {...Tools.extractConfig<Config>(props)} />;
};
export default Image;
