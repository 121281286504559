import React from 'react';
import getMarkdown from '../../../tools/markdown';

import { BlockProps, H2 as H2Config, Tools } from '../../../../core/block';
import * as Styled from './styled';
import { useInlets } from '../../../hooks';
import { interpolate } from '../../../../core/tools';

export type Config = H2Config;
export type Props = BlockProps & Config;

const md = getMarkdown();

const H2 = (props: Props) => {
    const inlets = useInlets(props.inlets) as H2Config['inlets'];
    const text = interpolate(props.text, { inlets }, { skipUnresolvedPlaceholders: true });

    return (
        <>
            {/* Simple */}
            {!props.useMarkdown && <Styled.H2 {...Tools.extractConfig<Config>(props)}>{text}</Styled.H2>}

            {/* Markdown */}
            {props.useMarkdown && <Styled.H2 {...Tools.extractConfig<Config>(props)} dangerouslySetInnerHTML={{ __html: md.renderInline(text) }} />}
        </>
    );
};
export default H2;
