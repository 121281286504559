import { isNil as _isNil, trim as _trim, includes as _includes, map as _map, isString as _isString } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import MarkdownIt from 'markdown-it';

import { create as createAction, Voting as VotingActions } from '../../../../core/action';
import { PollSelectors } from '../../../store/voting/selectors';
import { currentLanguage } from '../../../store/application/selectors';
import { Question, Choice } from '../../../../core/voting';
import { translate as t } from '../../../../core/translation';
import { IconSets, IconsSet, Name as BlockName, VotesCount as VotesCountConfig } from '../../../../core/block';
import Blocks from '../../../blocks/Blocks';
import VotesCount from '../../../blocks/VotesCount';
import RepresentVotesCount from '../../../blocks/RepresentVotesCount';
import { replaceSubstringByComponent } from '../../../tools';
import * as Styled from './styled';

export type Props = Question.StandardQuestion;

const md = new MarkdownIt({
    html: false,
    xhtmlOut: true,
    breaks: true,
    linkify: false,
});

const StandardQuestion = (props: Props) => {
    const dispatch = useDispatch();
    const [scrollMarkerRef, scrollMarkerInView] = useInView();
    const [questionTextRef, questionTextInView] = useInView({ rootMargin: '-100px 0px 0px 0px' });
    const [votesCountInfoRef, votesCountInfoInView] = useInView({ rootMargin: '-100px 0px 0px 0px' });
    const remainingPlusChoices = useSelector(PollSelectors.remainingChoices({ q: props.id, meaning: Choice.Meaning.PLUS }));
    const remainingMinusChoices = useSelector(PollSelectors.remainingChoices({ q: props.id, meaning: Choice.Meaning.MINUS }));
    const currentStepIdx = useSelector(PollSelectors.currentStepIndex());
    const numberOfStepsCount = useSelector(PollSelectors.numberOfSteps());
    const previousStepIdx = useSelector(PollSelectors.previousStep());
    const currLang = useSelector(currentLanguage());

    useEffect(() => {
        dispatch(createAction(VotingActions.questionEnter.TYPE, { question: props }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(createAction(VotingActions.questionConfig.TYPE, { q: props.id, config: props.config }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, props.id, JSON.stringify(props.config)]);

    const isQuestionTextNoteValid = (): boolean => !_isNil(props.note) && _trim(props.note) !== '';

    const showVotesCountInfoInHeader = (): boolean => !_isNil(props.votesCountInfo) && !votesCountInfoInView;

    const votesCountInfoContainsPlus = (): boolean => _includes(props.votesCountInfo?.text, '{{+}}');
    const votesCountInfoContainsMinus = (): boolean => _includes(props.votesCountInfo?.text, '{{-}}');

    const votesCountPlusConfig = (represent = false): VotesCountConfig => {
        return {
            name: represent ? BlockName.REPRESENT_VOTES_COUNT : BlockName.VOTES_COUNT,
            meaning: Choice.Meaning.PLUS,
            count: remainingPlusChoices,
            icon: props.votesCountInfo?.['+'] || { name: BlockName.ICON, icon: IconSets.Material.ADD },
            themeOverride: props.votesCountInfo?.themeOverride?.['+'],
        };
    };

    const votesCountMinusConfig = (represent = false): VotesCountConfig => {
        return {
            name: represent ? BlockName.REPRESENT_VOTES_COUNT : BlockName.VOTES_COUNT,
            meaning: Choice.Meaning.MINUS,
            count: remainingMinusChoices,
            icon: props.votesCountInfo?.['-'] || { name: BlockName.ICON, icon: IconSets.Material.REMOVE },
            themeOverride: props.votesCountInfo?.themeOverride?.['-'],
        };
    };

    const votesCountInfo = (votesCountInfo: Question.VotesCountInfo) => {
        // render Markdown
        const text = md.render(votesCountInfo.text);

        // replace {{+}} by VotesCount component
        const plusReplaced = replaceSubstringByComponent(
            text,
            '{{+}}',
            <Styled.VotesCountInfoWrapper>
                <RepresentVotesCount {...votesCountPlusConfig(true)} />
            </Styled.VotesCountInfoWrapper>,
        );

        // replace {{-}} by VotesCount component
        const minusReplaced = replaceSubstringByComponent(
            plusReplaced || text,
            '{{-}}',
            <Styled.VotesCountInfoWrapper>
                <RepresentVotesCount {...votesCountMinusConfig(true)} />
            </Styled.VotesCountInfoWrapper>,
        );

        // create final html
        return _map(minusReplaced, (i: any, iIdx: number) => {
            if (_isString(i)) {
                return <span key={iIdx} dangerouslySetInnerHTML={{ __html: i }} />;
            }

            return <React.Fragment key={iIdx}>{i}</React.Fragment>;
        });
    };

    const onBackButton = () => {
        dispatch(createAction(VotingActions.pollPreviousStep.TYPE));
    };

    return (
        <Styled.Wrapper>
            <Styled.Question {...props.layout}>
                {/* scroll marker */}
                <Styled.ScrollMarker ref={scrollMarkerRef} />

                {/* header */}
                <Styled.Header
                    size={props.layout?.size}
                    scrolled={!scrollMarkerInView}
                    containQuestionText={!questionTextInView}
                    containVotesIndicator={showVotesCountInfoInHeader()}
                >
                    {/* back button */}
                    <Styled.HeaderBackButton hide={_isNil(previousStepIdx)} onClick={onBackButton}>
                        <Styled.HeaderBackButtonIcon name="" set={IconsSet.CUSTOM} icon={IconSets.Custom.FULL_ARROW_LEFT} />
                    </Styled.HeaderBackButton>

                    {/* question info */}
                    <Styled.HeaderInfo>
                        {/* step */}
                        <Styled.HeaderStep>
                            {t('Question', currLang)} {currentStepIdx}/{numberOfStepsCount}
                        </Styled.HeaderStep>

                        {/* text */}
                        {!questionTextInView && (
                            <Styled.HeaderQuestion>
                                {props.text} {isQuestionTextNoteValid() && <span className="note">{props.note}</span>}
                            </Styled.HeaderQuestion>
                        )}

                        {/* votes counts */}
                        {showVotesCountInfoInHeader() && (
                            <Styled.HeaderVotesCounts>
                                {votesCountInfoContainsPlus() && <VotesCount {...votesCountPlusConfig()} />}
                                {votesCountInfoContainsMinus() && <VotesCount {...votesCountMinusConfig()} />}
                            </Styled.HeaderVotesCounts>
                        )}
                    </Styled.HeaderInfo>

                    {/* basket button */}
                    <Styled.HeaderBasketButton />
                </Styled.Header>

                {/* text */}
                <Styled.Text ref={questionTextRef}>
                    {props.text} {isQuestionTextNoteValid() && <span className="note">{props.note}</span>}
                </Styled.Text>

                {/* votes counts */}
                {props.votesCountInfo && (
                    <Styled.VotesCountInfo ref={votesCountInfoRef}>{votesCountInfo(props.votesCountInfo)}</Styled.VotesCountInfo>
                )}

                {/* blocks */}
                <Styled.Blocks>
                    <Blocks blocks={props.blocks} />
                </Styled.Blocks>
            </Styled.Question>
        </Styled.Wrapper>
    );
};
export default StandardQuestion;
