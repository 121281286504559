import { isNil as _isNil, map as _map } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ConfigProvider as AntConfigProvider } from 'antd';
import { Locale } from 'antd/es/locale-provider';

import { currentLanguage } from '../../../store/application/selectors';
import { create as createAction, Application as ApplicationActions } from '../../../../core/action';
import PageRouter from '../../Page/Router';
import Blocks from '../../../blocks/Blocks';
import PageWrapper from '../../PageWrapper/Standard';
import PageFooter from '../../PageFooter/Standard';
import { PageHeader, getHeaderHeight } from '../../PageHeader';
import HorizontalSpace from '../../../blocks/HorizontalSpace';
import {
    Website,
    DEFAULT_SHARING_TITLE,
    DEFAULT_SHARING_IMAGE,
    DEFAULT_SHARING_DESCRIPTION,
    DEFAULT_SEO_TITLE,
    DEFAULT_SEO_KEYWORDS,
    DEFAULT_SEO_DESCRIPTION,
} from '../../../../core/website';
import { useOutlets } from '../../../hooks';
import * as Styled from './styled';
import { localeByLanguageCode } from '../../../tools';
import { HORIZONTAL_SPACE } from '../../../../core/block/name';

const StandardWebsite = (props: Website) => {
    const dispatch = useDispatch();
    const outlet = useOutlets();
    const language: string | undefined = useSelector(currentLanguage());
    const localeToUse: Locale | undefined = localeByLanguageCode(language);

    useEffect(() => {
        // set website's default language
        dispatch(createAction(ApplicationActions.language.TYPE, props.languages.default));

        // process enter outlets, if there are some
        if (!_isNil(props.outlets?.enter)) {
            outlet(props.outlets?.enter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AntConfigProvider locale={localeToUse}>
            {/* language */}
            <Helmet>
                <html lang={props.languages.default} />
            </Helmet>

            {/* SEO head */}
            <Helmet>
                <title>{props.head?.seo?.title || DEFAULT_SEO_TITLE}</title>
                <meta name="description" content={props.head?.seo?.description || DEFAULT_SEO_DESCRIPTION} />
                <meta name="keywords" content={props.head?.seo?.keywords || DEFAULT_SEO_KEYWORDS} />
            </Helmet>

            {/* Sharing head */}
            <Helmet>
                <meta property="og:title" content={props.head?.sharing?.title || DEFAULT_SHARING_TITLE} />
                <meta name="twitter:title" content={props.head?.sharing?.title || DEFAULT_SHARING_TITLE} />
                <meta property="og:description" content={props.head?.sharing?.description || DEFAULT_SHARING_DESCRIPTION} />
                <meta name="twitter:description" content={props.head?.sharing?.description || DEFAULT_SHARING_DESCRIPTION} />
                <meta property="og:image" content={props.head?.sharing?.image || DEFAULT_SHARING_IMAGE} />
            </Helmet>

            {/* Favicon */}
            <Helmet>
                <link rel="icon" type="image/png" href={props.head?.favicon || '/favicon.png'} />
                <link rel="apple-touch-icon" href={props.head?.favicon || '/logo192.png'} />
            </Helmet>

            {/* Additional links */}
            <Helmet>
                {_map(props.head?.additionalLinks, (additionalLink) => (
                    <link rel={additionalLink.rel} href={additionalLink.href} />
                ))}
            </Helmet>

            <PageWrapper {...props.pageWrapperProps}>
                {!_isNil(props.header) && (
                    <>
                        <PageHeader styleOverride={props.headerStyleOverride}>
                            <Blocks blocks={props.header} />
                        </PageHeader>
                        <HorizontalSpace size={getHeaderHeight(props.headerStyleOverride)} name={HORIZONTAL_SPACE} />
                    </>
                )}

                {/* content */}
                <Styled.Content>
                    <PageRouter />
                </Styled.Content>

                {/* footer */}
                {!_isNil(props.footer) && (
                    <PageFooter styleOverride={props.footerStyleOverride}>
                        <Blocks blocks={props.footer} />
                    </PageFooter>
                )}
            </PageWrapper>
        </AntConfigProvider>
    );
};
export default StandardWebsite;
