import React from 'react';

import { BlockProps, VerticalSpace as VerticalSpaceConfig, Tools } from '../../../core/block';
import * as Styled from './styled';

export type Config = VerticalSpaceConfig;
export type Props = BlockProps & Config;

const VerticalSpace = (props: Props) => {
    return <Styled.VerticalSpace {...Tools.extractConfig<Config>(props)} />;
};
export default VerticalSpace;
