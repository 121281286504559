import React from 'react';

import { IfBlock as IfBlockInterface } from '../../../../core/block/interfaces';
import Blocks from '../../Blocks';

export function IfBlock({ data, condition, blocksIfTrue, blocksIfFalse }: IfBlockInterface) {
    // currently, the only supported condition is that a property exists
    const isConditionTrue = data && data[condition] !== undefined;
    const blocksToBeUsed = isConditionTrue ? blocksIfTrue : blocksIfFalse;

    return <Blocks blocks={blocksToBeUsed} />;
}

/* SAMPLE block definition
{
    "name": "$LOGIC:if",
    "condition": "description",
    "data": {
        "description": "{{ description }}"
    },
    "blocksIfTrue": [
        {
            "name": "$TYPOGRAPHY:p",
            "text": "**Popis:** {{ description }}"
        }
    ],
    "blocksIfFalse?": [
        // ...   
    ]
}
*/
