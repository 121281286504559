import { isNil as _isNil } from 'lodash';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const StyledIcon = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    fill: currentColor;
`;

export const StyledIconWrapper = styled.i`
    position: relative;
    width: 1em;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &:before {
        position: relative;
        content: '';
        display: block;
        padding-top: 100%;
    }
`;

type Props = HTMLAttributes<HTMLElement> & {
    onClick?: () => void;
};

const Icon = (props: Props) => {
    const onClick = () => {
        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <StyledIconWrapper onClick={onClick} className={props.className}>
            <StyledIcon viewBox="0 0 1024 1024">
                <path
                    fill="rgb(216, 0, 39)"
                    d="M992.154 690.086c20.582-55.464 31.846-115.458 31.846-178.086s-11.264-122.618-31.846-178.086l-480.154-44.522-480.154 44.522c-20.58 55.468-31.846 115.458-31.846 178.086s11.266 122.622 31.846 178.086l480.154 44.522 480.154-44.522z"
                />
                <path
                    fill="rgb(255, 255, 255)"
                    d="M512 1024c220.14 0 407.812-138.944 480.152-333.914h-960.308c72.344 194.97 260.012 333.914 480.156 333.914z"
                />
                <path
                    fill="rgb(255, 255, 255)"
                    d="M512 0c-220.144 0-407.812 138.944-480.156 333.914h960.31c-72.342-194.97-260.014-333.914-480.154-333.914z"
                />
                <circle cx="512" cy="512" r="512" stroke="#ADADAD" strokeWidth="1" fill="none" />
            </StyledIcon>
        </StyledIconWrapper>
    );
};

export default Icon;
