import { TaskProgressInfo } from '../../../core/block/interfaces';

export interface Task {
    done: boolean;
}
export type Tasks = Record<string, Task>;

export interface TaskGroupInfo {
    taskProgressInfo: TaskProgressInfo;
    tasks: Tasks;
}

// task group id => TaskGroupInfo
export type TasksGroup = Record<string, TaskGroupInfo>;

export interface PinSetInfo {
    pinSetId: string;
    pinCount: number;
    creationDate: string;
    title?: string /* will not be stored, it's generated by modifier*/;
}

export type PinSetInfos = PinSetInfo[];

// Store
export interface Store {
    taskGroup: TasksGroup;
    coordinatorName: string;
    coordinatorEmail: string;
    totalBudget: string;
    maxParticularBudget: string;
    workshopVenue: string;
    workshopStartDate: string;
    workshopStartTime: string;
    proposalsApprovingStartDate: string;
    proposalsApprovingEndDate: string;
    campaignStartDate: string;
    campaignEndDate: string;
    votingStartDate: string;
    votingEndDate: string;
    votingEndDatePlanned: string;
    resultsAnnouncementDate: string;
    proposals: string[];
    pinSets: PinSetInfos;
    pinsPerClassCount: number;
    pinsCount: number;
    classCount: number;
    isVotingPublished: boolean;
    foreword: string;
    name: string;
    winners: Record<string, boolean>; // options-id -> value
}

export const initialStore: Store = {
    taskGroup: {},
    coordinatorName: '',
    coordinatorEmail: '',
    totalBudget: '',
    maxParticularBudget: '',
    workshopVenue: '',
    workshopStartDate: '',
    workshopStartTime: '',
    proposalsApprovingStartDate: '',
    proposalsApprovingEndDate: '',
    campaignStartDate: '',
    campaignEndDate: '',
    votingStartDate: '',
    votingEndDate: '',
    votingEndDatePlanned: '',
    resultsAnnouncementDate: '',
    proposals: [],
    pinSets: [],
    pinsPerClassCount: 36,
    classCount: 1,
    pinsCount: 36, // === pinsPerClassCount * classCount
    isVotingPublished: false,
    foreword: '',
    name: '',
    winners: {},
};
