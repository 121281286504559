import styled, { css } from 'styled-components';

import { Config } from './index';
import IconComp from '../Icon';
import { font, color, gradient, animation } from '../../stylesheets/shared';
import { cssDisableUserSelect } from '../../stylesheets/snippets';
import { ifHoverIsSupported } from '../../stylesheets/tools';

export const Icon = styled(IconComp)`
    font-size: 24px;
    margin-right: 12px;
`;

export const Label = styled.div`
    font-family: ${font.family};
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 24px;

    p {
        font-family: ${font.family};
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: 24px;
        padding: 0;
        margin: 0;
        color: inherit;
    }
`;

export const LoadingWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${color.white};
    border-radius: 12px;
`;

type ButtonProps = {
    config: Config;
    inProgress?: boolean;
};
export const Button = styled.button<ButtonProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: ${(p: ButtonProps) => (p.config.icon ? '8px 32px 8px 24px' : '8px 32px')};
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    border-color: ${color.grey2};
    box-sizing: border-box;
    background: ${color.white};
    ${cssDisableUserSelect};
    transition: transform ${animation.timing.interactive} ease-out;

    ${ifHoverIsSupported(css`
        &:hover {
            background: ${gradient.sweetLight};
        }
    `)}

    &:active {
        transform: scale(0.97);
    }

    /* in-progress */
    ${(p: ButtonProps) => {
        if (p.inProgress === true) {
            return css`
                cursor: default;
                background: ${color.white};
            `;
        }
    }}

    ${Label} p {
        color: ${(p) => p.config.styleOverride?.color || 'initial'};
        font-size: ${(p) => p.config.styleOverride?.fontSize || 'initial'};
    }

    /* style override */
    ${(p: ButtonProps) => p.config.styleOverride}
`;
