import { Store } from '../interfaces';
import { Poll } from '../../../../core/voting';

const reducer = (state: Store): Store => {
    const flow: Poll.Flow = state.config?.flow || { start: '', end: '' };

    return {
        ...state,
        step: Poll.Tools.getFlowStart(flow),
    };
};

export default reducer;
