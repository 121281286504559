import { Status as ValidationStatus } from '../../validation';
import { Name as EntityName } from '../../entity';

export const TYPE = `${EntityName.ACTION_PREFIX}FORM:field_change`;

export interface Payload {
    f: string; // form Id
    fn: string; // field name
    vs: ValidationStatus; // field validation status
    v: undefined | string | number | boolean; // field value
}
