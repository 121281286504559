import styled, { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';

import { Config } from './index';
import IconComp from '../Icon';
import { color, gradient, animation } from '../../stylesheets/shared';
import { cssDisableUserSelect, cssAccent } from '../../stylesheets/snippets';
import { ifHoverIsSupported, onPhone } from '../../stylesheets/tools';

// Button
export const Label = styled.div`
    ${cssAccent}
    transition: color ${animation.timing.standart} ease-out;
`;

export const IconWrapper = styled.div`
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin: 0 24px 0 0;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    ${onPhone(css`
        width: 32px;
        height: 32px;
        margin: 0 12px 0 0;
    `)}
`;

export const Icon = styled(IconComp)`
    font-size: 32px;

    ${onPhone(css`
        font-size: 24px;
    `)}
`;

type ButtonProps = {
    config: Config;
};
export const Button = styled.button<ButtonProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 11px 48px;
    border-radius: 12px;
    border-style: solid;
    border-width: 2px;
    border-color: ${({ config }) => (config.themeOverride?.color ? config.themeOverride.color : color.black)};
    box-sizing: border-box;
    background: transparent;
    box-shadow: ${({ config }) => (config.themeOverride?.shadow ? config.themeOverride?.shadow : 'none')};
    ${cssDisableUserSelect}
    transition: border-color ${animation.timing.standart} ease-out, transform ${animation.timing.interactive} ease-out;

    /* style override */
    ${({ config }) => config.styleOverride}

    ${onPhone(css`
        padding: 11px 31px;
    `)}

    ${ifHoverIsSupported(css`
        &:hover {
            background: ${(p: ButtonProps) =>
                p.config.themeOverride?.color ? hexToRgba(p.config.themeOverride.color || '', 0.12) : gradient.sweetLigher};
        }
    `)}

    &:active {
        transform: scale(0.98);
    }

    /* Icon Wrapper */
    ${IconWrapper} {
        background: ${({ config }) => (config.themeOverride?.color ? config.themeOverride.color : color.black)};
        box-shadow: ${({ config }) => (config.themeOverride?.shadow ? config.themeOverride?.shadow : 'none')};
    }

    /* Label */
    ${Label} {
        color: ${({ config }) => (config.themeOverride?.color ? config.themeOverride.color : color.black)};
        text-shadow: ${({ config }) => (config.themeOverride?.shadow ? config.themeOverride?.shadow : 'none')};
    }
`;
