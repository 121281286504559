import { isNil as _isNil } from 'lodash';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const StyledIcon = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    fill: currentColor;
`;

export const StyledIconWrapper = styled.i`
    position: relative;
    width: 1em;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &:before {
        position: relative;
        content: '';
        display: block;
        padding-top: 100%;
    }
`;

type Props = HTMLAttributes<HTMLElement> & {
    onClick?: () => void;
};

const Icon = (props: Props) => {
    const onClick = () => {
        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <StyledIconWrapper onClick={onClick} className={props.className}>
            <StyledIcon viewBox="0 0 1024 1024">
                <path
                    fill="rgb(240, 240, 240)"
                    d="M1024 512c0 282.77-229.23 512-512 512s-512-229.23-512-512c0-282.77 229.23-512 512-512s512 229.23 512 512z"
                />
                <path
                    fill="rgb(216, 0, 39)"
                    d="M467.478 512c0 0-317.218 362.11-317.518 362.038 92.652 92.654 220.654 149.962 362.040 149.962 282.768 0 512-229.232 512-512h-556.522z"
                />
                <path
                    fill="rgb(0, 82, 180)"
                    d="M149.96 149.96c-199.948 199.948-199.948 524.13 0 724.080 82.626-82.626 162.092-162.092 362.040-362.040l-362.040-362.040z"
                />
            </StyledIcon>
        </StyledIconWrapper>
    );
};

export default Icon;
