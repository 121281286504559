import { size as _size, filter as _filter, isNil as _isNil } from 'lodash';

import { Flow, FlowStep } from '../interfaces';

export default (flow?: Flow): number => {
    if (_isNil(flow) || _isNil(flow.steps)) {
        return 0;
    }

    // get number of step which are NOT excluded from steps-count
    const steps: FlowStep[] = _filter(flow.steps, (step: FlowStep) => step.excludeFromStepsCounts !== true);

    return _size(steps);
};
