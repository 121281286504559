import styled, { css } from 'styled-components';

import { Config } from './index';
import { onTablet, onPhone } from '../../../stylesheets/tools';

// Bars
interface BarsConfig {
    config: Config;
}
export const Bars = styled.div<BarsConfig>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;

    /* style override */
    ${(p: BarsConfig) => p.config.styleOverride}

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;
