import React from 'react';

import { BlockProps, H3 as H3Config, Tools } from '../../../../core/block';
import * as Styled from './styled';

export type Config = H3Config;
export type Props = BlockProps & Config;

const H3 = (props: Props) => {
    return <Styled.H3 {...Tools.extractConfig<Config>(props)}>{props.text}</Styled.H3>;
};
export default H3;
