import styled from 'styled-components';

import { Config } from './index';
import IconComp from '../Icon';
import { cssP } from '../../stylesheets/snippets';
import { color, animation, meaningColor } from '../../stylesheets/shared';
import { Choice } from '../../../core/voting';

const getMeaningColor = (c: Config): string => {
    // color override
    if (c.themeOverride?.color) {
        return c.themeOverride?.color;
    }

    // default color by meaning
    return c.meaning === Choice.Meaning.PLUS ? meaningColor.plus : meaningColor.minus;
};

export const Count = styled.div`
    ${cssP};
    font-weight: bold;
`;

export const Cross = styled(IconComp)`
    color: ${color.black};
    font-size: 12px;
    margin: 0 3px;
`;

export const IconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    border: 1px solid ${color.grey2};
    background: ${color.white};
`;

export const Icon = styled(IconComp)`
    font-size: 16px;
    transition: color ${animation.timing.standart} ease-out;
`;

export const VotesCount = styled.div<{ config: Config; turnGray: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;

    /* style override */
    ${({ config }) => config.styleOverride}

    /* use correct color for icon */
    ${Icon} {
        color: ${({ config, turnGray }) => (turnGray ? color.grey2 : getMeaningColor(config))};
    }
`;
