import { isNil as _isNil, trim as _trim } from 'lodash';
import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';

import { Urls } from '../../../core/voting';

function usePollVoterMark(pollId: string, uid: string): [boolean | undefined, boolean, any | undefined] {
    const [config, loading, error] = useObjectVal<boolean>(firebase.database().ref(Urls.pollVoterMark(pollId, uid)));

    if (_isNil(pollId) || _isNil(uid) || _trim(pollId) === '' || _trim(uid) === '') {
        return [undefined, true, undefined];
    }

    return [config, loading, error];
}

export default usePollVoterMark;
