import { Block, HorizontalAlign } from '../block';
// import { PageWrapperProps } from '../../app/components/PageWrapper/Standard';
import { CSSObject } from 'styled-components';
import { Outlets, Outlet } from '../outlet';
import { StorageReference } from '../storage';

export const DEFAULT_SEO_TITLE = 'Decision 21';
export const DEFAULT_SEO_KEYWORDS = '';
export const DEFAULT_SEO_DESCRIPTION =
    'Více hlasů vám umožní lépe projevit svůj názor. Když volíte pouze jedinou možnost, říkáte tím jen jedno slovo. Pokud ale můžete rozdat hlasů více, odpovídáte celou větou. Použitím více hlasů navíc vyjadřujete svou vstřícnost se shodnout s ostatními.';
export const DEFAULT_SHARING_TITLE = DEFAULT_SEO_TITLE;
export const DEFAULT_SHARING_DESCRIPTION = DEFAULT_SEO_DESCRIPTION;
export const DEFAULT_SHARING_IMAGE = 'https://res.cloudinary.com/d21-me/image/upload/v1604867495/d21p%20-%20general/logo_for_sharing.jpg';

// Dialog
export interface DialogLayout {
    align?: HorizontalAlign;
}
export interface Dialog {
    name: string;
    title?: string;
}
export interface StandardDialog extends Dialog {
    blocks: Block[];
    layout?: DialogLayout;
}

// Page
export enum SizeType {
    STANDARD = 'standard',
    WIDE = 'wide',
    FULLWIDTH = 'full-width',
}
export interface PageHead {
    seo?: {
        title?: string;
        keywords?: string;
        description?: string;
    };
    sharing?: {
        title?: string;
        description?: string;
        image?: string;
    };
    favicon?: string;
}
export interface PageLayout {
    align?: HorizontalAlign;
    size?: SizeType;
}
export interface PageOutlets extends Outlets {
    enter?: Outlet | Outlet[];
}
export interface Page {
    name: string;
    head?: PageHead;
    outlets?: PageOutlets;
}
export interface StandardPage extends Page {
    header?: Block[];
    headerStyleOverride?: CSSObject;
    blocksRef?: StorageReference;
    blocks?: Block[];
    layout?: PageLayout;
    footer?: Block[];
    footerStyleOverride?: CSSObject;
}
export interface PollPage extends Page {
    pollId: string;
}
export interface ResultsPage extends Page {
    resultsId: string;
}

// Website
export interface Languages {
    default: string;
}
export interface Head {
    seo?: {
        title?: string;
        keywords?: string;
        description?: string;
    };
    sharing?: {
        title: string;
        description?: string;
        image?: string;
    };
    favicon?: string;
    additionalLinks?: {
        rel: string;
        href: string;
    }[];
}
export interface WebsiteOutlets extends Outlets {
    enter?: Outlet | Outlet[];
}
export interface Website {
    languages: Languages;
    name: string;
    head?: Head;
    outlets?: WebsiteOutlets;
    header?: Block[];
    headerStyleOverride?: CSSObject;
    footer?: Block[];
    footerStyleOverride?: CSSObject;
    // pageWrapperProps?: PageWrapperProps;
    pageWrapperProps?: any;
}
