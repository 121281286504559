import styled, { css } from 'styled-components';
import { Config } from './index';
import { onTablet, onPhone } from '../../stylesheets/tools';

const verticalSpaceOnPhone = (c: Config) => css`
    width: ${c.onPhone?.size || 'initial'};

    /* style override */
    ${c.onPhone?.styleOverride}
`;
const verticalSpaceOnTablet = (c: Config) => css`
    width: ${c.onTablet?.size || 'initial'};

    /* style override */
    ${c.onTablet?.styleOverride}
`;

export const VerticalSpace = styled.div<{ config: Config }>`
    box-sizing: border-box;
    width: ${({ config }) => config.size};

    /* style override */
    ${({ config }) => config.styleOverride}

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(
                      css`
                          ${verticalSpaceOnTablet(config)}
                      `,
                  )}
              `
            : ''}


    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(
                      css`
                          ${verticalSpaceOnPhone(config)}
                      `,
                  )}
              `
            : ''}
`;
