import { map as _map, isNil as _isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';

import { create as createAction, Voting as VotingActions } from '../../../../core/action';
import { PollSelectors } from '../../../store/voting/selectors';
import { currentLanguage } from '../../../store/application/selectors';
import { isItBlock } from '../../../../core/entity/tools';
import { Question, Tools, Name as VotingName } from '../../../../core/voting';
import { IconSets, IconsSet, Block } from '../../../../core/block';
import { translate as t } from '../../../../core/translation';
import BlockComp from '../../../blocks/Block';
import EmbeddedQuestion from '../Embedded';
import * as Styled from './styled';

export type Props = Question.CoverQuestion;

const CoverQuestion = (props: Props) => {
    const dispatch = useDispatch();
    const [scrollMarkerRef, scrollMarkerInView] = useInView();
    const currentStepIdx = useSelector(PollSelectors.currentStepIndex());
    const numberOfStepsCount = useSelector(PollSelectors.numberOfSteps());
    const previousStepIdx = useSelector(PollSelectors.previousStep());
    const currLang = useSelector(currentLanguage());

    useEffect(() => {
        dispatch(createAction(VotingActions.questionEnter.TYPE, { question: props }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(createAction(VotingActions.questionConfig.TYPE, { q: props.id, config: props.config }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, props.id, JSON.stringify(props.config)]);

    const onBackButton = () => {
        dispatch(createAction(VotingActions.pollPreviousStep.TYPE));
    };

    return (
        <Styled.Wrapper>
            <Styled.Question {...props.layout}>
                {/* scroll marker */}
                <Styled.ScrollMarker ref={scrollMarkerRef} />

                {/* header */}
                <Styled.Header scrolled={!scrollMarkerInView} size={props.layout?.size}>
                    {/* back button */}
                    <Styled.HeaderBackButton hide={_isNil(previousStepIdx)} onClick={onBackButton}>
                        <Styled.HeaderBackButtonIcon name="" set={IconsSet.CUSTOM} icon={IconSets.Custom.FULL_ARROW_LEFT} />
                    </Styled.HeaderBackButton>

                    {/* question info */}
                    <Styled.HeaderInfo>
                        {/* step */}
                        <Styled.HeaderStep>
                            {t('Question', currLang)} {currentStepIdx}/{numberOfStepsCount}
                        </Styled.HeaderStep>
                    </Styled.HeaderInfo>

                    {/* basket button */}
                    <Styled.HeaderBasketButton />
                </Styled.Header>

                {/* content */}
                <Styled.Content>
                    {_map(props.content, (c: Block | Question.Question, contentIdx: number) => {
                        // block
                        if (isItBlock(c.name)) {
                            return <BlockComp key={contentIdx} {...c} />;
                        }
                        // question
                        else if (Tools.isItQuestion(c.name)) {
                            // Embeded Question
                            if (c.name === VotingName.EMBEDDED_QUESTION) {
                                const questionConfig = c as Question.StandardQuestion;
                                return <EmbeddedQuestion key={questionConfig.id} {...questionConfig} />;
                            }
                        }
                    })}
                </Styled.Content>
            </Styled.Question>
        </Styled.Wrapper>
    );
};
export default CoverQuestion;
