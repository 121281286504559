// Entity prefixes
export const BLOCK_PREFIX = '$';
export const DESCRIPTOR_PREFIX = '=>';
export const COMPONENT_PREFIX = '~';
export const ACTION_PREFIX = '@';
export const SELECTOR_PREFIX = '#';
export const STREAM_EVENT_PREFIX = '->';

// Logical entity name
export const PROJECT = 'project';
export const POLL = 'poll';
export const QUESTION = 'question';
export const RESULTS = 'results';
export const RESULT = 'result';
export const WEBSITE = 'website';
export const PAGE = 'page';

export type EntityName = typeof PROJECT | typeof POLL | typeof QUESTION | typeof RESULTS | typeof RESULT | typeof WEBSITE | typeof PAGE;
