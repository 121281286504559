import { isNil as _isNil } from 'lodash';
import styled, { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';

import { Config } from './index';
import IconComp from '../Icon';
import { font, color, gradient, animation } from '../../stylesheets/shared';
import { cssDisableUserSelect } from '../../stylesheets/snippets';
import { ifHoverIsSupported } from '../../stylesheets/tools';
import { SecondaryCTAInlets } from '../../../core/block';
import { Tools } from '../../../core/validation';

export const Label = styled.div`
    font-family: ${font.family};
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 24px;
    color: ${color.black};
`;

export const IconWrapper = styled.div`
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 12px 0 0;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const Icon = styled(IconComp)`
    font-size: 16px;
    color: ${color.white};
`;

export const LoadingWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${color.white};
    border-radius: 12px;
`;

const disbaledCss = css`
    cursor: default;
    border-width: 1px;
    border-color: ${color.grey2};
    background: ${color.white};
    &:hover {
        background: ${color.white};
    }

    ${Label} {
        color: ${color.grey2};
    }

    ${IconWrapper},
    &:hover ${IconWrapper} {
        background: transparent;
        border: 1px solid ${color.grey2};

        ${Icon} {
            color: ${color.black};
        }
    }
`;

type ButtonProps = {
    config: Config;
    usedInlets: SecondaryCTAInlets;
    inProgress?: boolean;
    isEnabled?: boolean;
};
export const Button = styled.button<ButtonProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 8px 32px;
    border-radius: 12px;
    border: ${({ config }) => (config.themeOverride ? config.themeOverride.color : color.violet)} 1px solid;
    box-sizing: border-box;
    background: ${(p: ButtonProps) => (p.config.themeOverride ? hexToRgba(p.config.themeOverride.color || '', 0.06) : gradient.sweetLight)};
    ${cssDisableUserSelect}
    transition: transform ${animation.timing.interactive} ease-out;

    /* style override */
    ${({ config }) => config.styleOverride}

    ${ifHoverIsSupported(css`
        &:hover {
            background: ${(p: ButtonProps) => (p.config.themeOverride ? hexToRgba(p.config.themeOverride.color || '', 0.24) : gradient.sweetLigher)};
        }
    `)}

    &:active {
        transform: scale(0.97);
    }

    /* Icon Wrapper */
    ${IconWrapper} {
        background: ${({ config }) => (config.themeOverride ? config.themeOverride.color : gradient.sweet)};
    }
    ${ifHoverIsSupported(css`
        &:hover ${IconWrapper} {
            background: ${color.black};
        }
    `)}

    /* invalid */
    ${(p: ButtonProps) => {
        if (!_isNil(p.usedInlets.validationStatus) && Tools.isStatusInvalid(p.usedInlets.validationStatus)) {
            return disbaledCss;
        }
    }}

    ${(p: ButtonProps) => {
        if (!_isNil(p.isEnabled) && p.isEnabled === false) {
            return disbaledCss;
        }
    }}

    /* in-progress */
    ${(p: ButtonProps) => {
        if (p.inProgress === true) {
            return css`
                cursor: default;
                border-width: 1px;
                border-color: ${color.grey2};
                background: ${color.white};
                &:hover {
                    background: ${color.white};
                }

                ${Label} {
                    color: ${color.grey2};
                }

                ${IconWrapper},
                &:hover ${IconWrapper} {
                    background: transparent;
                    border: 1px solid ${color.grey2};

                    ${Icon} {
                        color: ${color.black};
                    }
                }
            `;
        }
    }}
`;
