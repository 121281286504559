import { isNil as _isNil, defer as _defer, isEqual as _isEqual, debounce as _debounce } from 'lodash';
import React, { useState, SyntheticEvent, useEffect, useCallback } from 'react';
import { Input as AntInput } from 'antd';

import { BlockProps, Tools, FormFieldString as FormFieldStringConfig, FormFieldInlets } from '../../../../core/block';
import { useOutlets, useInlets, useStringValidation, ValidationResult, VALIDATION_RESULT_UNTOUCHED, getOutletValidationStatus } from '../../../hooks';
import * as Styled from './styled';

export type Config = FormFieldStringConfig;
export type Props = BlockProps &
    Config & {
        onSubmit?: (value: string | undefined, validationResult: ValidationResult) => void;
        onChange?: (value: string, validationResult: ValidationResult) => void;
    };

const NumberFormField = (props: Props) => {
    const outlet = useOutlets();
    const inlets = useInlets(props.inlets) as FormFieldInlets;
    const validate = useStringValidation(props.validationScheme || {});
    const [validationResult, setValidationResult] = useState(VALIDATION_RESULT_UNTOUCHED);
    const [cachedValue, setCachedValue] = useState(props.defaultValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const processChangeOutlet = useCallback(
        _debounce((value: string, validationResult: ValidationResult) => {
            if (_isNil(props.outlets?.change)) {
                return;
            }

            const outletParameters = {
                value,
                validationStatus: getOutletValidationStatus(validationResult),
            };

            _defer(() => outlet(props.outlets?.change, outletParameters));
        }, 250),
        [],
    );

    const processChange = (value: string, validationResult: ValidationResult) => {
        if (_isNil(props.onChange)) {
            return;
        }

        props.onChange(value, validationResult);
    };

    const processValidation = async (value: string): Promise<ValidationResult> => {
        const validationResult = await validate(value);
        setValidationResult(validationResult);

        return validationResult;
    };

    // TODO possible candidate for refactoring into useInletValue()
    useEffect(() => {
        // we are not expecting any inlet
        if (_isNil(props.inlets)) {
            return;
        }

        // is inlet already resolved?
        if (Tools.isInletResolved(inlets.value)) {
            // ... yes value is defined, and it's different then cached value -> use it
            if (!_isNil(inlets.value) && !_isEqual(inlets.value, cachedValue)) {
                // use it as cached value ...
                setCachedValue(inlets.value);
                // ... and validate the value
                processValidation(inlets.value);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets.value)]);

    const handleChange = async (event: SyntheticEvent) => {
        // get value from input
        const value = (event.target as HTMLInputElement).value;
        // store it as local value
        setCachedValue(value);

        // validate the value
        processValidation(value).then((validationResult) => {
            // process all changes
            processChangeOutlet(value, validationResult);
            processChange(value, validationResult);
        });
    };

    const onSubmit = () => {
        if (_isNil(props.onSubmit)) {
            return;
        }

        props.onSubmit(cachedValue, validationResult);
    };

    return (
        <Styled.Number {...Tools.extractConfig<Config>(props)} onChange={handleChange}>
            {/* label */}
            {!_isNil(props.text) && <Styled.Label>{props.text}</Styled.Label>}

            {/* input */}
            <Styled.FormItem hasFeedback={true} validateStatus={validationResult.antValidationStatus} help={validationResult.message}>
                <AntInput
                    type="number"
                    placeholder={props.placeholder}
                    autoFocus={props.autoFocus}
                    autoComplete="off"
                    value={cachedValue}
                    maxLength={props.maxLength}
                    onPressEnter={onSubmit}
                />
            </Styled.FormItem>
        </Styled.Number>
    );
};

export default NumberFormField;
