import { Proposals as ProposalsActions } from '../../../core/action';

export const PROPOSALS_MAP_PIN_CLICKED = ProposalsActions.pinClicked.TYPE;
export type Payload = ProposalsActions.pinClicked.Payload;

export const reduceMapPinClicked = (state: any, payload: ProposalsActions.pinClicked.Payload) => {
    return {
        ...state,
        highlightedProposalId: payload.pin.item.attrs.originalId,
    };
};
