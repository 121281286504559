import { isNil as _isNil, isEmpty as _isEmpty, size as _size, get as _get, filter as _filter } from 'lodash';

import { Status as ValidationStatus } from '../../validation';
import { StandardQuestionConfig as QuestionConfig } from '../question';
import { Meaning, Tools as ChoiceTools, Choice } from '../choice';
import { Answer } from '../answer';
import { Errors } from './errors';

// return:
//    true -> valid
//    false -> invalid
//    string -> invalid ... string value is error core
const validatePlusOnly = (config: QuestionConfig, answer: Answer | undefined): ValidationStatus => {
    const choiceCount = _size(answer);
    const allowedChoices = _get(config, [Meaning.PLUS], 0);

    // allowed-count-choice & optional
    if (choiceCount <= allowedChoices && config.optional === true) {
        return true;
    }
    // allowed-count-choice & required
    if (choiceCount <= allowedChoices && config.optional !== true) {
        return true;
    }

    // more-choices
    if (choiceCount > allowedChoices) {
        return Errors.TOO_MANY_VOTES;
    }

    return false;
};

const validator = (config: QuestionConfig, answer: Answer | undefined): ValidationStatus => {
    const isAnswerEmpty = _isNil(answer) || _isEmpty(answer);

    // empty & optional
    if (isAnswerEmpty === true && config.optional === true) {
        return true;
    }
    // empty & required
    if (isAnswerEmpty === true && config.optional !== true) {
        return Errors.NO_VOTES;
    }

    const allowPlusVotes = _get(config, [Meaning.PLUS], 0) > 0;
    const allowMinusVotes = _get(config, [Meaning.MINUS], 0) > 0;

    // plus only
    if (allowPlusVotes && !allowMinusVotes) {
        return validatePlusOnly(config, answer);
    }

    // minus only
    if (!allowPlusVotes && allowMinusVotes) {
        console.error('D21 with only minus votes is not implemented');
        return false;
    }

    // plus and minus
    const plusCount = _size(_filter(answer, (ch: Choice) => ChoiceTools.hasChoicePlusMeaning(ch)));
    const minusCount = _size(_filter(answer, (ch: Choice) => ChoiceTools.hasChoiceMinusMeaning(ch)));
    const allowedPlusChoices = _get(config, [Meaning.PLUS], 0);
    const allowedMinusChoices = _get(config, [Meaning.MINUS], 0);
    // 1. check allowed plus count
    if (plusCount > allowedPlusChoices) {
        return Errors.TOO_MANY_PLUS_VOTES;
    }
    // 2. check allowed minus count
    else if (minusCount > allowedMinusChoices) {
        return Errors.TOO_MANY_MINUS_VOTES;
    }
    // 3. check 2:1 rule
    else if (minusCount * 2 > plusCount) {
        return Errors.RULE_2_1_BROKEN;
    }
    // -> it's valid
    else {
        return true;
    }
};

export default validator;
