import React from 'react';

import { useStorageJSON } from '../../hooks';
import { BlockProps, Block, BlocksRef as CoreBlocksRef } from '../../../core/block';
import Blocks from '../Blocks';

export type Props = BlockProps & CoreBlocksRef;

const BlocksRef = (props: Props) => {
    const storageJSON = useStorageJSON(props.reference) as Block[];

    return <Blocks blocks={storageJSON} />;
};
export default BlocksRef;
