import { Application as ApplicationActions } from '../../../../core/action';
import { Store } from '../interfaces';

const reducer = (state: Store, payload: ApplicationActions.shuffleGroup.Payload): Store => {
    return {
        ...state,
        shuffleGroup: {
            ...state.shuffleGroup,
            [payload.groupName]: payload.renderMap,
        },
    };
};

export default reducer;
