import React from 'react';

import * as B from '../../../core/block';
import GeneralBlock from '../GeneralBlock';
import Anchor from '../Anchor';
import Redirect from '../Redirect';
import RedirectTo404 from '../RedirectTo404';
import RepresentPrimaryCTA from '../RepresentPrimaryCTA';
import PrimaryCTA from '../PrimaryCTA';
import SecondaryCTA from '../SecondaryCTA';
import Icon from '../Icon';
import Button from '../Button';
import RepresentButton from '../RepresentButton';
import VoteButton from '../VoteButton';
import LinkButton from '../LinkButton';
import IconButton from '../IconButton';
import LinkItemButton from '../LinkItemButton';
import ProgressButton from '../ProgressButton';
import DatePicker from '../TimeDatePickers/DatePicker';
import TimePicker from '../TimeDatePickers/TimePicker';
import { Timeline } from '../Timeline';
import Title from '../Typography/Title';
import H1 from '../Typography/H1';
import H1WithStatus from '../Typography/H1WithStatus';
import H2 from '../Typography/H2';
import H3 from '../Typography/H3';
import P from '../Typography/P';
import NoteP from '../Typography/NoteP';
import RepresentP from '../Typography/RepresentP';
import Accent from '../Typography/Accent';
import Image from '../Image';
import YouTube from '../Video/YouTube';
import MP4 from '../Video/MP4';
import Section from '../Section';
import Bar from '../Bars/Bar';
import Bars from '../Bars/Bars';
import Rank from '../Rank';
import BarGraph from '../Graph/Bar';
import SignBarGraph from '../Graph/SignBar';
import DoubleSignBarGraph from '../Graph/DoubleSignBar';
import PortraitBarGraph from '../Graph/PortraitBar';
import PortraitSignBarGraph from '../Graph/PortraitSignBar';
import PortraitDoubleSignBarGraph from '../Graph/PortraitDoubleSignBar';
import FlexibleImage from '../FlexibleImage';
import CircularImage from '../CircularImage';
import VerticalLayout from '../VerticalLayout';
import HorizontalLayout from '../HorizontalLayout';
import Collapse from '../Collapse';
import AffixLayout from '../AffixLayout';
import CodSBomLayout from '../CodSBomLayout';
import VerticalSpace from '../VerticalSpace';
import HorizontalSpace from '../HorizontalSpace';
import HorizontalLine from '../HorizontalLine';
import SimpleVotingOption from '../VotingOption/Simple';
import StandardVotingOption from '../VotingOption/Standard';
import PortraitVotingOption from '../VotingOption/Portrait';
import VotesCount from '../VotesCount';
import FormFieldString from '../FormField/String';
import FormFieldLink from '../FormField/Link';
import FormFieldStringLong from '../FormField/StringLong';
import FormFieldPhone from '../FormField/Phone';
import FormFieldNumber from '../FormField/Number';
import FormFieldInputBox from '../FormField/InputBox';
import { FileUploaderBlock } from '../FormField/FileUploader';
import { FileList } from '../FileList';
import { ItemList } from '../ItemList';
import { RadioGroup } from '../FormField/RadioGroup';
import FormFieldRadioButton from '../FormField/RadioGroup/RadioButton';
import FormFieldCheckbox from '../FormField/Checkbox';
import { FormFieldDropdown } from '../FormField/Dropdown';
import { Map } from '../Map';
import { MapSearchBar } from '../Map/Search';
import DialogStepper from '../Dialog/Stepper';
import Switch from '../Dialog/Switch';
import TimeSwitch from '../Dialog/TimeSwitch';
import SMSAuthentication from '../Dialog/SMSAuthentication';
import PinAuthentication from '../Dialog/PinAuthentication';
import { ProposalsListBlock } from '../ProposalsList';
import TableLikeList from '../TableLikeList';
import StandardResultList from '../ResultList/Standard';
import PortraitResultList from '../ResultList/Portrait';
import { SplitPane } from '../SplitPane';
import { InteractiveSVG } from '../InteractiveSvg';
import OverlapPortraitResultList from '../ResultList/OverlapPortrait';
import TemplateList from '../TemplateList';
import UnorderedList from '../UnorderedList';
import { Menu } from '../Menu';
import MenuItem from '../Menu/Item';
import DevPlayground from '../DevPlayground';
import { IfBlock } from '../Logic/If';
import { ForEachBlock } from '../Logic/ForEach';
import Harmonogram from '../Harmonogram';
import SchoolPbResultsPanel from '../_customized/SchoolPbResultsPanel';

// Customized
import VolbyJinak2020OverallResults from '../_customized/VolbyJinak2020OverallResults';

export type Props = B.BlockProps & B.Block;

const Block = (props: Props) => {
    return (
        <>
            {/* General */}
            {props.name === B.Name.GENERAL_BLOCK && <GeneralBlock {...(props as B.GeneralBlock)} />}
            {props.name === B.Name.ANCHOR && <Anchor {...(props as B.Anchor)} />}
            {props.name === B.Name.REDIRECT && <Redirect {...(props as B.Redirect)} />}
            {props.name === B.Name.REDIRECT_TO_404 && <RedirectTo404 {...(props as B.RedirectTo404)} />}
            {/* CTA */}
            {props.name === B.Name.REPRESENT_PRIMARY_CTA && <RepresentPrimaryCTA {...(props as B.RepresentPrimaryCTA)} />}
            {props.name === B.Name.PRIMARY_CTA && <PrimaryCTA {...(props as B.PrimaryCTA)} />}
            {props.name === B.Name.SECONDARY_CTA && <SecondaryCTA {...(props as B.SecondaryCTA)} />}
            {/* Buttons */}
            {props.name === B.Name.VOTE_BUTTON && <VoteButton {...(props as B.VoteButton)} />}
            {props.name === B.Name.REPRESENT_BUTTON && <RepresentButton {...(props as B.RepresentButton)} />}
            {props.name === B.Name.BUTTON && <Button {...(props as B.Button)} />}
            {props.name === B.Name.LINK_BUTTON && <LinkButton {...(props as B.LinkButton)} />}
            {props.name === B.Name.ICON_BUTTON && <IconButton {...(props as B.IconButton)} />}
            {props.name === B.Name.PROGRESS_BUTTON && <ProgressButton {...(props as B.ProgressButton)} />}
            {props.name === B.Name.LINK_ITEM_BUTTON && <LinkItemButton {...(props as B.LinkItemButton)} />}
            {/* Typography */}
            {props.name === B.Name.TITLE && <Title {...(props as B.Title)} />}
            {props.name === B.Name.H1 && <H1 {...(props as B.H1)} />}
            {props.name === B.Name.H1_WITH_STATUS && <H1WithStatus {...(props as B.H1WithStatus)} />}
            {props.name === B.Name.H2 && <H2 {...(props as B.H2)} />}
            {props.name === B.Name.H3 && <H3 {...(props as B.H3)} />}
            {props.name === B.Name.P && <P {...(props as B.P)} />}
            {props.name === B.Name.NOTE_P && <NoteP {...(props as B.NoteP)} />}
            {props.name === B.Name.REPRESENT_P && <RepresentP {...(props as B.RepresentP)} />}
            {props.name === B.Name.ACCENT && <Accent {...(props as B.Accent)} />}
            {/* Graphic */}
            {props.name === B.Name.ICON && <Icon {...(props as B.Icon)} />}
            {props.name === B.Name.IMAGE && <Image {...(props as B.Image)} />}
            {props.name === B.Name.FLEXIBLE_IMAGE && <FlexibleImage {...(props as B.FlexibleImage)} />}
            {props.name === B.Name.CIRCULAR_IMAGE && <CircularImage {...(props as B.CircularImage)} />}
            {props.name === B.Name.HORIZONTAL_SPACE && <HorizontalSpace {...(props as B.HorizontalSpace)} />}
            {props.name === B.Name.VERTICAL_SPACE && <VerticalSpace {...(props as B.VerticalSpace)} />}
            {props.name === B.Name.HORIZONTAL_LINE && <HorizontalLine {...(props as B.HorizontalLine)} />}
            {props.name === B.Name.VOTES_COUNT && <VotesCount {...(props as B.VotesCount)} />}
            {props.name === B.Name.SECTION && <Section {...(props as B.Section)} />}
            {props.name === B.Name.BAR_GRAPH && <BarGraph {...(props as B.BarGraph)} />}
            {props.name === B.Name.SIGN_BAR_GRAPH && <SignBarGraph {...(props as B.SignBarGraph)} />}
            {props.name === B.Name.DOUBLE_SIGN_BAR_GRAPH && <DoubleSignBarGraph {...(props as B.DoubleSignBarGraph)} />}
            {props.name === B.Name.PORTRAIT_BAR_GRAPH && <PortraitBarGraph {...(props as B.PortraitBarGraph)} />}
            {props.name === B.Name.PORTRAIT_SIGN_BAR_GRAPH && <PortraitSignBarGraph {...(props as B.PortraitSignBarGraph)} />}
            {props.name === B.Name.PORTRAIT_DOUBLE_SIGN_BAR_GRAPH && <PortraitDoubleSignBarGraph {...(props as B.PortraitDoubleSignBarGraph)} />}
            {props.name === B.Name.YOUTUBE && <YouTube {...(props as B.YouTube)} />}
            {props.name === B.Name.MP4 && <MP4 {...(props as B.MP4)} />}
            {props.name === B.Name.BAR && <Bar {...(props as B.Bar)} />}
            {props.name === B.Name.BARS && <Bars {...(props as B.Bars)} />}
            {props.name === B.Name.RANK && <Rank {...(props as B.Rank)} />}
            {props.name === B.Name.INTERACTIVE_SVG && <InteractiveSVG {...(props as B.InteractiveSvg)} />}
            {props.name === B.Name.HARMONOGRAM && <Harmonogram {...(props as B.Harmonogram)} />}
            {/* Layout */}
            {props.name === B.Name.HORIZONTAL_LAYOUT && <HorizontalLayout {...(props as B.HorizontalLayout)} />}
            {props.name === B.Name.VERTICAL_LAYOUT && <VerticalLayout {...(props as B.VerticalLayout)} />}
            {props.name === B.Name.COLLAPSE && <Collapse {...(props as B.Collapse)} />}
            {props.name === B.Name.COD_SBOM_LAYOUT && <CodSBomLayout {...(props as B.CodSBomLayout)} />}
            {props.name === B.Name.TABLE_LIKE_LIST && <TableLikeList {...(props as B.TableLikeList)} />}
            {props.name === B.Name.SPLIT_PANE && <SplitPane {...(props as B.SplitPane)} />}
            {props.name === B.Name.AFFIX_LAYOUT && <AffixLayout {...(props as B.Affix)} />}
            {/* Voting Option */}
            {props.name === B.Name.SIMPLE_VOTING_OPTION && <SimpleVotingOption {...(props as B.SimpleVotingOption)} />}
            {props.name === B.Name.STANDARD_VOTING_OPTION && <StandardVotingOption {...(props as B.StandardVotingOption)} />}
            {props.name === B.Name.PORTRAIT_VOTING_OPTION && <PortraitVotingOption {...(props as B.PortraitVotingOption)} />}
            {/* Form Field */}
            {props.name === B.Name.FORM_FIELD_STRING && <FormFieldString {...(props as B.FormFieldString)} />}
            {props.name === B.Name.FORM_FIELD_LINK && <FormFieldLink {...(props as B.FormFieldLink)} />}
            {props.name === B.Name.FORM_FIELD_STRING_LONG && <FormFieldStringLong {...(props as B.FormFieldStringLong)} />}
            {props.name === B.Name.FORM_FIELD_PHONE && <FormFieldPhone {...(props as B.FormFieldPhone)} />}
            {props.name === B.Name.FORM_FIELD_NUMBER && <FormFieldNumber {...(props as B.FormFieldString)} />}
            {props.name === B.Name.FORM_FIELD_FILE_UPLOAD && <FileUploaderBlock {...(props as B.FileUploader)} />}
            {props.name === B.Name.FILE_LIST && <FileList {...(props as B.FileList)} />}
            {props.name === B.Name.ITEM_LIST && <ItemList {...(props as B.ItemList)} />}
            {props.name === B.Name.FORM_FIELD_RADIO_GROUP && <RadioGroup {...(props as B.FormFieldRadioGroup)} />}
            {props.name === B.Name.FORM_FIELD_RADIO && <FormFieldRadioButton {...(props as B.FormFieldRadio)} />}
            {props.name === B.Name.FORM_FIELD_CHECKBOX && <FormFieldCheckbox {...(props as B.FormFieldCheckbox)} />}
            {props.name === B.Name.FORM_FIELD_DROPDOWN && <FormFieldDropdown {...(props as B.FormFieldDropdown)} />}
            {props.name === B.Name.FORM_FIELD_INPUT_BOX && <FormFieldInputBox {...(props as B.FormFieldInputBox)} />}
            {props.name === B.Name.MAP && <Map {...(props as B.Map)} />}
            {props.name === B.Name.MAP_SEARCH && <MapSearchBar {...(props as B.MapSearch)} />}
            {/* Time & Date Pickers */}
            {props.name === B.Name.DATE_PICKER && <DatePicker {...(props as B.DatePicker)} />}
            {props.name === B.Name.TIME_PICKER && <TimePicker {...(props as B.TimePicker)} />}
            {props.name === B.Name.TIMELINE && <Timeline {...(props as B.Timeline)} />}

            {/* Dialog */}
            {props.name === B.Name.DIALOG_SMS_AUTHENTICATION && <SMSAuthentication {...(props as B.SMSAuthentication)} />}
            {props.name === B.Name.DIALOG_PIN_AUTHENTICATION && <PinAuthentication {...(props as B.PinAuthentication)} />}
            {props.name === B.Name.DIALOG_STEPPER && <DialogStepper {...(props as B.DialogStepper)} />}
            {props.name === B.Name.DIALOG_SWITCH && <Switch {...(props as B.Switch)} />}
            {props.name === B.Name.DIALOG_TIME_SWITCH && <TimeSwitch {...(props as B.TimeSwitch)} />}
            {/* Proposals */}
            {props.name === B.Name.PROPOSALS_LIST && <ProposalsListBlock {...(props as B.ProposalsList)} />}
            {/* Results */}
            {props.name === B.Name.STANDARD_RESULT_LIST && <StandardResultList {...(props as B.ResultList)} />}
            {props.name === B.Name.PORTRAIT_RESULT_LIST && <PortraitResultList {...(props as B.ResultList)} />}
            {props.name === B.Name.OVERLAP_PORTRAIT_RESULT_LIST && <OverlapPortraitResultList {...(props as B.OverlapResultList)} />}
            {/* List */}
            {props.name === B.Name.TEMPLATE_LIST && <TemplateList {...(props as B.TemplateList)} />}
            {props.name === B.Name.UNORDERED_LIST && <UnorderedList {...(props as B.UnorderedList)} />}
            {/* Menu */}
            {props.name === B.Name.MENU && <Menu {...(props as B.Menu)} />}
            {props.name === B.Name.MENU_ITEM && <MenuItem {...(props as B.MenuItem)} />}

            {/* Templating logic */}
            {props.name === B.Name.LOGIC_IF && <IfBlock {...(props as B.IfBlock)} />}
            {props.name === B.Name.LOGIC_FOREACH && <ForEachBlock {...(props as B.TemplateList)} />}
            {/* Dev */}
            {props.name === B.Name.DEV_PLAYGROUND && <DevPlayground />}

            {/* Customized */}
            {props.name === B.Name.VOLBY_JINAK_2020_OVERALL_RESULTS && <VolbyJinak2020OverallResults />}
            {/* Customized */}
            {props.name === B.Name.SCHOOL_PB_RESULTS_PANEL && <SchoolPbResultsPanel {...(props as B.SchoolPbResultsPanel)} />}
        </>
    );
};
export default Block;
