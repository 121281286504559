import { get as _get } from 'lodash';

import { Voting as VotingActions } from '../../../../core/action';
import { Store } from '../interfaces';

const reducer = (state: Store, payload: VotingActions.resultSetupCalculationMethod.Payload): Store => {
    return {
        ...state,
        result: {
            ...state.result,
            setup: {
                ...state.result.setup,
                [payload.r]: {
                    ..._get(state.result.setup, payload.r),
                    calculationMethod: payload.method,
                },
            },
        },
    };
};

export default reducer;
