import styled, { css } from 'styled-components';
import { Form as AntForm } from 'antd';
import hexToRgba from 'hex-to-rgba';

import { Config } from './index';
import { color, animation } from '../../../stylesheets/shared';
import { cssNoteP, cssP } from '../../../stylesheets/snippets';
import { ifHoverIsSupported } from '../../../stylesheets/tools';

const DEFAULT_TEXT_ALIGN = 'left';

export const FormItem = styled(AntForm.Item)`
    margin: 0;

    .ant-form-item-control-input {
        min-height: 0;

        .ant-checkbox-wrapper {
            display: inline-flex;
            /* checkbox */
            .ant-checkbox {
                .ant-checkbox-input {
                    width: 24px;
                    height: 24px;
                }
                .ant-checkbox-inner {
                    width: 24px;
                    height: 24px;
                    border-radius: 4px;
                    border-style: solid;
                    border-width: 1px;
                    border-color: ${color.grey2};
                    background-color: ${hexToRgba(color.blue, 0.04)};
                    transition: background-color ${animation.timing.interactive} ease-out;
                }

                /* turn off Ant checked effect */
                &:after {
                    display: none;
                }
            }
            ${ifHoverIsSupported(css`
                &:hover {
                    .ant-checkbox {
                        .ant-checkbox-inner {
                            background-color: ${hexToRgba(color.blue, 0.24)};
                        }
                    }
                }
            `)}
            .ant-checkbox {
                &.ant-checkbox-checked {
                    .ant-checkbox-inner {
                        border-color: ${color.blue};
                        background-color: ${color.blue};

                        &:after {
                            top: 47%;
                            left: 22%;
                            width: 7px;
                            height: 14px;
                        }
                    }
                }
            }

            /* label */
            span:nth-child(2) {
                padding: 0;
            }
        }
    }

    .ant-form-item-explain {
        ${cssNoteP};
        color: ${color.red};
        min-height: 0;
    }

    /* error */
    &.ant-form-item-has-error {
        .ant-checkbox {
            .ant-checkbox-inner {
                border-color: ${color.red} !important;
            }
        }
        .ant-checkbox-wrapper {
            ${ifHoverIsSupported(css`
                &:hover {
                    .ant-checkbox {
                        .ant-checkbox-inner {
                            background-color: ${hexToRgba(color.blue, 0.04)};
                        }
                    }
                }
            `)}
        }

        .ant-form-item-explain {
            ${cssNoteP};
            color: ${color.red};
        }
    }
`;

export const Label = styled.div`
    ${cssP};
    display: inline-block;
    padding: 0 0 0 12px;
`;

export const Checkbox = styled.div<{ config: Config }>`
    box-sizing: border-box;
    width: 100%;

    /* style override */
    ${({ config }) => config.styleOverride}

    ${FormItem} {
        .ant-form-item-explain {
            text-align: ${({ config }) => (config.align ? config.align : DEFAULT_TEXT_ALIGN)};
        }

        .ant-form-item-control-input .ant-checkbox-wrapper span:nth-child(2) {
            flex-shrink: 1;
        }
    }
`;
