import { isNil as _isNil, multiply as _multiply, toNumber as _toNumber } from 'lodash';

export default (val: any, params?: any): number => {
    if (_isNil(params)) {
        return val;
    }

    const multipliedResult = _multiply(val, params);
    return _toNumber(multipliedResult.toFixed(8));
};
