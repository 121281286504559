import { isNil as _isNil } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import 'intersection-observer';

import Application from './Application';
import { ErrorPage } from './pages/error';
import initializeConfiguration from '../configuration/initialize';
import configuration from '../configuration/configuration.json';
import { configure as configureStore, getStore } from './store';
import 'antd/dist/antd.css';

if (!_isNil(configuration.sentryDsn) && configuration.sentryDsn !== '') {
    Sentry.init({ dsn: configuration.sentryDsn });
} else {
    console.warn("Sentry DSN not found in the config. Errors won't be reported into sentry.io");
}
// throw new Error('sentry test: top level, with configuration.json');

// Google Tag Manager Setup
const tagManagerArgs = {
    gtmId: configuration.gtmId,
};

TagManager.initialize(tagManagerArgs);

// application rendering entry point
export default () => {
    // initialize entire configuration
    initializeConfiguration();

    // prepare initial redux store state
    configureStore();

    ReactDOM.render(
        <Sentry.ErrorBoundary fallback={() => <ErrorPage />}>
            <ReduxProvider store={getStore()}>
                <Application />
            </ReduxProvider>
        </Sentry.ErrorBoundary>,
        document.getElementById('root'),
    );
};
