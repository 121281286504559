import { isNil as _isNil } from 'lodash';

import { Modifier } from './interfaces';
import * as Name from '../name';
import multiplyBy from './multiply_by';
import getPinSetName from './school_pb/pin_set_name';
import getVoteCount from './school_pb/vote_count';
import getReadableDate from './readable_date';
import convertToString from './convert_to_string';
import readableTime from './readable_time';
import replace from './replace';
import negate from './negate';

export default (val?: any, m?: Modifier, ds?: any): any => {
    if (_isNil(m)) {
        return val;
    }

    if (m.name === Name.MULTIPLY_BY) {
        return multiplyBy(val, m.params);
    } else if (m.name === Name.SPB_PINSET_NAME) {
        return getPinSetName(val, ds);
    } else if (m.name === Name.SPB_VOTE_COUNT) {
        return getVoteCount(val);
    } else if (m.name === Name.READABLE_DATE) {
        return getReadableDate(val);
    } else if (m.name === Name.CONVERT_TO_STRING) {
        return convertToString(val);
    } else if (m.name === Name.READABLE_TIME) {
        return readableTime(val);
    } else if (m.name === Name.REPLACE) {
        return replace(val, m.params);
    } else if (m.name === Name.NEGATE) {
        return negate(val);
    }

    return val;
};
