import React from 'react';

import { BlockProps, VotesCount as VotesCountConfig, Tools, IconSets } from '../../../core/block';
import * as Styled from './styled';

export type Config = VotesCountConfig;
export type Props = BlockProps & Config;

const VotesCount = (props: Props) => {
    return (
        <Styled.VotesCount {...Tools.extractConfig<Config>(props)}>
            <Styled.Count>{props.count}</Styled.Count>
            <Styled.Cross name="" icon={IconSets.Material.CLEAR} />
            <Styled.IconWrapper>
                <Styled.Icon {...props.icon} />
            </Styled.IconWrapper>
        </Styled.VotesCount>
    );
};
export default VotesCount;
