import { get as _get, isNil as _isNil, max as _max, reduce as _reduce } from 'lodash';

import { StoreSelectorFunction } from '../../../../core/selector';
import { Choice, Question, Answer, Poll, Vote } from '../../../../core/voting';
import { RootState } from '../../root_reducer';
import { Store } from '../interfaces';

// get voting store
const getVotingStore = (store: RootState): Store => store.voting;

// poll respondents
export const pollRespondents = (): StoreSelectorFunction<number | undefined> => (rootStore: RootState): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    return store.config?.results?.respondents || 0;
};

// choice
export interface ChoiceSelectorPayload {
    q: string; // question id
    o: string; // option id
}
export const choice = (payload: ChoiceSelectorPayload): StoreSelectorFunction<Choice.Choice | undefined> => (
    rootStore: RootState,
): Choice.Choice | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    return _get(store.vote, [payload.q, payload.o]);
};

// choice meaning
export interface ChoiceMeaningSelectorPayload {
    q: string; // question id
    o: string; // option id
}
export const choiceMeaning = (payload: ChoiceMeaningSelectorPayload): StoreSelectorFunction<Choice.Meaning | undefined> => (
    rootStore: RootState,
): Choice.Meaning | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    const ch: Choice.Choice | undefined = choice(payload)(rootStore);
    return Choice.Tools.getChoiceMeaning(ch);
};

// compare choice
export interface CompareChoiceSelectorPayload {
    q: string; // question id
    o: string; // option id
    choice: Choice.Choice;
}
export const compareChoice = (payload: CompareChoiceSelectorPayload): StoreSelectorFunction<boolean> => (rootStore: RootState): boolean => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return false;
    }

    const chPayload: ChoiceSelectorPayload = { q: payload.q, o: payload.o };
    const ch: Choice.Choice | undefined = choice(chPayload)(rootStore);
    return ch === payload.choice;
};

// question validation
export interface QuestionValidationSelectorPayload {
    q: string; // question id
}
export const questionValidation = (payload: QuestionValidationSelectorPayload): StoreSelectorFunction<string | boolean> => (
    rootStore: RootState,
): string | boolean => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return false;
    }

    // standard question
    return _get(store.question.validationStatus, payload.q, false);
};

// remaining choices
export interface RemainingChoicesSelectorPayload {
    q: string; // question id
    meaning: Choice.Meaning;
}
export const remainingChoices = (payload: RemainingChoicesSelectorPayload): StoreSelectorFunction<number> => (rootStore: RootState): number => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return 0;
    }

    // get question configuration
    const config: Question.Config | undefined = _get(store.question.config, payload.q);
    if (_isNil(config)) {
        return 0;
    }

    const availableChoices = _get(config, [payload.meaning], 0);
    let usedChoices = 0;

    // get question answer
    const answer: Answer.Answer | undefined = _get(store.vote, payload.q);
    if (!_isNil(answer)) {
        usedChoices = _reduce(
            answer,
            (res: number, choice: Choice.Choice) => (Choice.Tools.getChoiceMeaning(choice) === payload.meaning ? res + 1 : res),
            0,
        );
    }

    return _max([0, availableChoices - usedChoices]) || 0;
};

// current step
export const currentStep = (): StoreSelectorFunction<string | undefined> => (rootStore: RootState): string | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    return store.step;
};

// current step index
export const currentStepIndex = (): StoreSelectorFunction<number | undefined> => (rootStore: RootState): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    return Poll.Tools.stepIndex(store.config?.flow, store.step);
};

// current step config
export const currentStepConfig = (): StoreSelectorFunction<Poll.FlowStep | undefined> => (rootStore: RootState): Poll.FlowStep | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    if (_isNil(store.step) || _isNil(store.config?.flow.steps)) {
        return undefined;
    }

    return _get(store.config?.flow.steps, store.step);
};

// previous step
export const previousStep = (): StoreSelectorFunction<string | undefined> => (rootStore: RootState): string | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    return Poll.Tools.getPreviousStep(store.config?.flow, store.step);
};

// number of steps
export const numberOfSteps = (): StoreSelectorFunction<number | undefined> => (rootStore: RootState): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    return Poll.Tools.numberOfSteps(store.config?.flow);
};

// config
export const config = (): StoreSelectorFunction<Poll.Poll | undefined> => (rootStore: RootState): Poll.Poll | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    return store.config;
};

// vote
export const vote = (): StoreSelectorFunction<Vote.Vote | undefined> => (rootStore: RootState): Vote.Vote | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    return store.vote;
};
