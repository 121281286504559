import React, { PropsWithChildren } from 'react';
import { isNil as _isNil, get as _get } from 'lodash';
import { CSSObject } from 'styled-components';
import { constant } from '../../stylesheets/shared';
import * as Styled from './styled';

export const getHeaderHeight = (headerStyleOverride?: CSSObject): string => {
    const defaultHeight = constant.websiteMenuHeight + 'px';
    if (!_isNil(headerStyleOverride)) {
        return '' + _get(headerStyleOverride, 'height', defaultHeight) + 'px';
    }
    return '' + defaultHeight + 'px';
};

export const PageHeader: React.FC<PropsWithChildren<Styled.HeaderProps>> = (props) => <Styled.Header {...props}>{props.children}</Styled.Header>;
