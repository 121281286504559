import React from 'react';

import { BlockProps, UnorderedList as UnorderedListConfig, Tools } from '../../../core/block';

import RepresentP from '../Typography/RepresentP';
import { map as _map } from 'lodash';
import { REPRESENT_P } from '../../../core/block/name';
import * as Styled from './styled';

export type Config = UnorderedListConfig;
export type Props = BlockProps & Config;

const getItems = (props: Props) => {
    return (
        <>
            {props.items.length > 0 &&
                _map(props.items, (item, index) => {
                    return (
                        <Styled.LI {...Tools.extractConfig<Config>(props)} key={item + index}>
                            <RepresentP text={item} useMarkdown={props.useMarkdown} name={REPRESENT_P} />
                        </Styled.LI>
                    );
                })}
        </>
    );
};

const UnorderedList = (props: Props) => {
    return <Styled.UL {...Tools.extractConfig<Config>(props)}>{getItems(props)}</Styled.UL>;
};
export default UnorderedList;
