import styled, { css } from 'styled-components';
import { Progress as AntProgress } from 'antd';
import { Config } from './index';
import { color, gradient, animation } from '../../stylesheets/shared';
import { cssDisableUserSelect } from '../../stylesheets/snippets';
import { ifHoverIsSupported } from '../../stylesheets/tools';

import IconComp from '../Icon';

export const DoneIcon = styled(IconComp)`
    position: absolute;
    top: -5px;
    left: 0;
    font-size: 24px;
    margin-right: 12px;
`;

type ButtonProps = {
    config: Config;
    isSelected: boolean;
};

export const Progress = styled(AntProgress)``;

export const Button = styled.a<ButtonProps>`
    height: 50px;
    min-width: 190px;
    display: flex;
    padding-right: 10px;
    border-radius: 12px;
    border-style: double;
    border-width: 1px;
    border-color: ${color.grey2};
    line-height:50px;
    box-sizing: border-box;
    text-decoration:none;
    background: ${color.white};
    ${cssDisableUserSelect};
    transition: transform ${animation.timing.interactive} ease-out;

    /* style override */
    ${(p: ButtonProps) => p.config.styleOverride}

    ${(p: ButtonProps) => {
        if (p.isSelected) {
            return css`
                border-color: #1890ff;
                border-width: 2px;
            `;
        }
    }}}

    ${ifHoverIsSupported(css`
        &:hover {
            background: ${gradient.sweetLight};
            text-decoration: none;
        }
    `)}

    &:active {
        text-decoration:none;
        transform: scale(1.05);
    }
`;

export const ProgressChartContainer = styled.div`
    position: relative;
    align-self: center;
    margin: 0px 15px 0px 5px;
`;
