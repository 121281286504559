import { combineReducers } from 'redux';

import { Action, Tools as ActionTools } from '../../core/action';
import voting from './voting/reducer';
import form from './form/reducer';
import application from './application/reducer';
import dialog from './dialog/reducer';
import planner from './planner/reducer';
import { proposalsReducer as proposals } from './proposals';
import schoolpb from './school_pb/reducer';

const rootReducer = combineReducers({
    application,
    voting,
    form,
    dialog,
    planner,
    proposals,
    schoolpb,
});

const combineReducersWithActionTargetCheck = (state: any = {}, action: Action<any>) => {
    // If action target is not for client, just return the state.
    // This is becasue we don't want allow client to process action synchronously if it's just for server ... but we want to see the action in Redux Dev Tools
    if (!ActionTools.isItForClient(action.target)) {
        return state;
    }

    return rootReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default combineReducersWithActionTargetCheck;
