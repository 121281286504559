import { isNil as _isNil, defer as _defer, map as _map, debounce as _debounce, isEqual as _isEqual } from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { Select as AntSelect } from 'antd';

import { IconSets } from '../../../../core/block';
import { BlockProps, FormFieldDropdown as FormFieldDropdownConfig, FormFieldInlets, DropdownItem, Tools } from '../../../../core/block';
import { useOutlets, useInlets } from '../../../hooks';
import { useStringValidation, ValidationResult, VALIDATION_RESULT_UNTOUCHED, getOutletValidationStatus } from '../../../hooks/useValidation';
import * as Styled from './styled';

export type Config = FormFieldDropdownConfig;
export type Props = BlockProps &
    Config & {
        onChange?: (value: string, validationResult: ValidationResult) => void;
    };

export const FormFieldDropdown: React.FC<Props> = (props: Props) => {
    const outlet = useOutlets();
    const inlets = useInlets(props.inlets) as FormFieldInlets;
    const validate = useStringValidation(props.validationScheme || {});
    const [validationResult, setValidationResult] = useState(VALIDATION_RESULT_UNTOUCHED);
    const [cachedValue, setCachedValue] = useState(props.defaultValue);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const processChangeOutlet = useCallback(
        _debounce((value: string, validationResult: ValidationResult) => {
            if (_isNil(props.outlets?.change)) {
                return;
            }

            const outletParameters = {
                value,
                validationStatus: getOutletValidationStatus(validationResult),
            };

            _defer(() => outlet(props.outlets?.change, outletParameters));
        }, 150),
        [],
    );

    const processChange = (value: string, validationResult: ValidationResult) => {
        if (_isNil(props.onChange)) {
            return;
        }

        props.onChange(value, validationResult);
    };

    const processValidation = async (value: string): Promise<ValidationResult> => {
        const validationResult = await validate(value);
        setValidationResult(validationResult);

        return validationResult;
    };

    // TODO possible candidate for refactoring into useInletValue()
    useEffect(() => {
        // we are not expecting any inlet
        if (_isNil(props.inlets)) {
            return;
        }

        // is inlet already resolved?
        if (Tools.isInletResolved(inlets.value)) {
            // ... yes value is defined, and it's different then cached value -> use it
            if (!_isNil(inlets.value) && !_isEqual(inlets.value, cachedValue)) {
                // use it as cached value ...
                setCachedValue(inlets.value);
                // ... and validate the value
                processValidation(inlets.value).then((validationResult) => {
                    // process all changes
                    processChangeOutlet(inlets.value, validationResult);
                    processChange(inlets.value, validationResult);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets.value)]);

    const handleChange = (value: any) => {
        // store it as local value
        setCachedValue(value);

        // validate the value
        processValidation(value).then((validationResult) => {
            // process all changes
            processChangeOutlet(value, validationResult);
            processChange(value, validationResult);
        });
    };

    const onDropdownVisibleChange = (open: boolean) => {
        setDropdownVisible(open);
    };

    return (
        <Styled.Dropdown {...Tools.extractConfig<Config>(props)}>
            {/* label */}
            {!_isNil(props.text) && <Styled.Label>{props.text}</Styled.Label>}

            <Styled.FormItem
                hasFeedback={!_isNil(props.validationScheme)}
                validateStatus={validationResult.antValidationStatus}
                help={validationResult.message}
            >
                <Styled.SelectDropdownStyles />
                <Styled.Select
                    autoFocus={props.autoFocus}
                    allowClear={props.allowClear}
                    value={cachedValue}
                    showSearch={props.allowFiltering}
                    optionFilterProp="children"
                    filterOption={true}
                    placeholder={props.placeholder}
                    suffixIcon={<Styled.Icon name="" icon={IconSets.Material.EXPAND_MORE} rotate={dropdownVisible} />}
                    onChange={handleChange}
                    onDropdownVisibleChange={onDropdownVisibleChange}
                >
                    {_map(props.items, (item: DropdownItem) => (
                        <AntSelect.Option value={item.value} key={item.value}>
                            {item.label}
                        </AntSelect.Option>
                    ))}
                </Styled.Select>
            </Styled.FormItem>
        </Styled.Dropdown>
    );
};
