import hexToRgba from 'hex-to-rgba';

export const font = {
    family: `'Open Sans', sans-serif`,
};

export const color = {
    black: '#111111',
    grey1: '#5C5C5C',
    grey2: '#ADADAD',
    grey3: '#E0E0E0',
    grey4: '#EDEDED',
    white: '#FFFFFF',
    green: '#28C940',
    red: '#FB2E3F',
    blue: '#263DBA',
    violet: '#482BB0',
};

export const meaningColor = {
    plus: color.blue,
    minus: color.red,
};

export const gradient = {
    sweet: `linear-gradient(to right, ${color.blue}, #6A19A6)`,
    sweetLigher: `linear-gradient(to right, ${hexToRgba(color.blue, 0.24)}, ${hexToRgba('#6A19A6', 0.32)})`,
    sweetLight: `linear-gradient(to right, ${hexToRgba(color.blue, 0.12)}, ${hexToRgba('#6A19A6', 0.06)})`,
};

export const shadow = {
    standard: `0px 2px 4px 0px rgba(0,0,0,0.24);`,
};

export const animation = {
    timing: {
        standart: '250ms',
        interactive: '150ms',
    },
};

export const resolution = {
    tablet: '800px',
    phone: '480px',
    verySmallPhone: '415px',
};

export const constant = {
    page: {
        size: {
            standard: 680,
            wide: 1080,
        },
    },
    question: {
        standard: {
            width: 680,
        },
        wide: {
            width: 1080,
        },
    },
    websiteMenuHeight: 48,
};
