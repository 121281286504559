import { isNil as _isNil } from 'lodash';
import React from 'react';
import MarkdownIt from 'markdown-it';

import { BlockProps, VoteButton as VoteButtonConfig, VoteButtonInlets, Tools } from '../../../core/block';
import { useInlets, useOutlets } from '../../hooks';
import * as Styled from './styled';

export type Config = VoteButtonConfig;
export type Props = BlockProps & Config;

const md = new MarkdownIt({
    html: false,
    xhtmlOut: true,
    breaks: true,
    linkify: true,
});

const VoteButton = (props: Props) => {
    const usedInlets = useInlets(props.inlets) as VoteButtonInlets;
    const outlet = useOutlets();

    const onClick = () => {
        if (_isNil(props.outlets?.click)) {
            console.warn(`Click outlet is not configured`);
            return;
        }

        outlet(props.outlets?.click);
    };

    return (
        <Styled.Button {...Tools.extractConfig<Config>(props)} usedInlets={usedInlets} onClick={onClick}>
            {/* icon */}
            {!_isNil(props.icon) && <Styled.Icon {...props.icon} />}
            {/* text */}
            {!_isNil(props.text) && (
                <>
                    {/* simple text -> bold */}
                    {!props.useMarkdown && <Styled.Label>{props.text}</Styled.Label>}
                    {/* markdown text */}
                    {props.useMarkdown === true && <Styled.MDLabel dangerouslySetInnerHTML={{ __html: md.renderInline(props.text) }} />}
                </>
            )}
        </Styled.Button>
    );
};
export default VoteButton;
