import styled, { css } from 'styled-components';
import { isNil as _isNil } from 'lodash';

import { Config } from './index';
import { color } from '../../stylesheets/shared';
import { onTablet, onPhone } from '../../stylesheets/tools';

export const Image = styled.img<{ config: Config }>`
    max-width: 100%;
    cursor: ${({ config }) => (_isNil(config.outlets?.click) ? 'default' : 'pointer')};

    /* style override */
    ${({ config }) => config.styleOverride}

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;

export const ImageBorder = styled.div<{ config: Config }>`
    border: 1px solid ${color.grey3};
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    ${Image} {
        width: 100%;
        cursor: ${({ config }) => (_isNil(config.outlets?.click) ? 'default' : 'pointer')};
    }

    /* Increase styles' specificity with && to beat the horizontal layout's margin */
    && {
        ${({ config }) => config.styleOverride}
    }

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;
