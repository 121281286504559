import { toString as _toString, isNil as _isNil } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import MarkdownIt from 'markdown-it';

import { BlockProps, PrimaryCTA as PrimaryCTAConfig, Tools, PrimaryCTAInlets, IconSets } from '../../../core/block';
import { Tools as ValidationTools } from '../../../core/validation';
import { translate as t } from '../../../core/translation';
import { useInlets, useOutlets } from '../../hooks';
import { currentLanguage } from '../../store/application/selectors';
import Loading from '../../components/Loading';
import CompactLoading from '../../components/CompactLoading';
import * as Styled from './styled';

export type Config = PrimaryCTAConfig;
export type Props = BlockProps &
    Config & {
        inProgress?: boolean;
        onClick?: () => void;
    };

const md = new MarkdownIt({
    html: false,
    xhtmlOut: true,
    breaks: true,
    linkify: true,
});

const PrimaryCTA = (props: Props) => {
    const usedInlets = useInlets(props.inlets) as PrimaryCTAInlets;
    const outlet = useOutlets();
    const currLang = useSelector(currentLanguage());
    const [buttonRef, buttonInView] = useInView();
    const [showMarkerValidation, setShowMarkerValidation] = useState<boolean>(false);
    const [displayInProgress, setDisplayInProgress] = useState<boolean>(false);

    useEffect(() => {
        // automatically hide marker validation when status is valid
        if (ValidationTools.isStatusValid(usedInlets.validationStatus) && showMarkerValidation) {
            setShowMarkerValidation(false);
        }
    }, [showMarkerValidation, usedInlets.validationStatus]);

    const hasValidationMessage: boolean = ValidationTools.hasStatusMessage(usedInlets.validationStatus);
    const shouldMarkerValidationBeVisible = showMarkerValidation && hasValidationMessage;
    const isInProgress: boolean = displayInProgress === true || props.inProgress === true;

    const processClickOutlet = async () => {
        if (_isNil(props.outlets?.click)) {
            return;
        }

        setDisplayInProgress(true);
        await outlet(props.outlets?.click);
        setDisplayInProgress(false);
    };

    const onMarker = () => {
        if (isInProgress) {
            return;
        }

        // should we care about validation satus?
        if (!_isNil(usedInlets.validationStatus)) {
            // is invalid?
            if (ValidationTools.isStatusInvalid(usedInlets.validationStatus)) {
                setShowMarkerValidation(!showMarkerValidation);
                return;
            }
        }

        processClickOutlet();

        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    const onButton = () => {
        if (isInProgress) {
            return;
        }

        // should we care about validation satus?
        if (!_isNil(usedInlets.validationStatus)) {
            // is invalid?
            if (ValidationTools.isStatusInvalid(usedInlets.validationStatus)) {
                return;
            }
        }

        processClickOutlet();

        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <>
            <Styled.ButtonWrapper>
                {/* button */}
                <Styled.Button {...Tools.extractConfig<Config>(props)} usedInlets={usedInlets} ref={buttonRef} onClick={onButton}>
                    {/* icon */}
                    {props.icon && (
                        <Styled.IconWrapper>
                            <Styled.Icon {...props.icon} />
                        </Styled.IconWrapper>
                    )}

                    {/* simple text */}
                    {!props.useMarkdown && <Styled.Label>{props.text}</Styled.Label>}
                    {/* markdown text */}
                    {props.useMarkdown && <Styled.Label dangerouslySetInnerHTML={{ __html: md.renderInline(props.text) }} />}

                    {/* loading */}
                    {isInProgress && (
                        <Styled.LoadingWrapper>
                            <Loading />
                        </Styled.LoadingWrapper>
                    )}
                </Styled.Button>

                {/* validation */}
                {hasValidationMessage && (
                    <Styled.ButtonValidation>
                        <Styled.ButtonValidationIcon name="" icon={IconSets.Material.ERROR_OUTLINE} />
                        <Styled.ButtonValidationMessage>{t(_toString(usedInlets.validationStatus), currLang)}</Styled.ButtonValidationMessage>
                    </Styled.ButtonValidation>
                )}
            </Styled.ButtonWrapper>

            {/* marker */}
            {props.useMarker === true && (
                <Styled.Marker hide={buttonInView} containsMessage={shouldMarkerValidationBeVisible}>
                    {/* validation */}
                    {shouldMarkerValidationBeVisible && (
                        <Styled.MarkerValidation>
                            <Styled.MarkerValidationIcon name="" icon={IconSets.Material.ERROR_OUTLINE} />
                            <Styled.MarkerValidationMessage>{t(_toString(usedInlets.validationStatus), currLang)}</Styled.MarkerValidationMessage>
                        </Styled.MarkerValidation>
                    )}

                    {/* button */}
                    <Styled.MarkerButton {...Tools.extractConfig<Config>(props)} usedInlets={usedInlets} onClick={onMarker}>
                        {/* icon */}
                        {props.icon && <Styled.MarkerIcon {...props.icon} />}

                        {/* loading */}
                        {isInProgress && (
                            <Styled.MarkerLoadingWrapper>
                                <CompactLoading />
                            </Styled.MarkerLoadingWrapper>
                        )}
                    </Styled.MarkerButton>
                </Styled.Marker>
            )}
        </>
    );
};
export default PrimaryCTA;
