import { Store } from '../interfaces';
import { Results } from '../../../../core/voting';

const reducer = (state: Store): Store => {
    const flow: Results.Flow = state.resultsConfig?.flow || { start: '' };

    return {
        ...state,
        resultsStep: Results.Tools.getFlowStart(flow),
    };
};

export default reducer;
