import { toString as _toString, isNil as _isNil, get as _get, defer as _defer, map as _map, trim as _trim, replace as _replace } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux';

import {
    BlockProps,
    Tools,
    SMSAuthentication as SMSAuthenticationConfig,
    FormFieldPhone as FormFieldPhoneConfig,
    FormFieldString as FormFieldStringConfig,
    SecondaryCTA as SecondaryCTAConfig,
    HorizontalAlign,
} from '../../../../core/block';
import { currentLanguage } from '../../../store/application/selectors';
import { formFieldValue } from '../../../store/form/selectors';
import { FieldValue } from '../../../store/form/interfaces';
import { RootState } from '../../../store/root_reducer';
import { translate as t } from '../../../../core/translation';
import { create as createAction, Form as FormActions } from '../../../../core/action';
import HorizontalSpace from '../../HorizontalSpace';
import SecondaryCTA from '../../SecondaryCTA';
import LinkButton from '../../LinkButton';
import StringField from '../../FormField/String';
import PhoneField from '../../FormField/Phone';
import RepresentP from '../../Typography/RepresentP';
import { useOutlets } from '../../../hooks';
import { replaceSubstringByComponent } from '../../../tools';
import * as Styled from './styled';

// TEMP
import firebase from 'firebase/app';
import 'firebase/auth';
// TEMP

export type Config = SMSAuthenticationConfig;
export type Props = BlockProps & Config;

const STEP_PHONE = 'phone';
const STEP_CODE = 'code';

const PHONE_FORM_ID = 'sms_authentication_phone_form';
const PHONE_FIELD_NAME = 'phone';

const CODE_FORM_ID = 'sms_authentication_code_form';
const CODE_FIELD_NAME = 'code';

// TEMP
let phoneConfirmationResult: firebase.auth.ConfirmationResult | undefined;
let recaptchaVerifier: firebase.auth.RecaptchaVerifier | undefined;

const SMSAuthentication = (props: Props) => {
    const dispatch = useDispatch();
    const outlet = useOutlets();
    const [phoneSendError, setPhoneSendError] = useState<string>('');
    const [phoneFormInProgress, setPhoneFormInProgress] = useState<boolean>(false);
    const [codeFormInProgress, setCodeFormInProgress] = useState<boolean>(false);
    const [codeVerificationError, setCodeVerificationError] = useState<string>('');
    const [step, setStep] = useState<string>(STEP_PHONE);
    const rootStore = useStore<RootState>();
    const currLang = useSelector(currentLanguage());

    const getPhoneNumber = (): FieldValue => formFieldValue({ f: PHONE_FORM_ID, fn: PHONE_FIELD_NAME })(rootStore.getState());
    const getCode = (): FieldValue => formFieldValue({ f: CODE_FORM_ID, fn: CODE_FIELD_NAME })(rootStore.getState());

    ///////////////////////////////////
    // phone
    const phoneFieldConfig: FormFieldPhoneConfig = {
        name: '',
        defaultPhoneCode: props.defaultPhoneCode,
        filterPhoneCodes: props.filterPhoneCodes,
        align: HorizontalAlign.CENTER,
        autoFocus: props.autoFocus,
        styleOverride: { maxWidth: '300px' },
        validationScheme: {
            phone: [t('PHONE_NUMBER_MUST_BE_IN_CORRECT_FORMAT', currLang)],
            min: [9, t('PHONE_NUMBER_MUST_BE_IN_CORRECT_FORMAT', currLang)],
            required: [' '],
        },
        outlets: {
            change: {
                type: '@FORM:field_change',
                payload: { f: PHONE_FORM_ID, fn: PHONE_FIELD_NAME, vs: '{{validationStatus}}', v: '{{value}}' },
            },
        },
    };
    const phoneSendMeACodeConfig: SecondaryCTAConfig = {
        name: '',
        text: t('SEND_ME_A_CODE', currLang),
        icon: { name: '', icon: 'sms' },
        inlets: {
            validationStatus: { type: '#FORM:form_validation', payload: { f: PHONE_FORM_ID } },
        },
    };

    // code
    const codeFieldConfig: FormFieldStringConfig = {
        name: '',
        placeholder: '123456',
        align: HorizontalAlign.CENTER,
        autoFocus: true, // we assume here that auto-focus in code input make sense to be true automatically, because it's part of single workflow
        styleOverride: { maxWidth: '256px' },
        validationScheme: {
            length: [6, ' '],
            required: [' '],
        },
        outlets: {
            change: {
                type: '@FORM:field_change',
                payload: { f: CODE_FORM_ID, fn: CODE_FIELD_NAME, vs: '{{validationStatus}}', v: '{{value}}' },
            },
        },
    };
    const codeEnterThePollConfig: SecondaryCTAConfig = {
        name: '',
        text: t('ENTER_THE_POLL', currLang),
        icon: { name: '', icon: 'keyboard_tab' },
        inlets: {
            validationStatus: { type: '#FORM:form_validation', payload: { f: CODE_FORM_ID } },
        },
    };
    ///////////////////////////////////

    useEffect(() => {
        recaptchaVerifier = undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createRecaptchaVerifier = (): firebase.auth.RecaptchaVerifier => {
        if (_isNil(recaptchaVerifier)) {
            recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' });
        }

        return recaptchaVerifier;
    };

    // TODO ... move it to separate library
    const convertStringToValidPhoneNumber = (s: string): string => {
        let phoneNumber = _trim(s);
        phoneNumber = _replace(phoneNumber, /\s/g, '');
        return phoneNumber;
    };

    const sendTheCode = () => {
        setPhoneFormInProgress(true);

        const phoneNumber: string = convertStringToValidPhoneNumber(_toString(getPhoneNumber()));

        firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, createRecaptchaVerifier())
            .then((confirmationResult: firebase.auth.ConfirmationResult) => {
                setPhoneSendError('');
                phoneConfirmationResult = confirmationResult;
                setStep(STEP_CODE);
                setPhoneFormInProgress(false);
            })
            .catch((error: any) => {
                setPhoneSendError(_get(error, 'message'));
                console.error(`Cannot send the SMS because ${JSON.stringify(error)}`);
                setPhoneFormInProgress(false);
            });
    };

    const processSuccess = () => {
        phoneConfirmationResult = undefined;
        recaptchaVerifier = undefined;
        dispatch(createAction(FormActions.clearForm.TYPE, PHONE_FORM_ID));
        dispatch(createAction(FormActions.clearForm.TYPE, CODE_FORM_ID));

        if (!_isNil(props.outlets?.success)) {
            outlet(props.outlets?.success);
        }
    };

    const onSendMeACode = () => {
        if (phoneFormInProgress === true) {
            return;
        }

        setPhoneSendError('');
        sendTheCode();
    };

    const onEnterThePoll = () => {
        setCodeVerificationError('');
        setCodeFormInProgress(true);

        if (_isNil(phoneConfirmationResult)) {
            console.error(`"phoneConfirmationResult" object is not defined`);
            setCodeVerificationError(t('WE_CANNOT_CONFIRM_THE_SMS_CODE_RIGHT_NOW', currLang));
            setCodeFormInProgress(false);
            return;
        }

        phoneConfirmationResult
            .confirm(_toString(getCode()))
            .then(() => {
                setCodeVerificationError('');
                console.log('The verification code is valid.');
                console.log('SMS authentication success.');
                setCodeFormInProgress(false);
                _defer(() => processSuccess());
            })
            .catch((error: any) => {
                setCodeVerificationError(t('THE_VERIFICATION_CODE_IS_INVALID', currLang));
                console.error(JSON.stringify(error));
                setCodeFormInProgress(false);
            });
    };

    const onUseDiferentPhoneNumber = () => {
        dispatch(createAction(FormActions.clearForm.TYPE, CODE_FORM_ID));
        setStep(STEP_PHONE);
    };

    const onSendMeACodeAgain = () => {
        if (phoneFormInProgress === true) {
            return;
        }

        setPhoneSendError('');
        setCodeVerificationError('');
        sendTheCode();
    };

    const onPhoneFormSubmit = () => {
        onSendMeACode();
    };

    const onCodeFormSubmit = () => {
        onEnterThePoll();
    };

    return (
        <Styled.Dialog {...Tools.extractConfig<Config>(props)}>
            <Styled.RecaptchaContainer id="recaptcha-container" />

            {/* Phone */}
            {step === STEP_PHONE && (
                <>
                    <RepresentP name="" text={t('ENTER_YOUR_PHONE_NUMBER', currLang)} />
                    <HorizontalSpace name="" size="12px" />
                    <PhoneField {...phoneFieldConfig} onSubmit={onPhoneFormSubmit} />
                    {phoneSendError !== '' && <Styled.ErrorMessage>{phoneSendError}</Styled.ErrorMessage>}
                    <HorizontalSpace name="" size="8px" />
                    <SecondaryCTA {...phoneSendMeACodeConfig} inProgress={phoneFormInProgress} onClick={onSendMeACode} />
                </>
            )}

            {/* Code */}
            {step === STEP_CODE && (
                <>
                    <Styled.CompoundMessageWrapper>
                        {_map(
                            replaceSubstringByComponent(
                                t('ENTER_THE_CODE_WE_SENT', currLang),
                                '{{PHONE}}',
                                <Styled.PhoneNumber>{getPhoneNumber()}</Styled.PhoneNumber>,
                            ),
                            (i: any, iIdx: number) => (
                                <React.Fragment key={iIdx}>{i}</React.Fragment>
                            ),
                        )}
                    </Styled.CompoundMessageWrapper>
                    <HorizontalSpace name="" size="12px" />
                    <StringField {...codeFieldConfig} onSubmit={onCodeFormSubmit} />
                    {codeVerificationError !== '' && <Styled.ErrorMessage>{codeVerificationError}</Styled.ErrorMessage>}
                    <HorizontalSpace name="" size="8px" />
                    <SecondaryCTA {...codeEnterThePollConfig} inProgress={codeFormInProgress} onClick={onEnterThePoll} />
                    <HorizontalSpace name="" size="16px" />
                    <LinkButton name="" text={t('SEND_ME_A_CODE_AGAIN', currLang)} onClick={onSendMeACodeAgain} />
                    <HorizontalSpace name="" size="16px" />
                    <LinkButton name="" text={t('USE_DIFFERENT_PHONE_NUMBER', currLang)} onClick={onUseDiferentPhoneNumber} />
                </>
            )}
        </Styled.Dialog>
    );
};
export default SMSAuthentication;
