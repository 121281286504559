import { isNil as _isNil } from 'lodash';
import React from 'react';
import MarkdownIt from 'markdown-it';

import { BlockProps, LinkButton as LinkButtonConfig, Tools } from '../../../core/block';
import { useOutlets } from '../../hooks';
import * as Styled from './styled';

export type Config = LinkButtonConfig;
export type Props = BlockProps &
    Config & {
        onClick?: () => void;
    };

const md = new MarkdownIt({
    html: false,
    xhtmlOut: true,
    breaks: true,
    linkify: true,
});

const LinkButton = (props: Props) => {
    const outlet = useOutlets();

    const onClick = () => {
        if (!_isNil(props.outlets?.click)) {
            outlet(props.outlets?.click);
        }

        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <Styled.Button {...Tools.extractConfig<Config>(props)} onClick={onClick}>
            {/* simle text */}
            {!props.useMarkdown && <Styled.Label>{props.text}</Styled.Label>}
            {/* markdown text */}
            {props.useMarkdown && <Styled.Label dangerouslySetInnerHTML={{ __html: md.render(props.text) }} />}
        </Styled.Button>
    );
};
export default LinkButton;
