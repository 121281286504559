import styled from 'styled-components';
import { Form as AntForm } from 'antd';
import hexToRgba from 'hex-to-rgba';

import { Config } from './index';
import { color } from '../../../stylesheets/shared';
import { cssNoteP, cssP, cssInput } from '../../../stylesheets/snippets';

const DEFAULT_TEXT_ALIGN = 'left';

export const FormItem = styled(AntForm.Item)`
    margin: 0;

    .ant-input {
        ${cssInput};
        background-color: ${color.white};
        border-color: ${color.grey2};
        border-radius: 12px;
        padding: 5px 12px;
        appearance: none;

        &:hover {
            border-color: ${hexToRgba(color.blue, 0.48)};
        }
        &:focus {
            box-shadow: none;
            border-color: ${color.blue};
        }
    }

    .ant-form-item-explain {
        ${cssNoteP};
        color: ${color.red};
        min-height: 0;
    }

    /* error */
    &.ant-form-item-has-error {
        .ant-input {
            border-color: ${color.red};

            &:focus {
                border-color: ${color.red};
            }
        }

        .ant-form-item-explain {
            ${cssNoteP};
            color: ${color.red};
        }
    }
`;

export const Label = styled.div`
    ${cssP};
    font-weight: bold;
    margin-bottom: 2px;
`;

export const String = styled.div<{ config: Config }>`
    box-sizing: border-box;
    width: 100%;

    /* style override */
    ${({ config }) => config.styleOverride}

    ${Label} {
        text-align: ${({ config }) => (config.align ? config.align : DEFAULT_TEXT_ALIGN)};
    }

    ${FormItem} {
        .ant-input {
            text-align: ${({ config }) => (config.align ? config.align : DEFAULT_TEXT_ALIGN)};
        }
        .ant-form-item-explain {
            text-align: ${({ config }) => (config.align ? config.align : DEFAULT_TEXT_ALIGN)};
        }
        &.ant-form-item-has-feedback {
            .ant-form-item-children-icon {
                right: -32px;
            }
        }
    }
`;

export const MaxCharsMessage = styled.div`
    ${cssNoteP}
    color: ${color.grey1}
`;
