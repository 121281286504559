import { isNil as _isNil } from 'lodash';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { cssMaterialIcon } from '../../../stylesheets/snippets';
import MaterialIcons from '../../../../core/block/icon_sets/material';

export const StyledIcon = styled.i`
    ${cssMaterialIcon}
    transform: rotate(-45deg);
`;

type Props = HTMLAttributes<HTMLElement> & {
    onClick?: () => void;
};

const Icon = (props: Props) => {
    const onClick = () => {
        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };
    return (
        <StyledIcon onClick={onClick} className={props.className}>
            {MaterialIcons.INSERT_LINK}
        </StyledIcon>
    );
};

export default Icon;
