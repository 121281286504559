import { SchoolPb as SchoolPbActions } from '../../../../core/action';
import { Store } from '../interfaces';
import { isNil as _isNil } from 'lodash';

const reducer = (state: Store, payload: SchoolPbActions.addProposal.Payload): Store => {
    if (!_isNil(payload.proposal) && payload.proposal.length !== 0) {
        const newProposals = state.proposals;
        newProposals.push(payload.proposal);
        const newState: Store = {
            ...state,
            proposals: newProposals,
        };
        return newState;
    }
    return state;
};

export default reducer;
