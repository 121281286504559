import { StoreSelectorFunction, Selector } from '../../core/selector';
import { PollSelectors, ResultsSelectors } from './voting/selectors';
import * as VotingSelectors from './voting/selectors';
import * as SchoolPBSelectors from './school_pb/selectors';
import * as FormSelectors from './form/selectors';
import * as DialogSelectors from './dialog/selectors';
import * as PlannerSelectors from './planner/selectors';
import * as ApplicationSelectors from './application/selectors';

import { RootState } from './root_reducer';
import _get from 'lodash/get';

export enum InletType {
    STORE,
    LOCATION,
}

export type SelectorDescriptor = { runnable: StoreSelectorFunction<any>; type: InletType };

const makeStoreSelector = <T = any>(StoreSelectorFunction: StoreSelectorFunction<T>): SelectorDescriptor => {
    return { runnable: StoreSelectorFunction, type: InletType.STORE };
};

const makeLocationSelector = <T = any>(StoreSelectorFunction: StoreSelectorFunction<T>): SelectorDescriptor => {
    return { runnable: StoreSelectorFunction, type: InletType.LOCATION };
};

const genericSelector = <T = any>(payload: { path: string }) => (rootState: RootState): T => {
    const val = _get(rootState, payload.path);
    return val;
};

const selectorMapping: Record<string, SelectorDescriptor> = {
    // school pb
    [SchoolPBSelectors.Types.TASKS_CHANGE]: makeStoreSelector(SchoolPBSelectors.tasksChange),
    [SchoolPBSelectors.Types.TASK_VALUE_CHANGE]: makeStoreSelector(SchoolPBSelectors.tasksValueChange),
    [SchoolPBSelectors.Types.COORDINATOR_EMAIL]: makeStoreSelector(SchoolPBSelectors.coordinatorEmail),
    [SchoolPBSelectors.Types.COORDINATOR_NAME]: makeStoreSelector(SchoolPBSelectors.coordinatorName),
    [SchoolPBSelectors.Types.MAX_PARTICULAR_BUDGET]: makeStoreSelector(SchoolPBSelectors.maxParticularBudget),
    [SchoolPBSelectors.Types.TOTAL_BUDGET]: makeStoreSelector(SchoolPBSelectors.totalBudget),
    [SchoolPBSelectors.Types.VOTING_START_DATE]: makeStoreSelector(SchoolPBSelectors.votingStartDate),
    [SchoolPBSelectors.Types.VOTING_END_DATE]: makeStoreSelector(SchoolPBSelectors.votingEndDate),
    [SchoolPBSelectors.Types.WORKSHOP_START_DATE]: makeStoreSelector(SchoolPBSelectors.workshopStartDate),
    [SchoolPBSelectors.Types.WORKSHOP_START_TIME]: makeStoreSelector(SchoolPBSelectors.workshopStartTime),
    [SchoolPBSelectors.Types.WORKSHOP_VENUE]: makeStoreSelector(SchoolPBSelectors.workshopVenue),
    [SchoolPBSelectors.Types.RESULTS_ANNOUNCEMENT_DATE]: makeStoreSelector(SchoolPBSelectors.resultsAnnouncementDate),
    [SchoolPBSelectors.Types.PROPOSALS_APPROVING_START_DATE]: makeStoreSelector(SchoolPBSelectors.proposalsApprovingStartDate),
    [SchoolPBSelectors.Types.PROPOSALS_APPROVING_END_DATE]: makeStoreSelector(SchoolPBSelectors.proposalsApprovingEndDate),
    [SchoolPBSelectors.Types.CAMPAIGN_START_DATE]: makeStoreSelector(SchoolPBSelectors.campaignStartDate),
    [SchoolPBSelectors.Types.CAMPAIGN_END_DATE]: makeStoreSelector(SchoolPBSelectors.campaignEndDate),
    [SchoolPBSelectors.Types.PROPOSALS]: makeStoreSelector(SchoolPBSelectors.proposals),
    [SchoolPBSelectors.Types.PROPOSAL_COUNT]: makeStoreSelector(SchoolPBSelectors.proposalsCount),
    [SchoolPBSelectors.Types.PIN_SETS]: makeStoreSelector(SchoolPBSelectors.pinSets),
    [SchoolPBSelectors.Types.PIN_COUNT]: makeStoreSelector(SchoolPBSelectors.pinsCount),
    [SchoolPBSelectors.Types.PIN_COUNT_PER_CLASS]: makeStoreSelector(SchoolPBSelectors.pinsPerClass),
    [SchoolPBSelectors.Types.CLASS_COUNT]: makeStoreSelector(SchoolPBSelectors.classCount),
    [SchoolPBSelectors.Types.VOTING_LAUNCHED]: makeStoreSelector(SchoolPBSelectors.votingLaunched),
    [SchoolPBSelectors.Types.FOREWORD]: makeStoreSelector(SchoolPBSelectors.foreword),
    [SchoolPBSelectors.Types.NAME]: makeStoreSelector(SchoolPBSelectors.name),
    [SchoolPBSelectors.Types.IS_PROPOSAL_WINNER]: makeStoreSelector(SchoolPBSelectors.isProposalWinner),
    [SchoolPBSelectors.Types.WINNERS]: makeStoreSelector(SchoolPBSelectors.winners),

    // voting
    [VotingSelectors.Types.POLL_RESPONDENTS]: makeStoreSelector(PollSelectors.pollRespondents),
    [VotingSelectors.Types.COMPARE_CHOICE]: makeStoreSelector(PollSelectors.compareChoice),
    [VotingSelectors.Types.CHOICE_MEANING]: makeStoreSelector(PollSelectors.choiceMeaning),
    [VotingSelectors.Types.QUESTION_VALIDATION]: makeStoreSelector(PollSelectors.questionValidation),
    [VotingSelectors.Types.REMAINING_CHOICES]: makeStoreSelector(PollSelectors.remainingChoices),
    [VotingSelectors.Types.RESULT_VALUES]: makeStoreSelector(ResultsSelectors.resultValues),
    [VotingSelectors.Types.RESULT_OVERLAP]: makeStoreSelector(ResultsSelectors.resultOverlap),
    [VotingSelectors.Types.RESULT_RESPONDENTS]: makeStoreSelector(ResultsSelectors.resultRespondents),
    [VotingSelectors.Types.RESULT_RESPONDENTS_IN_PERCENTAGE]: makeStoreSelector(ResultsSelectors.resultRespondentsInPercentage),
    [VotingSelectors.Types.RESULTS_RESPONDENTS]: makeStoreSelector(ResultsSelectors.resultsRespondents),
    [VotingSelectors.Types.RESULTS_PLUS_VOTES_COUNT]: makeStoreSelector(ResultsSelectors.resultsPlusVotesCount),
    [VotingSelectors.Types.RESULTS_MINUS_VOTES_COUNT]: makeStoreSelector(ResultsSelectors.resultsMinusVotesCount),
    [VotingSelectors.Types.RESULTS_VOTES_COUNT]: makeStoreSelector(ResultsSelectors.resultsVotesCount),
    [VotingSelectors.Types.RESULT_PLUS_VOTES_COUNT]: makeStoreSelector(ResultsSelectors.resultPlusVotesCount),
    [VotingSelectors.Types.RESULT_MINUS_VOTES_COUNT]: makeStoreSelector(ResultsSelectors.resultMinusVotesCount),
    [VotingSelectors.Types.RESULT_VOTES_COUNT]: makeStoreSelector(ResultsSelectors.resultVotesCount),
    [VotingSelectors.Types.RESULT_OPTION_VOTES_COUNT]: makeStoreSelector(ResultsSelectors.resultOptionVotesCount),
    [VotingSelectors.Types.RESULT_SETUP_CALCULATION_METHOD]: makeStoreSelector(ResultsSelectors.resultSetupCalculationMethod),
    // form
    [FormSelectors.Types.FORM_VALIDATION]: makeStoreSelector(FormSelectors.formValidation),
    [FormSelectors.Types.FORM_FIELD_VALUE]: makeStoreSelector(FormSelectors.formFieldValue),
    [FormSelectors.Types.FORM_VALUE]: makeStoreSelector(FormSelectors.formValue),
    // dialog
    [DialogSelectors.Types.CURRENT_STEPPER_STEP]: makeStoreSelector(DialogSelectors.currentStepperStep),
    // planner
    [PlannerSelectors.Types.EXECUTION]: makeStoreSelector(PlannerSelectors.execution),
    // application
    [ApplicationSelectors.Types.CURRENT_LANGUAGE]: makeStoreSelector(ApplicationSelectors.currentLanguage),
    [ApplicationSelectors.Types.POPUP]: makeStoreSelector(ApplicationSelectors.popup),
    [ApplicationSelectors.Types.SHUFFLE_GROUP]: makeStoreSelector(ApplicationSelectors.shuffleGroup),
    [ApplicationSelectors.Types.ROUTE]: makeLocationSelector(ApplicationSelectors.route),
    [ApplicationSelectors.Types.ROUTE_MATCH]: makeLocationSelector(ApplicationSelectors.routeMatch),
    [ApplicationSelectors.Types.BASE_URL]: makeLocationSelector(ApplicationSelectors.baseUrl),
    [ApplicationSelectors.Types.IS_LOADING]: makeStoreSelector(ApplicationSelectors.isLoading),

    // generic - allows access to arbitrary state
    '#GENERIC_SELECTOR': makeStoreSelector(genericSelector),
};

// get correct selector function based on [selector.type] and call it with [selector.payload]
// use this function to make sure, that real selector function is called.
export const getDescriptor = <T = any>(selector: Selector<T>): SelectorDescriptor => {
    return selectorMapping[selector.type];
};
