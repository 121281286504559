import styled, { css } from 'styled-components';

import { Config } from './index';
import { onPhone, onTablet } from '../../stylesheets/tools';

const horizontalSpaceOnTablet = (c: Config) => css`
    height: ${c.onTablet?.size || 'initial'};

    /* style override */
    ${c.onTablet?.styleOverride}
`;

const horizontalSpaceOnPhone = (c: Config) => css`
    height: ${c.onPhone?.size || 'initial'};

    /* style override */
    ${c.onPhone?.styleOverride}
`;

export const HorizontalSpace = styled.div<{ config: Config }>`
    box-sizing: border-box;
    height: ${({ config }) => config.size};

    /* style override */
    ${({ config }) => config.styleOverride}

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${horizontalSpaceOnTablet(config)}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${horizontalSpaceOnPhone(config)}
                  `)}
              `
            : ''}


    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${horizontalSpaceOnTablet(config)}
                  `)}
              `
            : ''}
`;
