import { Effect, create as createAction, Authentication as AuthenticationActions } from '../../../../core/action';
import { Auth as AuthTools } from '../../../tools';

export default function (): Effect {
    return async (dispatch: any): Promise<void> => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                await AuthTools.signOut();
                dispatch(createAction(AuthenticationActions.signOutSuccess.TYPE));
                resolve();
            } catch (error) {
                dispatch(createAction(AuthenticationActions.signOutError.TYPE));
                reject(error);
            }
        });
    };
}
