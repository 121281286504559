import styled, { css } from 'styled-components';
import { color } from '../../stylesheets/shared';
import { onPhone as onPhoneDo } from '../../stylesheets/tools';

import Icon from '../Icon';
import { SplitPane as SplitPaneBlock } from '../../../core/block/interfaces';

type Config = {
    styleOverride?: SplitPaneBlock['styleOverride'];
    onPhone?: SplitPaneBlock['onPhone'];
};

export const SplitPaneContainer = styled.div<Config>`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;

    ${({ styleOverride }) => styleOverride}

    /* on phone */
    ${({ onPhone }) =>
        onPhone
            ? css`
                  ${onPhoneDo(css`
                      ${onPhone.styleOverride}
                  `)}
              `
            : ''}
`;

export const SwapButtonIcon = styled(Icon)`
    font-size: 24px;
    color: ${color.white};
    transform: rotate(90deg);
`;
export const SwapButton = styled.button<{ visiblePane: 'primary' | 'secondary' }>`
    background-color: ${color.black};
    font-size: 12px;
    width: 100px;
    height: 32px;
    position: fixed;
    top: calc(50% - 100px);
    border-radius: 12px 12px 0 0;
    border-bottom: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    ${({ visiblePane }) => {
        if (visiblePane === 'primary') {
            return css`
                right: 0;
                transform: rotate(-90deg);
                transform-origin: right bottom;
            `;
        }
        return css`
            left: 0;
            transform: rotate(90deg);
            transform-origin: left bottom;
        `;
    }}
`;
