import { isNil as _isNil, defer as _defer } from 'lodash';

import { Action } from '../../../core/action';
import { execution as selectExecution } from './selectors';
import { Execution, Repetition } from './interfaces';
import { Tools as EntityTools } from '../../../core/entity';
import { Planner as PlannerActions, create as createAction } from '../../../core/action';

const middleware = (store: any) => (next: any) => (action: Action<any>) => {
    // let next middelware do it's job first
    const result = next(action);

    // try to get planned execution/s for dispatched actions
    const execution: Execution | undefined = selectExecution(action.type)(store.getState());
    if (!_isNil(execution)) {
        // execution is an action
        if (!_isNil(execution.ex.type) && EntityTools.isItAction(execution.ex.type)) {
            const a: Action<any> = execution.ex as Action<any>;
            _defer(() => store.dispatch(a));
        }

        // if execution is planned just once, remove it
        if (execution.repetition === Repetition.ONCE) {
            store.dispatch(createAction(PlannerActions.removePlan.TYPE, action.type));
        }
    }

    // just return previously generated result
    return result;
};

export default middleware;
