import styled, { css } from 'styled-components';

import { Config } from './index';
import { cssH2 } from '../../../stylesheets/snippets';
import { color } from '../../../stylesheets/shared';
import { onPhone, onTablet } from '../../../stylesheets/tools';

export const H2 = styled.h2<{ config: Config }>`
    ${cssH2};
    color: ${color.black};

    /* style override */
    ${({ config }) => config.styleOverride}

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;
