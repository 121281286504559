import { get as _get, isNil as _isNil } from 'lodash';

import { Flow, FlowStep } from '../interfaces';

export default (flow: Flow, currentStep: string): string | undefined => {
    // current is last step?
    if (flow.end === currentStep) {
        return undefined;
    }

    // find current step
    const currentStepDef: FlowStep | undefined = _get(flow.steps, currentStep);
    if (_isNil(currentStepDef)) {
        return undefined;
    }

    // next step id currentStep.to
    return currentStepDef.to;
};
