import { defer as _defer, isNil as _isNil } from 'lodash';

import { stringIsEmpty } from '../../../../core/tools';
import { Effect } from '../../../../core/action';
import history from '../../../application_history';

export default function (): Effect {
    return async (): Promise<void> => {
        _defer(() => {
            let referer = history.location.pathname;
            // search ?
            if (!_isNil(history.location.search) && !stringIsEmpty(history.location.search)) {
                referer = `${history.location.pathname}${history.location.search}`;
            }

            history.replace('/404', { referer });
        });
    };
}
