import React, { useEffect, useState } from 'react';
import { ProposalsList } from '../../components/Proposals';
import { useListItems } from '../../hooks/useListItems';
import { useDispatch } from 'react-redux';
import { setProposals } from '../../store/proposals';
import { useInlets } from '../../hooks';
import { Tools } from '../../../core/block';

import { ProposalsList as ProposalsListConfig } from '../../../core/block/interfaces';

export function ProposalsListBlock({ itemsPath, ...props }: ProposalsListConfig) {
    const [items, loading, error] = useListItems<any>(itemsPath);
    const [_items, _setItems] = useState(items || []);

    useEffect(() => {
        _setItems(items || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(items)]);

    const dispatch = useDispatch();
    useEffect(() => {
        if (loading || error) return;

        dispatch(setProposals({ proposals: _items || [] }) as any);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(_items)]);

    const inlets = useInlets(props.inlets);

    useEffect(() => {
        if (!props.inlets) {
            return;
        }

        const highlightedProposalId = inlets.highlightedProposal;
        if (Tools.isInletResolved(highlightedProposalId)) {
            const updatedItems = _items.map((item) => ({
                ...item,
                isHighlighted: item.attrs.originalId === highlightedProposalId,
            }));
            _setItems([...updatedItems]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets)]);

    if (loading) return <div>Nacitam...</div>;
    if (error) return <div>Jejda!</div>;

    return <ProposalsList {...{ items: _items, styleOverride: props.styleOverride, orderBy: (props as any).orderBy }} />;
}
