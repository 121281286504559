import { unset as _unset } from 'lodash';

import { Store } from '../interfaces';

const reducer = (state: Store): Store => {
    const newState = {
        ...state,
    };

    _unset(newState, ['popup']);

    return newState;
};

export default reducer;
