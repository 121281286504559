import { Action, SchoolPb as SchoolPbActions } from '../../../core/action';
import * as Reducers from './reducers';
import { initialStore, Store } from './interfaces';

export default function (state: Store = initialStore, action: Action<any>) {
    switch (action.type) {
        case SchoolPbActions.taskDone.TYPE:
            return Reducers.taskDoneChange(state, action.payload as SchoolPbActions.taskDone.Payload);
        case SchoolPbActions.campaignEndDate.TYPE:
            return Reducers.campaignEndDateChane(state, action.payload as SchoolPbActions.campaignEndDate.Payload);
        case SchoolPbActions.campaignStartDate.TYPE:
            return Reducers.campaignStartDateChane(state, action.payload as SchoolPbActions.campaignStartDate.Payload);
        case SchoolPbActions.coordinatorEmail.TYPE:
            return Reducers.coordinatorEmailChange(state, action.payload as SchoolPbActions.coordinatorEmail.Payload);
        case SchoolPbActions.coordinatorName.TYPE:
            return Reducers.coordinatorNameChange(state, action.payload as SchoolPbActions.coordinatorName.Payload);
        case SchoolPbActions.maxParticularBudget.TYPE:
            return Reducers.maxParticularBudgetChange(state, action.payload as SchoolPbActions.maxParticularBudget.Payload);
        case SchoolPbActions.proposalsApprovingEndDate.TYPE:
            return Reducers.proposalsApprovingEndDateChange(state, action.payload as SchoolPbActions.proposalsApprovingEndDate.Payload);
        case SchoolPbActions.proposalsApprovingStartDate.TYPE:
            return Reducers.proposalsApprovingStartDateChange(state, action.payload as SchoolPbActions.proposalsApprovingStartDate.Payload);
        case SchoolPbActions.resultsAnnouncementDate.TYPE:
            return Reducers.resultsAnnouncementDateChange(state, action.payload as SchoolPbActions.resultsAnnouncementDate.Payload);
        case SchoolPbActions.totalBudget.TYPE:
            return Reducers.totalBudgetChange(state, action.payload as SchoolPbActions.totalBudget.Payload);
        case SchoolPbActions.votingEndDate.TYPE:
            return Reducers.votingEndDateChange(state, action.payload as SchoolPbActions.votingEndDate.Payload);
        case SchoolPbActions.votingEndNow.TYPE:
            return Reducers.votingEndNow(state);
        case SchoolPbActions.votingStartDate.TYPE:
            return Reducers.votingStartDateChange(state, action.payload as SchoolPbActions.votingStartDate.Payload);
        case SchoolPbActions.workshopStartTime.TYPE:
            return Reducers.workshopStartTime(state, action.payload as SchoolPbActions.workshopStartTime.Payload);
        case SchoolPbActions.workshopStartDate.TYPE:
            return Reducers.workshopStartDate(state, action.payload as SchoolPbActions.workshopStartDate.Payload);
        case SchoolPbActions.workshopVenue.TYPE:
            return Reducers.workshopVenueChange(state, action.payload as SchoolPbActions.workshopVenue.Payload);
        case SchoolPbActions.votingProposals.TYPE:
            return Reducers.proposalsChange(state, action.payload as SchoolPbActions.votingProposals.Payload);
        case SchoolPbActions.addProposal.TYPE:
            return Reducers.addProposal(state, action.payload as SchoolPbActions.addProposal.Payload);
        case SchoolPbActions.removeProposal.TYPE:
            return Reducers.removeProposal(state, action.payload as SchoolPbActions.removeProposal.Payload);
        case SchoolPbActions.addPinSet.TYPE:
            return Reducers.pinSetChange(state, action.payload as SchoolPbActions.addPinSet.Payload);
        case SchoolPbActions.pinsPerClassCount.TYPE:
            return Reducers.pinsPerClassCountChange(state, action.payload as SchoolPbActions.pinsPerClassCount.Payload);
        case SchoolPbActions.classCount.TYPE:
            return Reducers.classCountChange(state, action.payload as SchoolPbActions.classCount.Payload);
        case SchoolPbActions.votingLaunched.TYPE:
            return Reducers.votingLaunchedChange(state, action.payload as SchoolPbActions.votingLaunched.Payload);
        case SchoolPbActions.foreword.TYPE:
            return Reducers.forewordChange(state, action.payload as SchoolPbActions.foreword.Payload);
        case SchoolPbActions.updateState.TYPE:
            return Reducers.updateStateChange(state, action.payload as SchoolPbActions.updateState.Payload);
        case SchoolPbActions.name.TYPE:
            return Reducers.nameChange(state, action.payload as SchoolPbActions.name.Payload);
        case SchoolPbActions.markWinner.TYPE:
            return Reducers.markWinner(state, action.payload as SchoolPbActions.markWinner.Payload);
        default:
            return state;
    }
}
