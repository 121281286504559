import {
    map as _map,
    forEach as _forEach,
    isNil as _isNil,
    get as _get,
    set as _set,
    round as _round,
    min as _min,
    sortBy as _sortBy,
    reverse as _reverse,
    toString as _toString,
} from 'lodash';
import React, { useEffect, useState } from 'react';

import {
    BlockProps,
    ResultList as ResultListConfig,
    ResultListInlets,
    Tools,
    ResultMethod,
    ResultListItem,
    GraphData,
    GraphTheme,
} from '../../../../core/block';
import { Result, Choice } from '../../../../core/voting';
import { useInlets } from '../../../hooks';
import Block from '../../Block';
import * as Styled from './styled';

export type Config = ResultListConfig;
export type Props = BlockProps & Config;

interface RenderListItem {
    id: string;
    resultValue: number;
    graphProps: {
        text?: string;
        portraitSrc?: string;
        subPortraitSrc?: string;
        data: GraphData | GraphData[];
        themeOverride?: GraphTheme;
    };
}

const PortraitResultList = (props: Props) => {
    const usedInlets = useInlets(props.inlets) as ResultListInlets;
    const [renderList, setRenderList] = useState<RenderListItem[]>([]);

    // re-calculate results
    useEffect(() => {
        if (_isNil(usedInlets.values) || _isNil(props.items)) {
            return;
        }

        // calcualte result values for each item
        const resultValuePerItem: Record<string, number> = {}; // item id -> value
        _forEach(props.items, (item: ResultListItem, itemId: string) => {
            // get value for item
            const value: Result.Value | undefined = _get(usedInlets.values, itemId);

            let resultValue = 0;

            // there is no value for corresponding list item
            if (_isNil(value)) {
                resultValue = 0;
            }
            // single vote
            else if (props.method === ResultMethod.SINGLE_VOTE) {
                resultValue = _get(value, [Choice.Meaning.PLUS], 0);
            }
            // multi vote
            else if (props.method === ResultMethod.MULTI_VOTE) {
                resultValue = _get(value, [Choice.Meaning.PLUS], 0);
            }
            // D21 - net total
            else if (props.method === ResultMethod.D21_NET_TOTAL) {
                const plus = _get(value, [Choice.Meaning.PLUS], 0);
                const minus = _get(value, [Choice.Meaning.MINUS], 0);
                resultValue = plus - minus;
            }
            // D21
            else if (props.method === ResultMethod.D21) {
                const plus = _get(value, [Choice.Meaning.PLUS], 0);
                const minus = _get(value, [Choice.Meaning.MINUS], 0);
                resultValue = plus - minus;
            }

            _set(resultValuePerItem, itemId, resultValue);
        });

        // calculate max absolute value
        // const maxAbsoluteValue: number =
        //     _reduce(
        //         props.items,
        //         (result: number | undefined, item: ResultListItem, itemId: string) => {
        //             result = result || 0;

        //             // get value for item
        //             const value: Result.Value | undefined = _get(usedInlets.values, itemId);

        //             // there is no value for corresponding list item
        //             if (_isNil(value)) {
        //                 return _max([result, 0]);
        //             }
        //             // single vote
        //             else if (props.method === ResultMethod.SINGLE_VOTE) {
        //                 const plus = _get(value, [Choice.Meaning.PLUS], 0);
        //                 return _max([result, plus]);
        //             }
        //             // multi vote
        //             else if (props.method === ResultMethod.MULTI_VOTE) {
        //                 const plus = _get(value, [Choice.Meaning.PLUS], 0);
        //                 return _max([result, plus]);
        //             }
        //             // D21 - net total
        //             else if (props.method === ResultMethod.D21_NET_TOTAL) {
        //                 const plus = _get(value, [Choice.Meaning.PLUS], 0);
        //                 const minus = _get(value, [Choice.Meaning.MINUS], 0);
        //                 return _max([result, Math.abs(plus - minus)]);
        //             }
        //             // D21
        //             else if (props.method === ResultMethod.D21) {
        //                 const plus = _get(value, [Choice.Meaning.PLUS], 0);
        //                 const minus = _get(value, [Choice.Meaning.MINUS], 0);
        //                 return _max([result, plus, Math.abs(minus)]);
        //             }

        //             return _max([result, 0]);
        //         },
        //         0,
        //     ) || 0;

        const respondents: number = usedInlets.respondents || 1;

        // calculate final render list
        // LEGEND
        // percentage -> (result value / respondents) * 100 ... D21 values per meaning
        // count -> result value ... D21 values per meaning
        // barSize: -> result value / max absolute value * 100 ... D21 values per meaning
        const renderList: RenderListItem[] = [];
        _forEach(props.items, (item: ResultListItem, itemId: string) => {
            const itemText: string | undefined = item.text;
            const itemPortrait: string | undefined = item.portraitSrc;
            const itemSubPortrait: string | undefined = item.subPortraitSrc;
            const itemResultValue: number = _get(resultValuePerItem, itemId, 0);
            const percentagePrecision: number = props.percentagePrecision || 0;

            // D21
            if (props.method === ResultMethod.D21) {
                const value: Result.Value | undefined = _get(usedInlets.values, itemId);
                const plusValue = _get(value, [Choice.Meaning.PLUS], 0);
                const minusValue = _get(value, [Choice.Meaning.MINUS], 0);

                const calculatedPlusPercentage = _round((plusValue / respondents) * 100, percentagePrecision);
                const calculatedMinusPercentage = _round((minusValue / respondents) * 100, percentagePrecision);

                const ri: RenderListItem = {
                    id: itemId,
                    resultValue: itemResultValue,
                    graphProps: {
                        text: itemText,
                        portraitSrc: itemPortrait,
                        subPortraitSrc: itemSubPortrait,
                        data: [
                            // plus
                            {
                                barSize: _min([100, calculatedPlusPercentage]) || 0,
                                count: plusValue,
                                percentage: _min([100, calculatedPlusPercentage]) || 0,
                            },
                            // minus
                            {
                                barSize: _min([100, calculatedMinusPercentage]) || 0,
                                count: -1 * minusValue,
                                percentage: -1 * (_min([100, calculatedMinusPercentage]) || 0),
                            },
                        ],
                        themeOverride: props.graphThemeOverride,
                    },
                };

                renderList.push(ri);
            }
            // Others
            else {
                const calculatedPercentage = _round((itemResultValue / respondents) * 100, percentagePrecision);

                const ri: RenderListItem = {
                    id: itemId,
                    resultValue: itemResultValue,
                    graphProps: {
                        text: itemText,
                        portraitSrc: itemPortrait,
                        subPortraitSrc: itemSubPortrait,
                        data: {
                            barSize: _min([100, calculatedPercentage]) || 0,
                            count: itemResultValue,
                            percentage: _min([100, calculatedPercentage]) || 0,
                        },
                        themeOverride: props.graphThemeOverride,
                    },
                };

                renderList.push(ri);
            }
        });

        // sort render list by result value
        const sortedRenderList = _sortBy(renderList, ['resultValue']);
        _reverse(sortedRenderList);

        // generate rank map
        const rankMap: Record<string, string> = {}; // value -> rank
        let lastRankValue: number | undefined = undefined;
        let rankLength = 0;
        _forEach(sortedRenderList, (rli: RenderListItem, rliIdx: number) => {
            // value is different
            if (rli.resultValue !== lastRankValue) {
                _set(rankMap, _toString(rli.resultValue), `${rliIdx + 1}.`);
                rankLength = 0;
                lastRankValue = rli.resultValue;
            }
            // value is the same
            else {
                rankLength++;
                _set(rankMap, _toString(rli.resultValue), `${rliIdx + 1 - rankLength}.-${rliIdx + 1}.`);
            }
        });

        // map rank map to render list items
        const rankedRenderList = _map(sortedRenderList, (rli: RenderListItem) => {
            const rank = _get(rankMap, _toString(rli.resultValue), '');
            return {
                ...rli,
                graphProps: {
                    ...rli.graphProps,
                    text: `${rank} ${rli.graphProps.text}`,
                },
            };
        });

        setRenderList(rankedRenderList);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.graphName, props.method, JSON.stringify(props.items), JSON.stringify(usedInlets.values)]);

    return (
        <Styled.List {...Tools.extractConfig<Config>(props)}>
            {_map(renderList, (rli: RenderListItem) => (
                <Styled.Item key={rli.id}>
                    <Block name={props.graphName} {...rli.graphProps} />
                </Styled.Item>
            ))}
        </Styled.List>
    );
};
export default PortraitResultList;
