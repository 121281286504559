import { isNil as _isNil } from 'lodash';
import firebase from 'firebase/app';
import 'firebase/auth';

export default (): Promise<any | undefined> =>
    new Promise<any | undefined>((resolve, reject) => {
        firebase
            .auth()
            .getRedirectResult()
            .then((uc: firebase.auth.UserCredential) => {
                // just to make sure that we really have some response
                if (!_isNil(uc)) {
                    // if user===null that means there were no redirect (no matter if user is signed in or not)
                    const user = _isNil(uc.user) ? undefined : uc.credential;
                    resolve(user);
                }
            })
            .catch((error: firebase.FirebaseError) => {
                reject(error.code);
            });
    });
