import {
    forEach as _forEach,
    set as _set,
    toString as _toString,
    values as _values,
    sortBy as _sortBy,
    reverse as _reverse,
    mapValues as _mapValues,
    get as _get,
    size as _size,
    isNil as _isNil,
} from 'lodash';

// we expected that values are sorted ... no matter if ascending or descending
const generateRankPerValue = (values: number[]): Record<string, string> => {
    const rankMap: Record<string, string> = {}; // value -> rank
    let lastValue: number | undefined = undefined;
    let rankLength = 0;

    _forEach(values, (value: number, rliIdx: number) => {
        // value is different
        if (value !== lastValue) {
            _set(rankMap, _toString(value), `${rliIdx + 1}.`);
            rankLength = 0;
            lastValue = value;
        }
        // value is the same
        else {
            rankLength++;
            _set(rankMap, _toString(value), `${rliIdx + 1 - rankLength}.-${rliIdx + 1}.`);
        }
    });

    return rankMap;
};

export default function (values?: Record<string, number>): Record<string, string> {
    if (_isNil(values) || _size(values) === 0) {
        return {};
    }

    const sortedValues: number[] = _reverse(_sortBy(_values(values)));
    const rankPerValue: Record<string, string> = generateRankPerValue(sortedValues); // value -> rank

    return _mapValues(values, (value: number) => _get(rankPerValue, _toString(value), ''));
}
