import { isNil as _isNil } from 'lodash';
import React, { useState } from 'react';
import MarkdownIt from 'markdown-it';

import { BlockProps, SecondaryCTA as SecondaryCTAConfig, SecondaryCTAInlets, Tools } from '../../../core/block';
import { Tools as ValidationTools } from '../../../core/validation';
import { useOutlets, useInlets } from '../../hooks';
import Loading from '../../components/Loading';
import * as Styled from './styled';

export type Config = SecondaryCTAConfig;
export type Props = BlockProps &
    Config & {
        inProgress?: boolean;
        isEnabled?: boolean;
        onClick?: () => void;
    };

const md = new MarkdownIt({
    html: false,
    xhtmlOut: true,
    breaks: true,
    linkify: true,
});

const SecondaryCTA = (props: Props) => {
    const usedInlets = useInlets(props.inlets) as SecondaryCTAInlets;
    const outlet = useOutlets();
    const [displayInProgress, setDisplayInProgress] = useState<boolean>(false);

    const isInProgress = (): boolean => displayInProgress === true || props.inProgress === true;

    const processClickOutlet = async () => {
        if (_isNil(props.outlets?.click)) {
            return;
        }

        setDisplayInProgress(true);
        await outlet(props.outlets?.click);
        setDisplayInProgress(false);
    };

    const onClick = () => {
        if (isInProgress()) {
            return;
        }

        // should we care about validation satus?
        if (!_isNil(usedInlets.validationStatus)) {
            // is invalid?
            if (ValidationTools.isStatusInvalid(usedInlets.validationStatus)) {
                return;
            }
        }

        processClickOutlet();

        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <Styled.Button
            {...Tools.extractConfig<Config>(props)}
            usedInlets={usedInlets}
            inProgress={props.inProgress}
            isEnabled={props.isEnabled}
            onClick={onClick}
        >
            {/* icon */}
            {props.icon && (
                <Styled.IconWrapper>
                    <Styled.Icon {...props.icon} />
                </Styled.IconWrapper>
            )}

            {/* simple text */}
            {!props.useMarkdown && <Styled.Label>{props.text}</Styled.Label>}
            {/* markdown text */}
            {props.useMarkdown && <Styled.Label dangerouslySetInnerHTML={{ __html: md.renderInline(props.text) }} />}

            {/* loading */}
            {isInProgress() && (
                <Styled.LoadingWrapper>
                    <Loading />
                </Styled.LoadingWrapper>
            )}
        </Styled.Button>
    );
};
export default SecondaryCTA;
