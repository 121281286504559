export const PROPOSALS_IS_ADDING = '@MAP:is_adding_proposal';

export type IsAddingProposalPayload = boolean;

export const action = (payload: IsAddingProposalPayload) => {
    return {
        type: PROPOSALS_IS_ADDING,
        payload,
    };
};

export const reduceIsAddingProposal = (state: any, payload: IsAddingProposalPayload) => {
    return {
        ...state,
        isAddingProposal: payload,
    };
};
