import * as Reducers from './reducers';
import { Store, initialStore } from './interfaces';
import { Action, Planner as PlannerActions } from '../../../core/action';

export default function (state: Store = initialStore, action: Action<any>) {
    switch (action.type) {
        case PlannerActions.planOnce.TYPE:
            return Reducers.planOnce(state, action.payload as PlannerActions.planOnce.Payload);

        case PlannerActions.planEvery.TYPE:
            return Reducers.planEvery(state, action.payload as PlannerActions.planEvery.Payload);

        case PlannerActions.removePlan.TYPE:
            return Reducers.removePlan(state, action.payload as PlannerActions.removePlan.Payload);

        default:
            return state;
    }
}
