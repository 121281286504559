import { isNil as _isNil } from 'lodash';
import React from 'react';
import MarkdownIt from 'markdown-it';

import { BlockProps, IconButton as IconButtonConfig, Tools } from '../../../core/block';
import { useOutlets } from '../../hooks';
import * as Styled from './styled';

export type Config = IconButtonConfig;
export type Props = BlockProps & Config;

const md = new MarkdownIt({
    html: false,
    xhtmlOut: true,
    breaks: true,
    linkify: true,
});

const IconButton = (props: Props) => {
    const outlet = useOutlets();

    const onClick = () => {
        if (_isNil(props.outlets?.click)) {
            return;
        }

        outlet(props.outlets?.click);
    };

    const text = (props: Props, isLeft: boolean) => {
        const canAdd = !_isNil(props.textAlignLeft) && props.textAlignLeft ? isLeft && props.textAlignLeft : !isLeft;
        if (!_isNil(props.text) && canAdd) {
            if (_isNil(props.useMarkdown)) {
                return <Styled.Label>{props.text}</Styled.Label>;
            } else {
                return <Styled.Label dangerouslySetInnerHTML={{ __html: md.render(props.text) }} />;
            }
        } else {
            return;
        }
    };

    return (
        <Styled.Button {...Tools.extractConfig<Config>(props)} onClick={onClick}>
            {text(props, true)}
            <Styled.Icon {...props.icon} />
            {text(props, false)}
        </Styled.Button>
    );
};
export default IconButton;
