import styled, { CSSObject } from 'styled-components';
import { constant, color } from '../../stylesheets/shared';

export interface HeaderProps {
    styleOverride?: CSSObject;
}

export const Header = styled.header<HeaderProps>`
    display: block;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: ${constant.websiteMenuHeight}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${color.grey3};
    background-color: ${color.white};
    z-index: 100;

    ${(props) => props.styleOverride}
`;
