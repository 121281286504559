import styled from 'styled-components';

import { Config } from './index';
import { color } from '../../../stylesheets/shared';
import { cssP } from '../../../stylesheets/snippets';

export const Panel = styled.div<{ config: Config }>`
    width: 100%;

    /* style override */
    ${({ config }) => config.styleOverride}
`;

// Columns
export const Col1 = styled.div`
    flex-shrink: 1;
    flex-grow: 1;
    padding: 0 4px 0 8px;
`;
export const Col2 = styled.div`
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0 4px;
    width: 60px;
    text-align: center;
`;
export const Col3 = styled.div`
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0 4px;
    width: 55px;
    text-align: center;
`;
export const Col4 = styled.div`
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0 8px 0 4px;
    width: 70px;
    text-align: center;
`;

// Header
export const Header = styled.div`
    display: flex;
    flex-direction: row;
    ${cssP};
    color: ${color.grey2};
    width: 100%;
    box-sizing: border-box;
    margin-top: 24px;
`;

// Row
export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${cssP};
    width: 100%;
    box-sizing: border-box;
    padding: 12px 0;
`;

// Results
export const ResultsItem = styled.div`
    width: 100%;
`;
