import { trim as _trim, isNil as _isNil } from 'lodash';
import React, { useEffect } from 'react';

import { Name as VotingName, Poll } from '../../../../core/voting';
import { PollPage as PollPageConfig } from '../../../../core/website';
import { usePollConfig, useOutlets } from '../../../hooks';
import RedirectTo404 from '../../../components/RedirectTo404';
import StandardPoll from '../../../components/Poll/Standard';
import Loading from '../../../components/Loading';

const PollPage = (props: PollPageConfig) => {
    const outlet = useOutlets();
    const [pollConfig, pollLoading, pollError] = usePollConfig(props.pollId);

    useEffect(() => {
        if (!_isNil(props.outlets?.enter)) {
            outlet(props.outlets?.enter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isPollIdValid: boolean = !_isNil(props.pollId) && _trim(props.pollId || '') !== '';

    return (
        <>
            {/* some of Id is not valid */}
            {!isPollIdValid && <RedirectTo404 />}

            {/* download by give Id */}
            {isPollIdValid && (
                <>
                    {/* loading... */}
                    {pollLoading && <Loading fullScreen={true} />}
                    {/* error */}
                    {pollError && <>loading error: {pollError}</>}
                    {/* does not exist */}
                    {!pollLoading && !pollError && _isNil(pollConfig) && <RedirectTo404 />}
                    {/* exists */}
                    {!_isNil(pollConfig) && (
                        <>
                            {/* standard */}
                            {pollConfig.name === VotingName.STANDARD_POLL && <StandardPoll {...(pollConfig as Poll.Poll)} />}
                        </>
                    )}
                </>
            )}
        </>
    );
};
export default PollPage;
