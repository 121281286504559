import React from 'react';
import { useParams } from 'react-router-dom';

const Editor = () => {
    const urlParams = useParams();

    console.log('*** url params in editor');
    console.log(urlParams);

    return <>Admin Editor</>;
};
export default Editor;
