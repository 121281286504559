import { Name as EntityName } from '../../entity';

export const TYPE = `${EntityName.ACTION_PREFIX}AUTHENTICATION:sign_in_with_pin`;

export interface Payload {
    pin: string;
    entityName: string;
    entityId: string;
    checkWhitelist?: boolean;
}
