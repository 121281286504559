import { reverse as _reverse, size as _size, sortBy as _sortBy, get as _get, keys as _keys } from 'lodash';

export default function (resultValues: Record<string, number> = {}): string[] {
    if (_size(resultValues) === 0) {
        return [];
    }

    const keys: string[] = _keys(resultValues);
    return _reverse(_sortBy(keys, (key: string) => _get(resultValues, key, 0)));
}
