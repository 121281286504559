import { isNil as _isNil, template as _template, replace as _replace } from 'lodash';
import React from 'react';
import getMarkdown from '../../../tools/markdown';
import { useInlets } from '../../../hooks';
import { BlockProps, Title as TitleConfig, TitleInlets, Tools } from '../../../../core/block';

import * as Styled from './styled';

export type Config = TitleConfig;
export type Props = BlockProps & Config;

const md = getMarkdown();

const Title = (props: Props) => {
    const usedInlets = useInlets(props.inlets) as TitleInlets;

    const getText = (input: string): string => {
        let resText = input;
        if (!_isNil(input)) {
            const template = _template(_replace(input, new RegExp('inlets.', 'g'), ''), { interpolate: /{{([\s\S]+?)}}/g });
            resText = template(usedInlets);
        }
        return resText;
    };

    return (
        <>
            {/* Simple */}
            {!props.useMarkdown && <Styled.Title {...Tools.extractConfig<Config>(props)}>{getText(props.text)}</Styled.Title>}

            {/* Markdown */}
            {props.useMarkdown && (
                <Styled.Title {...Tools.extractConfig<Config>(props)} dangerouslySetInnerHTML={{ __html: md.renderInline(getText(props.text)) }} />
            )}
        </>
    );
};
export default Title;
