import { get as _get } from 'lodash';
import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

import Page from '../index';

const PageRouter = () => {
    const routeMatch = useRouteMatch();
    const path = _get(routeMatch, ['path']);

    return (
        <Switch>
            {/* render actual page */}
            <Route path={`${path}`} exact>
                <Page />
            </Route>

            {/* go deeper to subpage */}
            <Route path={`${path}/:pageId`}>
                <PageRouter />
            </Route>
        </Switch>
    );
};
export default PageRouter;
