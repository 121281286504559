import { CSSProperties } from 'react';
import { CSSObject } from 'styled-components';

import { Choice, Result } from '../voting';
import { Outlets, Outlet } from '../outlet';
import { Selector } from '../selector';
import { Voting as VotingCalculation } from '../calculator';
import { Status as ValidationStatus } from '../validation';
import { StorageReference } from '../storage';

export type TaskGroupState = 'normal' | 'exception' | 'active' | 'success' | undefined;
export interface TaskProgressInfo {
    doneTasksCount: number;
    totalTasksCount: number;
    totalState: TaskGroupState;
}

// Inlets
export type Inlet = Selector<any> | number | boolean | string | string[] | TaskProgressInfo | Attachment[];
export type Inlets = Record<string, Inlet | undefined | null>;

// Aligns
export enum VerticalAlign {
    TOP = 'top',
    BOTTOM = 'bottom',
    CENTER = 'center',
    STRETCH = 'stretch',
    BASELINE = 'baseline',
}
export enum HorizontalAlign {
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',
    STRETCH = 'stretch',
}

// Result Method
export enum ResultMethod {
    D21 = 'd21',
    D21_NET_TOTAL = 'd21_net_total',
    SINGLE_VOTE = 'single_vote',
    MULTI_VOTE = 'multi_vote',
}

// Block
export interface Block {
    name: string;
}
export type BlockProps = {
    className?: string;
    style?: CSSProperties;
};
export interface WithStyleOverride {
    styleOverride?: CSSObject;
    hoverStyleOverride?: CSSObject;
    printStyleOverride?: CSSObject;
}
export interface BlockInShuffleGroup {
    shuffleGroup?: string;
}

// General Block
export interface GeneralBlockOutlets extends Outlets {
    click?: Outlet;
}
export interface GeneralBlock extends Block, WithStyleOverride {
    blocks?: Block[];
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
    outlets?: GeneralBlockOutlets;
}

// Anchor
export interface Anchor extends Block {
    id: string;
}

// Redirect
export interface Redirect extends Block {
    to: string;
}
// Redirect to 404
export interface RedirectTo404 extends Block {
    referer?: string;
}

// Blocks
export interface Blocks {
    blocks?: Block[];
}

// Blocks Reference
export interface BlocksRef {
    reference?: StorageReference;
}

// CodSBomLayout
export interface CodSBomLayout extends Block, WithStyleOverride {
    blocks?: Block[];
    itemsMargin?: string;
}

// Title
export type TitleInlets = Record<string, string>;
export interface Title extends Block, WithStyleOverride {
    text: string;
    useMarkdown?: boolean;
    inlets?: Inlets;
}

// H1
export interface H1 extends Block, WithStyleOverride {
    text: string;
    useMarkdown?: boolean;
    inlets?: Inlets;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// H1 With Status
export interface H1WithStatus extends Block, WithStyleOverride {
    icon: Icon;
    text: string;
    breakOnPhone: boolean;
}

// H2
export interface H2 extends Block, WithStyleOverride {
    text: string;
    useMarkdown?: boolean;
    inlets?: PInlets;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// H3
export interface H3 extends Block, WithStyleOverride {
    text: string;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// Accent
export interface AccentInlets {
    text?: string;
}
export interface Accent extends Block, WithStyleOverride {
    text: string;
    useMarkdown?: boolean;
    inlets?: Inlets;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// Collapse
export interface Collapse extends Block, WithStyleOverride {
    blocks?: Block[];
    title: string;
    isExpanded?: boolean;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// Horizontal Layput
export interface HorizontalLayout extends Block, WithStyleOverride {
    blocks?: Block[];
    alignItems?: VerticalAlign;
    alignContent?: HorizontalAlign;
    itemsMargin?: string;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// Horizontal Line
export interface HorizontalLine extends Block, WithStyleOverride {}

// Horizontal Space
export interface HorizontalSpace extends Block, WithStyleOverride {
    size: string;
    onTablet?: {
        size?: string;
        styleOverride?: CSSObject;
    };
    onPhone?: {
        size?: string;
        styleOverride?: CSSObject;
    };
}

// Table Like List
export interface TableLikeListRow extends WithStyleOverride {
    blocks: Block[]; // block = clumn
    columsSizes?: string[];
    alignItems?: VerticalAlign;
    alignContent?: HorizontalAlign;
}
export interface TableLikeList extends Block, WithStyleOverride {
    rows: TableLikeListRow[];
    columsSizes?: string[];
    alignItems?: VerticalAlign;
    alignContent?: HorizontalAlign;
}

// Icon
export enum IconsSet {
    CUSTOM = 'custom',
    MATERIAL = 'material',
    MATERIAL_OUTLINE = 'material_outline',
}
export interface IconOutlets extends Outlets {
    click?: Outlet;
}
export interface Icon extends Block, WithStyleOverride {
    icon: string;
    set?: IconsSet;
    outlets?: IconOutlets;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// YouTube
export interface YouTube extends Block, WithStyleOverride {
    url: string;
}
// MP4
export interface MP4 extends Block, WithStyleOverride {
    src: string;
    loop?: boolean;
    onTablet?: {
        src?: string;
        styleOverride?: CSSObject;
    };
    onPhone?: {
        src?: string;
        styleOverride?: CSSObject;
    };
}

// Image
export interface ImageOutlets extends Outlets {
    click?: Outlet;
}
export interface Image extends Block, WithStyleOverride {
    src: string;
    hasBorder?: boolean;
    outlets?: ImageOutlets;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// Flexible Image
export interface FlexibleImageOutlets extends Outlets {
    click?: Outlet;
}
export interface FlexibleImage extends Block, WithStyleOverride {
    src: string;
    aspect: string;
    outlets?: FlexibleImageOutlets;
    onTablet?: {
        src?: string;
        aspect?: string;
        styleOverride?: CSSObject;
    };
    onPhone?: {
        src?: string;
        aspect?: string;
        styleOverride?: CSSObject;
    };
}

export enum SVG_INTERACTIVE_IMAGES {
    CZ_REGIONS = 'cz_regions',
    CZ_SENATE_BURROWS = 'cz_senate_burrows',
}

export interface InteractiveSvg extends Block, WithStyleOverride {
    image: SVG_INTERACTIVE_IMAGES;
    /** Any element within the SVG with an id, where id maps to the key in this Record */
    interactiveElements?: Record<string, Outlets>;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// Harmonogram
export interface HarmonogramTheme {
    color?: string;
}
export enum HarmonogramRecordType {
    STANDARD = 'standard',
    YEARS_SEPARATOR = 'years-separator',
    GROUP_SEPARATOR = 'group-separator',
}
export interface HarmonogramRecord {
    type: HarmonogramRecordType;
    timeLabel?: string;
    text?: string;
    displayItAsVeryPast?: boolean;
    timing?: {
        from?: string;
        to?: string;
    };
}
export interface Harmonogram extends Block, WithStyleOverride {
    records: HarmonogramRecord[];
    themeOverride?: HarmonogramTheme;
}

// Section
export interface Section extends Block, WithStyleOverride {
    text?: string;
    icon?: Icon;
    menu?: Block[];
    blocks?: Block[];
}

// Rank
export interface Rank extends Block, WithStyleOverride {
    text: string;
}

// Bars
export interface BarsInlets {
    values?: VotingCalculation.Inputs;
    maximum?: number;
    calculationMethod?: VotingCalculation.Method;
}
export interface Bars extends Block, WithStyleOverride {
    calculationMethod?: VotingCalculation.Method;
    precision?: number; // default is 0
    inlets?: Inlets;
    barTemplates: Record<string, string>; // item id -> bar template
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}
export interface BarTheme {
    [Choice.Meaning.PLUS]?: { color?: string };
    [Choice.Meaning.MINUS]?: { color?: string };
}
export interface BarData {
    size: number; // in %
    count?: number;
    percentage?: number;
}
export interface Bar extends Block, WithStyleOverride {
    blocks?: Block[]; // bar description
    data?: BarData[]; // record per graphic bar
    doubleSign?: boolean;
    themeOverride?: BarTheme;
}

// Graphs
// !!!!
export interface GraphData {
    barSize: number;
    count?: number;
    percentage?: number;
}
export interface GraphTheme {
    [Choice.Meaning.PLUS]?: {
        color?: string;
    };
    [Choice.Meaning.MINUS]?: {
        color?: string;
    };
}
// Bar Graph
export interface BarGraph extends Block, WithStyleOverride {
    text?: string;
    data: GraphData;
    themeOverride?: GraphTheme;
}
// Sign Bar Graph
export interface SignBarGraph extends Block, WithStyleOverride {
    text?: string;
    data: GraphData;
}
// Double Sign Bar Graph
export interface DoubleSignBarGraph extends Block, WithStyleOverride {
    text?: string;
    data: GraphData[]; // 0 -> plus, 1 -> minus
}
// Portrait Bar Graph
export interface PortraitBarGraph extends Block, WithStyleOverride {
    portraitSrc: string;
    subPortraitSrc?: string;
    text?: string;
    data: GraphData;
    themeOverride?: GraphTheme;
}
// Portrait Sign Bar Graph
export interface PortraitSignBarGraph extends Block, WithStyleOverride {
    portraitSrc: string;
    subPortraitSrc?: string;
    text?: string;
    data: GraphData;
}
// Portrait Double Sign Bar Graph
export interface PortraitDoubleSignBarGraph extends Block, WithStyleOverride {
    portraitSrc: string;
    subPortraitSrc?: string;
    text?: string;
    data: GraphData[]; // 0 -> plus, 1 -> minus
}

// Circular Image
export interface CircularImage extends Block, WithStyleOverride {
    src: string;
}

// P
export type PInlets = Record<string, string>;
export interface P extends Block, WithStyleOverride {
    text: string;
    useMarkdown?: boolean;
    inlets?: Inlets;
    collapsible?: {
        visibleRows: number;
        expandText: string;
        collapseText: string;
    };
}

// Represent P
export type RepresentPInlets = Record<string, string>;
export interface RepresentP extends Block, WithStyleOverride {
    text: string;
    useMarkdown?: boolean;
    inlets?: Inlets;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// Note P
export interface NoteP extends Block, WithStyleOverride {
    text: string;
    useMarkdown?: boolean;
}

// Represent Primary CTA
export interface RepresentPrimaryCTAOutlets extends Outlets {
    click?: Outlet;
}
export interface RepresentPrimaryCTATheme {
    color?: string;
    shadow?: string;
}
export interface RepresentPrimaryCTA extends Block, WithStyleOverride {
    text: string;
    outlets?: RepresentPrimaryCTAOutlets;
    icon?: Icon;
    useMarkdown?: boolean;
    themeOverride?: RepresentPrimaryCTATheme;
}

// Primary CTA
export interface PrimaryCTAInlets {
    validationStatus?: ValidationStatus;
}
export interface PrimaryCTAOutlets extends Outlets {
    click?: Outlet;
}
export interface PrimaryCTATheme {
    color?: string;
}
export interface PrimaryCTA extends Block, WithStyleOverride {
    text: string;
    useMarkdown?: boolean;
    inlets?: Inlets;
    outlets?: PrimaryCTAOutlets;
    icon?: Icon;
    useMarker?: boolean;
    themeOverride?: PrimaryCTATheme;
}

// Secondary CTA
export interface SecondaryCTAInlets {
    validationStatus?: ValidationStatus;
}
export interface SecondaryCTAOutlets extends Outlets {
    click?: Outlet;
}
export interface SecondaryCTATheme {
    color?: string;
}
export interface SecondaryCTA extends Block, WithStyleOverride {
    text: string;
    useMarkdown?: boolean;
    icon?: Icon;
    inlets?: Inlets;
    outlets?: SecondaryCTAOutlets;
    themeOverride?: SecondaryCTATheme;
}

// Simple Voting Option
export interface SimpleVotingOptionTheme {
    [Choice.Meaning.PLUS]?: {
        color?: string;
    };
    [Choice.Meaning.MINUS]?: {
        color?: string;
    };
}
export interface SimpleVotingOptionInlets {
    selectedMeaning?: Choice.Meaning;
}
export interface SimpleVotingOption extends Block, WithStyleOverride, BlockInShuffleGroup {
    text: string;
    voteButtons?: Block[];
    inlets?: Inlets;
    themeOverride?: StandardVotingOptionTheme;
}
// Standard Voting Option
export interface StandardVotingOptionTheme {
    [Choice.Meaning.PLUS]?: {
        color?: string;
    };
    [Choice.Meaning.MINUS]?: {
        color?: string;
    };
}
export interface StandardVotingOptionInlets {
    selectedMeaning?: Choice.Meaning;
}
export interface StandardVotingOption extends Block, WithStyleOverride, BlockInShuffleGroup {
    text: string;
    blocks?: Block[];
    inlets?: Inlets;
    themeOverride?: StandardVotingOptionTheme;
}
// Portrait Voting Option
export interface PortraitVotingOptionTheme {
    [Choice.Meaning.PLUS]?: {
        color?: string;
    };
    [Choice.Meaning.MINUS]?: {
        color?: string;
    };
}
export interface PortraitVotingOptionInlets {
    selectedMeaning?: Choice.Meaning;
}
export interface PortraitVotingOption extends Block, WithStyleOverride, BlockInShuffleGroup {
    portraitSrc: string;
    subPortraitSrc?: string;
    text: string;
    blocks?: Block[];
    inlets?: Inlets;
    themeOverride?: PortraitVotingOptionTheme;
}

// Timeline
export type MilestoneInlets = Record<string, string>;
export interface Milestone {
    icon?: string;
    title: string;
    subTitle?: string;
    description?: string;
    inlets?: Inlets;
}
export interface Timeline extends Block, WithStyleOverride {
    milestones: Milestone[];
    direction: 'horizontal' | 'vertical';
    markAllMilestones?: 'wait' | 'process' | 'finish' | 'error';
    scale: number;
    color?: string;
    styleOverride?: CSSObject;
}

// Vertical Layout
export interface VerticalLayout extends Block, WithStyleOverride {
    blocks?: Block[];
    alignItems?: HorizontalAlign;
    alignContent?: VerticalAlign;
    itemsMargin?: string;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// Split Pane
export interface SplitPane extends Block, WithStyleOverride {
    panes: Block[];
    splitDirection?: 'vertical' | 'horizontal';
    textGoToSecondary?: string;
    textGoToPrimary?: string;
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// Affix Layout
export interface Affix extends Block, WithStyleOverride {
    blocks?: Block[];
    offsetTop?: number;
    offsetBottom?: number;
}

// Vertical Space
export interface VerticalSpace extends Block, WithStyleOverride {
    size: string;
    onTablet?: {
        size: string;
        styleOverride?: CSSObject;
    };
    onPhone?: {
        size: string;
        styleOverride?: CSSObject;
    };
}

// Represent Button
export interface RepresentButtonOutlets extends Outlets {
    click?: Outlet;
}
export interface RepresentButton extends Block, WithStyleOverride {
    outlets?: RepresentButtonOutlets;
    blocks: Block[];
}

// Button
export interface ButtonOutlets extends Outlets {
    click?: Outlet;
}
export interface Button extends Block, WithStyleOverride {
    text: string;
    useMarkdown?: boolean;
    outlets?: ButtonOutlets;
    icon?: Icon;
}

// Icon Button
export interface IconButtonOutlets extends Outlets {
    click?: Outlet;
}
export interface IconButton extends Block, WithStyleOverride {
    icon: Icon;
    text?: string;
    textAlignLeft?: boolean;
    iconSize?: string;
    outlets?: IconButtonOutlets;
    useMarkdown?: boolean;
}

// Progress button
export interface ProgressButtonInlets extends Inlets {
    tasks?: TaskProgressInfo;
    selected?: boolean;
}

export interface ProgressButton extends Block, WithStyleOverride {
    outlets?: ButtonOutlets;
    inlets?: Inlets;
    text: string;
}

// LinkButton
export interface LinkButtonOutlets extends Outlets {
    click?: Outlet;
}
export interface LinkButton extends Block, WithStyleOverride {
    text: string;
    useMarkdown?: boolean;
    outlets?: LinkButtonOutlets;
}

// Vote Button
export interface VoteButtonTheme {
    color?: string;
}
export interface VoteButtonInlets {
    selected?: boolean;
}
export interface VoteButtonOutlets extends Outlets {
    click?: Outlet;
}
export interface VoteButton extends Block, WithStyleOverride {
    text?: string;
    useMarkdown?: boolean;
    meaning: Choice.Meaning;
    outlets?: VoteButtonOutlets;
    inlets?: Inlets;
    icon?: Icon;
    themeOverride?: VoteButtonTheme;
}

// Votes Count
export type VotesCountInlets = Record<string, string>;
export interface VotesCountTheme {
    color?: string;
}
export interface VotesCount extends Block, WithStyleOverride {
    count: number | string;
    meaning: Choice.Meaning;
    icon: Icon;
    themeOverride?: VotesCountTheme;
    inlets?: Inlets;
}
// Represent Votes Count
export interface RepresentVotesCountTheme {
    color?: string;
}
export interface RepresentVotesCount extends Block, WithStyleOverride {
    count: number;
    meaning: Choice.Meaning;
    icon: Icon;
    themeOverride?: VotesCountTheme;
}

// Form Field
export interface FormFieldOutlets extends Outlets {
    change?: Outlet;
    submit?: Outlet;
}

export interface FormFieldInlets {
    value?: any;
}

export type FormFieldValidationScheme = Record<string, any[]>;
// general field
export interface FormField extends Block, WithStyleOverride {
    text?: string;
    autoFocus?: boolean;
    validateOnMount?: boolean;
}

// date range picker
export interface DateRangePicker extends FormField {
    startDateValidationScheme?: FormFieldValidationScheme;
    endDateValidationScheme?: FormFieldValidationScheme;
}

//date picker
export interface TimeDatePickerOutlet extends Outlets {
    change?: Outlet;
}
export interface DatePicker extends FormField {
    inlets?: Inlets;
    outlets?: TimeDatePickerOutlet;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// time picker

export interface TimePicker extends FormField {
    timeFormat?: string;
    inlets?: Inlets;
    outlets?: TimeDatePickerOutlet;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// form link
export interface FormFieldLink extends FormField {
    labelPlaceholder: string;
    linkPlaceholder: string;
    enableDelete: boolean;
    outlets?: FormFieldOutlets;
    align?: HorizontalAlign;
    linkValidationScheme?: FormFieldValidationScheme;
    labelValidationScheme?: FormFieldValidationScheme;
}
// string
export interface FormFieldString extends FormField {
    defaultValue?: string;
    value?: string;
    placeholder?: string;
    align?: HorizontalAlign;
    outlets?: FormFieldOutlets;
    inlets?: Inlets;
    defaultValueInlet?: Inlets;
    validationScheme?: FormFieldValidationScheme;
    /** Maximum number of characters */
    maxLength?: number;
    suppressValidationNotification?: boolean;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

export interface FormFieldInputBox extends FormField {
    input: FormFieldStringLong;
    button: Button;
    outlets?: FormFieldOutlets;
    inlets?: Inlets;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// string - long

export interface AutoSizeType {
    minRows?: number;
    maxRows?: number;
}
export interface FormFieldStringLong extends FormFieldString {
    rows?: number;
    /** Maximum number of characters */
    maxLength?: number;
    autoSize?: AutoSizeType;
    /**
     * A message to inform about the maximum charactes status.
     *
     * Available variables for interpolation: length, maxLength.
     * E.g. "Napsali jste {{ length }} / {{ maxLength }} znaků."
     */
    maxLengthMessage?: string;
}
// phone
export interface FormFieldPhone extends FormField {
    placeholder?: string;
    defaultPhoneCode?: string;
    filterPhoneCodes?: string[];
    align?: HorizontalAlign;
    outlets?: FormFieldOutlets;
    validationScheme?: FormFieldValidationScheme;
}
// number
export interface FormFieldNumber extends FormField {
    defaultValue?: number;
    placeholder?: string;
    align?: HorizontalAlign;
    outlets?: FormFieldOutlets;
    inlets?: Inlets;
    maxLength?: number;
    validationScheme?: FormFieldValidationScheme;
}
// checkbox
export interface FormFieldCheckbox extends FormField {
    align?: HorizontalAlign;
    outlets?: FormFieldOutlets;
    inlets?: Inlets;
    validationScheme?: FormFieldValidationScheme;
    valueMapping?: Record<any, any>; // field value -> real value
}
export interface FormFieldDropdown extends FormField {
    items?: DropdownItem[];
    defaultValue?: string;
    placeholder?: string;
    /** Allow filtering by typing the part of the option's name  */
    allowFiltering?: boolean;
    allowClear?: boolean;
    align?: HorizontalAlign;
    outlets?: FormFieldOutlets;
    inlets?: Inlets;
    validationScheme?: FormFieldValidationScheme;
    valueMapping?: Record<any, any>; // field value -> real value
}

// template list
export interface TemplateListInlets {
    data?: any[];
}
export interface TemplateList extends Block, WithStyleOverride {
    inlets?: Inlets;
    template: string;
    interleaving?: {
        blocks: Block[];
        useFirst?: boolean;
        useLast?: boolean;
    };
}

export interface DropdownItem {
    value: string;
    label: string;
}

// file upload
export interface FileUploader extends Block, WithStyleOverride {
    maxFiles?: number;
    /** Maximum size of a single file, in kB */
    maxSize?: number;
    /** Accepted content types */
    accept?: string;
    /** Texts that the file uploader displays in various states */
    texts?: {
        /** A text that prompts user to add a file */
        placeholder?: string;
        /** A text showing that currently dragged files may be dropped here.
         *  Interpolation variables: filesCount */
        dragOver?: string;
        /** A text for an item that is currently uploading */
        uploading?: string;
    };

    outlets?: FormFieldOutlets;
}

// link item button
export interface LinkItemButtonOutlets extends Outlets {
    click?: Outlet;
    delete?: Outlet;
}
export interface LinkItemButton extends Block, WithStyleOverride {
    label: string;
    url: string;
    icon?: string;
    iconSet?: IconsSet;
    useMarkdown?: boolean;
    outlets?: LinkItemButtonOutlets;
    enableDelete?: boolean;
}

export interface ItemListOutlets extends Outlets {
    change?: Outlet;
    delete?: Outlet;
}

export interface ItemList extends Block, WithStyleOverride {
    items: string[];
    isEditable?: boolean;
    enableDelete?: boolean;
    inlets?: Inlets;
    outlets?: ItemListOutlets;
    text?: string;
}

export interface FileListOutlets extends Outlets {
    itemClicked?: Outlet;
}

export interface FileList extends Block, WithStyleOverride {
    files: Attachment[];
    enableDelete?: boolean;
    outlets?: FileListOutlets;
    inlets?: Inlets;
}

export interface Attachment {
    url: string;
    title: string;
    type: string; // mime or extension

    thumbnailUrl?: string;
    size?: number;
}

export interface FormFieldRadioGroup extends Block, WithStyleOverride {
    group: string;
    blocks: Block[];
    outlets?: FormFieldOutlets;
    validationScheme?: FormFieldValidationScheme;
}

export interface FormFieldRadio extends FormField {
    group: string;
}

export interface Map extends Block, WithStyleOverride {
    // despite inaccurate typings, react-map-gl allows any valid CSS value,
    // see https://visgl.github.io/react-map-gl/docs/api-reference/static-map#width
    width?: number | string;
    height?: number | string;

    // these set up the initial viewport's position on the map,
    // it's advisable to set it up approximately to the area
    // of interest for the particular project, which is
    // e.g. a city or a district, where the proposals are taking place
    latitude?: number;
    longitude?: number;
    zoom?: number;

    /** If false, it's just a static display of the data */
    interactive?: boolean;
    canAddPin?: boolean;
    /** Path to the list items to be displayed in the map */
    listItemsPath?: string;
    /** Hardcoded pins */
    pins?: MapPin[];

    inlets?: Inlets;
    outlets?: FormFieldOutlets;
}

export interface MapPin {
    longitude: number;
    latitude: number;
    label?: string;
}

export interface MapSearch extends FormField {
    placeholder?: string;
    addressFormat?: {
        /** e.g. cs-CZ */
        culture?: string;

        // TODO - umoznit totalne custom format, kde pouziju jednotlivy fields z respone od Bing maps Location endpointu
        // template: string; // template = '{{addressLine}}, {{ zipCode }}, {{ city }}'
    };
    outlets?: FormFieldOutlets;
    inlets?: Inlets;
}

export interface ProposalsList extends Block, WithStyleOverride {
    itemsPath: string;
    inlets?: {
        highlightedProposalId?: Inlet;
    };
}

// Dialog
export interface SMSAuthenticationOutlets extends Outlets {
    success?: Outlet;
}
export interface SMSAuthentication extends Block, WithStyleOverride {
    defaultPhoneCode?: string;
    filterPhoneCodes?: string[];
    autoFocus?: boolean;
    outlets?: SMSAuthenticationOutlets;
}
export interface PinAuthenticationOutlets extends Outlets {
    success?: Outlet;
}
export interface PinAuthentication extends Block, WithStyleOverride {
    outlets?: PinAuthenticationOutlets;
    entityName: string; // defines name of the entity which is related to PIN
    entityId: string; // defines ID of the entity which is related to PIN
    checkWhitelist?: boolean;
    validationScheme?: FormFieldValidationScheme;
    placeholder?: string;
    buttonText: string;
    buttonIcon?: Icon;
}
export interface DialogStepper extends Block, WithStyleOverride {
    id: string;
    defaultStep: string;
    steps: Record<string, Block[]>; // step name -> Blocks[]
}

export interface SwitchInlets {
    case?: string;
}
export interface Switch extends Block, WithStyleOverride {
    defaultCase: string;
    cases: Record<string, Block[]>; // step name -> Blocks[]
    inlets: Inlets;
}

export interface TimeSwitchInlets {
    fromTime?: string;
    toTime?: string;
    startImmediately?: boolean;
}

export interface Intervals {
    before: Block[];
    within: Block[];
    after: Block[];
}
export interface TimeSwitch extends Block, WithStyleOverride {
    intervals: Intervals;
    fromTime: string;
    toTime: string;
    inlets?: Inlets;
    startImmediately: boolean;
}

// Result List
export interface ResultListItem {
    portraitSrc?: string;
    subPortraitSrc?: string;
    text?: string;
}
export interface ResultListInlets {
    values?: Result.Values;
    respondents?: number;
}
export interface ResultList extends Block, WithStyleOverride {
    method: ResultMethod;
    graphName: string; // Graph block name
    graphThemeOverride?: GraphTheme; // Graph block name
    percentagePrecision?: number; // default is 0
    items: Record<string, ResultListItem>; // item id -> ResultListItem
    inlets?: Inlets;
}
export interface OverlapResultListInlets {
    values?: Result.Values;
    overlap?: Record<string, Result.Values>;
}
export interface OverlapResultList extends Block, WithStyleOverride {
    baselineOptionId: string;
    detailed?: boolean;
    graphName: string; // Graph block name
    graphThemeOverride?: GraphTheme; // Graph block name
    percentagePrecision?: number; // default is 0
    items: Record<string, ResultListItem>; // item id -> ResultListItem
    inlets?: Inlets;
}

export interface UnorderedList extends Block, WithStyleOverride {
    items: string[];
    useMarkdown?: boolean;
    itemSpacing?: string;
}

// Menu
export interface Menu extends Block, WithStyleOverride {
    logo?: Block;
    additionalLogo?: Block;
    items: Block[];
    align?: HorizontalAlign;
    itemsMargin?: string;
    showOnlyOnMobile?: boolean;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

export interface MenuItemTheme {
    color?: string;
}
export interface MenuItemOutlets extends Outlets {
    click?: Outlet;
}
export interface MenuItemInlets {
    selected?: boolean;
}
export interface MenuItem extends Block, WithStyleOverride {
    text: string;
    subitems?: Block[];
    outlets?: LinkButtonOutlets;
    inlets?: Inlets;
    themeOverride?: MenuItemTheme;
    onTablet?: {
        styleOverride?: CSSObject;
    };
    onPhone?: {
        styleOverride?: CSSObject;
    };
}

// === TEMPLATING LOGIC

export interface IfBlock extends Block {
    condition: string;
    data: Record<string, any>;
    blocksIfTrue: Block[];
    blocksIfFalse?: Block[];
}

// Customized
export interface SchoolPbResultsPanel extends Block, WithStyleOverride {
    resultsId: string;
    resultId: string;
}
