import { noop as _noop, forEach as _forEach, get as _get } from 'lodash';
import * as yup from 'yup';

import { FormFieldValidationScheme } from '../../../core/block';

const phoneRegExp = /^(?=.*\d)[\d ]+$/;

const jsonToYup = (baseSchema: yup.Schema<any>, validationSchemaJson: FormFieldValidationScheme): yup.Schema<any> => {
    let result = baseSchema;
    _forEach(validationSchemaJson, (params: any[], validatorName: string) => {
        // phone
        if (validatorName === 'phone') {
            result = _get(result, 'matches', _noop).call(result, phoneRegExp, ...params);
        }
        // general YUP validator
        else {
            result = _get(result, validatorName, _noop).call(result, ...params);
        }
    });

    return result;
};

export default jsonToYup;
