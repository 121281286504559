import { Errors as VotingEngineErrors } from '../voting/engine/errors';

const globalTranslations = {
    // Application
    Question: {
        cs: 'Otázka',
        sk: 'Otázka',
        en: 'Question',
        be: 'Пытанне',
    },
    Step: {
        cs: 'Krok',
        sk: 'Krok',
        en: 'Step',
        be: 'Крок',
    },
    // SMS / PIN Auth
    WE_CANNOT_CONFIRM_THE_SMS_CODE_RIGHT_NOW: {
        cs: 'Momentálně nemůžeme potvrdit kód SMS.',
        sk: 'Momentálne nemôžeme potvrdiť kód SMS.',
        en: 'We cannot confirm the SMS code right now.',
        be: 'Зараз мы не можам пацвердзіць SMS.',
    },
    THE_VERIFICATION_CODE_IS_INVALID: {
        cs: 'Ověřovací kód není správný.',
        sk: 'Overovací kód nie je správny.',
        en: 'The verification code is invalid.',
        be: 'од праверкі несапраўдны.',
    },
    ENTER_YOUR_PHONE_NUMBER: {
        cs: 'Zadejte telefonní číslo, kam vám můžeme zaslat kód pro vstup do hlasování.',
        sk: 'ZZadajte telefónne číslo, kam vám môžeme zaslať kód pre vstup do hlasovania.',
        en: 'Enter your phone number where we will send you a code to enter the poll.',
        be: 'Увядзіце нумар тэлефона, куды мы можам адправіць вам код, каб увайсці ў апытанне.',
    },
    SEND_ME_A_CODE_AGAIN: {
        cs: 'Zašlete mi kód znova.',
        sk: 'Zašlite mi kód znova.',
        en: 'Send me a code again.',
        be: 'Дашліце мне яшчэ раз код.',
    },
    USE_DIFFERENT_PHONE_NUMBER: {
        cs: 'Použít jiné telefonní číslo.',
        sk: 'Použiť iné telefónne číslo.',
        en: 'Use different phone number.',
        be: 'Выкарыстоўвайце іншы нумар тэлефона.',
    },
    SEND_ME_A_CODE: {
        cs: 'Zaslat kód',
        sk: 'Zaslať kód',
        en: 'Send me a code',
        be: 'Дашліце мне код',
    },
    ENTER_THE_POLL: {
        cs: 'Vstoupit do hlasování',
        sk: 'Vstúpiť do hlasovania',
        en: 'Enter the poll',
        be: 'Галасаваць',
    },
    PHONE_NUMBER_MUST_BE_IN_CORRECT_FORMAT: {
        cs: 'Telefonní číslo musí mít správný formát.',
        sk: 'Telefónne číslo musí mať správny formát.',
        en: 'The phone number must be in the correct format.',
        be: 'Нумар тэлефона павінен быць у правільным фармаце.',
    },
    ENTER_THE_CODE_WE_SENT: {
        cs: 'Zadejte kód, který jsme vám zaslali na telefonní číslo {{PHONE}}.',
        sk: 'Zadajte kód, ktorý sme vám zaslali na telefónne číslo {{PHONE}}.',
        en: 'Enter the code we sent you to {{PHONE}} here.',
        be: 'Увядзіце код, які мы вам даслалі па адрасе {{PHONE}}.',
    },
    PIN_MUST_NOT_BE_BLANK: {
        cs: 'Pin nesmí být prázdný text.',
        sk: 'Pin nesmie byť prázdny text.',
        en: 'Pin must not be blank',
        be: '',
    },
    // Vote Engine
    [VotingEngineErrors.NO_VOTES]: {
        cs: 'Je potřeba udělit alespoň jeden hlas.',
        sk: 'Je potrebné udeliť aspoň jeden hlas.',
        en: 'You must cast at least one vote',
        be: 'Вы павінны аддаць хаця б адзін голас',
    },
    [VotingEngineErrors.TOO_MANY_VOTES]: {
        cs: 'Udělil/a jste příliš mnoho hlasů.',
        sk: 'Udelil/a ste príliš veľa hlasov.',
        en: 'You have used too many votes.',
        be: 'Вы выкарысталі занадта шмат галасоў',
    },
    [VotingEngineErrors.TOO_MANY_PLUS_VOTES]: {
        cs: 'Udělil/a jste příliš mnoho plusových hlasů.',
        sk: 'Udelil/a ste príliš veľa plusových hlasov.',
        en: 'You have used too many plus votes.',
        be: 'Вы выкарысталі занадта шмат галасоў.',
    },
    [VotingEngineErrors.TOO_MANY_MINUS_VOTES]: {
        cs: 'Udělil/a jste příliš mnoho mínusových hlasů.',
        sk: 'Udelil/a ste príliš veľa mínusových hlasov.',
        en: 'You have used too many minus votes.',
        be: 'Вы выкарысталі занадта шмат галасоў.',
    },
    [VotingEngineErrors.RULE_2_1_BROKEN]: {
        cs: 'Musíte udělit alespoň 2 plusové hlasy za každý minusový.',
        sk: 'Musíte udeliť aspoň 2 plusové hlasy za každý mínusový.',
        en: 'You must cast at least 2 plus votes for each minus vote.',
        be: 'Вы павінны пакінуць як мінімум два "за" за кожнае "супраць"',
    },
    // School PB
    DATE_OF_THE_EVENT: {
        cs: 'Datum konání',
        sk: 'Dátum konania',
    },
    SET_IN_THE_PREPARATION_STEP: {
        cs: 'nastavené v kroku Příprava',
        sk: 'nastavenej v kroku Príprava',
    },
    TOTAL_PROJECTS: {
        cs: 'Celkem projektů',
        sk: 'Celkom projektov',
    },
    SET_IN_THE_APPROVAL_STEP: {
        cs: 'nastavené v kroku Schvalování',
        sk: 'nastavenej v kroku Schvaľovanie',
    },
    VOTES_COUNT: {
        cs: 'Počet hlasů',
        sk: 'Počet hlasov',
    },
    SET_BASED_ON_THE_NUMBER_OF_PROJECTS: {
        cs: 'nastavené na základě počtu projektů',
        sk: 'nastavenej na základe počtu projektov',
    },
    TOTAL_NUMBER_OF_VOTING_STUDENTS: {
        cs: 'Celkový počet hlasujících žáků',
        sk: 'Celkový počet hlasujúcich žiakov',
    },
    PROJECT: {
        cs: 'Projekt',
        sk: 'Projekt',
    },
    VOTES: {
        cs: 'Hlasů',
        sk: 'Hlasov',
    },
    WINNER: {
        cs: 'Vítěz',
        sk: 'Víťaz',
    },
    DIPLOMA: {
        cs: 'Diplom',
        sk: 'Diplom',
    },
};

export default globalTranslations;
