import React, { useEffect, useRef } from 'react';

import * as Styled from './styled';
import Blocks from '../../blocks/Blocks';
import { Block } from '../../../core/block';

export interface ProposalsListItemProps extends ProposalsListItemData {
    isHightlighted?: boolean;
}

export interface ProposalsListItemData {
    // will e.g. contain raw proposal data, such as title, price, etc. for sorting and filtering
    attrs: any;
    blocks: Block[];
}

export const ProposalsListItem: React.FC<any> = (props: any) => {
    const { attrs, blocks, isHighlighted } = props;
    const proposalId = attrs.originalId;

    const listItemRef = useRef<HTMLLIElement | null>(null);
    useEffect(() => {
        if (isHighlighted) {
            listItemRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isHighlighted]);

    return (
        <Styled.ProposalListItemVisual isHighlighted={isHighlighted} key={proposalId} ref={listItemRef}>
            <Blocks blocks={blocks} />
        </Styled.ProposalListItemVisual>
    );
};
