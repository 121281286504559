import styled, { css } from 'styled-components';

import { Config } from './index';
import { color } from '../../stylesheets/shared';
import { cssDisableUserSelect, cssP } from '../../stylesheets/snippets';
import { ifHoverIsSupported } from '../../stylesheets/tools';

type ButtonProps = {
    config: Config;
};

export const Label = styled.div`
    ${cssP};
    color: ${color.blue};
    text-decoration: underline;

    p {
        ${cssP};
        color: ${color.blue};
        text-decoration: underline;
        padding: 0;
        margin: 0;
    }
`;

export const Button = styled.button<ButtonProps>`
    display: inline-block;
    background: none;
    cursor: pointer;
    ${cssDisableUserSelect};

    /* style override */
    ${(p: ButtonProps) => p.config.styleOverride}

    ${ifHoverIsSupported(css`
        &:hover {
            ${Label} {
                text-decoration: none;

                p {
                    text-decoration: none;
                }
            }
        }
    `)}
`;
