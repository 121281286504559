import styled from 'styled-components';

import { Config } from './index';

export const Image = styled.div<{ config: Config }>`
    position: relative;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${({ config }) => `url("${config.src}")`};

    /* style override */
    ${({ config }) => config.styleOverride}

    &:after {
        content: '';
        position: relative;
        display: block;
        padding-top: 100%;
    }
`;
