import React from 'react';

import { BlockProps, FormFieldRadio as FormFieldRadioConfig } from '../../../../../core/block';
import * as Styled from './styled';

export type Config = FormFieldRadioConfig;
export type Props = BlockProps &
    Config & {
        onSubmit?: () => void;
        selectedRadioValue?: string; // indicates which radio of the group is selected
    };

const RadioButtonFormField = (props: Props) => {
    const { text, group, selectedRadioValue } = props;

    return (
        <Styled.RadioLabel config={props}>
            <input type="radio" name={group} value={text} defaultChecked={text === selectedRadioValue} />
            {text}
        </Styled.RadioLabel>
    );
};
export default RadioButtonFormField;
