import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/app';
import 'firebase/auth';

function useAuthentication(): [any | undefined, boolean, any | undefined] {
    const [user, loading, error] = useAuthState(firebase.auth());

    return [user, loading, error];
}

export default useAuthentication;
