enum CustomIcons {
    FULL_ARROW_LEFT = 'full_arrow_left',
    FULL_ARROW_RIGHT = 'full_arrow_right',
    LINK = 'link',
    OPEN_LINK = 'open_link',
    LINK_MARK = 'link_mark',
    FACEBOOK = 'facebook',
    TWITTER = 'twitter',
    GOOGLE = 'google',
    GOOGLE_SOLID = 'google_solid',
    ODNOKLASSNIKI = 'odnoklassniki',
    VKONTAKTE = 'vkontakte',
    VIBER = 'viber',
    TELEGRAM = 'telegram',
    INSTAGRAM = 'instagram',
    PENTAGON = 'pentagon',
    YOUTUBE = 'youtube',
    SPOTIFY = 'spotify',
    // flags
    FLAG_CZECH_REPUBLIC = 'flag_czech_republic',
    FLAG_BELARUS = 'flag_belarus',
    FLAG_SWITZERLAND = 'flag_switzerland',
}

export default CustomIcons;
