import { get as _get, isNil as _isNil, unset as _unset, set as _set, isEmpty as _isEmpty } from 'lodash';

import { Voting as VotingActions } from '../../../../core/action';
import { Store } from '../interfaces';
import { Answer, Question } from '../../../../core/voting';

const reducer = (state: Store, payload: VotingActions.setChoices.Payload): Store => {
    const config: Question.StandardQuestionConfig | undefined = _get(state.question.config, [payload.q]);
    if (_isNil(config)) {
        console.error(`Cannot get config for Question with ID:${payload.q}.`);
        return state;
    }

    const newState: Store = {
        ...state,
    };

    const currentAnswer: Answer.Answer | undefined = _get(state.vote, [payload.q]);
    const newAnswer: Answer.Answer | undefined = {
        ...currentAnswer,
        ...payload.choices,
    };

    if (_isNil(newAnswer)) {
        _unset(newState, ['vote', payload.q]);
    } else {
        _set(newState, ['vote', payload.q], newAnswer);
    }

    // clear Vote store?
    if (_isNil(newState.vote) || _isEmpty(newState.vote)) {
        _unset(newState, ['vote']);
    }

    return newState;
};

export default reducer;
