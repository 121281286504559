import styled, { css } from 'styled-components';

import { Config } from './index';
import { onTablet, onPhone } from '../../../stylesheets/tools';
import VerticalSpace from '../../../blocks/VerticalSpace';
import hexToRgba from 'hex-to-rgba';
import { color } from '../../../stylesheets/shared';
import { cssInput } from '../../../stylesheets/snippets';

const DEFAULT_TEXT_ALIGN = 'left';

export const Space = styled(VerticalSpace)`
    ${() =>
        onPhone(css`
            height: 15px;
            width: 100%;
        `)}
`;

export const InputBox = styled.div<{ config: Config }>`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;

    /* style override */
    ${({ config }) => config.styleOverride}

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;

export const String = styled.div<{ config: Config }>`
    box-sizing: border-box;

    .ant-input {
        ${cssInput};
        background-color: ${color.white};
        border-color: ${color.grey2};
        border-radius: 12px;
        padding: 5px 12px;
        appearance: none;
        text-align: ${({ config }) => (config.input.align ? config.input.align : DEFAULT_TEXT_ALIGN)};

        &:hover {
            border-color: ${hexToRgba(color.blue, 0.48)};
        }
        &:focus {
            box-shadow: none;
            border-color: ${color.blue};
        }
    }

    /* style override */
    ${({ config }) => config.input.styleOverride}

    /* on tablet */
    ${({ config }) =>
        config.input.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.input.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.input.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.input.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;
