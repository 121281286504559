import { isNil as _isNil } from 'lodash';

import { Source } from './interfraces';

export const DEFAULT = '3L9Up92APh62dxDr';
export const DATRON = 'A3nyc7fTts96gadm';

export default function get(src?: Source): string {
    if (_isNil(src)) {
        return DEFAULT;
    }

    switch (src) {
        case Source.DEFAULT:
            return DEFAULT;
        case Source.DATRON:
            return DATRON;
        default:
            throw new Error(`Cannot recognize source of invitation`);
    }
}
