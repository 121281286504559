import { defer as _defer } from 'lodash';

import { Effect, Browser as BrowserActions } from '../../../../core/action';

export default function (payload: BrowserActions.href.Payload): Effect {
    return async (): Promise<void> => {
        _defer(() => {
            window.location.href = payload;
        });
    };
}
