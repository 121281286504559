import { isNil as _isNil, trim as _trim } from 'lodash';
import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';

import { Page } from '../../../core/website';
import { Question, Urls } from '../../../core/voting';

function usePollStep(pollId: string, stepId: string): [Page | Question.Question | undefined, boolean, any | undefined] {
    const [config, loading, error] = useObjectVal<Page | Question.Question>(firebase.database().ref(Urls.pollStep(pollId, stepId)));

    if (_isNil(pollId) || _isNil(stepId) || _trim(pollId) === '' || _trim(stepId) === '') {
        return [undefined, true, undefined];
    }

    return [config, loading, error];
}

export default usePollStep;
