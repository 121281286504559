import { isNil as _isNil } from 'lodash';
import React, { useEffect } from 'react';

import { StandardPage } from '../../../../core/website';
import PageFooter from '../../PageFooter/Standard';
import Blocks from '../../../blocks/Blocks';
import BlocksRef from '../../../blocks/BlocksRef';
import { PageHeader, getHeaderHeight } from '../../PageHeader';
import HorizontalSpace from '../../../blocks/HorizontalSpace';
import { useOutlets } from '../../../hooks';
import { HORIZONTAL_SPACE } from '../../../../core/block/name';
import * as Styled from './styled';

const Page = (props: StandardPage) => {
    const outlet = useOutlets();

    useEffect(() => {
        if (!_isNil(props.outlets?.enter)) {
            outlet(props.outlets?.enter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Styled.Wrapper containsFooter={!_isNil(props.footer)}>
            {!_isNil(props.header) && (
                <>
                    <PageHeader styleOverride={props.headerStyleOverride}>
                        <Blocks blocks={props.header} />
                    </PageHeader>
                    <HorizontalSpace size={getHeaderHeight(props.headerStyleOverride)} name={HORIZONTAL_SPACE} />
                </>
            )}
            <Styled.Page {...props.layout}>
                {/* blocks */}
                {!_isNil(props.blocks) && <Blocks blocks={props.blocks} />}
                {/* blocks reference */}
                {!_isNil(props.blocksRef) && <BlocksRef reference={props.blocksRef} />}
            </Styled.Page>
            {/* footer */}
            {!_isNil(props.footer) && (
                <PageFooter styleOverride={props.footerStyleOverride}>
                    <Blocks blocks={props.footer} />
                </PageFooter>
            )}
        </Styled.Wrapper>
    );
};
export default Page;
