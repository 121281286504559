import { Voting as VotingActions } from '../../../../core/action';
import { Store } from '../interfaces';

const reducer = (state: Store, payload: VotingActions.resultEnter.Payload): Store => {
    return {
        ...state,
        result: {
            ...state.result,
            config: {
                ...state.result.config,
                [payload.result.id]: payload.result.config,
            },
        },
    };
};

export default reducer;
