import { Voting as VotingActions } from '../../../../core/action';
import { Store } from '../interfaces';

const reducer = (state: Store, payload: VotingActions.pollEnter.Payload): Store => {
    return {
        ...state,
        config: payload.poll,
    };
};

export default reducer;
