import { isNil as _isNil } from 'lodash';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const StyledIcon = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    fill: currentColor;
`;

export const StyledIconWrapper = styled.i`
    position: relative;
    width: 1em;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &:before {
        position: relative;
        content: '';
        display: block;
        padding-top: 100%;
    }
`;

type Props = HTMLAttributes<HTMLElement> & {
    onClick?: () => void;
};

const Icon = (props: Props) => {
    const onClick = () => {
        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <StyledIconWrapper onClick={onClick} className={props.className}>
            <StyledIcon viewBox="0 0 1024 1024">
                <path d="M461.6 721.275c-79.384-8.279-150.96-27.83-212.241-75.759-7.604-5.963-15.454-11.711-22.393-18.371-26.822-25.761-29.525-55.264-8.301-85.668 18.157-26.018 48.636-32.978 80.317-18.039 6.134 2.896 11.979 6.51 17.578 10.392 114.207 78.472 271.098 80.639 385.733 3.528 11.357-8.708 23.498-15.808 37.568-19.433 27.359-7.025 52.873 3.024 67.555 26.962 16.773 27.337 16.559 54.020-4.108 75.244-31.702 32.539-69.828 56.079-112.191 72.509-40.057 15.529-83.931 23.348-127.355 28.538 6.553 7.132 9.641 10.639 13.738 14.757 58.964 59.243 118.186 118.239 176.957 177.664 20.023 20.248 24.206 45.355 13.181 68.906-12.055 25.739-39.038 42.673-65.495 40.861-16.763-1.158-29.836-9.502-41.451-21.203-44.497-44.775-89.83-88.725-133.425-134.348-12.687-13.266-18.79-10.757-29.986 0.761-44.765 46.084-90.259 91.46-136.021 136.578-20.548 20.259-45.001 23.905-68.831 12.333-25.332-12.28-41.451-38.137-40.207-64.123 0.858-17.578 9.502-31.005 21.567-43.049 58.353-58.267 116.545-116.684 174.758-175.048 3.85-3.882 7.443-8.001 13.052-13.996z" />
                <path d="M509.957 518.311c-141.619-0.483-257.745-117.885-256.92-259.729 0.815-143.41 117.017-258.979 260.030-258.582 143.303 0.386 258.325 117.617 257.606 262.518-0.729 141.555-117.66 256.287-260.716 255.794zM638.663 258.925c-0.247-70.429-56.337-126.497-126.626-126.562-70.879-0.075-127.473 57.012-126.926 128.084 0.526 70.161 57.098 125.832 127.58 125.575 70.268-0.247 126.208-56.68 125.972-127.098z" />
            </StyledIcon>
        </StyledIconWrapper>
    );
};

export default Icon;
