import { isUndefined as _isUndefined, isNil as _isNil } from 'lodash';

import { Effect, Tools as ActionTools, create as createAction, Authentication as AuthenticationActions } from '../../../../core/action';
import * as AuthTools from '../../../tools/authentication';

export default function (payload: AuthenticationActions.checkAuthenticationRedirectResult.Payload): Effect {
    return async (dispatch: any): Promise<void> => {
        return AuthTools.authenticationRedirectResult()
            .then((user: any) => {
                if (_isUndefined(user)) {
                    console.warn(`Auth not redirected`);
                    if (!_isNil(payload.onNotRedirected)) {
                        ActionTools.processAction(dispatch, payload.onNotRedirected);
                    }
                } else {
                    console.warn(`Auth redirection success, user: ${JSON.stringify(user)}`);
                    dispatch(createAction(AuthenticationActions.checkAuthenticationRedirectResultSuccess.TYPE));
                    if (!_isNil(payload.onSuccess)) {
                        ActionTools.processAction(dispatch, payload.onSuccess);
                    }
                }
            })
            .catch((error: string) => {
                console.warn(`Auth redirect failed because: ${error}`);
                dispatch(createAction(AuthenticationActions.checkAuthenticationRedirectResultError.TYPE));
                if (!_isNil(payload.onFail)) {
                    ActionTools.processAction(dispatch, payload.onFail);
                }
            });
    };
}
