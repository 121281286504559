import { toString as _toString, isNil as _isNil, defer as _defer } from 'lodash';
import React, { useState } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux';

import {
    BlockProps,
    Tools,
    PinAuthentication as PinAuthenticationConfig,
    FormFieldString as FormFieldStringConfig,
    HorizontalAlign,
} from '../../../../core/block';
import { currentLanguage } from '../../../store/application/selectors';
import { formFieldValue } from '../../../store/form/selectors';
import { FieldValue } from '../../../store/form/interfaces';
import { RootState } from '../../../store/root_reducer';
import { translate as t } from '../../../../core/translation';
import { stringIsEmpty } from '../../../../core/tools';
import { Authentication as AuthenticationActions, create as createAction, Form as FormActions } from '../../../../core/action';
import HorizontalSpace from '../../HorizontalSpace';
import SecondaryCTA from '../../SecondaryCTA';
import StringField from '../../FormField/String';
import { useOutlets } from '../../../hooks';
import * as Styled from './styled';

export type Config = PinAuthenticationConfig;
export type Props = BlockProps & Config;

const FORM_ID = 'pin_authentication_code_form';
const FIELD_NAME = 'pin';

const PinAuthentication = (props: Props) => {
    const dispatch = useDispatch();
    const outlet = useOutlets();
    const [pinFormInProgress, setPinFormInProgress] = useState<boolean>(false);
    const [pinVerificationError, setPinVerificationError] = useState<string>('');
    const rootStore = useStore<RootState>();
    const currLang = useSelector(currentLanguage());

    const getPin = (): FieldValue => formFieldValue({ f: FORM_ID, fn: FIELD_NAME })(rootStore.getState());

    ///////////////////////////////////
    // pin
    const pinFieldConfig: FormFieldStringConfig = {
        name: '',
        align: HorizontalAlign.CENTER,
        autoFocus: true,
        styleOverride: { maxWidth: '256px' },
        outlets: {
            change: {
                type: '@FORM:field_change',
                payload: { f: FORM_ID, fn: FIELD_NAME, vs: '{{validationStatus}}', v: '{{value}}' },
            },
        },
    };
    ///////////////////////////////////

    const processSuccess = () => {
        dispatch(createAction(FormActions.clearForm.TYPE, FORM_ID));

        if (!_isNil(props.outlets?.success)) {
            outlet(props.outlets?.success);
        }
    };

    const onSubmit = async () => {
        setPinVerificationError('');
        setPinFormInProgress(true);

        const pin: string = _toString(getPin());
        if (stringIsEmpty(pin)) {
            setPinVerificationError(t('PIN_MUST_NOT_BE_BLANK', currLang));
            setPinFormInProgress(false);
            return;
        }

        const actionPayload: AuthenticationActions.signInWithPin.Payload = {
            pin,
            entityName: props.entityName,
            entityId: props.entityId,
            checkWhitelist: props.checkWhitelist || false,
        };

        try {
            await dispatch(createAction(AuthenticationActions.signInWithPin.TYPE, actionPayload));
            setPinVerificationError('');
            console.log('The PIN verification code is valid.');
            console.log('PIN authentication success.');
            setPinFormInProgress(false);
            _defer(() => processSuccess());
        } catch (error) {
            setPinVerificationError(t('THE_VERIFICATION_CODE_IS_INVALID', currLang));
            console.error(JSON.stringify(error));
            setPinFormInProgress(false);
        }
    };

    const onPinInputSubmit = () => {
        onSubmit();
    };

    return (
        <Styled.Dialog {...Tools.extractConfig<Config>(props)}>
            <StringField validationScheme={props.validationScheme} placeholder={props.placeholder} onSubmit={onPinInputSubmit} {...pinFieldConfig} />
            {pinVerificationError !== '' && <Styled.ErrorMessage>{pinVerificationError}</Styled.ErrorMessage>}
            <HorizontalSpace name="" size="12px" />
            <SecondaryCTA
                name={''}
                inProgress={pinFormInProgress}
                text={props.buttonText}
                icon={props.buttonIcon}
                inlets={{ validationStatus: { type: '#FORM:form_validation', payload: { f: FORM_ID } } }}
                onClick={onSubmit}
            />
        </Styled.Dialog>
    );
};
export default PinAuthentication;
