import { isNil as _isNil } from 'lodash';
import React from 'react';

import { BlockProps, DoubleSignBarGraph as DoubleSignBarGraphConfig, Tools } from '../../../../core/block';
import H3 from '../../Typography/H3';
import * as Styled from './styled';

export type Config = DoubleSignBarGraphConfig;
export type Props = BlockProps & Config;

const DoubleSignBarGraph = (props: Props) => {
    return (
        <Styled.DoubleSignBarGraph {...Tools.extractConfig<Config>(props)}>
            {/* top line */}
            <Styled.TopLine>
                {/* title */}
                <Styled.Title>{!_isNil(props.text) && <H3 name="" text={props.text} />}</Styled.Title>
            </Styled.TopLine>

            {/* bar line */}
            <Styled.BarLine>
                {/* numbers line */}
                <Styled.NumbersLine>
                    {/* minus */}
                    {(!_isNil(props.data[1]?.count) || !_isNil(props.data[1]?.percentage)) && (
                        <Styled.Numbers>
                            {!_isNil(props.data[1]?.percentage) && <Styled.Percentage>{props.data[1].percentage} %</Styled.Percentage>}
                            {!_isNil(props.data[1]?.count) && <Styled.Votes>({props.data[1].count})</Styled.Votes>}
                        </Styled.Numbers>
                    )}
                    {/* plus */}
                    {(!_isNil(props.data[0]?.count) || !_isNil(props.data[0]?.percentage)) && (
                        <Styled.Numbers>
                            {!_isNil(props.data[0]?.percentage) && <Styled.Percentage>{props.data[0].percentage} %</Styled.Percentage>}
                            {!_isNil(props.data[0]?.count) && <Styled.Votes>({props.data[0].count})</Styled.Votes>}
                        </Styled.Numbers>
                    )}
                </Styled.NumbersLine>

                <Styled.MinusBar size={props.data[1]?.barSize} />
                <Styled.PlusBar size={props.data[0]?.barSize} />
            </Styled.BarLine>
        </Styled.DoubleSignBarGraph>
    );
};
export default DoubleSignBarGraph;
