import React from 'react';

import { BlockProps, YouTube as YouTubeConfig, Tools } from '../../../../core/block';
import * as Styled from './styled';

export type Config = YouTubeConfig;
export type Props = BlockProps & Config;

const YouTube = (props: Props) => {
    return (
        <Styled.YouTube {...Tools.extractConfig<Config>(props)}>
            <Styled.Player url={props.url} width={'100%'} height={'100%'} />
        </Styled.YouTube>
    );
};
export default YouTube;
