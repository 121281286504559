import { Name as EntityName } from '../../../core/entity';
import { Streamer, Events, Event } from '../../../core/stream';
import { Action, Tools as ActionTools } from '../../../core/action';
import { isNil as _isNil } from 'lodash';

export const UPDATE_PROPOSALS_ACTION = `${EntityName.ACTION_PREFIX}SCHOOLPB:update_proposals`;

export interface UpdateProposalsPayload {
    proposals: string[];
    resultsId: string;
    pollId: string;
    onDone?: Action<any> | Action<any>[];
    locale: string;
}

export function updateProposals(payload: UpdateProposalsPayload) {
    return async (dispatch: any) => {
        console.log('dispatching update proposals: ', payload);
        const event: Event<UpdateProposalsPayload> = Events.generateProposalsEvent(payload);
        const result = await Streamer.stream(event);
        console.log('update proposals result: ', result);

        if (!_isNil(payload.onDone)) {
            ActionTools.processAction(dispatch, payload.onDone, result);
        }
    };
}
