import { isNil as _isNil, get as _get } from 'lodash';
import { StoreSelectorFunction } from '../../../../core/selector';
import { RootState } from '../../root_reducer';
import { Store } from '../interfaces';
import getStore from './get_store';

export default (payload: string): StoreSelectorFunction<boolean> => (rootStore: RootState): boolean => {
    const store: Store = getStore(rootStore);

    if (_isNil(store)) {
        return false;
    }

    return _get(store.winners, payload, false);
};
