import React from 'react';
import { isNil as _isNil } from 'lodash';
import { BlockProps, Affix as AffixLayoutConfig, Tools } from '../../../core/block';
import Blocks from '../Blocks';
import * as Styled from './styled';

export type Config = AffixLayoutConfig;
export type Props = BlockProps & Config;

const AffixLayout = (props: Props) => {
    return (
        <Styled.Affix
            offsetTop={!_isNil(props.offsetTop) ? props.offsetTop : 0}
            offsetBottom={!_isNil(props.offsetBottom) ? props.offsetBottom : 0}
            {...Tools.extractConfig<Config>(props)}
        >
            <Blocks blocks={props.blocks} />
        </Styled.Affix>
    );
};
export default AffixLayout;
