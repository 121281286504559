import { isNil as _isNil, defer as _defer } from 'lodash';
import React, { useState, useEffect } from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import { BlockProps, Tools, DatePicker as DatePickerConfig } from '../../../../core/block';
import { useOutlets, useInlets } from '../../../hooks';
import * as Styled from './styled';
import moment, { Moment } from 'moment';

export type Config = DatePickerConfig;
export type Props = BlockProps &
    Config & {
        onSubmit?: () => void;
    };

const HARDCODED_CS_DATE_FORMATS = ['DD.MM.YYYY', 'DD. MM. YYYY', 'D.M.YYYY', 'D. M. YYYY'];

const DatePicker = (props: Props) => {
    const outlet = useOutlets();
    const inlets = useInlets(props.inlets);
    const [date, setDate] = useState(moment(''));

    useEffect(() => {
        if (!props.inlets) {
            return;
        }

        if (Tools.isInletResolved(inlets.value) && !_isNil(inlets.value)) {
            const strMoment = inlets.value as string;
            if (strMoment.length !== 0) {
                const newDate = moment(strMoment);
                setDate(newDate);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets)]);

    const processChangeOutlet = (newDate: Moment) => {
        if (_isNil(props.outlets?.change)) {
            return;
        }

        const outletParameters = {
            value: newDate.toISOString(),
        };

        _defer(() => outlet(props.outlets?.change, outletParameters));
    };

    const handleChange = (newDate: Moment | null) => {
        // console.log('date change', newDate);
        if (!_isNil(newDate)) {
            setDate(newDate);
            processChangeOutlet(newDate);
        }
    };

    // console.log('=== date', date, date.isValid());

    return (
        <Styled.Date {...Tools.extractConfig<Config>(props)}>
            {/* label */}
            {!_isNil(props.text) && <Styled.Label>{props.text}</Styled.Label>}

            {/* date picker */}
            <AntDatePicker
                allowClear={false}
                format={[moment.localeData().longDateFormat('l'), ...HARDCODED_CS_DATE_FORMATS]}
                onChange={(value) => {
                    handleChange(value);
                }}
                value={date.isValid() ? date : null}
            ></AntDatePicker>
        </Styled.Date>
    );
};

export default DatePicker;
