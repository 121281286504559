import { isNil as _isNil } from 'lodash';
import styled, { css } from 'styled-components';

import { Question as CoreQuestion } from '../../../../core/voting';
import { constant, color, shadow, animation, gradient } from '../../../stylesheets/shared';
import Icon from '../../../blocks/Icon';
import { onTablet } from '../../../stylesheets/tools';
import { cssP, cssDisableUserSelect } from '../../../stylesheets/snippets';
import { ifHoverIsSupported } from '../../../stylesheets/tools';

const DEFAULT_QUESTION_ALIGN = 'left';

const questionSize = (size?: CoreQuestion.SizeType): string => {
    if (size === CoreQuestion.SizeType.STANDARD) {
        return `${constant.question.standard.width}px`;
    }
    if (size === CoreQuestion.SizeType.WIDE) {
        return `${constant.question.wide.width}px`;
    }

    // default size
    return `${constant.question.standard.width}px`;
};

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`;

type QuestionProps = CoreQuestion.Layout;
export const Question = styled.div<QuestionProps>`
    width: 100%;
    max-width: ${({ size }) => questionSize(size)};
    box-sizing: border-box;
    text-align: ${({ align }) => (!_isNil(align) ? align : DEFAULT_QUESTION_ALIGN)};
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 40px;

    ${onTablet(css`
        padding-right: 0;
        padding-left: 0;
        padding-top: 50px;
    `)}
`;

export const ScrollMarker = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
`;

// Header Info
export const HeaderInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
`;
export const HeaderStep = styled.div`
    text-align: center;
    ${cssP}
`;

// Header Back Button
interface HeaderBackButtonProps {
    hide?: boolean;
}
export const HeaderBackButton = styled.button<HeaderBackButtonProps>`
    position: fixed;
    top: 8px;
    left: 104px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-width: 2px;
    border-style: solid;
    border-color: ${color.violet};
    background: ${color.white};
    box-sizing: border-box;
    width: 42px;
    height: 42px;
    flex-grow: 0;
    flex-shrink: 0;
    visibility: ${(p: HeaderBackButtonProps) => (p.hide === true ? 'hidden' : 'visible')} ${cssDisableUserSelect};
    transition: box-shadow ${animation.timing.interactive} ease-out;
    ${cssDisableUserSelect};

    ${ifHoverIsSupported(css`
        &:hover {
            background: ${gradient.sweetLight};
        }
    `)}

    ${onTablet(css`
        position: relative;
        top: auto;
        left: auto;
        margin-right: 8px;
        width: 34px;
        height: 34px;
    `)}
`;
export const HeaderBackButtonIcon = styled(Icon)`
    font-size: 24px;
    color: ${color.black};
`;

// Header Basket Button
export const HeaderBasketButton = styled.button`
    display: none;
    background: ${color.white};
    box-sizing: border-box;
    width: 42px;
    height: 42px;
    flex-grow: 0;
    flex-shrink: 0;
    ${cssDisableUserSelect};

    ${onTablet(css`
        display: block;
        position: relative;
        top: auto;
        left: auto;
        margin-left: 8px;
        width: 34px;
        height: 34px;
    `)}
`;

// Header
interface HeaderProps {
    scrolled: boolean;
    size?: CoreQuestion.SizeType;
}
export const Header = styled.div<HeaderProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: ${({ size }) => questionSize(size)};
    margin: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: ${color.white};
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 0 0 12px 12px;
    border-left: 1px solid ${color.grey3};
    border-right: 1px solid ${color.grey3};
    border-bottom: 1px solid ${color.grey3};
    z-index: 100;
    box-shadow: ${(p: HeaderProps) => (p.scrolled ? shadow.standard : '')};
    transition: box-shadow ${animation.timing.interactive} ease-out;

    ${onTablet(css`
        border-left: none;
        border-right: none;
        max-width: none;
        border-radius: 0;
        padding-left: 8px;
        padding-right: 8px;
        align-items: center;
    `)}

    /* use shadow also on back button ... but just on desktop */
    ${(p: HeaderProps) =>
        p.scrolled &&
        css`
            ${HeaderBackButton} {
                box-shadow: ${shadow.standard};

                ${onTablet(css`
                    box-shadow: none;
                `)}
            }
        `}
`;

// Content
export const Content = styled.div`
    width: 100%;
`;
