import React from 'react';

import { BlockProps, StandardVotingOption as StandardVotingOptionConfig, StandardVotingOptionInlets, Tools } from '../../../../core/block';
import { useInlets } from '../../../hooks';
import Blocks from '../../Blocks';
import * as Styled from './styled';

export type Config = StandardVotingOptionConfig;
export type Props = BlockProps & Config;

const VotingOption = (props: Props) => {
    const usedInlets = useInlets(props.inlets) as StandardVotingOptionInlets;

    return (
        <Styled.Option {...Tools.extractConfig<Config>(props)} usedInlets={usedInlets}>
            {/* text */}
            <Styled.Name>{props.text}</Styled.Name>
            {/* blocks */}
            {props.blocks && <Blocks blocks={props.blocks} />}
        </Styled.Option>
    );
};
export default VotingOption;
