import { isNil as _isNil, defer as _defer, isArray as _isArray } from 'lodash';

import { Action, Tools as ActionTools } from '../../../core/action';

const process = (dispatch: any, action: Action<any>, value?: any): any => {
    if (!_isNil(value)) {
        const payload = ActionTools.resolvePayloadTemplate(action, { value });
        return dispatch({
            ...action,
            payload,
        });
    } else {
        return dispatch(action);
    }
};

const processSequence = async (dispatch: any, actions: Action<any>[], value?: any): Promise<void> => {
    for (const action of actions) {
        await process(dispatch, action, value);
    }
};

export default (dispatch: any, actions: Action<any> | Action<any>[], value?: any) => {
    if (!_isNil(actions)) {
        _defer((actions) => {
            if (_isArray(actions)) {
                processSequence(dispatch, actions, value);
            } else {
                process(dispatch, actions, value);
            }
        }, actions);
    }
};
