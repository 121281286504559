import { isNil as _isNil } from 'lodash';
import styled, { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';

import { Config } from './index';
import IconComp from '../Icon';
import { font, color, animation, meaningColor } from '../../stylesheets/shared';
import { cssDisableUserSelect } from '../../stylesheets/snippets';
import { ifHoverIsSupported } from '../../stylesheets/tools';
import { Choice } from '../../../core/voting';
import { VoteButtonInlets } from '../../../core/block';

type ButtonProps = {
    config: Config;
    usedInlets: VoteButtonInlets;
};

const getMeaningColor = (p: ButtonProps): string => {
    // color override
    if (p.config.themeOverride?.color) {
        return p.config.themeOverride?.color;
    }

    // default color by meaning
    return p.config.meaning === Choice.Meaning.PLUS ? meaningColor.plus : meaningColor.minus;
};

const getButtonPadding = (p: ButtonProps): string => {
    // text + icon
    if (!_isNil(p.config.icon) && !_isNil(p.config.text)) {
        return '8px 32px 8px 24px';
    }
    // just text
    else if (_isNil(p.config.icon) && !_isNil(p.config.text)) {
        return '8px 32px';
    }
    // just icon
    else if (!_isNil(p.config.icon) && _isNil(p.config.text)) {
        return '8px 12px 8px 24px';
    }

    return '8px 32px';
};

export const Icon = styled(IconComp)`
    font-size: 24px;
    margin-right: 12px;
`;

export const Label = styled.div`
    font-family: ${font.family};
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 24px;
`;
export const MDLabel = styled.div`
    font-family: ${font.family};
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
`;

export const Button = styled.button<ButtonProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: ${(p: ButtonProps) => getButtonPadding(p)};
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    border-color: ${(p: ButtonProps) => (p.usedInlets.selected ? getMeaningColor(p) : color.grey2)};
    box-sizing: border-box;
    background-color: ${(p: ButtonProps) => (p.usedInlets.selected ? getMeaningColor(p) : hexToRgba(getMeaningColor(p), 0.04))};
    ${cssDisableUserSelect};
    transition: background-color ${animation.timing.interactive} ease-out, transform ${animation.timing.interactive} ease-out;

    /* style override */
    ${(p: ButtonProps) => p.config.styleOverride}

    /* enable hover only in stand-by state */
    ${(p: ButtonProps) =>
        !p.usedInlets.selected &&
        css`
            ${ifHoverIsSupported(css`
                &:hover {
                    background-color: ${(p: ButtonProps) => hexToRgba(getMeaningColor(p), 0.24)};
                }
            `)}
        `}

    &:active {
        transform: scale(0.97);
    }

    /* change label based on "selected" */
    ${Label} {
        color: ${(p: ButtonProps) => (p.usedInlets.selected ? color.white : color.black)};
    }
    ${MDLabel} {
        color: ${(p: ButtonProps) => (p.usedInlets.selected ? color.white : color.black)};
    }

    /* change icon based on "selected" */
    ${Icon} {
        color: ${(p: ButtonProps) => (p.usedInlets.selected ? color.white : getMeaningColor(p))};
    }
`;
