import { isNil as _isNil } from 'lodash';
import React from 'react';

import { BlockProps, GeneralBlock as GeneralBlockConfig, Tools } from '../../../core/block';
import { useOutlets } from '../../hooks';
import Blocks from '../Blocks';
import * as Styled from './styled';

export type Config = GeneralBlockConfig;
export type Props = BlockProps &
    Config & {
        onClick?: () => void;
    };

const GeneralBlock = (props: Props) => {
    const outlet = useOutlets();

    const processClickOutlet = async () => {
        if (_isNil(props.outlets?.click)) {
            return;
        }

        await outlet(props.outlets?.click);
    };

    const onClick = () => {
        processClickOutlet();

        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <Styled.GeneralBlock {...Tools.extractConfig<Config>(props)} onClick={onClick}>
            {!_isNil(props.blocks) && <Blocks blocks={props.blocks} />}
        </Styled.GeneralBlock>
    );
};
export default GeneralBlock;
