import { remove as _remove, cloneDeep as _cloneDeep } from 'lodash';

import { Store } from '../interfaces';
import { SchoolPb as SchoolPbActions } from '../../../../core/action';

const reducer = (state: Store, payload: SchoolPbActions.removeProposal.Payload): Store => {
    if (state.proposals.length !== 0) {
        const newProposals = _cloneDeep(state.proposals);
        _remove(newProposals, function (n, index) {
            return index === payload.itemIndex;
        });
        const newState: Store = {
            ...state,
            proposals: newProposals,
        };
        return newState;
    }
    return state;
};

export default reducer;
