import { map as _map } from 'lodash';
import React from 'react';
import moment from 'moment';

import { BlockProps, Harmonogram as HarmonogramConfig, HarmonogramRecord, HarmonogramRecordType, Tools } from '../../../core/block';
import getMarkdown from '../../tools/markdown';
import { stringIsEmpty } from '../../../core/tools';
import * as Styled from './styled';

export type Config = HarmonogramConfig;
export type Props = BlockProps & Config;

export enum RecordState {
    VERY_PAST = 'very-past',
    PAST = 'past',
    ACTIVE = 'active',
    FUTURE = 'future',
}

const md = getMarkdown();

const Harmonogram = (props: Props) => {
    const getRecordState = (record: HarmonogramRecord): RecordState => {
        if (record.displayItAsVeryPast === true) {
            return RecordState.VERY_PAST;
        }

        const now = moment();
        const f = stringIsEmpty(record.timing?.from || '') ? '1918-10-28' : record.timing?.from;
        const t = stringIsEmpty(record.timing?.to || '') ? '2112-12-21' : record.timing?.to;

        // past?
        if (now.isAfter(t)) {
            return RecordState.PAST;
        }
        // active?
        if (now.isBetween(f, t, undefined, '[]')) {
            return RecordState.ACTIVE;
        }
        // future
        if (now.isBefore(f)) {
            return RecordState.FUTURE;
        }

        return RecordState.PAST;
    };

    return (
        <Styled.Harmonogram {...Tools.extractConfig<Config>(props)}>
            <Styled.ProgressLine />

            {_map(props.records, (record: HarmonogramRecord) => {
                if (record.type === HarmonogramRecordType.GROUP_SEPARATOR) {
                    return (
                        <Styled.Record>
                            <Styled.GroupSeparator />
                        </Styled.Record>
                    );
                }
                if (record.type === HarmonogramRecordType.YEARS_SEPARATOR) {
                    return (
                        <Styled.Record>
                            <Styled.YearsSeparator />
                        </Styled.Record>
                    );
                }
                if (record.type === HarmonogramRecordType.STANDARD) {
                    return (
                        <Styled.Record>
                            {/* time */}
                            <Styled.RecordTime>
                                <Styled.TimeCapsule
                                    state={getRecordState(record)}
                                    themeOverride={props.themeOverride}
                                    dangerouslySetInnerHTML={{ __html: md.renderInline(record.timeLabel || '') }}
                                />
                            </Styled.RecordTime>

                            {/* info */}
                            <Styled.RecordInfo>
                                <Styled.Text
                                    state={getRecordState(record)}
                                    dangerouslySetInnerHTML={{ __html: md.renderInline(record.text || '') }}
                                />
                            </Styled.RecordInfo>
                        </Styled.Record>
                    );
                }
            })}
        </Styled.Harmonogram>
    );
};
export default Harmonogram;
