import { isNil as _isNil, size as _size, map as _map } from 'lodash';
import React from 'react';
import { Dropdown as AntDropdown } from 'antd';

import Block from '../../Block';
import { BlockProps, MenuItem as MenuItemConfig, MenuItemInlets, Tools, IconSets } from '../../../../core/block';
import { useOutlets, useInlets, useOnTablet } from '../../../hooks';
import * as Styled from './styled';

export type Config = MenuItemConfig;
export type Props = BlockProps &
    Config & {
        onClick?: () => void;
    };

const MenuItem = (props: Props) => {
    const isTablet = useOnTablet();
    const outlet = useOutlets();
    const inlets = useInlets(props.inlets) as MenuItemInlets;

    const selected = !_isNil(props.inlets?.selected) && Tools.isInletResolved(inlets.selected) ? inlets.selected : false;

    const onClick = () => {
        if (!_isNil(props.outlets?.click)) {
            outlet(props.outlets?.click);
        }

        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    // dropdown
    if (!_isNil(props.subitems) && _size(props.subitems) > 0) {
        // on desktop
        if (!isTablet) {
            const subMenu = (
                <Styled.Menu>
                    {_map(props.subitems, (si, isIdx: number) => (
                        <Block key={isIdx} {...si} />
                    ))}
                </Styled.Menu>
            );

            return (
                <AntDropdown overlay={subMenu} trigger={['hover']}>
                    <Styled.Button {...Tools.extractConfig<Config>(props)} selected={selected} onClick={onClick}>
                        {props.text}
                        <Styled.ButtonIcon name="" icon={IconSets.Material.EXPAND_MORE} />
                    </Styled.Button>
                </AntDropdown>
            );
        }
        // on mobile
        else {
            return (
                <>
                    <Styled.Button {...Tools.extractConfig<Config>(props)} selected={selected} onClick={onClick}>
                        {props.text}
                    </Styled.Button>

                    <Styled.MobileMenu>
                        {_map(props.subitems, (si, isIdx: number) => (
                            <Block key={isIdx} {...si} />
                        ))}
                    </Styled.MobileMenu>
                </>
            );
        }
    }
    // single button
    else {
        return (
            <Styled.Button {...Tools.extractConfig<Config>(props)} selected={selected} onClick={onClick}>
                {props.text}
            </Styled.Button>
        );
    }
};
export default MenuItem;
