import { isNil as _isNil, includes as _includes } from 'lodash';
import { Location } from 'history';
import { StoreSelectorFunction } from '../../../../core/selector';

export interface Payload {
    location: string;
    exactMatch: boolean;
}

export default (payload: Payload): StoreSelectorFunction<boolean> => (location: Location): boolean => {
    if (_isNil(location as Location)) {
        return false;
    }

    if (payload.exactMatch) {
        return location.pathname === payload.location;
    } else {
        return _includes(location.pathname, payload.location);
    }
};
