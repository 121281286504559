import styled, { css } from 'styled-components';
import { isNil as _isNil } from 'lodash';
import { Config } from './index';

export const UL = styled.ul<{ config: Config }>`
    margin-left: 20px;
    /* style override */
    ${({ config }) => config.styleOverride}
`;

export const LI = styled.li<{ config: Config }>`
    ${({ config }) => {
        if (!_isNil(config.itemSpacing)) {
            return css`
                margin-bottom: ${config.itemSpacing};
            `;
        }
    }};
    /* style override */
    ${({ config }) => config.styleOverride}
`;
