import styled, { css } from 'styled-components';
import { Config } from './index';
import { Collapse as AntCollapse } from 'antd';
import { onTablet, onPhone } from '../../stylesheets/tools';

export const Collapse = styled(AntCollapse)<{ config: Config }>`
    /* style override */
    ${({ config }) => config.styleOverride}

    .ant-collapse-header {
        font-size: 24px;
    }

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;
