import { isNil as _isNil, isEqual as _isEqual, defer as _defer, get as _get, isUndefined as _isUndefined, findKey as _findKey } from 'lodash';
import React, { useState, useEffect, SyntheticEvent } from 'react';
import { Checkbox as AntCheckbox } from 'antd';

import { BlockProps, Tools, FormFieldCheckbox as FormFieldCheckboxConfig, FormFieldInlets } from '../../../../core/block';
import { stringToBoolean } from '../../../../core/tools';
import { useOutlets, useInlets } from '../../../hooks';
import { useBooleanValidation, ValidationResult, VALIDATION_RESULT_UNTOUCHED, getOutletValidationStatus } from '../../../hooks/useValidation';
import * as Styled from './styled';

export type Config = FormFieldCheckboxConfig;
export type Props = BlockProps &
    Config & {
        onChange?: (value: any) => void;
    };

const DEFAULT_CHECKED_STATE = false;

function mapValue(value: any, mapping: any) {
    // without mapping just return orignal value
    if (_isNil(mapping)) {
        return value;
    }

    const mappedValue = _get(mapping, value);
    return !_isUndefined(mappedValue) ? mappedValue : value;
}

function mapValueBack(mappedValue: any, mapping: any) {
    // without mapping just return given value
    if (_isNil(mapping)) {
        return mappedValue;
    }

    // try to find the original value
    const originalValue = _findKey(mapping, (mv: any) => _isEqual(mv, mappedValue));
    // there is no corresponding original value
    if (_isUndefined(originalValue)) {
        return mappedValue;
    }

    // convert originalValue to boolean
    return stringToBoolean(originalValue);
}

const ChecboxFormField = (props: Props) => {
    const outlet = useOutlets();
    const inlets = useInlets(props.inlets) as FormFieldInlets;

    // the actual checkbox component is in a "controlled" mode, because we need to provide external state from inlets
    const [isChecked, setIsChecked] = useState(DEFAULT_CHECKED_STATE);

    const validate = useBooleanValidation(props.validationScheme || {});
    const [validationResult, setValidationResult] = useState<ValidationResult>(VALIDATION_RESULT_UNTOUCHED);

    // TODO possible candidate for refactoring into useInletValue()
    useEffect(() => {
        // we are not expecting any inlet
        if (_isNil(props.inlets)) {
            return;
        }

        // is inlet already resolved?
        if (Tools.isInletResolved(inlets.value)) {
            // ... yes but there is no "value" -> use default value
            if (_isNil(inlets.value)) {
                setIsChecked(DEFAULT_CHECKED_STATE);
            }
            // "value" is defined
            else {
                const rawValue = mapValueBack(inlets.value, props.valueMapping);
                setIsChecked(rawValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets.value)]);

    const processChangeOutlet = (fieldValue: boolean, validationResult: ValidationResult) => {
        if (_isNil(props.outlets?.change)) {
            return;
        }

        // map value by props ... if there is some mapping
        const value = mapValue(fieldValue, props.valueMapping);

        // create outlet parameters
        const outletParameters = {
            value,
            validationStatus: getOutletValidationStatus(validationResult),
        };

        _defer(() => outlet(props.outlets?.change, outletParameters));

        if (!_isNil(props.onChange)) {
            props.onChange(value);
        }
    };

    const handleChange = (event: SyntheticEvent) => {
        const value = (event.target as HTMLInputElement).checked;
        setIsChecked(value);

        return validate(value).then((validationResult) => {
            setValidationResult(validationResult);
            processChangeOutlet(value, validationResult);
        });
    };

    return (
        <Styled.Checkbox {...Tools.extractConfig<Config>(props)} onChange={handleChange}>
            <Styled.FormItem
                hasFeedback={!_isNil(props.validationScheme)}
                validateStatus={validationResult.antValidationStatus}
                help={validationResult.message}
            >
                {/* input */}
                <AntCheckbox autoFocus={props.autoFocus} checked={isChecked}>
                    {/* label */}
                    {!_isNil(props.text) && <Styled.Label>{props.text}</Styled.Label>}
                </AntCheckbox>
            </Styled.FormItem>
        </Styled.Checkbox>
    );
};

export default ChecboxFormField;
