import { Name as EntityName } from '../../entity';
import { Block } from '../../block';

export const TYPE = `${EntityName.ACTION_PREFIX}APPLICATION:show_popup`;

export interface Payload {
    websiteId: string; // website Id
    dialogId: string; // dialog Id
    blocks?: Block[];
    overlayColor?: string;
}
