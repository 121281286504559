import { isNil as _isNil } from 'lodash';
import React from 'react';

import { BlockProps, PortraitVotingOption as PortraitVotingOptionConfig, PortraitVotingOptionInlets, Tools } from '../../../../core/block';
import { useInlets } from '../../../hooks';
import Blocks from '../../Blocks';
import CircularImage from '../../CircularImage';
import * as Styled from './styled';

export type Config = PortraitVotingOptionConfig;
export type Props = BlockProps & Config;

const VotingOption = (props: Props) => {
    const usedInlets = useInlets(props.inlets) as PortraitVotingOptionInlets;

    return (
        <Styled.Option {...Tools.extractConfig<Config>(props)} usedInlets={usedInlets}>
            {/* portrait */}
            <Styled.Portrait>
                <CircularImage name="" src={props.portraitSrc} />

                {/* sub-portrait */}
                {!_isNil(props.subPortraitSrc) && (
                    <Styled.SubPortrait>
                        <CircularImage name="" src={props.subPortraitSrc} />
                    </Styled.SubPortrait>
                )}
            </Styled.Portrait>

            {/* text */}
            <Styled.Name>{props.text}</Styled.Name>

            {/* blocks */}
            {props.blocks && <Blocks blocks={props.blocks} />}
        </Styled.Option>
    );
};
export default VotingOption;
