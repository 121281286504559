import { isNil as _isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { create as createAction, Voting as VotingActions } from '../../../../core/action';
import { Result } from '../../../../core/voting';
import Blocks from '../../../blocks/Blocks';
import { useOutlets } from '../../../hooks';
import * as Styled from './styled';

export type Props = Result.SingleResult;

const SingleResultsPage = (props: Props) => {
    const dispatch = useDispatch();
    const outlet = useOutlets();

    useEffect(() => {
        dispatch(createAction(VotingActions.resultEnter.TYPE, { result: props }));

        if (!_isNil(props.outlets?.enter)) {
            outlet(props.outlets?.enter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(createAction(VotingActions.resultConfig.TYPE, { r: props.id, config: props.config }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, props.id, JSON.stringify(props.config)]);

    return (
        <Styled.Wrapper>
            <Styled.ResultsPage {...props.layout}>
                {/* blocks */}
                <Blocks blocks={props.blocks} />
            </Styled.ResultsPage>
        </Styled.Wrapper>
    );
};
export default SingleResultsPage;
