import { isNil as _isNil } from 'lodash';

import { StoreSelectorFunction } from '../../../../core/selector';
import { RootState } from '../../root_reducer';
import { Store, PopupStore } from '../interfaces';
import getStore from './get_store';

export default (): StoreSelectorFunction<PopupStore | undefined> => (rootStore: RootState): PopupStore | undefined => {
    const store: Store = getStore(rootStore);
    if (_isNil(store)) {
        return;
    }

    return store.popup;
};
