import { isNil as _isNil } from 'lodash';

import { Action, Application as ApplicationActions } from '../../../core/action';

export const SAVED_STATE_SESSION_LOCAL_STORAGE_KEY = 'd21p_saved_state_session';

const isLocalstorageAvailable = (): boolean => {
    return !_isNil(window) && !_isNil(window.localStorage);
};

export const getSavedStateSession = (): any | undefined => {
    // FIX ... sometime localstorage seems to be null ... but without it we have nothing to do with that
    // https://sentry.io/organizations/demokracie-21-sro/issues/1802326768/?project=5304451&query=is%3Aunresolved
    if (!isLocalstorageAvailable()) {
        console.error('Localstorage seems to be disabled. Please enable it and reload the page.');
        return;
    }

    const savedState = window.localStorage.getItem(SAVED_STATE_SESSION_LOCAL_STORAGE_KEY);
    // there is no previously saved state
    if (_isNil(savedState)) {
        return;
    }

    // clear saved state
    window.localStorage.removeItem(SAVED_STATE_SESSION_LOCAL_STORAGE_KEY);

    return JSON.parse(savedState);
};

const middleware = (store: any) => (next: any) => (action: Action<any>) => {
    // let next middelware do it's job first
    const result = next(action);

    // check if event is save_state_session event
    if (action.type === ApplicationActions.saveStateSession.TYPE) {
        if (isLocalstorageAvailable()) {
            window.localStorage.setItem(SAVED_STATE_SESSION_LOCAL_STORAGE_KEY, JSON.stringify(store.getState()));
        }
    }

    // just return previously generated result
    return result;
};

export default middleware;
