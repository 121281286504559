import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';

import { Dialog, Urls } from '../../../core/website';

function useDialog(websiteId: string, dialogId: string): [Dialog | undefined, boolean, any | undefined] {
    const [config, loading, error] = useObjectVal<Dialog>(firebase.database().ref(Urls.dialog(websiteId, dialogId)));

    return [config, loading, error];
}

export default useDialog;
