import { toNumber as _toNumber, replace as _replace } from 'lodash';
import { useEffect, useState } from 'react';

import { resolution } from '../../stylesheets/shared';

function useOnPhone(): any {
    const [phoneResolution, setPhoneResolution] = useState<boolean>(false);

    const checkSize = () => {
        const phoneResolutionLimit = _toNumber(_replace(resolution.phone, 'px', ''));
        setPhoneResolution(document.documentElement.clientWidth <= phoneResolutionLimit);
    };

    useEffect(() => {
        window.addEventListener('resize', checkSize);
        window.addEventListener('orientationchange', checkSize);

        return (): void => {
            window.removeEventListener('resize', checkSize);
            window.removeEventListener('orientationchange', checkSize);
        };
    });

    useEffect(() => {
        checkSize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return phoneResolution;
}

export default useOnPhone;
