import styled, { css } from 'styled-components';

import LoadingComp from '../Loading';
import Icon from '../../blocks/Icon';
import { color, shadow, gradient } from '../../stylesheets/shared';
import { cssDisableUserSelect } from '../../stylesheets/snippets';
import { ifHoverIsSupported } from '../../stylesheets/tools';

export const Overlay = styled.div<{ overlayColor?: string }>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    ${({ overlayColor }) => (overlayColor ? `background: ${overlayColor}` : '')};
`;

// Close Button
export const CloseButton = styled.button`
    position: absolute;
    right: 8px;
    top: 8px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-width: 2px;
    border-style: solid;
    border-color: ${color.violet};
    background: ${color.white};
    box-sizing: border-box;
    width: 34px;
    height: 34px;
    ${cssDisableUserSelect};

    ${ifHoverIsSupported(css`
        &:hover {
            background: ${gradient.sweetLight};
        }
    `)}
`;
export const CloseButtonIcon = styled(Icon)`
    font-size: 24px;
    color: ${color.black};
`;

// Loading
export const Loading = styled(LoadingComp)`
    position: absolute;
    top: 33%;
    left: 50%;
`;

// Popup
export const Popup = styled.div`
    position: relative;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    max-width: 680px;
    min-height: 240px;
    padding: 0 16px;
    margin: 24px 12px;
    border: 1px solid ${color.grey3};
    background-color: ${color.white};
    box-shadow: ${shadow.standard};
    overflow: auto;
`;
