import { Store } from '../interfaces';
import { SchoolPb as SchoolPbActions } from '../../../../core/action';

const reducer = (state: Store, payload: SchoolPbActions.markWinner.Payload): Store => {
    const newState: Store = {
        ...state,
        winners: {
            ...state.winners,
            [payload.optionId]: payload.winner,
        },
    };

    return newState;
};

export default reducer;
