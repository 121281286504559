import { isNil as _isNil, set as _set, get as _get, unset as _unset, cloneDeep as _cloneDeep, isEmpty as _isEmpty } from 'lodash';

import { Answer } from '../answer';
import { Choice } from '../choice';

const solver = (optionId: string, newChoice: Choice, currentAnswer?: Answer, autoFix?: boolean): Answer | undefined => {
    let answer: Answer | undefined;

    // nothing is answered yet
    if (_isNil(currentAnswer) || _isEmpty(currentAnswer)) {
        answer = _set({}, optionId, newChoice);
    }
    // same choice is already answered
    else if (_get(currentAnswer, optionId) === newChoice) {
        answer = _cloneDeep(currentAnswer);
        _unset(answer, optionId);
    }
    // choice is not answered yet + auto-fix is on
    else if (_get(currentAnswer, optionId) !== newChoice && autoFix === true) {
        answer = _set({}, optionId, newChoice);
    }
    // choice is not answered yet + auto-fix is off
    else if (_get(currentAnswer, optionId) !== newChoice) {
        answer = _cloneDeep(currentAnswer);
        _set(answer, optionId, newChoice);
    }

    // clean up answer
    if (_isNil(answer) || _isEmpty(answer)) {
        answer = undefined;
    }

    return answer;
};

export default solver;
