import { isNil as _isNil, defer as _defer } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { create as createAction, Voting as VotingActions } from '../../../../core/action';
import { PollSelectors } from '../../../store/voting/selectors';
import RedirectTo404 from '../../../components/RedirectTo404';
import Loading from '../../../components/Loading';
import StandardPollStep from '../../../components/PollStep/Standard';
import { useAuthentication, usePollVoterMark } from '../../../hooks';
import { Poll as CorePoll } from '../../../../core/voting';
import { Auth as AuthTools } from '../../../tools';

const Poll = (props: CorePoll.Poll) => {
    const dispatch = useDispatch();
    const step = useSelector(PollSelectors.currentStep());
    const stepConfig = useSelector(PollSelectors.currentStepConfig());
    const [authUser, authLoading, authError] = useAuthentication();
    const [voterMark, voterMarkLoading, voterMarkError] = usePollVoterMark(props.id, AuthTools.signedUserUid() || '');

    useEffect(() => {
        dispatch(createAction(VotingActions.pollEnter.TYPE, { poll: props }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(createAction(VotingActions.pollConfig.TYPE, { poll: props }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, JSON.stringify(props)]);

    const redirectToFirstStep = () => {
        console.warn('User is NOT authenticated, poll is redirecting to the first step!');

        dispatch(createAction(VotingActions.pollFirstStep.TYPE));
    };

    const redirectToLastStep = () => {
        console.warn('User already voted, poll is redirecting to the last step!');

        _defer(() => {
            dispatch(createAction(VotingActions.pollLastStep.TYPE));
        });
    };

    const isCurrentStepTheLastOne = (): boolean => {
        return CorePoll.Tools.getFlowEnd(props.flow) === step;
    };

    const allowVoterMarkRenderTheStep = (): boolean => {
        // voter-mark does not exist
        if (_isNil(voterMark)) {
            return true;
        }

        // voter-mark exists, but it's not true
        if (!_isNil(voterMark) && voterMark !== true) {
            return true;
        }

        // voter-mark exists and it's true, but current step is the last one
        if (!_isNil(voterMark) && voterMark === true && isCurrentStepTheLastOne()) {
            return true;
        }

        return false;
    };

    return (
        <>
            {/* Poll is not published */}
            {!CorePoll.Tools.isPollPublished(props) && <RedirectTo404 />}

            {/* Poll is not running ... timewise */}
            {!CorePoll.Tools.isPollRunning(props.timing?.from, props.timing?.to) && <RedirectTo404 />}

            {/* auth loading... */}
            {authLoading && <Loading fullScreen={true} />}
            {/* auth error */}
            {authError && <>auth error: {authError}</>}
            {/* user is NOT authenticated */}
            {!authLoading && !authError && _isNil(authUser) && (
                <>
                    {/* Poll Step needs authentication ... redirect to first step */}
                    {CorePoll.Tools.requireStepAuthentication(stepConfig) && <>{redirectToFirstStep()}</>}

                    {/* Poll Step DOES NOT need authentication */}
                    {!CorePoll.Tools.requireStepAuthentication(stepConfig) && (
                        <>
                            {/* standard poll step */}
                            {!_isNil(step) && !_isNil(props) && <StandardPollStep pollId={props.id} stepId={step} />}
                        </>
                    )}
                </>
            )}
            {/* user is authenticated */}
            {!_isNil(authUser) && (
                <>
                    {/* vater-mark loading... */}
                    {voterMarkLoading && <Loading fullScreen={true} />}
                    {/* vater-mark error */}
                    {voterMarkError && <>voter-mark error: {voterMarkError}</>}
                    {/* we've get vater-mark result */}
                    {!voterMarkLoading && !voterMarkError && (
                        <>
                            {/* redirect to last step */}
                            {!allowVoterMarkRenderTheStep() && <>{redirectToLastStep()}</>}
                            {/* render the step step */}
                            {allowVoterMarkRenderTheStep() && (
                                <>
                                    {/* standard poll step */}
                                    {!_isNil(step) && !_isNil(props) && <StandardPollStep pollId={props.id} stepId={step} />}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};
export default Poll;
