import { Name as EntityName } from '../../../core/entity';
import { Streamer, Events, Event } from '../../../core/stream';
import { Action, Tools as ActionTools } from '../../../core/action';
import { isNil as _isNil } from 'lodash';

export const UPDATE_POLL_ACTION = `${EntityName.ACTION_PREFIX}SCHOOLPB:update_poll`;

export interface UpdatePollPayload {
    // votingStart: string;
    // votingEnd: string;
    pollId: string;
    publish: boolean;
    onDone?: Action<any> | Action<any>[];
}

export function updatePoll(payload: UpdatePollPayload) {
    return async (dispatch: any) => {
        const event: Event<UpdatePollPayload> = Events.generatePollEvent(payload);
        const result = await Streamer.stream(event);
        console.log('update poll result: ', result);

        if (!_isNil(payload.onDone)) {
            ActionTools.processAction(dispatch, payload.onDone, result);
        }
    };
}
