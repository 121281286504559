import { cloneDeep as _cloneDeep, merge as _merge } from 'lodash';

import { SchoolPb as SchoolPbActions } from '../../../../core/action';
import { Store } from '../interfaces';

const reducer = (state: Store, payload: SchoolPbActions.votingProposals.Payload): Store => {
    const change = {
        proposals: _cloneDeep(payload.proposals),
    };
    const newState: Store = _merge(_cloneDeep(state), change);

    return newState;
};

export default reducer;
