import { cloneDeep as _cloneDeep, unset as _unset } from 'lodash';

import { Store } from '../interfaces';
import { Planner as PlannerAction } from '../../../../core/action';

const reducer = (state: Store, payload: PlannerAction.removePlan.Payload): Store => {
    const newPlan = _cloneDeep(state.plan);
    _unset(newPlan, payload);

    return {
        ...state,
        plan: newPlan,
    };
};

export default reducer;
