import { isNil as _isNil, get as _get } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Block, BlockProps, DialogStepper as DialogStepperConfig } from '../../../../core/block';
import { Tools as WebsiteTools } from '../../../../core/website';
import { Dialog as DialogActions, create as createAction } from '../../../../core/action';
import { currentStepperStep } from '../../../store/dialog/selectors';
import { useAuthentication, useStepperStep } from '../../../hooks';
import Loading from '../../../components/Loading';
import Blocks from '../../Blocks';
import { Auth } from '../../../tools';

export type Config = DialogStepperConfig;
export type Props = BlockProps & Config;

const DialogStepper = (props: Props) => {
    const urlParams = useParams();
    const websiteId = WebsiteTools.getWebsiteIdFromParams(urlParams);
    const dispatch = useDispatch();
    const step = useSelector(currentStepperStep(props.id));
    const [authUser, authLoading, authError] = useAuthentication();
    const [storedStep, storedStepLoading, storedStepError] = useStepperStep(websiteId, props.id, Auth.signedUserUid() || '');

    useEffect(() => {
        // if there is no existing step, display the default one
        if (_isNil(step)) {
            dispatch(createAction(DialogActions.stepperStep.TYPE, { s: props.id, sn: props.defaultStep }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getStepBlocks = (): Block[] | undefined => {
        if (_isNil(step)) {
            return undefined;
        }

        return _get(props.steps, step);
    };

    const getBlocks = (stepName: string): Block[] | undefined => {
        return _get(props.steps, stepName);
    };

    return (
        <>
            {/* auth loading... */}
            {authLoading && <Loading />}
            {/* auth error */}
            {authError && <>auth error: {authError}</>}
            {/* user is NOT authenticated*/}
            {!authLoading && !authError && _isNil(authUser) && <Blocks blocks={getStepBlocks()} />}
            {/* user is authenticated */}
            {!_isNil(authUser) && (
                <>
                    {/* stored step loading... */}
                    {storedStepLoading && <Loading />}
                    {/* stored step error */}
                    {storedStepError && <>stored step: {storedStepError}</>}
                    {/* stored step does not exist */}
                    {!storedStepLoading && !storedStepError && _isNil(storedStep) && <Blocks blocks={getStepBlocks()} />}
                    {/* stored step exists */}
                    {!_isNil(storedStep) && <Blocks blocks={getBlocks(storedStep)} />}
                </>
            )}
        </>
    );
};
export default DialogStepper;
