import { isString as _isString } from 'lodash';
import React from 'react';

import { BlockProps, VotesCount as VotesCountConfig, Tools, IconSets, VotesCountInlets } from '../../../core/block';
import { interpolate } from '../../../core/tools';
import { useInlets } from '../../hooks';
import * as Styled from './styled';

export type Config = VotesCountConfig;
export type Props = BlockProps & Config;

const VotesCount = (props: Props) => {
    const inlets = useInlets(props.inlets) as VotesCountInlets;
    let count = props.count;
    if (_isString(props.count)) {
        count = interpolate(props.count, { inlets }, { skipUnresolvedPlaceholders: true });
    }

    return (
        <Styled.VotesCount turnGray={count <= 0} {...Tools.extractConfig<Config>(props)}>
            <Styled.Count>{count}</Styled.Count>
            <Styled.Cross name="" icon={IconSets.Material.CLEAR} />
            <Styled.IconWrapper>
                <Styled.Icon {...props.icon} />
            </Styled.IconWrapper>
        </Styled.VotesCount>
    );
};
export default VotesCount;
