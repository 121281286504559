import { createGlobalStyle } from 'styled-components';
import { color } from './shared';
import { cssP, cssH1, cssH2, cssH3, cssA } from './snippets';

export const GlobalStyles = createGlobalStyle`
    * {
        border: 0;
        margin: 0;
        padding: 0;
        outline: 0;
        flex-shrink: 0;
        flex-grow: 0;
    }

    p, ol, ul, dl {
        margin: 0;
    }
    ol, ul {
        padding: 0;
    }

    ::selection{
        background: ${color.blue};
        color: ${color.white};
    }
    ::-moz-selection{
        background: ${color.blue};
        color: ${color.white};
    }



    html {
        -webkit-print-color-adjust: exact;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
    }

    body {
        -webkit-print-color-adjust: exact;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-font-smoothing: antialiased;
        background: ${color.white};
        box-sizing: border-box;
        ${cssP};
        color: ${color.black};

        &.disable-scroll {
            overflow: hidden;
        }
    }

    h1 {
        ${cssH1};
        color: ${color.black};
    }

    h2 {
        ${cssH2};
        color: ${color.black};
    }

    h3 {
        ${cssH3};
        color: ${color.black};
    }

    p {
        ${cssP};
        color: ${color.black};
    }

    a {
        ${cssA};
    }
`;
