import { isNil as _isNil, get as _get } from 'lodash';

import { StoreSelectorFunction } from '../../../../core/selector';
import { RootState } from '../../root_reducer';
import { Store, FieldValue } from '../interfaces';
import getStore from './get_store';

export interface Payload {
    f: string; // form id
    fn: string; // form field name
}

export default (payload: Payload): StoreSelectorFunction<FieldValue> => (rootStore: RootState): FieldValue => {
    const store: Store = getStore(rootStore);
    if (_isNil(store)) {
        return;
    }

    return _get(store.fields.value, [payload.f, payload.fn]);
};
