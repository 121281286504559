import { Store } from '../interfaces';
import { Results } from '../../../../core/voting';

const reducer = (state: Store): Store => {
    const currentStep = state.resultsStep || '';
    const flow: Results.Flow = state.resultsConfig?.flow || { start: '' };

    return {
        ...state,
        resultsStep: Results.Tools.getPreviousStep(flow, currentStep),
    };
};

export default reducer;
