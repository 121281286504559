import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';

import { Page, Urls } from '../../../core/website';

function usePage(websiteId: string, pageId: string): [Page | undefined, boolean, any | undefined] {
    const [config, loading, error] = useObjectVal<Page>(firebase.database().ref(Urls.page(websiteId, pageId)));

    return [config, loading, error];
}

export default usePage;
