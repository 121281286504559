import styled, { css } from 'styled-components';

import { cssP } from '../../../stylesheets/snippets';
import { animation, meaningColor, color } from '../../../stylesheets/shared';
import CircularImage from '../../CircularImage';
import { Config } from './index';

const getBarColor = (barSize: number): string => {
    if (barSize >= 0) {
        return meaningColor.plus;
    } else {
        return meaningColor.minus;
    }
};

// portrait
export const Portrait = styled(CircularImage)`
    position: relative;
    box-sizing: border-box;
    width: 120px;
`;
export const SubPortrait = styled(CircularImage)`
    position: absolute;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    width: 44px;
`;
export const PortraitWrapper = styled.div`
    position: relative;
    width: 120px;
    margin-bottom: 24px;
`;

// title
export const Title = styled.div`
    text-align: center;
    padding: 0 0 12px 0;
`;

// spacer
export const Spacer = styled.div`
    flex-grow: 1;
`;

// bar line
interface BarProps {
    size: number;
    colorOverride?: string;
}
export const Bar = styled.div<BarProps>`
    position: absolute;
    top: 12px;
    left: 50%;
    height: 8px;
    border-radius: 0 4px 4px 0;
    background-color: ${(p: BarProps) => getBarColor(p.size)};
    box-sizing: border-box;
    width: ${(p: BarProps) => (Math.abs(p.size) > 1 ? `${Math.abs(p.size) / 2}%` : '1px')};
    transition: width ${animation.timing.standart} ease-out;

    ${(p: BarProps) => {
        if (p.size < 0) {
            return css`
                transform: rotate(180deg);
                transform-origin: 0% 50%;
            `;
        }
    }}
`;
export const BarZeroSign = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background-color: ${color.grey3};
`;
export const BarLine = styled.div`
    position: relative;
    height: 32px;
    box-sizing: border-box;
    width: 100%;

    &:before {
        position: absolute;
        display: block;
        content: '';
        top: 12px;
        left: 0;
        height: 8px;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
        background-color: ${color.grey3};
    }
`;

// Number
export const Percentage = styled.div`
    ${cssP}
    font-weight: bold;
`;
export const Votes = styled.div`
    ${cssP}
    margin-left: 4px;
`;
export const Numbers = styled.div`
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

// Wrapper
export const PortraitSignBarGraph = styled.div<{ config: Config }>`
    display: flex;
    flex-direction: column;
    align-items: center;

    /* style override */
    ${({ config }) => config.styleOverride}
`;
