import React, { useState } from 'react';

import Button from '../Button';
import Bar from '../Bars/Bar';
import { BlockProps } from '../../../core/block';
import * as Styled from './styled';

export type Props = BlockProps;

const singleVote_1 = {
    doubleSign: false,
    data: [{ size: 100, count: 5, percentage: 100 }],
};
const singleVote_2 = {
    doubleSign: false,
    data: [{ size: 80, count: 4, percentage: 80 }],
};
const D21NetTotal_1 = {
    doubleSign: true,
    data: [{ size: 50, count: 5, percentage: 50 }],
};
const D21NetTotal_2 = {
    doubleSign: true,
    data: [{ size: -25, count: -2, percentage: -25 }],
};
const D21_1 = {
    doubleSign: true,
    data: [
        { size: 75, count: 4, percentage: 75 },
        { size: 25, count: -2, percentage: -25 },
    ],
};
const D21_2 = {
    doubleSign: true,
    data: [
        { size: 16, count: 1, percentage: 16 },
        { size: 48, count: -3, percentage: -48 },
    ],
};

const b = [
    {
        name: '$LAYOUT:horizontal',
        alignItems: 'center',
        blocks: [
            { name: '$GRAPHIC:rank', text: '1' },
            { name: '$GRAPHIC:vertical_space', size: '8px' },
            { name: '$TYPOGRAPHY:p', text: 'Christopher Columbus' },
        ],
    },
];

const DevPlayground = (props: Props) => {
    const [barData, setBarData] = useState(singleVote_1);

    return (
        <Styled.Wrapper className={props.className} style={props.style}>
            <Styled.Buttons>
                <Button name="" text="Single_Vote_1" onClick={() => setBarData(singleVote_1)} />
                <Button name="" text="Single_Vote_2" onClick={() => setBarData(singleVote_2)} />
                <Button name="" text="D21_Net_Total_1" onClick={() => setBarData(D21NetTotal_1)} />
                <Button name="" text="D21_Net_Total_2" onClick={() => setBarData(D21NetTotal_2)} />
                <Button name="" text="D21_1" onClick={() => setBarData(D21_1)} />
                <Button name="" text="D21_2" onClick={() => setBarData(D21_2)} />
            </Styled.Buttons>

            <Bar name="" blocks={b} doubleSign={barData.doubleSign} data={barData.data} />
        </Styled.Wrapper>
    );
};
export default DevPlayground;
