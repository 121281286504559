import { every as _every, find as _find, isString as _isString } from 'lodash';

import { Status } from '../../validation';

export default (statuses: Status[]): Status => {
    if (_every(statuses, (v: boolean) => v === true)) {
        return true;
    }

    if (_every(statuses, (v: boolean) => v === false)) {
        return false;
    }

    const firstStringStatus = _find(statuses, (v: string | boolean) => _isString(v));
    return firstStringStatus || false;
};
