import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/functions';

import firebaseConf from './firebase.client.json';
import conf from './configuration.json';

const printReport = async () => {
    // get Firebase environment name
    const firebaseEnvDS: firebase.database.DataSnapshot = await firebase.database().ref('/__ENV__/').once('value');

    // print entire report
    console.log(`Configuration:`);
    console.log(`--------------`);
    console.log(`process.env.NODE_ENV: ${process.env.NODE_ENV}`);
    console.log(`Firebase ENV: ${firebaseEnvDS.val()}`);
    console.log(`Use Redux Dev Tools: ${conf.useReduxDevTools}`);
    console.log(`Functions Region: ${firebaseConf.functionsRegion}`);
    console.log(`Use Functions Emulator: ${conf.useLocalFunctionsEmulator}`);
};

const initialize = () => {
    // Firebase
    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConf.client);
    }
    if (conf.useLocalFunctionsEmulator === true) {
        firebase.functions().useFunctionsEmulator('http://localhost:5001');
    }

    // print report
    printReport();
};

export default initialize;
