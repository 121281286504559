import { Action, Target } from './interfaces';
import { Selector } from '../selector';

export default function <T>(type: string, payload?: T, selectors?: Selector<any>[], target?: Target): Action<T> {
    return {
        type,
        payload,
        selectors,
        target,
    };
}
