import React from 'react';

import { BlockProps, HorizontalSpace as HorizontalSpaceConfig, Tools } from '../../../core/block';
import * as Styled from './styled';

export type Config = HorizontalSpaceConfig;
export type Props = BlockProps & Config;

const HorizontalSpace = (props: Props) => {
    return <Styled.HorizontalSpace {...Tools.extractConfig<Config>(props)} />;
};
export default HorizontalSpace;
