import { defer as _defer } from 'lodash';
import { Effect } from '../../../../core/action';

export default function (): Effect {
    return async (): Promise<void> => {
        _defer(() => {
            window.print();
        });
    };
}
