import React from 'react';
import { Helmet } from 'react-helmet';

import PageWrapper from '../../components/PageWrapper/Standard';
import PageFooter from '../../components/PageFooter/Standard';
import * as Styled from './styled';

export function ErrorPage() {
    const onReload = () => {
        window.location.reload();
    };

    return (
        <>
            <Helmet>
                <title>Oops | Decision 21</title>
            </Helmet>
            <PageWrapper>
                <Styled.Content>
                    <Styled.Page>
                        <Styled.TitleWrapper>
                            <h1 className="grey">Oops</h1>
                            <h1>Something went wrong</h1>
                        </Styled.TitleWrapper>

                        <Styled.ContentWrapper>
                            We&apos;re sorry, but something went wrong while displaying this webpage. We track these errors automatically, but if the
                            problem persist feel free to contact us at <a href="mailto:support@decision21.org">support@decision21.org</a>.<br />
                            <br />
                            To continue try to{' '}
                            <span className="linkLike" onClick={onReload}>
                                reload
                            </span>{' '}
                            this page.
                        </Styled.ContentWrapper>
                    </Styled.Page>
                </Styled.Content>
                <PageFooter>Powered by Decision 21</PageFooter>
            </PageWrapper>
        </>
    );
}
