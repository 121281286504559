import { Meaning } from '../choice/interfaces';

export interface FlowStep {
    to?: string; // typically last step has no "to" attribute
    excludeFromStepsCounts?: boolean;
    requireAuthentication?: boolean;
}
export interface Flow {
    start: string; // starting point
    end: string; // ending point
    steps?: Record<string, FlowStep>; // currect-step-id -> next-step-id
}

export enum Mode {
    INFINITELY_TIMES = 'infinitely_times',
    JUST_ONCE = 'just_once',
}

export interface Poll {
    id: string;
    name: string;
    timing?: {
        from?: string;
        to?: string;
    };
    published: boolean;
    mode: Mode;
    requireAuthentication?: boolean;
    flow: Flow;
    results?: {
        respondents?: number;
        votes?: {
            [Meaning.PLUS]?: number;
            [Meaning.MINUS]?: number;
        };
    };
}
