import { Name as EntityName } from '../../../../core/entity';

const SELECTOR_PREFIX = `${EntityName.SELECTOR_PREFIX}VOTING:`;

export const POLL_RESPONDENTS = `${SELECTOR_PREFIX}poll_respondents`;
export const COMPARE_CHOICE = `${SELECTOR_PREFIX}compare_choice`;
export const CHOICE_MEANING = `${SELECTOR_PREFIX}choice_meaning`;
export const QUESTION_VALIDATION = `${SELECTOR_PREFIX}question_validation`;
export const REMAINING_CHOICES = `${SELECTOR_PREFIX}remaining_choices`;
// Results
export const RESULTS_RESPONDENTS = `${SELECTOR_PREFIX}results_respondents`;
export const RESULTS_PLUS_VOTES_COUNT = `${SELECTOR_PREFIX}results_plus_votes_count`;
export const RESULTS_MINUS_VOTES_COUNT = `${SELECTOR_PREFIX}results_minus_votes_count`;
export const RESULTS_VOTES_COUNT = `${SELECTOR_PREFIX}results_votes_count`;
// Result
export const RESULT_VALUES = `${SELECTOR_PREFIX}result_values`;
export const RESULT_OVERLAP = `${SELECTOR_PREFIX}result_overlap`;
export const RESULT_RESPONDENTS = `${SELECTOR_PREFIX}result_respondents`;
export const RESULT_RESPONDENTS_IN_PERCENTAGE = `${SELECTOR_PREFIX}result_respondents_in_percentage`;
export const RESULT_PLUS_VOTES_COUNT = `${SELECTOR_PREFIX}result_plus_votes_count`;
export const RESULT_MINUS_VOTES_COUNT = `${SELECTOR_PREFIX}result_minus_votes_count`;
export const RESULT_VOTES_COUNT = `${SELECTOR_PREFIX}result_votes_count`;
export const RESULT_OPTION_VOTES_COUNT = `${SELECTOR_PREFIX}result_option_votes_count`;
export const RESULT_SETUP_CALCULATION_METHOD = `${SELECTOR_PREFIX}result_setup_calculation_method`;
