import React from 'react';
import { firstBy } from 'thenby';
import * as Styled from './styled';

import { ProposalsListItem, ProposalsListItemProps } from './proposal_list_item';

interface Props {
    items: ProposalsListItemProps[];
    styleOverride?: Styled.ListProps['styleOverride'];
}

export const ProposalsList: React.FC<Props> = (props: Props) => {
    // spread the attrs to the root of each item
    // this is because the sorting library doesn't
    // support sorting by nested properties
    const itemsTemp = props.items.map((i) => ({ ...i, ...i.attrs }));

    // sort items if configured to do so
    const orderBy = (props as any).orderBy || [];
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const sortedItems = sortData(Array.from(itemsTemp), orderBy);

    return (
        <Styled.List {...props}>
            {sortedItems.map((item) => (
                <ProposalsListItem {...item} key={item.attrs.originalId} />
            ))}
        </Styled.List>
    );
};

function sortData(data: any[], sortInfo: any) {
    if (!sortInfo || sortInfo.length < 1) {
        return data;
    }

    let sorter = firstBy(sortInfo[0].fieldName, sortInfo[0].direction);
    for (const orderByItem of sortInfo.slice(1)) {
        sorter = sorter.thenBy(orderByItem.fieldName, orderByItem.direction);
    }

    const sortedData = [...data].sort(sorter);
    return sortedData;
}
