import React from 'react';

import { BlockProps, Anchor as AnchorConfig } from '../../../core/block';

export type Props = BlockProps & AnchorConfig;

const Anchor = (props: Props) => {
    return <div id={props.id} />;
};
export default Anchor;
