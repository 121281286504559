import { Authentication as AuthenticationActions } from '../../../../core/action';
import { Streamer, Events, Event } from '../../../../core/stream';

export default function (payload: AuthenticationActions.generatePins.Payload) {
    return async () => {
        const event: Event<AuthenticationActions.generatePins.Payload> = Events.generatePinsEvent(payload);
        const result = await Streamer.stream(event);

        // const byteArray = new Uint8Array(JSON.parse(result).data);
        // const blob = new Blob([byteArray], { type: 'application/pdf' });
        const blob = new Blob([result], { type: 'text/html;charset=UTF-8' });

        saveAs(blob, 'pin-kody.html');
    };
}
