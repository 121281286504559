import styled, { css, createGlobalStyle } from 'styled-components';
import { Config } from './index';
import { cssP, cssInput } from '../../../stylesheets/snippets';
import { color } from '../../../stylesheets/shared';
import hexToRgba from 'hex-to-rgba';
import { onTablet, onPhone } from '../../../stylesheets/tools';

export const Label = styled.div`
    ${cssP};
    font-weight: bold;
    margin-bottom: 2px;
`;

export const Time = styled.div<{ config: Config }>`
    box-sizing: border-box;
    width: 100%;

    .ant-picker {
        ${cssInput};
        background-color: ${color.white};
        border-color: ${color.grey2};
        border-radius: 12px;
        padding: 5px 12px;
        appearance: none;
        width: 100%;

        &:hover {
            border-color: ${hexToRgba(color.blue, 0.48)};
        }
        &:focus {
            box-shadow: none;
            border-color: ${color.blue};
        }
    }

    /* style override */
    ${({ config }) => config.styleOverride}

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;

/* Need to have it as global style, because it's not a child of the input's container */
export const DropdownStyles = createGlobalStyle`
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
        text-align: left;
        padding-left: 12px;
    }
`;
