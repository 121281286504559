import { StoreSelectorFunction } from '../../../../core/selector';
import { matchPath } from 'react-router';

export interface RouteMatchPayload {
    route: string;
    paramName: string;
}

export default (payload: RouteMatchPayload): StoreSelectorFunction<string> => () => {
    const routeMatch: any = matchPath(window.location.pathname, { path: payload.route });
    return decodeURIComponent(routeMatch?.params[payload.paramName] || '');
};
