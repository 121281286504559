import { isNil as _isNil, trim as _trim, split as _split, first as _first, size as _size } from 'lodash';

import { stringIsEmpty } from '../../tools';

export default (path?: string): string | undefined => {
    if (_isNil(path) || stringIsEmpty(path)) {
        return undefined;
    }

    // remove first and last slashes ... if their are there
    const pathWithoutSlashes: string | undefined = _trim(path, '/');
    if (stringIsEmpty(pathWithoutSlashes)) {
        return undefined;
    }

    // split rest of path into parts
    const pathParts: string[] | undefined = _split(pathWithoutSlashes, '/');
    if (_size(pathParts) === 0) {
        return undefined;
    }

    return _first(pathParts);
};
