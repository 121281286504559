import { get as _get } from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import * as Styled from './styled';
import Blocks from '../../blocks/Blocks';
import { color } from '../../stylesheets/shared';
import { Name as BlockName } from '../../../core/block';
import PageWrapper from '../../components/PageWrapper/Standard';
import PageFooter from '../../components/PageFooter/Standard';

const Page404 = () => {
    const location = useLocation();
    const referer = _get(location, ['state', 'referer'], '/');

    const pageBlocks = [
        {
            name: BlockName.HORIZONTAL_SPACE,
            size: '96px',
            onPhone: {
                size: '48px',
            },
        },
        {
            name: BlockName.H1,
            text: '404',
            styleOverride: { color: color.grey2 },
        },
        {
            name: BlockName.H1,
            text: 'Page not found',
        },
        {
            name: BlockName.HORIZONTAL_SPACE,
            size: '48px',
        },
        {
            name: BlockName.P,
            useMarkdown: true,
            text: `The requested URL **${referer}** was not found on Decision 21 platform.`,
        },
        {
            name: BlockName.HORIZONTAL_SPACE,
            size: '96px',
            onPhone: {
                size: '48px',
            },
        },
        {
            name: BlockName.P,
            useMarkdown: true,
            text: 'Do you think that something got broken on our side? \nPlease let us know at support@decision21.org.',
        },
    ];

    return (
        <>
            <Helmet>
                <title>404 | Decision 21</title>
            </Helmet>
            <PageWrapper>
                <Styled.Content>
                    <Styled.Page>
                        <Blocks blocks={pageBlocks} />
                    </Styled.Page>
                </Styled.Content>
                <PageFooter>Powered by Decision 21</PageFooter>
            </PageWrapper>
        </>
    );
};
export default Page404;
