import { isNil as _isNil, merge as _merge, assign as _assign } from 'lodash';
import selectorProcessor from './selector_processor';
import { Action } from '../../action';
import { Inlets } from '../../block/interfaces';

export default (action: Action<any>, store: any, location: any): any => {
    if (_isNil(action) || _isNil(action.selectors)) {
        return action;
    }

    const changes: Inlets = selectorProcessor(action.selectors, store, location);
    const newSelectors = {
        selectors: {},
    };

    _assign(newSelectors.selectors, changes);

    return _merge(action, newSelectors);
};
