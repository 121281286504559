import styled, { css } from 'styled-components';

import { cssP } from '../../stylesheets/snippets';
import { color } from '../../stylesheets/shared';
import { onPhone } from '../../stylesheets/tools';
import { HarmonogramTheme } from '../../../core/block';
import { Config, RecordState } from './index';

const getActiveColor = (themeOverride?: HarmonogramTheme) => {
    return themeOverride?.color || color.blue;
};

const getBorderColorForState = (state: RecordState, themeOverride?: HarmonogramTheme) => {
    if (state === RecordState.VERY_PAST) {
        return color.white;
    }
    if (state === RecordState.PAST) {
        return getActiveColor(themeOverride);
    }
    if (state === RecordState.ACTIVE) {
        return getActiveColor(themeOverride);
    }
    if (state === RecordState.FUTURE) {
        return color.grey3;
    }
};

const getBackgroundColorForState = (state: RecordState, themeOverride?: HarmonogramTheme) => {
    if (state === RecordState.VERY_PAST) {
        return color.white;
    }
    if (state === RecordState.PAST) {
        return color.white;
    }
    if (state === RecordState.ACTIVE) {
        return getActiveColor(themeOverride);
    }
    if (state === RecordState.FUTURE) {
        return color.white;
    }
};

const getTextColorColorForState = (state: RecordState) => {
    if (state === RecordState.VERY_PAST) {
        return color.black;
    }
    if (state === RecordState.PAST) {
        return color.black;
    }
    if (state === RecordState.ACTIVE) {
        return color.white;
    }
    if (state === RecordState.FUTURE) {
        return color.grey2;
    }
};

const getTextWeightForState = (state: RecordState) => {
    if (state === RecordState.VERY_PAST) {
        return 'normal';
    }
    if (state === RecordState.PAST) {
        return 'normal';
    }
    if (state === RecordState.ACTIVE) {
        return 'bold';
    }
    if (state === RecordState.FUTURE) {
        return 'normal';
    }
};

// capsule
interface TimeCapsuleProps {
    state: RecordState;
    themeOverride?: HarmonogramTheme;
}
export const TimeCapsule = styled.div<TimeCapsuleProps>`
    ${cssP};
    font-weight: bold;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    padding: 1px 8px;
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    border-color: ${(config: TimeCapsuleProps) => getBorderColorForState(config.state, config.themeOverride)};
    background-color: ${(config: TimeCapsuleProps) => getBackgroundColorForState(config.state, config.themeOverride)};
    color: ${(config: TimeCapsuleProps) => getTextColorColorForState(config.state)};
`;

// text
interface TextProps {
    state: RecordState;
}
export const Text = styled.div<TextProps>`
    ${cssP};
    text-align: left;
    font-weight: ${(config: TextProps) => getTextWeightForState(config.state)};
`;

// record
export const GroupSeparator = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    :before {
        position: relative;
        content: '';
        height: 1px;
        display: block;
        width: 67%;
        background-color: ${color.grey3};

        ${onPhone(css`
            width: 60%;
        `)}
    }
`;
export const YearsSeparator = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    :before {
        position: relative;
        content: '';
        height: 1px;
        display: block;
        width: 100%;
        background-color: ${color.grey3};
    }
`;
export const RecordTime = styled.div`
    box-sizing: border-box;
    width: 33%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 12px;

    ${onPhone(css`
        width: 40%;
    `)}
`;
export const RecordInfo = styled.div`
    box-sizing: border-box;
    padding: 2px 12px;
    flex-grow: 1;
    flex-shrink: 1;
`;
export const Record = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 6px 0;
`;

// harmonogram
export const ProgressLine = styled.div`
    position: absolute;
    top: 6px;
    bottom: 6px;
    left: 0;
    width: 33%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;

    ${onPhone(css`
        width: 40%;
    `)}

    :before {
        content: '';
        display: block;
        width: 2px;
    }
`;

interface HarmonogramProps {
    config: Config;
}
export const Harmonogram = styled.div<HarmonogramProps>`
    position: relative;
    box-sizing: border-box;
    width: 100%;

    /* style override */
    ${({ config }) => config.styleOverride}

    ${ProgressLine} {
        :before {
            background-color: ${(p: HarmonogramProps) => getActiveColor(p.config.themeOverride)};
        }
    }
`;
