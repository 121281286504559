import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 48px;

    & > * {
        margin: 0 4px;
    }
`;
