// Modifiers
export const MULTIPLY_BY = `multiply_by`;
export const READABLE_DATE = 'readable_date';
export const CONVERT_TO_STRING = 'convert_to_string';
export const READABLE_TIME = 'readable_time';
export const REPLACE = 'replace';
export const NEGATE = 'negate';

// school pb
export const SPB_PINSET_NAME = 'spb_pinset_name';
export const SPB_VOTE_COUNT = 'spb_vote_count';
