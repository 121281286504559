import { isNil as _isNil } from 'lodash';
import styled, { css } from 'styled-components';

import { Config } from './index';
import { onPhone, onTablet } from '../../stylesheets/tools';

type ImageProps = {
    config: Config;
};
export const Image = styled.div<ImageProps>`
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    width: 100%;
    cursor: ${({ config }) => (_isNil(config.outlets?.click) ? 'default' : 'pointer')};
    background-image: ${({ config }) => `url("${config.src}")`};

    /* style override */
    ${({ config }) => config.styleOverride}

    &:after {
        content: '';
        position: relative;
        display: block;
        padding-top: ${({ config }) => config.aspect};
    }

    /* on tablet */
    ${(p: ImageProps) => {
        if (!_isNil(p.config.onTablet)) {
            return onTablet(css`
                background-image: ${(p: ImageProps) => `url("${p.config.onTablet?.src || p.config.src}")`};

                &:after {
                    padding-top: ${(p: ImageProps) => p.config.onTablet?.aspect || p.config.aspect};
                }

                /* style override */
                ${(p: ImageProps) => p.config.onTablet?.styleOverride};
            `);
        }
    }}

    /* on phone */
    ${(p: ImageProps) => {
        if (!_isNil(p.config.onPhone)) {
            return onPhone(css`
                background-image: ${(p: ImageProps) => `url("${p.config.onPhone?.src || p.config.src}")`};

                &:after {
                    padding-top: ${(p: ImageProps) => p.config.onPhone?.aspect || p.config.aspect};
                }

                /* style override */
                ${(p: ImageProps) => p.config.onPhone?.styleOverride};
            `);
        }
    }}
`;
