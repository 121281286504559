import { isNil as _isNil } from 'lodash';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const StyledIcon = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    fill: currentColor;
`;

export const StyledIconWrapper = styled.i`
    position: relative;
    width: 1em;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &:before {
        position: relative;
        content: '';
        display: block;
        padding-top: 100%;
    }
`;

type Props = HTMLAttributes<HTMLElement> & {
    onClick?: () => void;
};

const Icon = (props: Props) => {
    const onClick = () => {
        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <StyledIconWrapper onClick={onClick} className={props.className}>
            <StyledIcon viewBox="0 0 1024 1024">
                <path d="M978.283 0h-365.714c-25.247 0-45.715 20.468-45.715 45.715s20.468 45.715 45.715 45.715h255.351l-470.53 470.533c-17.853 17.853-17.853 46.795 0 64.649 8.924 8.926 20.623 13.389 32.322 13.389s23.4-4.461 32.326-13.391l470.532-470.531v255.351c0 25.247 20.468 45.715 45.715 45.715s45.715-20.468 45.715-45.715v-365.715c0-25.247-20.47-45.715-45.717-45.715z" />
                <path d="M785.775 469.333c-25.527 0-46.223 20.695-46.223 46.223v416.001h-647.109v-647.113h416c25.527 0 46.223-20.695 46.223-46.223s-20.695-46.221-46.223-46.221h-462.221c-25.527 0-46.223 20.695-46.223 46.223v739.556c0 25.526 20.695 46.221 46.223 46.221h739.555c25.527 0 46.223-20.695 46.223-46.223v-462.222c0-25.527-20.697-46.223-46.225-46.223z" />
            </StyledIcon>
        </StyledIconWrapper>
    );
};

export default Icon;
