import { isNil as _isNil } from 'lodash';
import styled from 'styled-components';

import { Result as CoreResult } from '../../../../core/voting';
import { constant } from '../../../stylesheets/shared';
import { HorizontalAlign } from '../../../../core/block';

const DEFAULT_PAGE_ALIGN = 'left';
const DEFAULT_PAGE_ITEMS_ALIGN = 'flex-start';

const pageSize = (size?: CoreResult.SizeType): string => {
    if (size === CoreResult.SizeType.STANDARD) {
        return `${constant.page.size.standard}px`;
    }
    if (size === CoreResult.SizeType.WIDE) {
        return `${constant.page.size.wide}px`;
    }

    // default size
    return `${constant.page.size.standard}px`;
};

const pageAlign = (a: HorizontalAlign): string => {
    switch (a) {
        case HorizontalAlign.CENTER:
            return 'center';
        case HorizontalAlign.LEFT:
            return 'flex-start';
        case HorizontalAlign.RIGHT:
            return 'flex-end';
        case HorizontalAlign.STRETCH:
            return 'stretch';
    }
};

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`;

type ResultsPageProps = CoreResult.Layout;
export const ResultsPage = styled.div<ResultsPageProps>`
    position: relative;
    width: 100%;
    max-width: ${({ size }) => pageSize(size)};
    box-sizing: border-box;
    text-align: ${({ align }) => (!_isNil(align) ? align : DEFAULT_PAGE_ALIGN)};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: ${({ align }) => (!_isNil(align) ? pageAlign(align) : DEFAULT_PAGE_ITEMS_ALIGN)};
`;
