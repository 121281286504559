import styled, { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';

import { color, shadow, animation } from '../../../stylesheets/shared';
import { cssInput, cssP } from '../../../stylesheets/snippets';
import { ifHoverIsSupported } from '../../../stylesheets/tools';

import { AutoComplete as AntdAutoComplete } from 'antd';

export const Label = styled.div`
    ${cssP};
    font-weight: bold;
    margin-bottom: 2px;
`;

export const AutoComplete = styled(AntdAutoComplete)`
    /* width: 100%; */

    width: 100%;
    min-width: 320px;

    /* increasing selector specificity to beat the antd's selectors */
    .ant-select-selector.ant-select-selector.ant-select-selector {
        cursor: pointer;
        box-sizing: border-box;
        border: ${color.grey2} 1px solid;
        border-radius: 12px;
        width: 100%;
        height: 36px;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 12px;
        transition: background-color ${animation.timing.interactive} ease-out;

        ${ifHoverIsSupported(css`
            &:hover {
                border-color: ${hexToRgba(color.blue, 0.48)};
            }
        `)}
    }

    &.ant-select-focused {
        .ant-select-selector.ant-select-selector.ant-select-selector {
            border-color: ${color.blue};
            box-shadow: none;
        }
    }

    .ant-select-selector.ant-select-selector .ant-select-selection-search-input {
        height: 100%;
    }
`;
export const AutoCompleteItem = styled(AntdAutoComplete.Option)``;

export const Input = styled.input`
    ${cssInput};
    background-color: ${color.white};
    border: 1px solid ${color.grey2};
    border-radius: 12px;
    padding: 5px 12px;
    appearance: none;

    &:hover {
        border-color: ${hexToRgba(color.blue, 0.48)};
    }
    &:focus {
        box-shadow: none;
        border-color: ${color.blue};
    }
`;

export const Suggestions = styled.div`
    border: ${color.grey3} 1px solid;
    border-radius: 12px;
    padding: 6px 0;
    box-shadow: ${shadow.standard};
`;

export const SuggestionItem = styled.div`
    width: 100%;
    height: 32px;

    &:hover {
        background-color: ${color.grey3};
    }
`;
