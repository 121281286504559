import { isNil as _isNil, toString as _toString } from 'lodash';
import React from 'react';

import { BlockProps, MP4 as MP4Config, Tools } from '../../../../core/block';
import { useOnTablet, useOnPhone } from '../../../hooks';
import * as Styled from './styled';

export type Config = MP4Config;
export type Props = BlockProps & Config;

const MP4 = (props: Props) => {
    const onTablet = useOnTablet();
    const onPhone = useOnPhone();

    const getSrc = (): string => {
        let src = props.src;

        if (!_isNil(props.onTablet?.src) && onTablet) {
            src = _toString(props.onTablet?.src);
        }

        if (!_isNil(props.onPhone?.src) && onPhone) {
            src = _toString(props.onPhone?.src);
        }

        return src;
    };

    const getLoop = (): boolean => {
        if (_isNil(props.loop)) {
            return true;
        }

        return props.loop;
    };

    return (
        <Styled.MP4 autoPlay muted playsInline loop={getLoop()} {...Tools.extractConfig<Config>(props)}>
            <source src={getSrc()} type="video/mp4" />
        </Styled.MP4>
    );
};
export default MP4;
