import styled from 'styled-components';

import { cssNoteP } from '../../../stylesheets/snippets';
import { color } from '../../../stylesheets/shared';
import { Config } from './index';

// Error message
export const ErrorMessage = styled.div`
    ${cssNoteP};
    color: ${color.red};
    text-align: center;
`;

// Recaptcha Container
export const RecaptchaContainer = styled.div`
    display: none;
`;

// Phone Number
export const PhoneNumber = styled.b`
    display: inline-block;
    margin: 0 4px;
`;

// Compound Message Wrapper
export const CompoundMessageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`;

// Dialog
export const Dialog = styled.div<{ config: Config }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    /* style override */
    ${({ config }) => config.styleOverride}
`;
