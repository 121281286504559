import { Name as EntityName } from '../../../core/entity';
import { Streamer, Events, Event } from '../../../core/stream';
import { Action, Tools as ActionTools } from '../../../core/action';
import { isNil as _isNil } from 'lodash';

export const UPDATE_RESULTS_ACTION = `${EntityName.ACTION_PREFIX}SCHOOLPB:update_results`;

export interface UpdateResultsPayload {
    // resultsStart: string;
    // resultsEnd: string;
    resultsIds: string[];
    pollId: string;
    publish: boolean;
    onDone?: Action<any> | Action<any>[];
}

export function updateResults(payload: UpdateResultsPayload) {
    return async (dispatch: any) => {
        const event: Event<UpdateResultsPayload> = Events.generateResultsEvent(payload);
        const result = await Streamer.stream(event);
        console.log('update results result: ', result);

        if (!_isNil(payload.onDone)) {
            ActionTools.processAction(dispatch, payload.onDone, result);
        }
    };
}
