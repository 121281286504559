import styled, { css } from 'styled-components';
import { isNil as _isNil } from 'lodash';
import { Config } from './index';
import IconComp from '../Icon';
import { font, color, gradient, animation } from '../../stylesheets/shared';
import { cssDisableUserSelect } from '../../stylesheets/snippets';
import { ifHoverIsSupported } from '../../stylesheets/tools';

type ButtonProps = {
    config: Config;
};

export const Icon = styled(IconComp)`
    font-size: 24px;
`;

export const Button = styled.button<ButtonProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    border-color: ${color.grey2};
    box-sizing: border-box;
    background: ${color.white};
    ${cssDisableUserSelect};
    transition: transform ${animation.timing.interactive} ease-out;

    ${({ config }) => {
        if (!_isNil(config.iconSize)) {
            return css`
                ${Icon} {
                    font-size: ${config.iconSize};
                }
            `;
        }
    }}

    /* style override */
    ${(p: ButtonProps) => p.config.styleOverride}

    ${ifHoverIsSupported(css`
        &:hover {
            background: ${gradient.sweetLight};
        }
    `)}

    &:active {
        transform: scale(0.97);
    }
`;

export const Label = styled.div`
    font-family: ${font.family};
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 24px;

    p {
        font-family: ${font.family};
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: 24px;
        padding: 0;
        margin: 0;
    }
`;
