import { isNil as _isNil, size as _size, get as _get } from 'lodash';
import React, { useEffect } from 'react';
import { useSpring } from 'react-spring';

import Blocks from '../../Blocks';
import { BlockProps, Bar as BarConfig, BarData, Tools } from '../../../../core/block';
import * as Styled from './styled';

export type Config = BarConfig;
export type Props = BlockProps & Config;

const Bar = (props: Props) => {
    const [line1, setLine1] = useSpring(() => ({ width: '0%', left: props.doubleSign === true ? '50%' : '0%' }));
    const [line2, setLine2] = useSpring(() => ({ width: '0%', left: '50%' }));
    const [topLineNumbersAnim, setTopLineNumbersAnim] = useSpring(() => ({ opacity: 0 }));
    const [bottomLineNumbersAnim, setBottomLineNumbersAnim] = useSpring(() => ({ opacity: 0 }));
    const [bottomLineAnim, setBottomLineAnim] = useSpring(() => ({ height: 0 }));

    const doubleBar = _size(props.data) === 2;
    const firstDataSet: BarData = _get(props.data, 0, { size: 0 });
    const secondDataSet: BarData = _get(props.data, 1, { size: 0 });

    // lines
    useEffect(() => {
        // size
        let line1Size = 0;
        let line2Size = 0;
        // we expected 2 bars
        if (doubleBar) {
            line1Size = firstDataSet.size / 2;
            line2Size = secondDataSet.size / 2;
        }
        // we expected just one bar
        else {
            // blue
            if (firstDataSet.size > 0) {
                line1Size = props.doubleSign === true ? firstDataSet.size / 2 : firstDataSet.size;
                line2Size = 0;
            }
            // red
            else {
                line1Size = 0;
                line2Size = props.doubleSign === true ? Math.abs(firstDataSet.size) / 2 : Math.abs(firstDataSet.size);
            }
        }

        // left
        const line1Left = props.doubleSign === true ? 50 : 0;
        const line2Left = 50;

        setLine1({ width: `${line1Size}%`, left: `${line1Left}%` });
        setLine2({ width: `${line2Size}%`, left: `${line2Left}%` });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props)]);

    // number
    useEffect(() => {
        // top line
        setTopLineNumbersAnim({ opacity: doubleBar ? 0 : 1 });
        // bottom line
        setBottomLineNumbersAnim({ opacity: doubleBar ? 1 : 0 });
        setBottomLineAnim({ height: doubleBar ? 22 : 0 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props)]);

    return (
        <Styled.Bar {...Tools.extractConfig<Config>(props)}>
            {/* top line */}
            <Styled.TopLine>
                <Styled.TopLineBlocks>{!_isNil(props.blocks) && <Blocks blocks={props.blocks} />}</Styled.TopLineBlocks>
                <Styled.TopLineNumbers style={topLineNumbersAnim}>
                    {!_isNil(firstDataSet.percentage) && <Styled.Percentage>{firstDataSet.percentage} %</Styled.Percentage>}
                    {!_isNil(firstDataSet.count) && <Styled.Count>({firstDataSet.count})</Styled.Count>}
                </Styled.TopLineNumbers>
            </Styled.TopLine>

            {/* bar line */}
            <Styled.BarLine doubleSign={props.doubleSign}>
                <Styled.ValueLine switched={false} style={line1} />
                <Styled.ValueLine switched={true} style={line2} />
            </Styled.BarLine>

            {/* bottom line */}
            <Styled.BottomLine style={bottomLineAnim}>
                <Styled.Numbers style={bottomLineNumbersAnim}>
                    {!_isNil(secondDataSet.percentage) && <Styled.Percentage>{secondDataSet.percentage} %</Styled.Percentage>}
                    {!_isNil(secondDataSet.count) && <Styled.Count>({secondDataSet.count})</Styled.Count>}
                </Styled.Numbers>
                <Styled.Numbers style={bottomLineNumbersAnim}>
                    {!_isNil(firstDataSet.percentage) && <Styled.Percentage>{firstDataSet.percentage} %</Styled.Percentage>}
                    {!_isNil(firstDataSet.count) && <Styled.Count>({firstDataSet.count})</Styled.Count>}
                </Styled.Numbers>
            </Styled.BottomLine>
        </Styled.Bar>
    );
};
export default Bar;
