import styled, { css } from 'styled-components';

import { Config } from './index';
import { cssNoteP } from '../../../stylesheets/snippets';
import { color } from '../../../stylesheets/shared';
import { ifHoverIsSupported } from '../../../stylesheets/tools';

export const P = styled.p<{ config: Config }>`
    ${cssNoteP};
    color: ${color.black};

    /* style override */
    ${({ config }) => config.styleOverride}
`;

export const MD = styled.div<{ config: Config }>`
    /* style override */
    ${({ config }) => config.styleOverride}

    p {
        ${cssNoteP};
        color: ${color.black};
    }

    a {
        ${cssNoteP};
        color: ${color.blue};
        text-decoration: underline;

        ${ifHoverIsSupported(css`
            &:hover {
                text-decoration: none;
            }
        `)}
    }
`;
