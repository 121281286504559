import { isNil as _isNil, map as _map } from 'lodash';
import styled, { css, CSSObject } from 'styled-components';

import { Config } from './index';
import { onPhone } from '../../stylesheets/tools';
import { VerticalAlign, HorizontalAlign } from '../../../core/block';

const DEFAULT_ITEMS_ALIGN = 'flex-start';
const DEFAULT_CONTENT_ALIGN = 'flex-start';
const DEFAULT_TEXT_CONTENT_ALIGN = 'left';

const alignItems = (a: VerticalAlign): string => {
    switch (a) {
        case VerticalAlign.CENTER:
            return 'center';
        case VerticalAlign.TOP:
            return 'flex-start';
        case VerticalAlign.BOTTOM:
            return 'flex-end';
        case VerticalAlign.STRETCH:
            return 'stretch';
        case VerticalAlign.BASELINE:
            return 'baseline';
    }
};

const alignContent = (a: HorizontalAlign): string => {
    switch (a) {
        case HorizontalAlign.CENTER:
            return 'center';
        case HorizontalAlign.LEFT:
            return 'flex-start';
        case HorizontalAlign.RIGHT:
            return 'flex-end';
        case HorizontalAlign.STRETCH:
            return 'stretch';
    }
};

const alignTextContent = (a: HorizontalAlign): string => {
    switch (a) {
        case HorizontalAlign.CENTER:
            return 'center';
        case HorizontalAlign.LEFT:
            return 'left';
        case HorizontalAlign.RIGHT:
            return 'right';
        case HorizontalAlign.STRETCH:
            return 'stretch';
    }
};

// Row
interface RowProps {
    columnsCount: number;
    columsSizes?: string[];
    alignItems?: VerticalAlign;
    alignContent?: HorizontalAlign;
    styleOverride?: CSSObject;
}
export const Row = styled.div<RowProps>`
    display: flex;
    flex-direction: row;
    /* items align */
    align-items: ${(p: RowProps) => (p.alignItems ? alignItems(p.alignItems) : DEFAULT_ITEMS_ALIGN)};
    /* content align */
    justify-content: ${(p: RowProps) => (p.alignContent ? alignContent(p.alignContent) : DEFAULT_CONTENT_ALIGN)};
    text-align: ${(p: RowProps) => (p.alignContent ? alignTextContent(p.alignContent) : DEFAULT_TEXT_CONTENT_ALIGN)};
    width: 100%;
    box-sizing: border-box;
    padding: 12px 24px;

    /* style override */
    ${(p: RowProps) => p.styleOverride}

    ${onPhone(css`
        padding: 12px 8px;
    `)}

    /* columns */
    & > * {
        width: ${(p: RowProps) => `${100 / p.columnsCount}%`};
        box-sizing: border-box;
        padding: ${(p: RowProps) => (p.columnsCount > 1 ? '0 4px' : '0')};

        &:first-child {
            padding: ${(p: RowProps) => (p.columnsCount > 1 ? '0 4px 0 0' : '0')};
        }
        &:last-child {
            padding: ${(p: RowProps) => (p.columnsCount > 1 ? '0 0 0 4px' : '0')};
        }
    }

    ${(p: RowProps) => {
        /* distribute column sizes by description */
        if (!_isNil(p.columsSizes)) {
            return _map(
                p.columsSizes,
                (s: string, i: number) => css`
                    & > *:nth-child(${i + 1}) {
                        width: ${s};
                    }
                `,
            );
        }
    }}
`;

// List
interface TableLikeListProps {
    config: Config;
}
export const List = styled.div<TableLikeListProps>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;

    /* style override */
    ${(p: TableLikeListProps) => p.config.styleOverride}

    ${onPhone(css`
        padding: 0;
    `)}
`;
