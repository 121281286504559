import { isNil as _isNil, isEmpty as _isEmpty, size as _size, get as _get } from 'lodash';

import { Status as ValidationStatus } from '../../validation';
import { StandardQuestionConfig as QuestionConfig } from '../question';
import { Meaning } from '../choice/interfaces';
import { Answer } from '../answer';
import { Errors } from './errors';

// return:
//    true -> valid
//    false -> invalid
//    string -> invalid ... string value is error core
const validator = (config: QuestionConfig, answer: Answer | undefined): ValidationStatus => {
    const isAnswerEmpty = _isNil(answer) || _isEmpty(answer);

    // empty & optional
    if (isAnswerEmpty === true && config.optional === true) {
        return true;
    }
    // empty & required
    if (isAnswerEmpty === true && config.optional !== true) {
        return Errors.NO_VOTES;
    }

    const choiceCount = _size(answer);
    const allowedChoices = _get(config, [Meaning.PLUS], 0);

    // allowed-count-choice & optional
    if (choiceCount <= allowedChoices && config.optional === true) {
        return true;
    }
    // allowed-count-choice & required
    if (choiceCount <= allowedChoices && config.optional !== true) {
        return true;
    }

    // more-choices
    if (choiceCount > allowedChoices) {
        return Errors.TOO_MANY_VOTES;
    }

    return false;
};

export default validator;
