import { css } from 'styled-components';

import { font, color } from './shared';
import { onPhone, ifHoverIsSupported } from './tools';

// typography
export const cssTitle = css`
    font-family: ${font.family};
    font-weight: bold;
    font-style: normal;
    font-size: 68px;
    line-height: 72px;
    letter-spacing: -1.36px;
    margin: 0;
    padding: 0;

    ${onPhone(css`
        font-size: 42px;
        line-height: 48px;
        letter-spacing: -0.84px;
    `)}
`;

export const cssH1 = css`
    font-family: ${font.family};
    font-weight: bold;
    font-style: normal;
    font-size: 48px;
    line-height: 52px;
    margin: 0;
    padding: 0;

    ${onPhone(css`
        font-size: 32px;
        line-height: 36px;
    `)}
`;

export const cssH2 = css`
    font-family: ${font.family};
    font-weight: bold;
    font-style: normal;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
    padding: 0;

    ${onPhone(css`
        font-size: 20px;
        line-height: 24px;
    `)}
`;

export const cssH3 = css`
    font-family: ${font.family};
    font-weight: bold;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0;
`;

export const cssAccent = css`
    font-family: ${font.family};
    font-weight: normal;
    font-style: normal;
    font-size: 26px;
    letter-spacing: -0.49px;
    line-height: 36px;
    margin: 0;
    padding: 0;

    ${onPhone(css`
        font-size: 20px;
        letter-spacing: -0.38px;
        line-height: 30px;
    `)}
`;

export const cssRepresentP = css`
    font-family: ${font.family};
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding: 0;
`;

export const cssP = css`
    font-family: ${font.family};
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    margin: 0;
    padding: 0;
`;

export const cssNoteP = css`
    font-family: ${font.family};
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
    padding: 0;
`;

export const cssInput = css`
    font-family: ${font.family};
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding: 0;
`;

export const cssA = css`
    color: ${color.blue};
    text-decoration: underline;

    &:hover {
        color: ${color.blue};
        text-decoration: underline;
    }

    ${ifHoverIsSupported(css`
        &:hover {
            color: ${color.blue};
            text-decoration: none;
        }
    `)}
`;

// material icons
export const cssMaterialIcon = css`
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
`;

export const cssMaterialIconOutline = css`
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
`;

// disable user selections
export const cssDisableUserSelect = css`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;
