import React from 'react';

import { BlockProps, HorizontalLayout as HorizontalLayoutConfig, Tools } from '../../../core/block';
import Blocks from '../Blocks';
import * as Styled from './styled';

export type Config = HorizontalLayoutConfig;
export type Props = BlockProps & Config;

const HorizontalLayout = (props: Props) => {
    return (
        <Styled.HorizontalLayout {...Tools.extractConfig<Config>(props)}>
            <Blocks blocks={props.blocks} />
        </Styled.HorizontalLayout>
    );
};
export default HorizontalLayout;
