import { isNil as _isNil } from 'lodash';
import React from 'react';
import MarkdownIt from 'markdown-it';

import { BlockProps, RepresentPrimaryCTA as RepresentPrimaryCTAConfig, Tools } from '../../../core/block';
import { useOutlets } from '../../hooks';
import * as Styled from './styled';

export type Config = RepresentPrimaryCTAConfig;
export type Props = BlockProps &
    Config & {
        onClick?: () => void;
    };

const md = new MarkdownIt({
    html: true,
    xhtmlOut: true,
    breaks: true,
    linkify: true,
});

const RepresentPrimaryCTA = (props: Props) => {
    const outlet = useOutlets();

    const processClickOutlet = async () => {
        if (_isNil(props.outlets?.click)) {
            return;
        }

        outlet(props.outlets?.click);
    };

    const onButton = () => {
        processClickOutlet();

        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <Styled.Button {...Tools.extractConfig<Config>(props)} onClick={onButton}>
            {/* icon */}
            {props.icon && (
                <Styled.IconWrapper>
                    <Styled.Icon {...props.icon} />
                </Styled.IconWrapper>
            )}

            {/* simle text */}
            {!props.useMarkdown && <Styled.Label>{props.text}</Styled.Label>}
            {/* markdown text */}
            {props.useMarkdown && <Styled.Label dangerouslySetInnerHTML={{ __html: md.render(props.text) }} />}
        </Styled.Button>
    );
};
export default RepresentPrimaryCTA;
