import { isNil as _isNil } from 'lodash';
import styled, { css } from 'styled-components';
import { Drawer } from 'antd';
import hexToRgba from 'hex-to-rgba';

import { Config } from './index';
import IconComp from '../Icon';
import { color } from '../../stylesheets/shared';
import { cssDisableUserSelect } from '../../stylesheets/snippets';
import { ifHoverIsSupported, onTablet, onPhone } from '../../stylesheets/tools';
import { HorizontalAlign } from '../../../core/block';

const DEFAULT_ALIGN = 'flex-start';

const alignContent = (a: HorizontalAlign): string => {
    switch (a) {
        case HorizontalAlign.CENTER:
            return 'center';
        case HorizontalAlign.LEFT:
            return 'flex-start';
        case HorizontalAlign.RIGHT:
            return 'flex-end';
        case HorizontalAlign.STRETCH:
            return 'stretch';
    }
};

// Menu
export const MenuLogoWrapper = styled.div`
    height: 100%;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
export const MenuAdditionalLogoWrapper = styled.div`
    height: 100%;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

interface MenuItemsProps {
    align?: HorizontalAlign;
    itemsMargin?: string;
}
export const MenuItems = styled.div<MenuItemsProps>`
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    width: 100%;
    justify-content: ${({ align }) => (align ? alignContent(align) : DEFAULT_ALIGN)};

    /* logo */
    ${MenuLogoWrapper} {
        position: ${(p: MenuItemsProps) => (p.align === HorizontalAlign.CENTER ? 'absolute' : 'relative')};
    }
    /* additional logo */
    ${MenuAdditionalLogoWrapper} {
        position: absolute;
    }

    /* all child items */
    ${({ itemsMargin }) => {
        if (!_isNil(itemsMargin)) {
            return css`
                & > * {
                    margin: ${itemsMargin};
                }
            `;
        }
    }}
`;

type MenuContainerProps = {
    config: Config;
};
export const MenuContainer = styled.div<MenuContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    backdrop-filter: blur(24px);
    background-color: ${hexToRgba(color.white, 0.72)};
    border-bottom: 1px solid ${color.grey3};
    z-index: 100;

    /* style override */
    ${({ config }) => config.styleOverride}

    /* on tablet */
    ${onTablet(css`
        align-items: center;
        justify-content: space-between;
    `)}

    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;

// Mobile
export const MobileMenuButtonIcon = styled(IconComp)`
    color: ${color.black};
    font-size: 32px;
    ${cssDisableUserSelect};
`;
export const MobileMenuButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 8px;

    ${ifHoverIsSupported(css`
        &:hover {
            background-color: ${hexToRgba(color.grey1, 0.48)};

            ${MobileMenuButtonIcon} {
                color: ${color.white};
            }
        }
    `)}
`;
// Drawer
export const DrawerMenuCloseButtonWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
export const DrawerMenuCloseButtonIcon = styled(IconComp)`
    font-size: 32px;
    color: ${color.black};
    ${cssDisableUserSelect};
`;
export const DrawerMenuCloseButton = styled.button`
    cursor: pointer;
    width: 48px;
    height: 48px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: none;
    border-radius: 8px;

    ${ifHoverIsSupported(css`
        &:hover {
            background-color: ${hexToRgba(color.grey1, 0.48)};

            ${DrawerMenuCloseButtonIcon} {
                color: ${color.white};
            }
        }
    `)}
`;
export const MobileMenuDrawer = styled(Drawer)`
    .ant-drawer-body {
        background-color: ${color.white};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 12px 12px 12px;
    }
`;
