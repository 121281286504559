import { isNil as _isNil } from 'lodash';
import React from 'react';

import { useOutlets } from '../../hooks';
import { BlockProps, Icon as IconConfig, IconsSet, IconSets, Tools } from '../../../core/block';
import * as Styled from './styled';

export type Config = IconConfig;
export type Props = BlockProps &
    Config & {
        onClick?: () => void;
    };

const Icon = (props: Props) => {
    const outlet = useOutlets();

    const processClickOutlet = async () => {
        if (_isNil(props.outlets?.click)) {
            return;
        }

        await outlet(props.outlets?.click);
    };

    const onClick = () => {
        processClickOutlet();

        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <>
            {/* Material icon set - Default */}
            {(_isNil(props.set) || props.set === IconsSet.MATERIAL) && (
                <Styled.MaterialIcon onClick={onClick} {...Tools.extractConfig<Config>(props)}>
                    {props.icon}
                </Styled.MaterialIcon>
            )}
            {/* Material Outline icon set */}
            {props.set === IconsSet.MATERIAL_OUTLINE && (
                <Styled.MaterialOutlineIcon onClick={onClick} {...Tools.extractConfig<Config>(props)}>
                    {props.icon}
                </Styled.MaterialOutlineIcon>
            )}

            {/* Custom icon set */}
            {props.set === IconsSet.CUSTOM && (
                <>
                    {props.icon === IconSets.Custom.FULL_ARROW_LEFT && (
                        <Styled.FullArrowLeft onClick={onClick} {...Tools.extractConfig<Config>(props)} />
                    )}
                    {props.icon === IconSets.Custom.FULL_ARROW_RIGHT && (
                        <Styled.FullArrowRight onClick={onClick} {...Tools.extractConfig<Config>(props)} />
                    )}
                    {props.icon === IconSets.Custom.LINK && <Styled.Link onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.OPEN_LINK && <Styled.OpenLink onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.LINK_MARK && <Styled.LinkMark onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.FACEBOOK && <Styled.Facebook onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.TWITTER && <Styled.Twitter onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.YOUTUBE && <Styled.Youtube onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.GOOGLE && <Styled.Google onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.GOOGLE_SOLID && <Styled.GoogleSolid onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.SPOTIFY && <Styled.Spotify onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.ODNOKLASSNIKI && (
                        <Styled.Odnoklassniki onClick={onClick} {...Tools.extractConfig<Config>(props)} />
                    )}
                    {props.icon === IconSets.Custom.VKONTAKTE && <Styled.VKontakte onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.VIBER && <Styled.Viber onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.TELEGRAM && <Styled.Telegram onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.INSTAGRAM && <Styled.Instagram onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.PENTAGON && <Styled.Pentagon onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {/* flags */}
                    {props.icon === IconSets.Custom.FLAG_BELARUS && <Styled.FlagBelarus onClick={onClick} {...Tools.extractConfig<Config>(props)} />}
                    {props.icon === IconSets.Custom.FLAG_CZECH_REPUBLIC && (
                        <Styled.FlagCzechRepublic onClick={onClick} {...Tools.extractConfig<Config>(props)} />
                    )}
                    {props.icon === IconSets.Custom.FLAG_SWITZERLAND && (
                        <Styled.FlagSwitzerland onClick={onClick} {...Tools.extractConfig<Config>(props)} />
                    )}
                </>
            )}
        </>
    );
};
export default Icon;
