import { isNil as _isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { create as createAction, Voting as VotingActions } from '../../../../core/action';
import { ResultsSelectors } from '../../../store/voting/selectors';
import RedirectTo404 from '../../../components/RedirectTo404';
import Loading from '../../../components/Loading';
import StandardResultsStep from '../../../components/ResultsStep/Standard';
import { useAuthentication, useOutlets } from '../../../hooks';
import { Results as CoreResults } from '../../../../core/voting';

const Results = (props: CoreResults.Results) => {
    const dispatch = useDispatch();
    const outlet = useOutlets();
    const step = useSelector(ResultsSelectors.currentStep());
    const stepConfig = useSelector(ResultsSelectors.currentStepConfig());
    const [authUser, authLoading, authError] = useAuthentication();

    useEffect(() => {
        dispatch(createAction(VotingActions.resultsEnter.TYPE, { results: props }));

        if (!_isNil(props.outlets?.enter)) {
            outlet(props.outlets?.enter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(createAction(VotingActions.resultsConfig.TYPE, { results: props }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, JSON.stringify(props)]);

    const redirectToFirstStep = () => {
        console.warn('User is NOT authenticated, results are redirecting to the first step!');

        dispatch(createAction(VotingActions.resultsFirstStep.TYPE));
    };

    return (
        <>
            {/* check if results are published */}
            {!CoreResults.Tools.areResultsPublished(props) && <RedirectTo404 />}

            {/* check if results fit into defined timeframe */}
            {!CoreResults.Tools.areResultsRunning(props.timing?.from, props.timing?.to) && <RedirectTo404 />}

            {/* auth loading... */}
            {authLoading && <Loading fullScreen={true} />}
            {/* auth error */}
            {authError && <>auth error: {authError}</>}
            {/* user is NOT authenticated*/}
            {!authLoading && !authError && _isNil(authUser) && (
                <>
                    {/* Results Step needs authentication ... redirect to first step */}
                    {CoreResults.Tools.requireStepAuthentication(stepConfig) && <>{redirectToFirstStep()}</>}

                    {/* Results Step DOES NOT need authentication */}
                    {!CoreResults.Tools.requireStepAuthentication(stepConfig) && (
                        <>
                            {/* standard results step */}
                            {!_isNil(step) && !_isNil(props) && <StandardResultsStep resultsId={props.id} stepId={step} />}
                        </>
                    )}
                </>
            )}
            {/* user is authenticated */}
            {!_isNil(authUser) && (
                <>
                    {/* standard results step */}
                    {!_isNil(step) && !_isNil(props) && <StandardResultsStep resultsId={props.id} stepId={step} />}
                </>
            )}
        </>
    );
};
export default Results;
