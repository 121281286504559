import { get as _get, isNil as _isNil, unset as _unset, set as _set, isEmpty as _isEmpty } from 'lodash';

import { Store } from '../interfaces';
import { Answer, Engine, Question } from '../../../../core/voting';
import { Voting as VotingActions } from '../../../../core/action';

const reducer = (state: Store, payload: VotingActions.choice.Payload): Store => {
    const config: Question.StandardQuestionConfig | undefined = _get(state.question.config, [payload.q]);
    if (_isNil(config)) {
        console.error(`Cannot get config for Question with ID:${payload.q}.`);
        return state;
    }

    const newState: Store = {
        ...state,
    };

    const currentAnswer: Answer.Answer | undefined = _get(state.vote, [payload.q]);
    let newAnswer: Answer.Answer | undefined;

    if (config.type === Question.MethodType.SINGLE_VOTE) {
        newAnswer = Engine.singleVoteSolver(payload.o, payload.choice, currentAnswer, config.autoFix);
    } else if (config.type === Question.MethodType.MULTI_VOTE) {
        newAnswer = Engine.multiVoteSolver(payload.o, payload.choice, currentAnswer, config.autoFix);
    } else if (config.type === Question.MethodType.D21) {
        newAnswer = Engine.d21Solver(payload.o, payload.choice, currentAnswer, config.autoFix);
    }

    if (_isNil(newAnswer)) {
        _unset(newState, ['vote', payload.q]);
    } else {
        _set(newState, ['vote', payload.q], newAnswer);
    }

    // clear Vote store?
    if (_isNil(newState.vote) || _isEmpty(newState.vote)) {
        _unset(newState, ['vote']);
    }

    return newState;
};

export default reducer;
