import { isNil as _isNil } from 'lodash';
import MarkdownIt from 'markdown-it';

const getMarkdown = (): MarkdownIt => {
    const md = new MarkdownIt({
        html: true,
        xhtmlOut: true,
        breaks: true,
        linkify: true,
    });

    const defaultRender =
        md.renderer.rules.link_open ||
        function (tokens, idx, options, env, self) {
            return self.renderToken(tokens, idx, options);
        };

    md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
        // If you are sure other plugins can't add `target` - drop check below
        //const aIndex = tokens[idx].attrIndex('target');
        const href = tokens[idx].attrGet('href');
        if (!_isNil(tokens)) {
            //TODO: refactor - temp solution
            if (!_isNil(href)) {
                const onClickAttr = `window.open("${href}");return false;`;
                tokens[idx].attrPush(['onclick', onClickAttr]);
            }
            /*
            if (aIndex < 0) {
                //tokens[idx].attrPush(['target', '_blank']); // add new attribute
                //tokens[idx].attrPush(['rel', 'noopener noreferrer']);

            } else {
                const token = tokens[idx];
                if (!_isNil(token) && !_isNil(token.attrs)) {
                    token.attrs[aIndex][1] = '_blank'; // replace value of existing attr
                }
            }
            */
        }

        // pass token to default renderer.
        return defaultRender(tokens, idx, options, env, self);
    };

    return md;
};

export default getMarkdown;
