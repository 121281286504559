import { SchoolPb as SchoolPbActions } from '../../../../core/action';
import { Store } from '../interfaces';

const reducer = (state: Store, payload: SchoolPbActions.workshopStartTime.Payload): Store => {
    const newState: Store = {
        ...state,
        workshopStartTime: payload.workshopStartTime,
    };

    return newState;
};

export default reducer;
