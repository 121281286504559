import styled, { CSSObject } from 'styled-components';

import { cssP } from '../../../stylesheets/snippets';

export interface FooterProps {
    styleOverride?: CSSObject;
}

export const Footer = styled.footer<FooterProps>`
    ${cssP}
    width: 100%;
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: left;
    padding: 96px 0 12px 0;

    ${(props) => props.styleOverride}
`;
