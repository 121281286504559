import { isNil as _isNil, trim as _trim } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { create as createAction, Voting as VotingActions } from '../../../../core/action';
import { Question } from '../../../../core/voting';
import Blocks from '../../../blocks/Blocks';
import * as Styled from './styled';

export type Props = Question.StandardQuestion;

const EmbeddedQuestion = (props: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(createAction(VotingActions.questionEnter.TYPE, { question: props }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(createAction(VotingActions.questionConfig.TYPE, { q: props.id, config: props.config }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, props.id, JSON.stringify(props.config)]);

    const isQuestionTextNoteValid = (): boolean => !_isNil(props.note) && _trim(props.note) !== '';

    return (
        <Styled.Wrapper>
            <Styled.Question {...props.layout}>
                {/* text */}
                <Styled.Text>
                    {props.text} {isQuestionTextNoteValid() && <span className="note">{props.note}</span>}
                </Styled.Text>

                {/* blocks */}
                <Styled.Blocks>
                    <Blocks blocks={props.blocks} />
                </Styled.Blocks>
            </Styled.Question>
        </Styled.Wrapper>
    );
};
export default EmbeddedQuestion;
