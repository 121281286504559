import { isNil as _isNil } from 'lodash';
import styled from 'styled-components';

import { constant } from '../../../stylesheets/shared';
import { PageLayout, SizeType } from '../../../../core/website';
import { HorizontalAlign } from '../../../../core/block';

const DEFAULT_PAGE_ALIGN = 'left';
const DEFAULT_PAGE_ITEMS_ALIGN = 'flex-start';

const pageSize = (size?: SizeType): string => {
    if (size === SizeType.STANDARD) {
        return `${constant.question.standard.width}px`;
    }
    if (size === SizeType.WIDE) {
        return `${constant.question.wide.width}px`;
    }
    if (size === SizeType.FULLWIDTH) {
        return '100%';
    }

    // default size
    return `${constant.question.standard.width}px`;
};

const pageAlign = (a: HorizontalAlign): string => {
    switch (a) {
        case HorizontalAlign.CENTER:
            return 'center';
        case HorizontalAlign.LEFT:
            return 'flex-start';
        case HorizontalAlign.RIGHT:
            return 'flex-end';
        case HorizontalAlign.STRETCH:
            return 'stretch';
    }
};

interface WrapperProps {
    containsFooter?: boolean;
}
export const Wrapper = styled.div<WrapperProps>`
    position: relative;
    width: 100%;
    min-height: ${({ containsFooter }) => (containsFooter ? '100vh' : 'auto')};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
`;

type PageProps = PageLayout;
export const Page = styled.div<PageProps>`
    position: relative;
    width: 100%;
    max-width: ${({ size }) => pageSize(size)};
    box-sizing: border-box;
    text-align: ${({ align }) => (!_isNil(align) ? align : DEFAULT_PAGE_ALIGN)};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: ${({ align }) => (!_isNil(align) ? pageAlign(align) : DEFAULT_PAGE_ITEMS_ALIGN)};
`;
