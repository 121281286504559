import firebase from 'firebase/app';
import 'firebase/auth';

export default (token: string): Promise<void> => {
    return new Promise<any | undefined>((resolve, reject) => {
        firebase
            .auth()
            .signInWithCustomToken(token)
            .then(() => {
                resolve();
            })
            .catch((error: firebase.FirebaseError) => {
                reject(error);
            });
    });
};
