import { Vote } from '../voting';
import { Event, EventType } from './interfaces';
import { timestampInLocalISO } from '../tools';
import { Descriptors, Descriptor } from '../descriptor';

// Results
export interface UpdateResultsPayload {
    // resultsStart: string;
    // resultsEnd: string;
    resultsIds: string[];
    pollId: string;
    publish: boolean;
}
export type GenerateResultsEventType = Event<UpdateResultsPayload>;
/** Server-side action/event creator */
export const generateResultsEvent = (payload: UpdateResultsPayload): GenerateResultsEventType => {
    return {
        type: EventType.UPDATE_RESULTS,
        timestamp: timestampInLocalISO(),
        payload,
    };
};

// Voting
export interface UpdatePollPayload {
    // votingStart: string;
    // votingEnd: string;
    pollId: string;
    publish: boolean;
}
export type GeneratePollEventType = Event<UpdatePollPayload>;
/** Server-side action/event creator */
export const generatePollEvent = (payload: UpdatePollPayload): GeneratePollEventType => {
    return {
        type: EventType.UPDATE_POLL,
        timestamp: timestampInLocalISO(),
        payload,
    };
};

//Proposals
export interface UpdateProposalsPayload {
    proposals: string[];
    pollId: string;
    resultsId: string;
    locale: string;
}
export type GenerateProposalsEventType = Event<UpdateProposalsPayload>;
export const generateProposalsEvent = (payload: UpdateProposalsPayload): GenerateProposalsEventType => {
    return {
        type: EventType.UPDATE_PROPOSALS,
        timestamp: timestampInLocalISO(),
        payload,
    };
};

// Store Vote
export interface StoreVoteEventPayload {
    pollId: string;
    vote: Vote.Vote;
}
export type StoreVoteEventType = Event<StoreVoteEventPayload>;
export const storeVote = (pollId: string, vote: Vote.Vote): StoreVoteEventType => {
    return {
        type: EventType.STORE_VOTE,
        timestamp: timestampInLocalISO(),
        payload: {
            pollId,
            vote,
        },
    };
};

// Mark Voter
export interface MarkVoterEventPayload {
    pollId: string;
}
export type MarkVoterEventType = Event<MarkVoterEventPayload>;
export const markVoter = (pollId: string): MarkVoterEventType => {
    return {
        type: EventType.MARK_VOTER,
        timestamp: timestampInLocalISO(),
        payload: {
            pollId,
        },
    };
};

// School pb
export interface GetSPBStateEventPayload {
    sPBId: string;
}
export type GetSPBStateEventType = Event<GetSPBStateEventPayload>;
export const getSPBState = (payload: GetSPBStateEventPayload): GetSPBStateEventType => {
    return {
        type: EventType.GET_SPB_STATE,
        timestamp: timestampInLocalISO(),
        payload,
    };
};

export interface GetSPBResultEventPayload {
    resultsId: string;
    resultId: string;
}
export type GetSPBResultEventType = Event<GetSPBResultEventPayload>;
export const getSPBResult = (payload: GetSPBResultEventPayload): GetSPBResultEventType => {
    return {
        type: EventType.GET_SPB_RESULT,
        timestamp: timestampInLocalISO(),
        payload,
    };
};

// Project

// Store Form
export interface StoreFormEventPayload {
    projectId: string;
    formId: string;
    formValue: any;
}
export type StoreFormEventType = Event<StoreFormEventPayload>;
export const storeForm = (projectId: string, formId: string, formValue: any): StoreFormEventType => {
    return {
        type: EventType.STORE_FORM,
        timestamp: timestampInLocalISO(),
        payload: {
            projectId,
            formId,
            formValue,
        },
    };
};

// Store Stepper Step
export interface StoreStepperStepEventPayload {
    websiteId: string;
    stepperId: string;
    stepName: any;
}
export type StoreStepperStepEventType = Event<StoreStepperStepEventPayload>;
export const storeStepperStep = (websiteId: string, stepperId: string, stepName: string): StoreStepperStepEventType => {
    return {
        type: EventType.STORE_STEPPER_STEP,
        timestamp: timestampInLocalISO(),
        payload: {
            websiteId,
            stepperId,
            stepName,
        },
    };
};

export interface GeneratePinSetPayload {
    pinCount: number;
    target: {
        type: string;
        id: string;
    };
    pinLength?: number;
    alphabet?: string;
    startDate?: string;
    endDate?: string;
}
export type GeneratePinSetEventType = Event<GeneratePinSetPayload>;
/** Server-side action/event creator */
export const generatePinSetEvent = (payload: GeneratePinSetPayload): GeneratePinSetEventType => {
    return {
        type: EventType.GENERATE_PIN_SET,
        timestamp: timestampInLocalISO(),
        payload,
    };
};

export interface SavePinSetPayload {
    pinSetId: string;
    pinCountPerPage: number;
    locale?: string;
    pollName: string;
    target: {
        type: string;
        id: string;
    };
}
export type SavePinSetEventType = Event<SavePinSetPayload>;
/** Server-side action/event creator */
export const generateSavePinSetEvent = (payload: SavePinSetPayload): SavePinSetEventType => {
    return {
        type: EventType.SAVE_PIN_SET,
        timestamp: timestampInLocalISO(),
        payload,
    };
};

export interface GeneratePinsPayload {
    numberOfPins: number;
    target: {
        type: string;
        id: string;
    };
    pinLength?: number;
    alphabet?: string;
}
export type GeneratePinsEventType = Event<GeneratePinsPayload>;
/** Server-side action/event creator */
export const generatePinsEvent = (payload: GeneratePinsPayload): GeneratePinsEventType => {
    return {
        type: EventType.GENERATE_PINS,
        timestamp: timestampInLocalISO(),
        payload,
    };
};

// Admin

// Reindex descriptors
export interface ReindexDescriptorsEventPayload {
    indexes: Record<string, number>; // descriptor id -> its index
}
export type ReindexDescriptorsEventType = Event<ReindexDescriptorsEventPayload>;
export const reindexDescriptors = (indexes: Record<string, number>): ReindexDescriptorsEventType => {
    return {
        type: EventType.REINDEX_DESCRIPTORS,
        timestamp: timestampInLocalISO(),
        payload: {
            indexes,
        },
    };
};

// Remove descriptor
export interface RemoveDescriptorEventPayload {
    descriptorId: string;
    indexes: Record<string, number>; // descriptor id -> its index
}
export type RemoveDescriptorEventType = Event<RemoveDescriptorEventPayload>;
export const removeDescriptor = (descriptorId: string, indexes: Record<string, number>): RemoveDescriptorEventType => {
    return {
        type: EventType.REMOVE_DESCRIPTOR,
        timestamp: timestampInLocalISO(),
        payload: {
            descriptorId,
            indexes,
        },
    };
};

// Add descriptor
export interface AddDescriptorEventPayload {
    descriptor: Descriptor;
    indexes: Record<string, number>; // descriptor id -> its index
}
export type AddDescriptorEventType = Event<AddDescriptorEventPayload>;
export const addDescriptor = (descriptor: Descriptor, indexes: Record<string, number>): AddDescriptorEventType => {
    return {
        type: EventType.ADD_DESCRIPTOR,
        timestamp: timestampInLocalISO(),
        payload: {
            descriptor,
            indexes,
        },
    };
};

// Update descriptor
export interface UpdateDescriptorEventPayload {
    descriptorId: string;
    change: any;
}
export type UpdateDescriptorEventType = Event<UpdateDescriptorEventPayload>;
export const updateDescriptor = (descriptorId: string, change: any): UpdateDescriptorEventType => {
    return {
        type: EventType.UPDATE_DESCRIPTOR,
        timestamp: timestampInLocalISO(),
        payload: {
            descriptorId,
            change,
        },
    };
};

// Snapshot page
export interface SnapshotPageEventPayload {
    descriptors: Descriptors;
}
export type SnapshotPageEventType = Event<SnapshotPageEventPayload>;
export const snapshotPage = (descriptors: Descriptors): SnapshotPageEventType => {
    return {
        type: EventType.SNAPSHOT_PAGE,
        timestamp: timestampInLocalISO(),
        payload: {
            descriptors,
        },
    };
};

// Auth

// Create custom token
export interface CreateCustomTokenEventPayload {
    uid: string;
}
export type CreateCustomTokenEventType = Event<CreateCustomTokenEventPayload>;
export const createCustomToken = (uid: string): CreateCustomTokenEventType => {
    return {
        type: EventType.CREATE_CUSTOM_TOKEN,
        timestamp: timestampInLocalISO(),
        payload: {
            uid,
        },
    };
};

// Check entity whitelist
export interface CheckEntityWhitelistEventPayload {
    entityName: string;
    entityId: string;
    toCheck: string;
}
export type CheckEntityWhitelistEventType = Event<CheckEntityWhitelistEventPayload>;
export const checkEntityWhitelist = (entityName: string, entityId: string, toCheck: string): CheckEntityWhitelistEventType => {
    return {
        type: EventType.CHECK_ENTITY_WHITELIST,
        timestamp: timestampInLocalISO(),
        payload: {
            entityName,
            entityId,
            toCheck,
        },
    };
};
