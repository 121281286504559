import React, { PropsWithChildren } from 'react';

import * as Styled from './styled';

export type PageWrapperProps = Styled.WrapperProps;

const PageWrapper: React.FC = (props: PropsWithChildren<PageWrapperProps>) => (
    <Styled.Wrapper {...props}>
        <Styled.Page>{props.children}</Styled.Page>
    </Styled.Wrapper>
);
export default PageWrapper;
