import { isNil as _isNil, cloneDeep as _cloneDeep } from 'lodash';
import { PinSetInfos, Store } from '../../../../app/store/school_pb/interfaces';
import moment from 'moment';

function getClassInfo(classCount: number, locale: string) {
    if (locale === 'cs') {
        let classInfo;
        if (classCount === 1) {
            classInfo = `**${classCount} třída**`;
        } else if (classCount <= 4) {
            classInfo = `**${classCount} třídy**`;
        } else {
            classInfo = `**${classCount} tříd**`;
        }

        return classInfo;
    }

    if (locale === 'sk') {
        let classInfo;
        if (classCount === 1) {
            classInfo = `**${classCount} trieda**`;
        } else if (classCount <= 4) {
            classInfo = `**${classCount} triedy**`;
        } else {
            classInfo = `**${classCount} tried**`;
        }

        return classInfo;
    }
}

function getPinSetTitle(locale: string, classCount: number, pinCount: number, dateCreated: string) {
    const classInfo = getClassInfo(classCount, locale);

    if (locale === 'cs') {
        return `${classInfo} - ${pinCount} kódů (generováno ${dateCreated})`;
    }

    if (locale === 'sk') {
        return `${classInfo} - ${pinCount} kódov (generované ${dateCreated})`;
    }

    return `'!! MISSING TRANSLATION FOR PIN SET TITLE (locale: ${locale})`;
}

export default (val: PinSetInfos, store: any): PinSetInfos => {
    if (_isNil(val)) {
        return val;
    }

    const localeData = moment.localeData();
    const locale = moment.locale();
    const dateFormat = localeData.longDateFormat('L');

    const resVal = _cloneDeep(val);
    const spbStore = store.schoolpb as Store;
    for (const pinSetInfo of resVal) {
        const classCnt = pinSetInfo.pinCount / spbStore.pinsPerClassCount;
        const date = moment(pinSetInfo.creationDate).format(dateFormat);
        pinSetInfo.title = getPinSetTitle(locale, classCnt, pinSetInfo.pinCount, date);
    }

    return resVal;
};
