import { defer as _defer, join as _join, map as _map } from 'lodash';

import { Effect, Browser as BrowserActions } from '../../../../core/action';

export default function (payload: BrowserActions.shareOnFacebook.Payload): Effect {
    return async (): Promise<void> => {
        const config: Record<string, string | number> = {
            width: 550,
            height: 400,
            left: window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - 550 / 2,
            top: window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - 400 / 2,
            location: 'no',
            toolbar: 'no',
            status: 'no',
            directories: 'no',
            menubar: 'no',
            scrollbars: 'yes',
            resizable: 'no',
            centerscreen: 'yes',
            chrome: 'yes',
        };

        _defer(() => {
            window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(payload)}`,
                '',
                _join(
                    _map(config, (v: string, k: string) => `${k}=${v}`),
                    ',',
                ),
            );
        });
    };
}
