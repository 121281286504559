import { map as _map } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Browser as BrowserActions, create as createAction } from '../../../../core/action';
import { EntityList as CoreEntityList } from '../../../../core/admin';
import Item from './Item';
import * as Styled from './styled';

interface Props {
    list: CoreEntityList.List;
}

const EntityList = (props: Props) => {
    const dispatch = useDispatch();

    const handleItemClick = (item: CoreEntityList.Item) => {
        dispatch(createAction(BrowserActions.route.TYPE, `/admin/${item.id}`));
    };

    const onItemCmd = (cmd: CoreEntityList.Command, item: CoreEntityList.Item) => {
        if (cmd === CoreEntityList.COMMAND_CLICK) {
            handleItemClick(item);
        }
    };

    return (
        <Styled.List>
            {_map(props.list, (item: CoreEntityList.Item) => (
                <Item key={item.id} item={item} onCmd={onItemCmd} />
            ))}
        </Styled.List>
    );
};
export default EntityList;
