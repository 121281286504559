import styled from 'styled-components';
import ReactPlayer from 'react-player';

import { Config } from './index';

export const Player = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const YouTube = styled.div<{ config: Config }>`
    position: relative;
    width: 100%;
    box-sizing: border-box;

    &:before {
        position: relative;
        display: block;
        content: '';
        padding-top: 56.25%;
    }

    /* style override */
    ${({ config }) => config.styleOverride}
`;
