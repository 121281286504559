import { cloneDeep as _cloneDeep } from 'lodash';

import { Store, Repetition, Execution } from '../interfaces';
import { Planner as PlannerAction } from '../../../../core/action';

const reducer = (state: Store, payload: PlannerAction.planOnce.Payload): Store => {
    const ex: Execution = {
        repetition: Repetition.EVERY,
        ex: _cloneDeep(payload.ex),
    };

    return {
        ...state,
        plan: {
            ...state.plan,
            [payload.trigger]: ex,
        },
    };
};

export default reducer;
