import { isNil as _isNil } from 'lodash';
import styled from 'styled-components';

import { DialogLayout } from '../../../../core/website';
import { HorizontalAlign } from '../../../../core/block';

const DEFAULT_DIALOG_ALIGN = 'left';
const DEFAULT_DIALOG_ITEMS_ALIGN = 'flex-start';

const dialogAlign = (a: HorizontalAlign): string => {
    switch (a) {
        case HorizontalAlign.CENTER:
            return 'center';
        case HorizontalAlign.LEFT:
            return 'flex-start';
        case HorizontalAlign.RIGHT:
            return 'flex-end';
        case HorizontalAlign.STRETCH:
            return 'stretch';
    }
};

type DialogProps = DialogLayout;
export const Dialog = styled.div<DialogProps>`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    text-align: ${({ align }) => (!_isNil(align) ? align : DEFAULT_DIALOG_ALIGN)};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: ${({ align }) => (!_isNil(align) ? dialogAlign(align) : DEFAULT_DIALOG_ITEMS_ALIGN)};
`;
