import * as Name from './name';
import * as Tools from './tools';
import * as CustomIconSet from './icon_sets/custom';
import * as MaterialIconSet from './icon_sets/material';

const IconSets = {
    Custom: CustomIconSet.default,
    Material: MaterialIconSet.default,
};

export * from './interfaces';
export { Name, Tools, IconSets };
