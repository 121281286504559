import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import _noop from 'lodash/noop';

import * as Styled from './styled';
import MaterialIcons from '../../../core/block/icon_sets/material';
import { IconsSet, Attachment } from '../../../core/block';
import Icon from '../../blocks/Icon';
import { interpolate } from '../../../core/tools/interpolation';

const CLOUDINARY_CLOUD_NAME = 'd21-me';
const CLOUDINARY_UPLOAD_PRESET = 'dev-m0rvvljg'; // production-yqlmwjm3
export const DEFAULT_UPLOAD_URL = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/auto/upload`;
// https://www.mocky.io/v2/5cc8019d300000980a055e76

const prepareUploadBody = (file: File) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
    return formData;
};

const doUpload = (file: File) => {
    const uploadUrl = DEFAULT_UPLOAD_URL;
    const abortController = new AbortController();
    const uploadPromise = fetch(uploadUrl, {
        method: 'POST',
        body: prepareUploadBody(file),
        signal: abortController.signal,
    }).then((response) => response.json());

    return { upload: uploadPromise, aborter: abortController };
};

function createThumbnailUrl(publicId: string) {
    return `https://res.cloudinary.com/d21-me/image/upload/c_fill,w_48/v1/${publicId}`;
}

function deleteByToken(deleteToken: string) {
    const url = `https://api.cloudinary.com/v1_1/d21-me/delete_by_token`;
    const payload = new FormData();
    payload.append('token', deleteToken);

    return fetch(url, { method: 'POST', body: payload });
}

function without<TItem>(array: TItem[], itemToBeRemoved: TItem) {
    return array.filter((item) => item !== itemToBeRemoved);
}

function toAttachment(uploadedFile: AttachmentInternal): Attachment {
    const { ...output } = uploadedFile;
    return output;
}

const imageRegex = /\.(gif|jpe?g|tiff|png|webp|bmp)$/i;
function hasVisualThumbnail(filenameOrMediaType: string) {
    return filenameOrMediaType.startsWith('image/') || imageRegex.test(filenameOrMediaType);
}

export interface FileUploaderProps {
    /** Change handler delegate */
    onChange?: (files: Attachment[]) => void;
    /** Maximum number of files that will be accepted */
    maxFiles?: number;
    /** Maximum size of a single file, in bytes */
    maxFileSize?: number;
    /** Maximum size of all the files together, in bytes */
    maxTotalSize?: number;
    /** Accepted content types. Currently, the behavior comes from react-dropzone, which in turn takes it from https://www.npmjs.com/package/attr-accept */
    accept?: string;
    texts?: {
        /** A text that prompts user to add a file */
        placeholder?: string;
        dragOver?: string;
        uploading?: string;
    };
}

interface AttachmentInternal extends Attachment {
    deleteToken: string;
}

const DEFAULT_TEXTS = {
    placeholder: 'Drop files or click here to pick',
    dragOver: 'Drop {{ filesCount }} files here',
    uploading: 'Uploading...',
};

export const FileUploader: React.FC<FileUploaderProps> = (props) => {
    const { maxFiles, maxFileSize, /* TODO maxTotalSize, */ accept, onChange = _noop } = props;
    const texts = { ...DEFAULT_TEXTS, ...props.texts };

    const [files, setFiles] = useState<AttachmentInternal[]>([]);
    const [filesInProgress, setFilesInProgress] = useState<any[]>([]);

    const shouldAcceptAdditionalFiles = !maxFiles || files.length < maxFiles;

    const onDrop = (acceptedFiles: File[]) => {
        // get rid of files that are over the max file count
        if (maxFiles) {
            const currentMaxFiles = maxFiles - files.length;
            acceptedFiles = acceptedFiles.slice(0, currentMaxFiles);
        }

        acceptedFiles.forEach((rawFile) => {
            const { upload, aborter } = doUpload(rawFile);
            const fileInProgress = { raw: rawFile, thumbnail: hasVisualThumbnail(rawFile.type) ? URL.createObjectURL(rawFile) : undefined, aborter };
            setFilesInProgress((current) => [fileInProgress, ...current]);

            upload.then((data) => {
                setFilesInProgress((current) => without(current, fileInProgress));
                const uploadedFile: AttachmentInternal = {
                    title: rawFile.name,
                    url: data.secure_url,
                    type: data.format,
                    thumbnailUrl: hasVisualThumbnail(rawFile.type) ? createThumbnailUrl(data.public_id) : undefined,
                    size: rawFile.size,
                    deleteToken: data.delete_token,
                };

                setFiles((currentFiles) => {
                    const newFiles = [uploadedFile, ...currentFiles];

                    const attachmentsPayload = newFiles.map(toAttachment);
                    onChange(attachmentsPayload);

                    return newFiles;
                });
            });

            return fileInProgress;
        });
    };

    const dropzoneConfig = { onDrop, accept, maxSize: maxFileSize, multiple: maxFiles !== 1 };

    const { getRootProps, getInputProps, isDragActive, draggedFiles } = useDropzone(dropzoneConfig);
    const { onClick, ...rootPropsWithoutOnClick } = getRootProps();

    return (
        <Styled.DroppableArea {...rootPropsWithoutOnClick}>
            <Styled.Blurrable className={isDragActive ? 'blur-on' : ''}>
                <Styled.Blur>
                    {shouldAcceptAdditionalFiles && (
                        <Styled.CallToActionSlot onClick={onClick}>
                            <input type="file" {...getInputProps()} />
                            <Styled.IconCircle icon={MaterialIcons.ADD} name="dummy_because_of_block_interface" />
                            &nbsp;{texts.placeholder}
                        </Styled.CallToActionSlot>
                    )}

                    {filesInProgress.map((file: any) => (
                        /* CAUTION: Using file.name as key. May cause bugs,
                                if uploading files with the same name at the same time. */
                        <Styled.UploadProgressItem key={file.raw.name}>
                            <div>
                                {/* Using height as the dominant dimension, 
                                because images are more likely to be wider than taller,
                                and we want the whole thumbnail square to be filled. */}
                                {file.thumbnail ? (
                                    <Styled.ImageThumbnail src={file.thumbnail} />
                                ) : (
                                    <Styled.IconThumbnail icon={MaterialIcons.DESCRIPTION} set={IconsSet.MATERIAL_OUTLINE} name="dummy" />
                                )}
                            </div>
                            <Icon icon={MaterialIcons.AUTORENEW} name="dummy" />
                            &nbsp;{texts.uploading}
                            <Styled.SlotActionButton
                                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    e.stopPropagation();
                                    file.aborter.abort();
                                    setFilesInProgress((current) => without(current, file));
                                }}
                            >
                                <Styled.IconCircle
                                    icon={MaterialIcons.DELETE}
                                    set={IconsSet.MATERIAL_OUTLINE}
                                    name="dummy_because_of_block_interface"
                                />
                            </Styled.SlotActionButton>
                        </Styled.UploadProgressItem>
                    ))}

                    {files.map((file) => (
                        <Styled.UploadListItem key={file.url}>
                            {file.thumbnailUrl ? (
                                <Styled.ImageThumbnail src={file.thumbnailUrl} />
                            ) : (
                                <Styled.IconThumbnail icon={MaterialIcons.DESCRIPTION} set={IconsSet.MATERIAL_OUTLINE} name="dummy" />
                            )}
                            <input
                                defaultValue={file.title}
                                onInput={(e) => {
                                    file.title = (e.target as any).value;
                                    onChange(files);
                                }}
                                title={file.title}
                            />
                            <Styled.SlotActionButton
                                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    e.stopPropagation();
                                    deleteByToken(file.deleteToken).then(() => setFiles((currentFiles) => without(currentFiles, file)));
                                }}
                            >
                                <Styled.IconCircle
                                    icon={MaterialIcons.DELETE}
                                    set={IconsSet.MATERIAL_OUTLINE}
                                    name="dummy_because_of_block_interface"
                                />
                            </Styled.SlotActionButton>
                        </Styled.UploadListItem>
                    ))}
                </Styled.Blur>
                <Styled.BlurTinting />
            </Styled.Blurrable>

            {isDragActive && (
                <Styled.DragOverlay>
                    <Icon icon={MaterialIcons.SAVE_ALT} name="dummy" />
                    {interpolate(texts.dragOver, { filesCount: draggedFiles.length })}
                </Styled.DragOverlay>
            )}
        </Styled.DroppableArea>
    );
};
