import styled from 'styled-components';

import { Config } from './index';
import { color } from '../../stylesheets/shared';

export const HorizontalLine = styled.div<{ config: Config }>`
    background-color: ${color.grey3};
    height: 1px;
    width: 100%;

    /* style override */
    ${({ config }) => config.styleOverride}
`;
