import * as Reducers from './reducers';
import { Store, initialStore } from './interfaces';
import { Action, Dialog as DialogActions } from '../../../core/action';

export default function (state: Store = initialStore, action: Action<any>) {
    switch (action.type) {
        case DialogActions.stepperStep.TYPE:
            return Reducers.stepperStep(state, action.payload as DialogActions.stepperStep.Payload);

        default:
            return state;
    }
}
