import React from 'react';

import { BlockProps, Rank as RankConfig, Tools } from '../../../core/block';
import * as Styled from './styled';

export type Config = RankConfig;
export type Props = BlockProps & Config;

const Rank = (props: Props) => {
    return (
        <Styled.Rank {...Tools.extractConfig<Config>(props)}>
            <Styled.Text>{props.text}</Styled.Text>
        </Styled.Rank>
    );
};
export default Rank;
