import { isNil as _isNil, isArray as _isArray, cloneDeep as _cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Tools } from '../../../core/entity';
import { Action, Tools as ActionTools } from '../../../core/action';
import { Tools as SelectorTools } from '../../../core/selector';
import { Outlet } from '../../../core/outlet';
import { RootState } from '../../../app/store/root_reducer';

function useOutlets(): any {
    const dispatch = useDispatch();
    const store = useSelector((rootStore: RootState) => rootStore); // THIS SHOULDN'T BE HERE
    const location = useLocation(); // THIS SHOULDN'T BE HERE

    const processOutlet = (outlet: Outlet, parameters?: any): any => {
        // outlet is action
        if (!_isNil(outlet.type) && Tools.isItAction(outlet.type)) {
            let action: Action<any> = _cloneDeep(outlet) as Action<any>;
            // resolve selectors
            action = SelectorTools.resolvePayloadSelectors(action, store, location);
            // resolve template action ... if it's template
            const payload = ActionTools.resolvePayloadTemplate(action, parameters);
            return dispatch({
                ...action,
                payload,
            });
        }

        throw new Error(`Cannot recognize type of outlet`);
    };

    const processOutletsSequence = async (outlets: Outlet[], parameters?: any): Promise<void> => {
        for (const outlet of outlets) {
            await processOutlet(outlet, parameters);
        }
    };

    return (outlet: Outlet | Outlet[], parameters?: any) => {
        // outlet is array
        if (_isArray(outlet)) {
            return processOutletsSequence(outlet, parameters);
        }

        // single outlet
        return processOutlet(outlet, parameters);
    };
}

export default useOutlets;
