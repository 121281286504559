import { get as _get, isNil as _isNil } from 'lodash';

import { StoreSelectorFunction } from '../../../../core/selector';
import { RootState } from '../../root_reducer';
import { Store } from '../interfaces';
import getStore from './get_store';

export interface Payload {
    groupName: string;
}

export default (payload: Payload): StoreSelectorFunction<number[] | undefined> => (rootStore: RootState): number[] | undefined => {
    const store: Store = getStore(rootStore);
    if (_isNil(store)) {
        return;
    }

    return _get(store.shuffleGroup, payload.groupName);
};
