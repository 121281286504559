import { size as _size } from 'lodash';
import React from 'react';

import { BlockProps, SimpleVotingOption as SimpleVotingOptionConfig, SimpleVotingOptionInlets, Tools } from '../../../../core/block';
import { useInlets } from '../../../hooks';
import Blocks from '../../Blocks';
import * as Styled from './styled';

export type Config = SimpleVotingOptionConfig;
export type Props = BlockProps & Config;

const VotingOption = (props: Props) => {
    const usedInlets = useInlets(props.inlets) as SimpleVotingOptionInlets;

    return (
        <Styled.Option {...Tools.extractConfig<Config>(props)} usedInlets={usedInlets} numberOfVoteButtons={_size(props.voteButtons)}>
            {/* text */}
            <Styled.Name>{props.text}</Styled.Name>

            {/* vote buttons */}
            {props.voteButtons && (
                <Styled.VoteButtons numberOfVoteButtons={_size(props.voteButtons)}>
                    <Blocks blocks={props.voteButtons} />
                </Styled.VoteButtons>
            )}
        </Styled.Option>
    );
};
export default VotingOption;
