import React, { useState, SyntheticEvent, useEffect } from 'react';
import _defer from 'lodash/defer';

import Blocks from '../../Blocks';
import { BlockProps, FormFieldRadioGroup as FormFieldRadioGroupConfig } from '../../../../core/block';
import { useOutlets, useStringValidation, VALIDATION_RESULT_UNTOUCHED, ValidationResult, getOutletValidationStatus } from '../../../hooks';
import * as Styled from './styled';

export interface Props extends BlockProps, FormFieldRadioGroupConfig {}

// filters out any other sources of change events, which may bubble up from the DOM
function isRelevantTarget(target: SyntheticEvent['target'], radioGroupName: string) {
    const input = target as HTMLInputElement;
    return input.type === 'radio' && input.name === radioGroupName;
}

export const RadioGroup: React.FC<Props> = (props: Props) => {
    const [selectedRadioValue, setSelectedRadioValue] = useState<string | undefined>();

    const validate = useStringValidation(props.validationScheme || {});
    const [validationResult, setValidationResult] = useState(VALIDATION_RESULT_UNTOUCHED);
    const [touched, setTouched] = useState(false);

    const outlet = useOutlets();
    const runOutlets = (value: string, validationResult: ValidationResult) => {
        if (!props.outlets?.change) {
            return;
        }

        const outletParameters = {
            value,
            validationStatus: getOutletValidationStatus(validationResult),
        };

        _defer(() => outlet(props.outlets?.change, outletParameters));
    };

    // on mount, set form validity state in pristine state of the component
    useEffect(() => {
        (async () => {
            const validationResult = await validate('');
            runOutlets('', validationResult);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event: SyntheticEvent) => {
        if (!isRelevantTarget(event.target, props.group)) {
            return;
        }

        event.stopPropagation();
        setTouched(true);

        const radio = event.target as HTMLInputElement;
        setSelectedRadioValue(radio.value);

        validate(radio.value).then((validationResult) => {
            setValidationResult(validationResult);
            runOutlets(radio.value, validationResult);
        });
    };

    return (
        <div onChange={handleChange}>
            <Blocks blocks={props.blocks} {...{ selectedRadioValue }} />
            {!validationResult.isValid && touched && <Styled.ErrorMessage>{validationResult.message}</Styled.ErrorMessage>}
        </div>
    );
};
