import styled, { css } from 'styled-components';

import { Config } from './index';
import { onPhone } from '../../stylesheets/tools';

const DEFAULT_ITEMS_MARGIN = '0px 12px';

export const Layout = styled.div<{ config: Config }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    /* style override */
    ${({ config }) => config.styleOverride}

    ${onPhone(css`
        justify-content: space-between;
    `)}

    /* all child items */
    & > * {
        margin: ${({ config }) => (config.itemsMargin ? config.itemsMargin : DEFAULT_ITEMS_MARGIN)};
    }
`;
