import { isNil as _isNil } from 'lodash';
import { Dispatch } from 'redux';

import { Effect } from '../../../../core/action';
import { RootState } from '../../root_reducer';
import { PollSelectors } from '../selectors';
import { Vote, Poll } from '../../../../core/voting';
import { Streamer, Events, Event } from '../../../../core/stream';
import { create as createAction, Voting as VotingActions } from '../../../../core/action';

export default function (): Effect {
    return async (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                // get current poll id
                const pollConfig: Poll.Poll | undefined = PollSelectors.config()(getState());
                if (_isNil(pollConfig)) {
                    throw new Error(`Cannot find current poll config `);
                }

                // get current vote
                const vote: Vote.Vote | undefined = PollSelectors.vote()(getState());
                if (_isNil(vote)) {
                    throw new Error(`Cannot find current vote`);
                }

                const event: Event<Events.StoreVoteEventPayload> = Events.storeVote(pollConfig.id, vote);
                const result = await Streamer.stream(event);

                dispatch(createAction(VotingActions.sendVoteSuccess.TYPE));
                resolve(result);
            } catch (error) {
                dispatch(createAction(VotingActions.sendVoteError.TYPE));
                reject(error);
            }
        });
    };
}
