import {
    map as _map,
    forEach as _forEach,
    isNil as _isNil,
    max as _max,
    get as _get,
    set as _set,
    round as _round,
    min as _min,
    sortBy as _sortBy,
    reverse as _reverse,
    omit as _omit,
} from 'lodash';
import React, { useEffect, useState } from 'react';

import {
    BlockProps,
    OverlapResultList as OverlapResultListConfig,
    OverlapResultListInlets,
    Tools,
    ResultListItem,
    GraphData,
    GraphTheme,
} from '../../../../core/block';
import { Result, Choice } from '../../../../core/voting';
import { useInlets } from '../../../hooks';
import Block from '../../Block';
import * as Styled from './styled';

export type Config = OverlapResultListConfig;
export type Props = BlockProps & Config;

interface RenderListItem {
    id: string;
    resultValue: number;
    graphProps: {
        text?: string;
        portraitSrc?: string;
        subPortraitSrc?: string;
        data: GraphData | GraphData[];
        themeOverride?: GraphTheme;
    };
}

const OverlapPortraitResultList = (props: Props) => {
    const usedInlets = useInlets(props.inlets) as OverlapResultListInlets;
    const [renderList, setRenderList] = useState<RenderListItem[]>([]);

    // re-calculate results
    useEffect(() => {
        if (_isNil(usedInlets.values) || _isNil(usedInlets.overlap) || _isNil(props.items)) {
            return;
        }

        // get value for baseline item
        const baselineValue: Result.Value | undefined = _get(usedInlets.values, props.baselineOptionId);
        // calculate max absolute value
        let maxAbsoluteValue = 0;
        if (!_isNil(baselineValue)) {
            const plus = _get(baselineValue, [Choice.Meaning.PLUS], 0);
            const minus = _get(baselineValue, [Choice.Meaning.MINUS], 0);
            maxAbsoluteValue = _max([0, Math.abs(plus), Math.abs(minus)]) || 0;
        }

        // remove baseline option from list
        const items = _omit(props.items, props.baselineOptionId);

        // calculate result values for each item
        const resultValuePerItem: Record<string, number> = {}; // item id -> value
        _forEach(items, (item: ResultListItem, itemId: string) => {
            // get overlap value for item
            const value: Result.Value | undefined = _get(usedInlets.overlap, [props.baselineOptionId, itemId]);

            let resultValue = 0;

            // there is no value for corresponding list item
            if (_isNil(value)) {
                resultValue = 0;
            } else {
                const plus = _get(value, [Choice.Meaning.PLUS], 0);
                const minus = _get(value, [Choice.Meaning.MINUS], 0);
                resultValue = plus - minus;
            }

            _set(resultValuePerItem, itemId, resultValue);
        });

        // calculate final render list
        // LEGEND
        // percentage -> (result value / maxAbsoluteValue) * 100 ... D21 values per meaning
        // count -> result value ... D21 values per meaning
        // barSize: -> result value / maxAbsoluteValue * 100 ... D21 values per meaning
        const renderList: RenderListItem[] = [];
        _forEach(items, (item: ResultListItem, itemId: string) => {
            const itemText: string | undefined = item.text;
            const itemPortrait: string | undefined = item.portraitSrc;
            const itemSubPortrait: string | undefined = item.subPortraitSrc;
            const itemResultValue: number = _get(resultValuePerItem, itemId, 0);
            const percentagePrecision: number = props.percentagePrecision || 0;

            // detailed
            if (props.detailed === true) {
                // get overlap value for item
                const value: Result.Value | undefined = _get(usedInlets.overlap, [props.baselineOptionId, itemId]);
                const plusValue = _get(value, [Choice.Meaning.PLUS], 0);
                const minusValue = _get(value, [Choice.Meaning.MINUS], 0);

                const calculatedPlusPercentage = maxAbsoluteValue === 0 ? 0 : _round((plusValue / maxAbsoluteValue) * 100, percentagePrecision);
                const calculatedMinusPercentage = maxAbsoluteValue === 0 ? 0 : _round((minusValue / maxAbsoluteValue) * 100, percentagePrecision);

                const ri: RenderListItem = {
                    id: itemId,
                    resultValue: itemResultValue,
                    graphProps: {
                        text: itemText,
                        portraitSrc: itemPortrait,
                        subPortraitSrc: itemSubPortrait,
                        data: [
                            // plus
                            {
                                barSize: _min([100, calculatedPlusPercentage]) || 0,
                                count: plusValue,
                                percentage: _min([100, calculatedPlusPercentage]) || 0,
                            },
                            // minus
                            {
                                barSize: _min([100, calculatedMinusPercentage]) || 0,
                                count: -1 * minusValue,
                                percentage: -1 * (_min([100, calculatedMinusPercentage]) || 0),
                            },
                        ],
                        themeOverride: props.graphThemeOverride,
                    },
                };

                renderList.push(ri);
            }
            // total
            else {
                const calculatedPercentage = maxAbsoluteValue === 0 ? 0 : _round((itemResultValue / maxAbsoluteValue) * 100, percentagePrecision);

                const ri: RenderListItem = {
                    id: itemId,
                    resultValue: itemResultValue,
                    graphProps: {
                        text: itemText,
                        portraitSrc: itemPortrait,
                        subPortraitSrc: itemSubPortrait,
                        data: {
                            barSize: _min([100, calculatedPercentage]) || 0,
                            count: itemResultValue,
                            percentage: _min([100, calculatedPercentage]) || 0,
                        },
                        themeOverride: props.graphThemeOverride,
                    },
                };

                renderList.push(ri);
            }
        });

        // sort render list by result value
        const sortedRenderList = _sortBy(renderList, ['resultValue']);
        _reverse(sortedRenderList);

        setRenderList(sortedRenderList);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.graphName, props.baselineOptionId, JSON.stringify(props.items), JSON.stringify(usedInlets.values)]);

    return (
        <Styled.List {...Tools.extractConfig<Config>(props)}>
            {_map(renderList, (rli: RenderListItem) => (
                <Styled.Item key={rli.id}>
                    <Block name={props.graphName} {...rli.graphProps} />
                </Styled.Item>
            ))}
        </Styled.List>
    );
};
export default OverlapPortraitResultList;
