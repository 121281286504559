import { map as _map, isArray as _isArray, isNil as _isNil, get as _get, round as _round, min as _min, mapValues as _mapValues } from 'lodash';
import React, { useEffect, useState } from 'react';

import { BlockProps, Block, Bars as BarsConfig, BarsInlets, BarData, Tools } from '../../../../core/block';
import { Choice } from '../../../../core/voting';
import { Voting as VotingCalculator } from '../../../../core/calculator';
import { interpolate } from '../../../../core/tools';
import { useInlets } from '../../../hooks';
import Blocks from '../../Blocks';
import * as Styled from './styled';

export type Config = BarsConfig;
export type Props = BlockProps & Config;

const Bars = (props: Props) => {
    const inlets = useInlets(props.inlets) as BarsInlets;
    const [idsToRender, setIdsToRender] = useState<string[]>([]);
    const [ranksToRender, setRanksToRender] = useState<Record<string, string>>({});
    const [dataToRender, setDataToRender] = useState<Record<string, BarData[]>>({});

    const generateBarBlock = (id: string): Block[] => {
        const barTemplate = _get(props.barTemplates, id, '');
        const barTemplateData = {
            rank: _get(ranksToRender, id, ''),
            data: _get(dataToRender, id, ''),
        };

        const barInterpolatedTemplate = interpolate(barTemplate, { data: barTemplateData }, { skipUnresolvedPlaceholders: true });
        const barBlocks: Block | Block[] = JSON.parse(barInterpolatedTemplate);

        // Blocks component can render only array of block definition
        return _isArray(barBlocks) ? barBlocks : [barBlocks];
    };

    // re-calculate results
    useEffect(() => {
        if (_isNil(inlets.values) || !Tools.isInletResolved(inlets.values) || _isNil(inlets.maximum) || !Tools.isInletResolved(inlets.maximum)) {
            return;
        }

        // calculation method can be defined by inlet or directly
        let calculationMethod: VotingCalculator.Method | undefined = props.calculationMethod;
        if (_isNil(calculationMethod)) {
            if (!Tools.isInletResolved(inlets.calculationMethod)) {
                return;
            }

            calculationMethod = inlets.calculationMethod;
        }

        // make sure we will calculate result value for each bar
        // There are 2 possible options
        // A) some bars didn't receive any value
        // B) there are some values we don't want to render
        const valuesToCalculate = _mapValues(props.barTemplates, (barTmpl: string, barId: string) => _get(inlets.values, barId, {}));
        // calculate result values
        const resultValues = VotingCalculator.resultValues(valuesToCalculate, calculationMethod);
        // sort bar ids by result value
        const sortedBarIds: string[] = VotingCalculator.sortResultValues(resultValues);
        setIdsToRender(sortedBarIds);
        // calculate rank map for each bar
        const rankMap: Record<string, string> = VotingCalculator.rankMap(resultValues);
        setRanksToRender(rankMap);
        // get maximum value
        const maximum: number = inlets.maximum || 1;
        // get precision
        const precision: number = props.precision || 0;

        // calculate bars data
        const barsData: Record<string, BarData[]> = _mapValues(props.barTemplates, (barTmpl: string, barId: string) => {
            const resultValue: number = _get(resultValues, barId, 0);

            // D21
            if (calculationMethod === VotingCalculator.Method.D21) {
                const plusValue = _get(inlets.values, [barId, Choice.Meaning.PLUS], 0);
                const minusValue = _get(inlets.values, [barId, Choice.Meaning.MINUS], 0);
                const plusSize = _round((plusValue / maximum) * 100, precision);
                const minusSize = _round((minusValue / maximum) * 100, precision);

                return [
                    {
                        size: _min([100, plusSize]) || 0,
                        count: plusValue,
                        percentage: _min([100, plusSize]) || 0,
                    },
                    {
                        size: _min([100, minusSize]) || 0,
                        count: -1 * minusValue,
                        percentage: -1 * (_min([100, minusSize]) || 0),
                    },
                ];
            }
            // Others
            else {
                const size = _round((resultValue / maximum) * 100, precision);
                return [
                    {
                        size: _min([100, size]) || 0,
                        count: resultValue,
                        percentage: _min([100, size]) || 0,
                    },
                ];
            }
        });
        setDataToRender(barsData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets), JSON.stringify(props.barTemplates), props.calculationMethod, props.precision]);

    return (
        <Styled.Bars {...Tools.extractConfig<Config>(props)}>
            {_map(idsToRender, (barId: string) => (
                <Blocks key={barId} blocks={generateBarBlock(barId)} />
            ))}
        </Styled.Bars>
    );
};
export default Bars;
