import { isNil as _isNil } from 'lodash';
import styled, { css } from 'styled-components';

import { Question as CoreQuestion } from '../../../../core/voting';
import { constant, color } from '../../../stylesheets/shared';
import { onTablet } from '../../../stylesheets/tools';
import { cssH2, cssH3 } from '../../../stylesheets/snippets';

const DEFAULT_QUESTION_ALIGN = 'left';

const questionSize = (size?: CoreQuestion.SizeType): string => {
    if (size === CoreQuestion.SizeType.STANDARD) {
        return `${constant.question.standard.width}px`;
    }
    if (size === CoreQuestion.SizeType.WIDE) {
        return `${constant.question.wide.width}px`;
    }

    // default size ... in embedded mode we should take parent size by default
    return `100%`;
};

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`;

// question
type QuestionProps = CoreQuestion.Layout;
export const Question = styled.div<QuestionProps>`
    width: 100%;
    max-width: ${({ size }) => questionSize(size)};
    box-sizing: border-box;
    text-align: ${({ align }) => (!_isNil(align) ? align : DEFAULT_QUESTION_ALIGN)};
    padding-right: 24px;
    padding-left: 24px;

    ${onTablet(css`
        padding-right: 0;
        padding-left: 0;
    `)}
`;

// text
export const Text = styled.div`
    ${cssH2}
    padding: 0 0 24px 0;

    .note {
        ${cssH3};
        color: ${color.grey2};
    }
`;

// blocks
export const Blocks = styled.div`
    width: 100%;
`;
