import { isNil as _isNil, map as _map, size as _size, defer as _defer, debounce as _debounce } from 'lodash';
import React, { useState, useCallback, SyntheticEvent, useEffect } from 'react';
import { Input as AntInput, Dropdown as AntDropdown } from 'antd';

import { BlockProps, Tools, FormFieldPhone as FormFieldPhoneConfig, IconSets, IconsSet } from '../../../../core/block';
import { stringIsEmpty } from '../../../../core/tools';
import { useOutlets, ValidationResult, VALIDATION_RESULT_UNTOUCHED, getOutletValidationStatus, useStringValidation } from '../../../hooks';
import * as International from '../../../../core/internationalisation';
import * as Styled from './styled';

export type Config = FormFieldPhoneConfig;
export type Props = BlockProps &
    Config & {
        onSubmit?: (value: string | undefined, validationResult: ValidationResult) => void;
        onChange?: (value: string, validationResult: ValidationResult) => void;
    };

const PhoneFormField = (props: Props) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [selectedPhoneCode, setSelectedPhoneCode] = useState<string>(props.defaultPhoneCode || '+420');
    const outlet = useOutlets();
    const validate = useStringValidation(props.validationScheme || {});
    const [validationResult, setValidationResult] = useState(VALIDATION_RESULT_UNTOUCHED);
    const [cachedValue, setCachedValue] = useState<string | undefined>();

    const availableCountries = International.Tools.filterByPhoneCodes(props.filterPhoneCodes);
    const selectedCountryIcon = International.Tools.flagIconByPhoneCode(selectedPhoneCode);
    const placeholder = !_isNil(props.placeholder) ? props.placeholder : International.Tools.phoneExampleByPhoneCode(selectedPhoneCode);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const processChangeOutlet = useCallback(
        _debounce((value: string, validationResult: ValidationResult) => {
            if (_isNil(props.outlets?.change)) {
                return;
            }

            const outletParameters = {
                value,
                validationStatus: getOutletValidationStatus(validationResult),
            };

            _defer(() => outlet(props.outlets?.change, outletParameters));
        }, 250),
        [],
    );

    // on mount, set form validity state in pristine state of the component
    useEffect(() => {
        (async () => {
            const validationResult = await validate('');
            processChangeOutlet('', validationResult);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const runSubmitOutlet = (value: string, validationResult: ValidationResult) => {
        if (!props.outlets?.submit) {
            return;
        }

        const outletParameters = {
            value,
            validationStatus: getOutletValidationStatus(validationResult),
        };

        outlet(props.outlets?.submit, outletParameters);
    };

    const processChange = (value: string, validationResult: ValidationResult) => {
        if (_isNil(props.onChange)) {
            return;
        }

        props.onChange(value, validationResult);
    };

    const processValidation = async (value: string): Promise<ValidationResult> => {
        const validationResult = await validate(value);
        setValidationResult(validationResult);

        return validationResult;
    };

    // TODO ... this field currnetly doesn't support Inlets
    // // TODO possible candidate for refactoring into useInletValue()
    // useEffect(() => {
    //     // we are not expecting any inlet
    //     if (_isNil(props.inlets)) {
    //         return;
    //     }

    //     // is inlet already resolved?
    //     if (Tools.isInletResolved(inlets.value)) {
    //         // ... yes value is defined, and it's different then cached value -> use it
    //         if (!_isNil(inlets.value) && !_isEqual(inlets.value, cachedValue)) {
    //             // use it as cached value ...
    //             setCachedValue(inlets.value);
    //             // ... and validate the value
    //             processValidation(inlets.value).then((validationResult) => {
    //                 // process all changes
    //                 processChangeOutlet(inlets.value, validationResult);
    //                 processChange(inlets.value, validationResult);
    //             });
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [JSON.stringify(inlets.value)]);

    const handleChange = async (event: SyntheticEvent) => {
        // get value from input
        const value = (event.target as HTMLInputElement).value;
        // store it as local value
        setCachedValue(value);

        // validate the value
        processValidation(value).then((validationResult) => {
            // build entire phone number
            const phoneNumber = _isNil(value) || stringIsEmpty(value) ? '' : `${selectedPhoneCode} ${value}`; // do not send just the phone code with empty phone number
            // process all changes
            processChangeOutlet(phoneNumber, validationResult);
            processChange(phoneNumber, validationResult);
        });
    };

    const onSubmit = () => {
        if (!_isNil(props.onSubmit)) {
            props.onSubmit(cachedValue, validationResult);
        }

        if (!_isNil(cachedValue)) {
            runSubmitOutlet(cachedValue, validationResult);
        }
    };

    const onPrefixMenuVisibleChange = (visible: boolean) => {
        setIsDropdownOpen(visible);
    };

    const onPrefixMenuItem = (code: string) => {
        setIsDropdownOpen(false);
        setSelectedPhoneCode(code);
    };

    const countriesMenu = (
        <Styled.DropdownMenu>
            {_map(availableCountries, (country: International.Country) => (
                <Styled.DropdownMenuItem key={country.phoneCode} onClick={() => onPrefixMenuItem(country.phoneCode)}>
                    <Styled.DropdownMenuItemIcon name="" set={IconsSet.CUSTOM} icon={country.flagIcon} />
                    <Styled.DropdownMenuItemText>{country.localizedName}</Styled.DropdownMenuItemText>
                    <Styled.DropdownMenuItemPrefix>({country.phoneCode})</Styled.DropdownMenuItemPrefix>
                </Styled.DropdownMenuItem>
            ))}
        </Styled.DropdownMenu>
    );

    return (
        <Styled.Phone {...Tools.extractConfig<Config>(props)} onChange={handleChange}>
            {/* label */}
            {!_isNil(props.text) && <Styled.Label>{props.text}</Styled.Label>}

            {/* input */}
            <Styled.FormItem
                validateStatus={validationResult.antValidationStatus}
                hasFeedback={!_isNil(props.validationScheme)}
                help={validationResult.message}
            >
                <Styled.FormItemLayout>
                    {/* country phone code dropdown */}
                    {_size(availableCountries) > 1 && (
                        <AntDropdown overlay={countriesMenu} trigger={['click']} onVisibleChange={onPrefixMenuVisibleChange}>
                            <Styled.Dropdown active={isDropdownOpen}>
                                <Styled.DropdownIcon name="" set={IconsSet.CUSTOM} icon={selectedCountryIcon} />
                                <Styled.DropdownPrefix>{selectedPhoneCode}</Styled.DropdownPrefix>
                                <Styled.DropdownArrow name="" icon={IconSets.Material.KEYBOARD_ARROW_DOWN} />
                            </Styled.Dropdown>
                        </AntDropdown>
                    )}
                    {/* country phone code without dropdown */}
                    {_size(availableCountries) <= 1 && (
                        <Styled.Dropdown active={false} pasive={true}>
                            <Styled.DropdownIcon name="" set={IconsSet.CUSTOM} icon={selectedCountryIcon} />
                            <Styled.DropdownPrefix>{selectedPhoneCode}</Styled.DropdownPrefix>
                        </Styled.Dropdown>
                    )}

                    {/* text input */}
                    <AntInput type="tel" placeholder={placeholder} autoFocus={props.autoFocus} autoComplete="off" onPressEnter={onSubmit} />
                </Styled.FormItemLayout>
            </Styled.FormItem>
        </Styled.Phone>
    );
};
export default PhoneFormField;
