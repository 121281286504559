import { isNil as _isNil } from 'lodash';
import React from 'react';

import { BlockProps, PortraitDoubleSignBarGraph as PortraitDoubleSignBarGraphConfig, Tools } from '../../../../core/block';
import H3 from '../../Typography/H3';
import * as Styled from './styled';

export type Config = PortraitDoubleSignBarGraphConfig;
export type Props = BlockProps & Config;

const PortraitDoubleSignBarGraph = (props: Props) => {
    return (
        <Styled.PortraitDoubleSignBarGraph {...Tools.extractConfig<Config>(props)}>
            {/* portrait */}
            <Styled.PortraitWrapper>
                <Styled.Portrait name="" src={props.portraitSrc} />
                {/* sub-portrait */}
                {!_isNil(props.subPortraitSrc) && <Styled.SubPortrait name="" src={props.subPortraitSrc} />}
            </Styled.PortraitWrapper>

            {/* title */}
            <Styled.Title>{!_isNil(props.text) && <H3 name="" text={props.text} />}</Styled.Title>

            {/* spacer */}
            <Styled.Spacer />

            {/* bar line */}
            <Styled.BarLine>
                <Styled.BarZeroSign />
                <Styled.MinusBar size={props.data[1]?.barSize} />
                <Styled.PlusBar size={props.data[0]?.barSize} />
            </Styled.BarLine>

            {/* numbers line */}
            <Styled.NumbersLine>
                {/* minus */}
                {(!_isNil(props.data[1]?.count) || !_isNil(props.data[1]?.percentage)) && (
                    <Styled.Numbers>
                        {!_isNil(props.data[1]?.percentage) && <Styled.Percentage>{props.data[1].percentage} %</Styled.Percentage>}
                        {!_isNil(props.data[1]?.count) && <Styled.Votes>({props.data[1].count})</Styled.Votes>}
                    </Styled.Numbers>
                )}
                {/* plus */}
                {(!_isNil(props.data[0]?.count) || !_isNil(props.data[0]?.percentage)) && (
                    <Styled.Numbers>
                        {!_isNil(props.data[0]?.percentage) && <Styled.Percentage>{props.data[0].percentage} %</Styled.Percentage>}
                        {!_isNil(props.data[0]?.count) && <Styled.Votes>({props.data[0].count})</Styled.Votes>}
                    </Styled.Numbers>
                )}
            </Styled.NumbersLine>
        </Styled.PortraitDoubleSignBarGraph>
    );
};
export default PortraitDoubleSignBarGraph;
