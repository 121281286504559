import { Meaning } from '../choice/interfaces';
import { Block, HorizontalAlign, Icon, VotesCountTheme } from '../../block';

// Method
export enum MethodType {
    D21 = 'd21',
    SINGLE_VOTE = 'single_vote',
    MULTI_VOTE = 'multi_vote',
    APPROVAL = 'approval',
    COVER = 'cover',
}

// Layout
export enum SizeType {
    STANDARD = 'standard',
    WIDE = 'wide',
}
export interface Layout {
    align?: HorizontalAlign;
    size?: SizeType;
}

// Votes Count Info
export interface VotesCountInfo {
    [Meaning.PLUS]?: Icon;
    [Meaning.MINUS]?: Icon;
    themeOverride?: {
        [Meaning.PLUS]?: VotesCountTheme;
        [Meaning.MINUS]?: VotesCountTheme;
    };
    text: string; // pattern is: "For this question you can cast up to {{+}} and {{-}}."
}

// Config
export interface Config {
    type: MethodType;
}
export interface StandardQuestionConfig extends Config {
    [Meaning.PLUS]?: number;
    [Meaning.MINUS]?: number;
    optional?: boolean;
    autoFix?: boolean;
}
export interface CoverQuestionConfig extends Config {
    embeddedQuestionIds?: string[];
}

// Question
export interface Question {
    id: string;
    name: string;
    config: Config;
    layout?: Layout;
}
export interface CoverQuestion extends Question {
    config: CoverQuestionConfig;
    content: (Block | Question)[];
}
export interface StandardQuestion extends Question {
    text: string;
    note?: string;
    config: StandardQuestionConfig;
    votesCountInfo?: VotesCountInfo;
    blocks: Block[];
}
