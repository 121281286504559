import { Block, HorizontalAlign } from '../../block';
import { Meaning } from '../choice/interfaces';
import { Outlets, Outlet } from '../../outlet';

// Layout
export enum SizeType {
    STANDARD = 'standard',
    WIDE = 'wide',
}
export interface Layout {
    align?: HorizontalAlign;
    size?: SizeType;
}

// Value
export interface Value {
    [Meaning.PLUS]?: number;
    [Meaning.MINUS]?: number;
}

export type Values = Record<string, Value>; // option Id -> Value

// Config
export interface Config {
    respondents: number; // numner of respondents for the result
    // count?: Value; // total number of votes for each meaning ... { "+": ..., "-": ... }
    votes?: Value; // total number of votes for each meaning ... { "+": ..., "-": ... }
    values?: Values; // number of votes each meaning per option ... <option-id>: { "+": ..., "-": ... }
    overlap?: Record<string, Values>; // how many times <option-id> overlaps with other <overlaping-option-id> in one vote ... <option-id>: { <overlaping-option-id>: { "+": ..., "-": ... } }
}

// Result
export interface ResultOutlets extends Outlets {
    enter?: Outlet | Outlet[];
}
export interface Result {
    id: string;
    name: string;
    outlets?: ResultOutlets;
    // config: Record<string, Config>; // <question-id>: Config
    config: Config;
    layout?: Layout;
}
export interface StandardResult extends Result {
    text: string;
    note?: string;
    blocks: Block[];
}
export interface SingleResult extends Result {
    blocks: Block[];
}
