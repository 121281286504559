import { unset as _unset } from 'lodash';

import { Store } from '../interfaces';

const reducer = (state: Store): Store => {
    const newState = { ...state };

    // remove vote
    _unset(newState, ['vote']);

    // remove question configs and velidations
    _unset(newState, ['question', 'config']);
    _unset(newState, ['question', 'validationStatus']);

    return newState;
};

export default reducer;
