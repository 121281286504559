import React from 'react';

import { InteractiveSvg, SVG_INTERACTIVE_IMAGES } from '../../../core/block/interfaces';
import { isNil as _isNil } from 'lodash';
import { BlockProps, Tools } from '../../../core/block';
import { useOutlets } from '../../hooks';
import * as Styled from './styled';

import { SVG_CZ_REGIONS } from './images/cz_regions/cz_regions';
import { SVG_CZ_SENATE_BURROWS } from './images/cz_senate_burrows';

const IMAGE_MAPPING: Record<SVG_INTERACTIVE_IMAGES, any> = {
    [SVG_INTERACTIVE_IMAGES.CZ_REGIONS]: SVG_CZ_REGIONS,
    [SVG_INTERACTIVE_IMAGES.CZ_SENATE_BURROWS]: SVG_CZ_SENATE_BURROWS,
};

export type Config = InteractiveSvg;
export type Props = BlockProps & Config;

export const InteractiveSVG: React.FC<Props> = (props) => {
    const runOutlets = useOutlets();

    const handleClick = (event: React.MouseEvent) => {
        const target = event.target as any;
        const targetId = target.dataset.customId || target.id;
        console.log('clicked', targetId, target);
        const elementOutlets = !_isNil(props.interactiveElements) ? props.interactiveElements[targetId] : null;
        if (_isNil(elementOutlets)) {
            return;
        }

        runOutlets(elementOutlets.click);
    };

    const SvgImage = IMAGE_MAPPING[props.image];
    if (!SvgImage) {
        return <div>IMAGE NOT FOUND</div>;
    }

    return (
        <Styled.SvgContainer {...Tools.extractConfig<Config>(props)}>
            <SvgImage onClick={handleClick} activeElements={props.interactiveElements} />
        </Styled.SvgContainer>
    );
};
