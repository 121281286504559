import { isNil as _isNil, trim as _trim } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';

import { create as createAction, Voting as VotingActions } from '../../../../core/action';
import { ResultsSelectors } from '../../../store/voting/selectors';
import { currentLanguage } from '../../../store/application/selectors';
import { Result } from '../../../../core/voting';
import { IconSets, IconsSet } from '../../../../core/block';
import { translate as t } from '../../../../core/translation';
import { useOutlets } from '../../../hooks';
import Blocks from '../../../blocks/Blocks';
import * as Styled from './styled';

export type Props = Result.StandardResult;

const StandardResultsPage = (props: Props) => {
    const dispatch = useDispatch();
    const outlet = useOutlets();
    const [scrollMarkerRef, scrollMarkerInView] = useInView();
    const [textRef, textInView] = useInView({ rootMargin: '-100px 0px 0px 0px' });
    const currentStepIdx = useSelector(ResultsSelectors.currentStepIndex());
    const numberOfStepsCount = useSelector(ResultsSelectors.numberOfSteps());
    const previousStepIdx = useSelector(ResultsSelectors.previousStep());
    const currLang = useSelector(currentLanguage());

    useEffect(() => {
        dispatch(createAction(VotingActions.resultEnter.TYPE, { result: props }));

        if (!_isNil(props.outlets?.enter)) {
            outlet(props.outlets?.enter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(createAction(VotingActions.resultConfig.TYPE, { r: props.id, config: props.config }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, props.id, JSON.stringify(props.config)]);

    const isTextNoteValid = !_isNil(props.note) && _trim(props.note) !== '';

    const onBackButton = () => {
        dispatch(createAction(VotingActions.resultsPreviousStep.TYPE));
    };

    return (
        <Styled.Wrapper>
            <Styled.ResultsPage {...props.layout}>
                {/* scroll marker */}
                <Styled.ScrollMarker ref={scrollMarkerRef} />

                {/* header */}
                <Styled.Header size={props.layout?.size} scrolled={!scrollMarkerInView} containText={!textInView}>
                    {/* back button */}
                    <Styled.HeaderBackButton hide={_isNil(previousStepIdx)} onClick={onBackButton}>
                        <Styled.HeaderBackButtonIcon name="" set={IconsSet.CUSTOM} icon={IconSets.Custom.FULL_ARROW_LEFT} />
                    </Styled.HeaderBackButton>

                    {/* header info */}
                    <Styled.HeaderInfo>
                        {/* step */}
                        <Styled.HeaderStep>
                            {t('Step', currLang)} {currentStepIdx}/{numberOfStepsCount}
                        </Styled.HeaderStep>

                        {/* text */}
                        {!textInView && (
                            <Styled.HeaderText>
                                {props.text} {isTextNoteValid && <span className="note">{props.note}</span>}
                            </Styled.HeaderText>
                        )}
                    </Styled.HeaderInfo>

                    {/* basket button */}
                    <Styled.HeaderBasketButton />
                </Styled.Header>

                {/* text */}
                <Styled.Text ref={textRef}>
                    {props.text} {isTextNoteValid && <span className="note">{props.note}</span>}
                </Styled.Text>

                {/* blocks */}
                <Styled.Blocks>
                    <Blocks blocks={props.blocks} />
                </Styled.Blocks>
            </Styled.ResultsPage>
        </Styled.Wrapper>
    );
};
export default StandardResultsPage;
