import { Vote, Question, Poll, Results, Result } from '../../../core/voting';
import { Voting } from '../../../core/calculator';
import { Status as ValidationStatus } from '../../../core/validation';

// Store
export interface SimpleResultSetup {
    calculationMethod?: Voting.Method;
}
export interface Store {
    vote?: Vote.Vote;
    config?: Poll.Poll;
    step?: string;
    question: {
        validationStatus?: Record<string, ValidationStatus>;
        config?: Record<string, Question.Config>;
    };
    resultsConfig?: Results.Results;
    resultsStep?: string;
    result: {
        // config?: Record<string, Record<string, Result.Config>>; // <result-id>: { <question-id>: Config }
        config?: Record<string, Result.Config>;
        setup?: Record<string, SimpleResultSetup>;
    };
}
export const initialStore: Store = {
    question: {},
    result: {},
};
