import { Choice } from '../../voting';

export interface Input {
    [Choice.Meaning.PLUS]?: number;
    [Choice.Meaning.MINUS]?: number;
}

export type Inputs = Record<string, Input>; // item id -> Input

export enum Method {
    PLUS = 'plus',
    MINUS = 'minus',
    COMBINATION = 'combination',
    D21 = 'd21',
}
