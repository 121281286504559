import { isNil as _isNil } from 'lodash';
import styled, { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';

import { Config } from './index';
import IconComp from '../Icon';
import { font, color, gradient, animation, shadow } from '../../stylesheets/shared';
import { cssDisableUserSelect, cssP } from '../../stylesheets/snippets';
import { ifHoverIsSupported, onPhone, onTablet } from '../../stylesheets/tools';
import { PrimaryCTAInlets } from '../../../core/block';
import { Tools } from '../../../core/validation';

// Button Wrapper
export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

// Button Validation
export const ButtonValidationIcon = styled(IconComp)`
    color: ${color.white};
    font-size: 24px;
    margin-right: 12px;
`;
export const ButtonValidationMessage = styled.p`
    ${cssP};
    color: ${color.white};
    text-align: left;
`;
export const ButtonValidation = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${color.black};
    padding: 8px 32px 8px 24px;
    border-radius: 12px;

    ${onPhone(css`
        padding: 8px 16px;
    `)}
`;

// Button
export const Label = styled.div`
    font-family: ${font.family};
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    line-height: 36px;
    color: ${color.black};
    transition: color ${animation.timing.standart} ease-out;
`;

export const IconWrapper = styled.div`
    border-radius: 50%;
    width: 36px;
    height: 36px;
    margin: 0 12px 0 0;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`;

export const Icon = styled(IconComp)`
    font-size: 24px;
    color: ${color.white};
    transition: color ${animation.timing.standart} ease-out;
`;

export const LoadingWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${color.white};
    border-radius: 12px;
`;

type ButtonProps = {
    config: Config;
    usedInlets: PrimaryCTAInlets;
};
export const Button = styled.button<ButtonProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 11px 75px;
    border-radius: 12px;
    border-style: solid;
    border-width: 2px;
    border-color: ${({ config }) => (config.themeOverride ? config.themeOverride.color : color.violet)};
    box-sizing: border-box;
    background: ${(p: ButtonProps) => (p.config.themeOverride ? hexToRgba(p.config.themeOverride.color || '', 0.06) : gradient.sweetLight)};
    ${cssDisableUserSelect}
    transition: border-color ${animation.timing.standart} ease-out, transform ${animation.timing.interactive} ease-out;

    /* style override */
    ${({ config }) => config.styleOverride}

    ${onPhone(css`
        padding: 11px 47px;
    `)}

    ${ifHoverIsSupported(css`
        &:hover {
            background: ${(p: ButtonProps) => (p.config.themeOverride ? hexToRgba(p.config.themeOverride.color || '', 0.24) : gradient.sweetLigher)};
        }
    `)}

    &:active {
        transform: scale(0.98);
    }

    /* Icon Wrapper */
    ${IconWrapper} {
        background: ${({ config }) => (config.themeOverride ? config.themeOverride.color : gradient.sweet)};
    }
    ${ifHoverIsSupported(css`
        &:hover ${IconWrapper} {
            background: ${color.black};
        }
    `)}

    /* invalid */
    ${(p: ButtonProps) => {
        if (!_isNil(p.usedInlets.validationStatus) && Tools.isStatusInvalid(p.usedInlets.validationStatus)) {
            return css`
                cursor: default;
                border-width: 1px;
                border-color: ${color.grey2};
                background: ${color.white};
                &:hover {
                    background: ${color.white};
                }

                ${Label} {
                    color: ${color.grey2};
                }

                ${IconWrapper},
                &:hover ${IconWrapper} {
                    background: transparent;
                    border: 1px solid ${color.grey2};

                    ${Icon} {
                        color: ${color.black};
                    }
                }
            `;
        }
    }}
`;

// Marker
export const MarkerIcon = styled(IconComp)`
    font-size: 32px;
    color: ${color.white};
    transition: color ${animation.timing.standart} ease-out;
`;

export const MarkerLoadingWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background-color: ${color.white};
    border-radius: 50%;
`;

type MarkerButtonProps = {
    config: Config;
    usedInlets: PrimaryCTAInlets;
};
export const MarkerButton = styled.button<MarkerButtonProps>`
    position: absolute;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    cursor: pointer;
    top: 0;
    right: 104px;
    background: ${({ config }) => (config.themeOverride ? config.themeOverride.color : gradient.sweet)};
    box-shadow: ${shadow.standard};
    ${cssDisableUserSelect};
    transition: transform ${animation.timing.interactive} ease-out;

    ${ifHoverIsSupported(css`
        &:hover {
            background: ${color.black};
        }
    `)}

    &:active {
        transform: scale(0.94);
    }

    /* invalid */
    ${(p: MarkerButtonProps) => {
        if (!_isNil(p.usedInlets.validationStatus) && Tools.isStatusInvalid(p.usedInlets.validationStatus)) {
            return css`
                cursor: default;
                border-style: solid;
                border-width: 1px;
                border-color: ${color.grey2};
                background: ${color.white};
                &:hover {
                    background: ${color.white};
                }

                ${MarkerIcon},
                &:hover ${MarkerIcon} {
                    color: ${color.black};
                }
            `;
        }
    }}

    ${onTablet(css`
        right: 40px;
    `)}

    ${onPhone(css`
        right: 16px;
    `)}
`;

export const MarkerValidationIcon = styled(IconComp)`
    color: ${color.white};
    font-size: 24px;
    margin-right: 12px;
`;
export const MarkerValidationMessage = styled.p`
    ${cssP};
    color: ${color.white};
    text-align: left;
`;
export const MarkerValidation = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${color.black};
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 84px;
    height: 60px;
    box-sizing: border-box;
    border-radius: 30px;

    ${onPhone(css`
        padding-left: 16px;
        padding-right: 76px;
    `)}
`;

type MarkerProps = {
    hide: boolean;
    containsMessage: boolean;
};
export const Marker = styled.div<MarkerProps>`
    position: fixed;
    height: 60px;
    bottom: 48px;
    right: 0;
    z-index: 100;
    padding: ${({ containsMessage }) => (containsMessage ? '0 104px' : '0')};
    max-width: 100%;
    box-sizing: border-box;
    visibility: ${({ hide }) => (hide === true ? 'hidden' : 'visible')};
    opacity: ${({ hide }) => (hide === true ? '0' : '1')};
    transition: visibility ${animation.timing.interactive} ease-out, opacity ${animation.timing.interactive} ease-out;

    ${onTablet(css`
        bottom: 24px;
        padding: ${(p: MarkerProps) => (p.containsMessage ? '0 40px' : '0')};
    `)}

    ${onPhone(css`
        bottom: 24px;
        padding: ${(p: MarkerProps) => (p.containsMessage ? '0 16px' : '0')};
    `)}
`;
