import React from 'react';
import getMarkdown from '../../../tools/markdown';

import { BlockProps, NoteP as NotePConfig, Tools } from '../../../../core/block';
import * as Styled from './styled';

export type Config = NotePConfig;
export type Props = BlockProps & Config;

const md = getMarkdown();

const NoteP = (props: Props) => {
    return (
        <>
            {/* Simple */}
            {!props.useMarkdown && <Styled.P {...Tools.extractConfig<Config>(props)}>{props.text}</Styled.P>}

            {/* Markdown */}
            {props.useMarkdown && (
                <Styled.MD {...Tools.extractConfig<Config>(props)} dangerouslySetInnerHTML={{ __html: md.renderInline(props.text) }} />
            )}
        </>
    );
};
export default NoteP;
