import { unset as _unset } from 'lodash';

import { Store } from '../interfaces';

const reducer = (state: Store, payload: string): Store => {
    const newState: Store = {
        ...state,
    };

    _unset(newState, ['fields', 'validationStatus', payload]);
    _unset(newState, ['fields', 'value', payload]);
    _unset(newState, ['form', 'validationStatus', payload]);

    return newState;
};

export default reducer;
