import {
    isNil as _isNil,
    size as _size,
    get as _get,
    mapValues as _mapValues,
    reduce as _reduce,
    round as _round,
    min as _min,
    map as _map,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { BlockProps, SchoolPbResultsPanel as SchoolPbResultsPanelConfig, Tools, BarData } from '../../../../core/block';
import { Voting as VotingCalculator } from '../../../../core/calculator';
import RepresentP from '../../Typography/RepresentP';
import HorizontalLine from '../../HorizontalLine';
import LinkButton from '../../LinkButton';
import Checkbox from '../../FormField/Checkbox';
import { translate as t } from '../../../../core/translation';
import { currentLanguage } from '../../../store/application/selectors';
import { proposals, winners } from '../../../store/school_pb/selectors';
import { Streamer, Events, Event } from '../../../../core/stream';
import { Result } from '../../../../core/voting';
import { Browser as BrowserAction, SchoolPb as SchoolPbAction, Target as ActionTarget } from '../../../../core/action';
import { Types as SchoolPbTypes } from '../../../store/school_pb/selectors';
import * as Styled from './styled';

export type Config = SchoolPbResultsPanelConfig;
export type Props = BlockProps & Config;

const SchoolPbResultsPanel = (props: Props) => {
    const [respondents, setRespondents] = useState<number>(0);
    const [result, setResult] = useState<Result.Config>();
    const currLang = useSelector(currentLanguage());
    const proposalsList = useSelector(proposals());
    const winnerProposals = useSelector(winners());
    const [idsToRender, setIdsToRender] = useState<string[]>([]);
    const [resultItems, setResultItems] = useState<Record<string, string>>({});
    const [ranksToRender, setRanksToRender] = useState<Record<string, string>>({});
    const [dataToRender, setDataToRender] = useState<Record<string, BarData[]>>({});

    const recalculateResult = () => {
        // make sure we have everything we need
        if (_isNil(proposalsList) || _size(proposalsList) === 0 || _isNil(result) || _size(result) === 0) {
            return;
        }

        // create result items from proposals
        const resItems = _reduce(
            proposalsList,
            (result, proposal, proposalIdx) => {
                return {
                    ...result,
                    [`o${proposalIdx}`]: proposal,
                };
            },
            {},
        );
        setResultItems(resItems);

        // relculate results
        const valuesToCalculate = _mapValues(resItems, (resTmpl: string, resId: string) => _get(result.values, resId, {}));
        const resultValues = VotingCalculator.resultValues(valuesToCalculate, VotingCalculator.Method.PLUS);
        const sortedBarIds: string[] = VotingCalculator.sortResultValues(resultValues);
        setIdsToRender(sortedBarIds);
        const rankMap: Record<string, string> = VotingCalculator.rankMap(resultValues);
        setRanksToRender(rankMap);
        const maximum: number = respondents;
        const precision = 0;

        // calculate results data
        const resData: Record<string, BarData[]> = _mapValues(resItems, (resTitle: string, resId: string) => {
            const resultValue: number = _get(resultValues, resId, 0);

            const size = _round((resultValue / maximum) * 100, precision);
            return [
                {
                    size: _min([100, size]) || 0,
                    count: resultValue,
                    percentage: _min([100, size]) || 0,
                },
            ];
        });
        setDataToRender(resData);
    };

    useEffect(() => {
        // download results
        const event: Event<any> = Events.getSPBResult({
            resultsId: props.resultsId,
            resultId: props.resultId,
        });

        Streamer.stream(event).then((result: Result.Config) => {
            // set new respondents count
            setRespondents(result?.respondents || 0);
            // set new results
            setResult(result || {});

            // recalculate results
            recalculateResult();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resultId, props.resultsId]);

    useEffect(() => {
        // recalculate result
        recalculateResult();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(proposalsList), JSON.stringify(result)]);

    const getRankById = (id: string): string => _get(ranksToRender, id, '');
    const getTextById = (id: string): string => _get(resultItems, id, '');
    const geValueById = (id: string): string => _get(dataToRender, [id, '0', 'count'], '0');
    const isProposalWiner = (id: string): boolean => _get(winnerProposals, [id], false);

    return (
        <Styled.Panel {...Tools.extractConfig<Config>(props)}>
            <RepresentP
                name=""
                text={`${t('DATE_OF_THE_EVENT', currLang)}: **{{inlets.votingStartDate}} - {{inlets.votingEndDate}}** *(${t(
                    'SET_IN_THE_PREPARATION_STEP',
                    currLang,
                )})*`}
                useMarkdown={true}
                inlets={{
                    votingStartDate: {
                        type: '#SCHOOLPB:voting_start_date',
                        modifiers: [{ name: 'readable_date' }],
                    },
                    votingEndDate: {
                        type: '#SCHOOLPB:voting_end_date',
                        modifiers: [{ name: 'readable_date' }],
                    },
                }}
            />
            <RepresentP
                name=""
                text={`${t('TOTAL_PROJECTS', currLang)}: **{{inlets.projectsCount}}** *(${t('SET_IN_THE_APPROVAL_STEP', currLang)})*`}
                useMarkdown={true}
                inlets={{
                    projectsCount: { type: '#SCHOOLPB:proposal_count' },
                }}
            />
            <RepresentP
                name=""
                text={`${t('VOTES_COUNT', currLang)}: **{{inlets.proposalsCount}}** *(${t('SET_BASED_ON_THE_NUMBER_OF_PROJECTS', currLang)})*`}
                useMarkdown={true}
                inlets={{
                    proposalsCount: { type: '#SCHOOLPB:proposal_count', modifiers: [{ name: 'spb_vote_count' }] },
                }}
            />
            <RepresentP name="" text={`${t('TOTAL_NUMBER_OF_VOTING_STUDENTS', currLang)}: **${respondents || 0}**`} useMarkdown={true} />

            <Styled.Header>
                <Styled.Col1>{t('PROJECT', currLang)}</Styled.Col1>
                <Styled.Col2>{t('VOTES', currLang)}</Styled.Col2>
                <Styled.Col3>{t('WINNER', currLang)}</Styled.Col3>
                <Styled.Col4>{t('DIPLOMA', currLang)}</Styled.Col4>
            </Styled.Header>
            <HorizontalLine name="" />
            {_map(idsToRender, (resId: string) => (
                <Styled.ResultsItem key={resId}>
                    <Styled.Row>
                        <Styled.Col1>
                            <b>{getRankById(resId)}</b> {getTextById(resId)}
                        </Styled.Col1>
                        <Styled.Col2>{geValueById(resId)}</Styled.Col2>
                        <Styled.Col3>
                            <Checkbox
                                name=""
                                outlets={{
                                    change: {
                                        type: SchoolPbAction.markWinner.TYPE,
                                        payload: { schoolpbId: props.resultsId, optionId: resId, winner: '{{value}}' },
                                        target: ActionTarget.BOTH,
                                    },
                                }}
                                inlets={{
                                    value: {
                                        type: SchoolPbTypes.IS_PROPOSAL_WINNER,
                                        payload: resId,
                                    },
                                }}
                            />
                        </Styled.Col3>
                        <Styled.Col4>
                            {isProposalWiner(resId) && (
                                <LinkButton
                                    name=""
                                    text="Diplom"
                                    outlets={{
                                        click: { type: BrowserAction.hrefInNewWindow.TYPE, payload: `./project/${getTextById(resId)}/diploma` },
                                    }}
                                />
                            )}
                        </Styled.Col4>
                    </Styled.Row>
                    <HorizontalLine name="" />
                </Styled.ResultsItem>
            ))}
        </Styled.Panel>
    );
};
export default SchoolPbResultsPanel;
