import { Block } from '../../../core/block';

// Store
export interface PopupStore {
    websiteId: string;
    dialogId: string;
    blocks?: Block[];
    overlayColor?: string;
}
export interface Store {
    language: string; // current application language
    loading: boolean;
    shuffleGroup?: Record<string, number[]>;
    popup?: PopupStore;
}
export const initialStore: Store = {
    language: 'en',
    loading: false,
};
