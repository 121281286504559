import { saveAs } from 'file-saver';

import { Streamer, Events, Event } from '../../../../core/stream';
import { Authentication as AuthenticationActions } from '../../../../core/action';

export default function (payload: AuthenticationActions.savePinSet.Payload) {
    return async () => {
        const event: Event<AuthenticationActions.savePinSet.Payload> = Events.generateSavePinSetEvent(payload);
        const result = await Streamer.stream(event);
        const blob = new Blob([result], { type: 'text/html;charset=UTF-8' });
        saveAs(blob, 'pin-kody.html');
    };
}
