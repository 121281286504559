import { get as _get } from 'lodash';
import { Choice } from '../../voting';

import { Input, Method } from './interfaces';

export default function (input: Input = {}, method: Method = Method.PLUS): number {
    switch (method) {
        case Method.PLUS:
            return _get(input, [Choice.Meaning.PLUS], 0);

        case Method.MINUS:
            return _get(input, [Choice.Meaning.MINUS], 0);

        case Method.COMBINATION: {
            const plus = _get(input, [Choice.Meaning.PLUS], 0);
            const minus = _get(input, [Choice.Meaning.MINUS], 0);
            return plus - minus;
        }

        case Method.D21: {
            const plus = _get(input, [Choice.Meaning.PLUS], 0);
            const minus = _get(input, [Choice.Meaning.MINUS], 0);
            return plus - minus;
        }

        default:
            return 0;
    }
}
