import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';

import { Poll, Urls } from '../../../core/voting';

function usePollConfig(pollId: string): [Poll.Poll | undefined, boolean, any | undefined] {
    const [config, loading, error] = useObjectVal<Poll.Poll>(firebase.database().ref(Urls.pollConfig(pollId)));

    return [config, loading, error];
}

export default usePollConfig;
