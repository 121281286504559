import { isNil as _isNil } from 'lodash';
import { Dispatch } from 'redux';

import { Effect, create as createAction, Form as FormActions } from '../../../../core/action';
import { RootState } from '../../root_reducer';
import * as Selectors from '../selectors';
import { FormValue } from '../interfaces';
import { Streamer, Events, Event } from '../../../../core/stream';

export default function (payload: FormActions.sendForm.Payload): Effect {
    return async (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const formId = payload.f;
                const projectId = payload.p;

                // get form value
                const formValueSelectorPayload: Selectors.FormValuePayload = {
                    f: formId,
                };
                let formValue: FormValue | undefined = Selectors.formValue(formValueSelectorPayload)(getState());
                if (_isNil(formValue)) {
                    console.warn(`Cannot find value of form ${formId} ... using {} instead.`);
                    formValue = {};
                }

                const event: Event<Events.StoreFormEventPayload> = Events.storeForm(projectId, formId, formValue);
                const result = await Streamer.stream(event);

                dispatch(createAction(FormActions.sendFormSuccess.TYPE));
                resolve(result);
            } catch (error) {
                dispatch(createAction(FormActions.sendFormError.TYPE));
                reject(error);
            }
        });
    };
}
