import { some as _some, size as _size, forEach as _forEach, set as _set, cloneDeep as _cloneDeep } from 'lodash';
import { Store } from '../interfaces';
import { Question } from '../../../../core/voting';
import questionValidation from './question_validation';

const reducer = (state: Store, childQuestionId: string): Store => {
    // find all parent question id->config
    const parentQuestions: Record<string, Question.CoverQuestionConfig> = {};
    _forEach(state.question.config, (qc: Question.Config, qId: string) => {
        // question should be cover ... otherwiese it doesn't make sense
        if (qc.type !== Question.MethodType.COVER) {
            return;
        }

        // cover qeustion should contain embedded question id
        const cqc: Question.CoverQuestionConfig = qc as Question.CoverQuestionConfig;
        const containsEmbeddedQuestionId = _some(cqc.embeddedQuestionIds, (qId: string) => qId === childQuestionId);
        if (containsEmbeddedQuestionId === true) {
            _set(parentQuestions, qId, cqc);
        }
    });

    // no parent questions were found
    if (_size(parentQuestions) === 0) {
        return state;
    }

    // revalidate all cover questions
    let newState = _cloneDeep(state);
    _forEach(parentQuestions, (pqConfig: Question.CoverQuestionConfig, pqId: string) => {
        newState = questionValidation(newState, pqId);
    });

    return newState;
};

export default reducer;
