import { isNil as _isNil } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';

import { IconSets, Block } from '../../../core/block';
import { create as createAction, Application as ApplicationActions } from '../../../core/action';
import { Name as WebsiteName, StandardDialog as StandardDialogConfig } from '../../../core/website';
import StandardDialog from './Standard';
import { useDialog } from '../../hooks';
import * as Styled from './styled';
import Blocks from '../../blocks/Blocks';

interface Props {
    websiteId: string;
    dialogId: string;
    blocks?: Block[];
    overlayColor?: string;
}
const Popup = ({ blocks, ...props }: Props) => {
    const dispatch = useDispatch();
    const [dialog, dialogLoading, dialogError] = useDialog(props.websiteId, props.dialogId);

    const onClose = () => {
        dispatch(createAction(ApplicationActions.hidePopup.TYPE));
    };

    return (
        <Styled.Overlay overlayColor={props.overlayColor}>
            <Styled.Popup>
                {blocks && <Blocks blocks={blocks} />}

                {!blocks && (
                    <>
                        {/* loading... */}
                        {dialogLoading && <Styled.Loading />}
                        {/* error */}
                        {dialogError && <>loading error: {dialogError}</>}
                        {/* does not exist */}
                        {!dialogLoading && !dialogError && _isNil(dialog) && <>Cannot find the dialog data</>}
                        {/* exists */}
                        {!_isNil(dialog) && (
                            <>
                                {/* standard */}
                                {dialog.name === WebsiteName.STANDARD_DIALOG && <StandardDialog {...(dialog as StandardDialogConfig)} />}
                            </>
                        )}
                    </>
                )}

                {/* close button */}
                <Styled.CloseButton onClick={onClose}>
                    <Styled.CloseButtonIcon name="" icon={IconSets.Material.CLOSE} />
                </Styled.CloseButton>
            </Styled.Popup>
        </Styled.Overlay>
    );
};
export default Popup;
