import { isNil as _isNil, findKey as _findKey } from 'lodash';

import { Flow, FlowStep } from '../interfaces';

export default (flow?: Flow, currentStep?: string): string | undefined => {
    if (_isNil(flow) || _isNil(currentStep)) {
        return undefined;
    }

    // current is the first step?
    if (flow.start === currentStep) {
        return undefined;
    }

    // find current step id which contains .to same as current step
    return _findKey(flow.steps, (step: FlowStep) => step.to === currentStep);
};
