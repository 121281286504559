import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';

export function useListItems<TItem>(rtdbPath?: string): [TItem[] | undefined, boolean, any | undefined] {
    const rtdbQuery = rtdbPath ? firebase.database().ref(rtdbPath) : null;
    const [items, loading, error] = useObjectVal<TItem[]>(rtdbQuery);

    const itemArray = items ? Object.values(items) : undefined;

    return [itemArray, loading, error];
}
