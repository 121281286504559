import React, { useState, useEffect, SyntheticEvent, FocusEvent } from 'react';
import { isNil as _isNil, remove as _remove } from 'lodash';
import { Input as AntInput } from 'antd';
import { ItemList as ItemListConfig, IconsSet } from '../../../core/block/interfaces';
import * as Styled from './styled';
import MaterialIcons from '../../../core/block/icon_sets/material';
import { Tools } from '../../../core/block';
import { useOutlets, useInlets } from '../../hooks';

interface ItemInterface {
    content: string;
    isEditable?: boolean;
    enableDelete?: boolean;
    index: number;
    onDelete(index: number): void;
    onChange(index: number, newVal: string): void;
}

export const Item: React.FC<ItemInterface> = (props: ItemInterface) => {
    const [content, setContent] = useState(props.content);
    let itemInput: AntInput;

    const onSubmit = () => {
        setContent(itemInput.state.value);
        props.onChange(props.index, content);
    };

    const handleOnFocus = async (event: FocusEvent) => {
        if (!_isNil(props.isEditable) && props.isEditable) {
            (event.target as HTMLInputElement).select();
        }
    };

    const handleChange = async (event: SyntheticEvent) => {
        // get value from input
        const value = (event.target as HTMLInputElement).value;
        // store it as local value
        setContent(value);
    };

    return (
        <Styled.ListItem isEditable={!_isNil(props.isEditable) && props.isEditable} isDeletable={!_isNil(props.enableDelete) && props.enableDelete}>
            <AntInput
                type="string"
                autoComplete="off"
                value={content}
                readOnly={!props.isEditable}
                ref={(input) => {
                    itemInput = input as AntInput;
                }}
                onChange={handleChange}
                onPressEnter={onSubmit}
                onBlur={onSubmit}
                onFocus={handleOnFocus}
            />

            {!_isNil(props.enableDelete) && props.enableDelete && (
                <Styled.ListItemActionButton
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        props.onDelete(props.index);
                    }}
                >
                    <Styled.IconCircle icon={MaterialIcons.DELETE} set={IconsSet.MATERIAL_OUTLINE} name="dummy_because_of_block_interface" />
                </Styled.ListItemActionButton>
            )}
        </Styled.ListItem>
    );
};

export const ItemList: React.FC<ItemListConfig> = ({ items = [], ...props }) => {
    const inlets = useInlets(props.inlets);
    const [content, setContent] = useState(!props.inlets ? [] : items);

    useEffect(() => {
        if (!props.inlets) {
            return;
        }

        if (Tools.isInletResolved(inlets.value)) {
            const content = _isNil(inlets.value) ? [] : inlets.value;
            setContent(content as string[]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets)]);

    const runOutlets = useOutlets();
    const runDeleteOutlet = (index: number) => {
        if (!props.outlets?.delete) {
            return;
        }

        _remove(content, function (n, i) {
            return i === index;
        });

        const outletParameters = {
            index,
            value: content,
        };

        runOutlets(props.outlets?.delete, outletParameters);
    };

    const runChangeOutlet = (index: number, newVal: string) => {
        if (!props.outlets?.change) {
            return;
        }
        content[index] = newVal;
        const outletParameters = {
            value: content,
        };

        runOutlets(props.outlets?.change, outletParameters);
    };

    return (
        <div>
            {/* label */}
            {!_isNil(props.text) && <Styled.Label>{props.text}</Styled.Label>}
            {/* items */}
            {content.length === 0 && <div>...</div>}
            {content.length > 0 &&
                content.map((item, index) => (
                    <Item
                        {...{
                            content: item,
                            index: index,
                            onDelete: runDeleteOutlet,
                            onChange: runChangeOutlet,
                            isEditable: props.isEditable,
                            enableDelete: props.enableDelete,
                        }}
                        key={index + item}
                    />
                ))}
        </div>
    );
};
