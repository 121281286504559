import styled, { css } from 'styled-components';

import { Config } from './index';
import { onPhone } from '../../../stylesheets/tools';

// Item
export const Item = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 240px;
    margin: 36px 16px;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    & > * {
        width: 100%;
    }
`;

// List
interface ListProps {
    config: Config;
}
export const List = styled.div<ListProps>`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;

    /* style override */
    ${(p: ListProps) => p.config.styleOverride}

    ${onPhone(css`
        padding: 0;
    `)}
`;
