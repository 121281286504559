import { isNil as _isNil } from 'lodash';

// see Tabulka 2: Doporučené počty hlasů při omezeném počtu kandidátů

export default (choices: number): number => {
    if (_isNil(choices) || choices <= 0) {
        return 0;
    }

    if (choices === 1) {
        return 1;
    } else if (choices <= 8) {
        return 2;
    } else if (choices <= 11) {
        return 3;
    } else if (choices <= 14) {
        return 4;
    } else if (choices <= 17) {
        return 5;
    } else if (choices <= 20) {
        return 6;
    }

    return 7;
};
