import { isNil as _isNil } from 'lodash';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const StyledIcon = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    fill: currentColor;
`;

export const StyledIconWrapper = styled.i`
    position: relative;
    width: 1em;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &:before {
        position: relative;
        content: '';
        display: block;
        padding-top: 100%;
    }
`;

type Props = HTMLAttributes<HTMLElement> & {
    onClick?: () => void;
};

const Icon = (props: Props) => {
    const onClick = () => {
        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <StyledIconWrapper onClick={onClick} className={props.className}>
            <StyledIcon viewBox="0 0 1001 1024">
                <path
                    fill="rgb(66, 133, 244)"
                    d="M1000.124 521.579c0-41.953-3.404-72.567-10.772-104.315h-479.060v189.354h281.197c-5.667 47.057-36.282 117.924-104.315 165.544l-0.954 6.339 151.47 117.342 10.494 1.047c96.377-89.010 151.939-219.972 151.939-375.312z"
                />
                <path
                    fill="rgb(52, 168, 83)"
                    d="M510.293 1020.482c137.763 0 253.417-45.357 337.893-123.591l-161.010-124.729c-43.086 30.048-100.915 51.024-176.882 51.024-134.93 0-249.45-89.006-290.273-212.031l-5.984 0.508-157.501 121.891-2.060 5.726c83.905 166.677 256.254 281.201 455.817 281.201z"
                />
                <path
                    fill="rgb(251, 188, 5)"
                    d="M220.020 611.156c-10.772-31.748-17.005-65.767-17.005-100.915 0-35.152 6.234-69.167 16.439-100.915l-0.285-6.762-159.474-123.849-5.218 2.482c-34.581 69.167-54.424 146.838-54.424 229.044s19.843 159.873 54.424 229.040l165.544-128.125z"
                />
                <path
                    fill="rgb(235, 67, 53)"
                    d="M510.293 197.292c95.81 0 160.44 41.386 197.292 75.971l144.001-140.601c-88.439-82.205-203.53-132.663-341.293-132.663-199.563 0-371.911 114.52-455.817 281.197l164.977 128.129c41.39-123.025 155.91-212.034 290.839-212.034z"
                />
            </StyledIcon>
        </StyledIconWrapper>
    );
};

export default Icon;
