import { isNil as _isNil } from 'lodash';

import { StoreSelectorFunction } from '../../../../core/selector';
import { RootState } from '../../root_reducer';
import { Store } from '../interfaces';
import getStore from './get_store';

export interface Payload {
    taskGroupId: string; // task group id
    taskId: string; // task id
}

export default (payload: Payload): StoreSelectorFunction<boolean> => (rootStore: RootState): boolean => {
    const store: Store = getStore(rootStore);

    if (_isNil(store)) {
        return false;
    }

    const group = store.taskGroup[payload.taskGroupId];
    if (!_isNil(group) && !_isNil(group.tasks)) {
        return group.tasks[payload.taskId].done;
    }

    return false;
};
