import styled from 'styled-components';

export const RegionAreaPath = styled.path`
    color: #000000;
    clip-rule: nonzero;
    display: inline;
    overflow: visible;
    visibility: visible;
    opacity: 1;
    isolation: auto;
    mix-blend-mode: normal;
    color-interpolation: sRGB;
    color-interpolation-filters: linearRGB;
    solid-color: #000000;
    solid-opacity: 1;
    vector-effect: none;
    fill: white;
    fill-opacity: 1;
    fill-rule: nonzero;
    stroke: none;
    stroke-width: 3.7795279;
    stroke-linecap: butt;
    stroke-linejoin: round;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
    stroke-dashoffset: 0;
    stroke-opacity: 1;
    marker: none;
    color-rendering: auto;
    image-rendering: auto;
    shape-rendering: auto;
    text-rendering: auto;
    enable-background: accumulate;

    &:hover {
        fill: #d2232a;
    }
`;

export const RegionBorderPath = styled.path`
    fill: #000000;
    stroke-width: 0.99999994;
    stroke: 'none';
`;
