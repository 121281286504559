import React, { useState } from 'react';

import { SplitPane as SplitPaneBlock, IconSets } from '../../../core/block';
import Block from '../Block';
import * as Styled from './styled';
import { useOnTablet, useOnPhone } from '../../hooks';

export const SplitPane: React.FC<SplitPaneBlock> = ({ panes, ...props }) => {
    const [PrimaryPane, SecondaryPane] = panes;
    const [visiblePane, setVisiblePane] = useState<'primary' | 'secondary'>('primary');

    const toggleVisiblePane = () => {
        if (visiblePane === 'primary') {
            setVisiblePane('secondary');
        } else {
            setVisiblePane('primary');
        }
    };

    const isPhone = useOnPhone();
    const isTablet = useOnTablet();
    const shouldUseSplitPane = isPhone || isTablet;

    const primaryPaneStyle = { width: visiblePane === 'primary' ? '100%' : 0, overflow: 'hidden', transition: 'width 0.3s ease-out' };
    const secondaryPaneStyle = { width: visiblePane === 'secondary' ? '100%' : 0, overflow: 'hidden', transition: 'width 0.3s ease-out' };

    return (
        (shouldUseSplitPane && (
            <Styled.SplitPaneContainer styleOverride={props.styleOverride} onPhone={props.onPhone}>
                <Block {...PrimaryPane} style={primaryPaneStyle} />
                <Block {...SecondaryPane} style={secondaryPaneStyle} />

                <Styled.SwapButton onClick={toggleVisiblePane} visiblePane={visiblePane}>
                    <Styled.SwapButtonIcon name="" icon={IconSets.Material.FIRST_PAGE} />
                </Styled.SwapButton>
            </Styled.SplitPaneContainer>
        )) ||
        (!shouldUseSplitPane && (
            <Styled.SplitPaneContainer styleOverride={props.styleOverride} onPhone={props.onPhone}>
                <Block {...PrimaryPane} />
                <Block {...SecondaryPane} />
            </Styled.SplitPaneContainer>
        ))
    );
};
