import { isNil as _isNil, defer as _defer, isEqual as _isEqual, debounce as _debounce } from 'lodash';
import React, { useState, SyntheticEvent, useEffect, useCallback } from 'react';
import { Input as AntInput } from 'antd';

import { BlockProps, Tools, FormFieldString as FormFieldStringConfig, FormFieldInlets } from '../../../../core/block';
import { useOutlets, useInlets, useStringValidation, ValidationResult, VALIDATION_RESULT_UNTOUCHED, getOutletValidationStatus } from '../../../hooks';
import * as Styled from './styled';

export type Config = FormFieldStringConfig;
export type Props = BlockProps &
    Config & {
        onSubmit?: (value: string | undefined, validationResult: ValidationResult) => void;
        onChange?: (value: string, validationResult: ValidationResult) => void;
        onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
    };

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const StringFormField = ({ onPaste = noop, ...props }: Props) => {
    const outlet = useOutlets();
    const inlets = useInlets(props.inlets) as FormFieldInlets;
    const defaultValueInlet = useInlets(props.defaultValueInlet, { processAsDefaultValueInlet: true, property: 'value' }) as FormFieldInlets;
    const validate = useStringValidation(props.validationScheme || {});
    const [validationResult, setValidationResult] = useState(VALIDATION_RESULT_UNTOUCHED);
    const [cachedValue, setCachedValue] = useState(!_isNil(props.value) ? props.value : props.defaultValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const processChangeOutlet = useCallback(
        _debounce((value: string, validationResult: ValidationResult) => {
            if (_isNil(props.outlets?.change)) {
                return;
            }

            const outletParameters = {
                value,
                validationStatus: getOutletValidationStatus(validationResult),
            };
            _defer(() => outlet(props.outlets?.change, outletParameters));
        }, 250),
        [],
    );

    // on mount, set form validity state in pristine state of the component
    useEffect(() => {
        if (!_isNil(props.validateOnMount) && props.validateOnMount) {
            (async () => {
                const validationResult = await validate(cachedValue);
                console.log('onMount processChangeOutlet: ', cachedValue);
                processChangeOutlet(cachedValue || '', validationResult);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const runSubmitOutlet = (value: string, validationResult: ValidationResult) => {
        if (!props.outlets?.submit) {
            return;
        }

        const outletParameters = {
            value,
            validationStatus: getOutletValidationStatus(validationResult),
        };

        return outlet(props.outlets?.submit, outletParameters);
    };

    const processChange = (value: string, validationResult: ValidationResult) => {
        if (_isNil(props.onChange)) {
            return;
        }

        props.onChange(value, validationResult);
    };

    const processValidation = async (value: string): Promise<ValidationResult> => {
        const validationResult = await validate(value);
        setValidationResult(validationResult);

        return validationResult;
    };

    // TODO possible candidate for refactoring into useInletValue()
    useEffect(() => {
        // we are not expecting any inlet
        if (_isNil(props.inlets)) {
            return;
        }

        // is inlet already resolved?
        if (Tools.isInletResolved(inlets.value)) {
            // ... yes value is defined, and it's different then cached value -> use it
            if (!_isNil(inlets.value) && !_isEqual(inlets.value, cachedValue)) {
                // use it as cached value ...
                setCachedValue(inlets.value);
                // ... and validate the value
                processValidation(inlets.value).then((validationResult) => {
                    // process all changes
                    processChangeOutlet(inlets.value, validationResult);
                    processChange(inlets.value, validationResult);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets.value)]);

    // set default value
    useEffect(() => {
        if (_isNil(props.defaultValueInlet)) {
            return;
        }

        // is inlet already resolved?
        if (Tools.isInletResolved(defaultValueInlet.value)) {
            // ... yes value is defined, and it's different then cached value -> use it
            if (!_isNil(defaultValueInlet.value)) {
                // use it as cached value ...
                setCachedValue(defaultValueInlet.value);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(defaultValueInlet.value)]);

    const handleChange = async (event: SyntheticEvent) => {
        // get value from input
        const value = (event.target as HTMLInputElement).value;
        // store it as local value
        setCachedValue(value);

        // validate the value
        processValidation(value).then((validationResult) => {
            // process all changes
            processChangeOutlet(value, validationResult);
            processChange(value, validationResult);
        });
    };

    const onSubmit = () => {
        if (!_isNil(props.onSubmit)) {
            props.onSubmit(cachedValue, validationResult);
        }

        if (!_isNil(cachedValue)) {
            return runSubmitOutlet(cachedValue, validationResult);
        }
    };

    return (
        <Styled.String {...Tools.extractConfig<Config>(props)} onChange={handleChange}>
            {/* label */}
            {!_isNil(props.text) && <Styled.Label>{props.text}</Styled.Label>}

            {/* input */}
            <Styled.FormItem
                validateStatus={
                    !_isNil(props.suppressValidationNotification) && props.suppressValidationNotification === true
                        ? ''
                        : validationResult.antValidationStatus
                }
                hasFeedback={!_isNil(props.validationScheme)}
                help={validationResult.message}
            >
                <AntInput
                    type="string"
                    placeholder={props.placeholder}
                    autoFocus={props.autoFocus}
                    autoComplete="off"
                    value={!_isNil(props.value) ? props.value : cachedValue}
                    maxLength={props.maxLength}
                    onPressEnter={onSubmit}
                    onPaste={onPaste}
                />
            </Styled.FormItem>
        </Styled.String>
    );
};

export default StringFormField;
