import { isNil as _isNil } from 'lodash';

import { Effect, Authentication as AuthenticationActions, create as createAction, Tools as ActionTools } from '../../../../core/action';
import { stringIsEmpty } from '../../../../core/tools';
import { Invitation } from '../../../../core/cryptography';
import history from '../../../application_history';

export default function (payload: AuthenticationActions.signInWithInvitation.Payload): Effect {
    return async (dispatch: any): Promise<any> => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                // check if URL contains search string
                if (_isNil(history.location.search) || stringIsEmpty(history.location.search)) {
                    throw new Error(`URL doesn't contain invitation`);
                }

                // get "invitation" parametr from URL
                const searchParams = new URLSearchParams(history.location.search);
                if (!searchParams.has('invitation')) {
                    throw new Error(`URL doesn't contain invitation`);
                }

                // get and decode invitation
                const invitationEncoded = searchParams.get('invitation') || '';
                const invitationDecoded = decodeURIComponent(invitationEncoded);

                // get and decode source
                const sourceEncoded = searchParams.get('source') || '';
                let sourceDecoded: string | undefined = undefined;
                if (!stringIsEmpty(sourceEncoded) && !_isNil(sourceEncoded)) {
                    sourceDecoded = decodeURIComponent(sourceEncoded);
                }

                // decrypt pin
                const invitation = Invitation.decrypt(invitationDecoded, sourceDecoded as Invitation.Source);
                if (_isNil(invitation) || stringIsEmpty(invitation)) {
                    throw new Error(`RL doesn't contain valid invitation`);
                }

                // sign in with PIN
                const signInWithPinPayload: AuthenticationActions.signInWithPin.Payload = {
                    pin: invitation,
                    entityName: payload.entityName,
                    entityId: payload.entityId,
                    checkWhitelist: payload.checkWhitelist || false,
                };
                await dispatch(createAction(AuthenticationActions.signInWithPin.TYPE, signInWithPinPayload));

                console.info(`Invitation sign-in success`);
                dispatch(createAction(AuthenticationActions.signInWithInvitationSuccess.TYPE));

                // process success actions
                if (!_isNil(payload.success)) {
                    ActionTools.processAction(dispatch, payload.success);
                }

                resolve();
            } catch (error) {
                console.error(`Invitation sign-in failed because: ${error}`);
                dispatch(createAction(AuthenticationActions.signInWithInvitationError.TYPE));

                // process fail actions
                if (!_isNil(payload.fail)) {
                    ActionTools.processAction(dispatch, payload.fail);
                }

                reject();
            }
        });
    };
}
