import { Name as EntityName } from '../entity';

// Voting
export const VOTING_EVENT_PREFIX = `${EntityName.STREAM_EVENT_PREFIX}VOTING:`;
const STORE_VOTE = `${VOTING_EVENT_PREFIX}store_vote`;
const MARK_VOTER = `${VOTING_EVENT_PREFIX}mark_voter`;

// Project
export const PROJECT_EVENT_PREFIX = `${EntityName.STREAM_EVENT_PREFIX}PROJECT:`;
const STORE_FORM = `${PROJECT_EVENT_PREFIX}store_form`;
const STORE_STEPPER_STEP = `${PROJECT_EVENT_PREFIX}stepper_step`;
const GENERATE_PINS = `${PROJECT_EVENT_PREFIX}generate_pins`;
const GENERATE_PIN_SET = `${PROJECT_EVENT_PREFIX}generate_pin_set`;
const SAVE_PIN_SET = `${PROJECT_EVENT_PREFIX}save_pin_set`;

// Admin
export const ADMIN_EVENT_PREFIX = `${EntityName.STREAM_EVENT_PREFIX}ADMIN:`;
const REINDEX_DESCRIPTORS = `${ADMIN_EVENT_PREFIX}reindex_descriptors`;
const REMOVE_DESCRIPTOR = `${ADMIN_EVENT_PREFIX}remove_descriptor`;
const UPDATE_DESCRIPTOR = `${ADMIN_EVENT_PREFIX}update_descriptor`;
const ADD_DESCRIPTOR = `${ADMIN_EVENT_PREFIX}add_descriptor`;
const SNAPSHOT_PAGE = `${ADMIN_EVENT_PREFIX}snapshot_page`;

// Auth
export const AUTH_EVENT_PREFIX = `${EntityName.STREAM_EVENT_PREFIX}AUTH:`;
const CREATE_CUSTOM_TOKEN = `${AUTH_EVENT_PREFIX}create_custom_token`;
const CHECK_ENTITY_WHITELIST = `${AUTH_EVENT_PREFIX}check_entity_whitelist`;

// School pb
export const SCHOOLPB_EVENT_PREFIX = `${EntityName.STREAM_EVENT_PREFIX}SCHOOLPB:`;
const GET_SPB_STATE = `${SCHOOLPB_EVENT_PREFIX}get_spb_state`;
const GET_SPB_RESULT = `${SCHOOLPB_EVENT_PREFIX}get_spb_result`;
const UPDATE_POLL = `${SCHOOLPB_EVENT_PREFIX}update_poll`;
const UPDATE_RESULTS = `${SCHOOLPB_EVENT_PREFIX}update_results`;
const UPDATE_PROPOSALS = `${SCHOOLPB_EVENT_PREFIX}update_proposals`;

export const EventType = {
    STORE_VOTE,
    MARK_VOTER,
    UPDATE_POLL,
    UPDATE_RESULTS,
    UPDATE_PROPOSALS,
    STORE_FORM,
    STORE_STEPPER_STEP,
    GENERATE_PINS,
    GENERATE_PIN_SET,
    SAVE_PIN_SET,
    REINDEX_DESCRIPTORS,
    REMOVE_DESCRIPTOR,
    ADD_DESCRIPTOR,
    UPDATE_DESCRIPTOR,
    SNAPSHOT_PAGE,
    CREATE_CUSTOM_TOKEN,
    CHECK_ENTITY_WHITELIST,
    GET_SPB_STATE,
    GET_SPB_RESULT,
};

export interface Event<T> {
    type: string;
    timestamp: string; // local data&time in ISO format
    payload: T;
}
