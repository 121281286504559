import styled from 'styled-components';
import { color } from '../../stylesheets/shared';
import IconOriginal from '../../blocks/Icon';
import { animation } from '../../stylesheets/shared';

const CIRCLE_BORDER_THICKNESS = 1;
const CIRCLE_DIAMETER = 32;
export const IconCircle = styled(IconOriginal)`
    color: black;
    background: white;
    width: ${CIRCLE_DIAMETER}px;
    height: ${CIRCLE_DIAMETER}px;
    min-width: ${CIRCLE_DIAMETER}px;
    border: ${CIRCLE_BORDER_THICKNESS}px solid ${color.violet};
    border-radius: 50%;
    font-size: 20px;
    margin-right: 8px;
    line-height: ${CIRCLE_DIAMETER - 2 * CIRCLE_BORDER_THICKNESS}px;
    text-align: center;
    vertical-align: middle;
`;

const BORDER_THICKNESS = 1;

const OVERLAY_SHRINK = 6;

export const DragOverlay = styled.div`
    position: absolute;
    top: ${OVERLAY_SHRINK}px;
    left: ${OVERLAY_SHRINK}px;
    width: calc(100% - ${2 * OVERLAY_SHRINK}px);
    height: calc(100% - ${2 * OVERLAY_SHRINK}px);

    border: 1px dashed white;
    border-radius: 12px;

    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Blur = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 12px;
`;

export const BlurTinting = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;

    background: black;
    opacity: 37%;
    display: none;
`;

export const Blurrable = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    &.blur-on {
        overflow: hidden;

        ${Blur} {
            filter: blur(8px);
            transition: filter ${animation.timing.standart} ease-out;
        }

        ${BlurTinting} {
            display: initial;
        }
    }
`;

// generic template for further styling
export const FileUploaderSlot = styled.div`
    border: ${BORDER_THICKNESS}px solid ${color.grey2};
    border-radius: 12px;
    color: ${color.black};
    background-color: ${color.white};
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 270px;
    height: ${64 + 2 * BORDER_THICKNESS}px;
    padding: 8px 12px;
    appearance: none;
    position: relative;
`;

export const SlotActionButton = styled.button`
    position: absolute;
    top: -8px;
    right: -8px;
    background: none;
    cursor: pointer;

    ${IconCircle}:hover {
        color: white;
        background: black;
        border-color: black;
    }
`;

export const CallToActionSlot = styled(FileUploaderSlot)`
    cursor: pointer;
    > input {
        display: none;
    }
`;

export const ImageThumbnail = styled.img`
    display: block;
    width: 48px;
    max-height: 48px;
`;

export const IconThumbnail = styled(IconOriginal)`
    font-size: 48px;
    display: block;
    width: 48px;
`;

export const UploadListItem = styled(FileUploaderSlot)`
    justify-content: flex-start;

    input {
        width: 100%;
        flex-shrink: 1;
    }

    > ${ImageThumbnail}, ${IconThumbnail} {
        margin-right: 8px;
    }
`;

export const UploadProgressItem = styled(UploadListItem)`
    justify-content: center;

    > div {
        opacity: 50%;
        position: absolute;
        width: 48px;
        height: 48px;
        left: 12px;
        top: 8px;
        display: flex;
        align-items: center;
    }
`;

export const DroppableArea = styled.div`
    border-radius: 12px;
    position: relative;
    width: 100%;

    ${FileUploaderSlot}:not(:last-child) {
        margin-bottom: 12px;
    }
`;
