import { isNil as _isNil } from 'lodash';
import React from 'react';

import { useOutlets } from '../../hooks';
import { BlockProps, Image as ImageConfig, Tools } from '../../../core/block';
import * as Styled from './styled';

export type Config = ImageConfig;
export type Props = BlockProps &
    Config & {
        onClick?: () => void;
    };

const Image = (props: Props) => {
    const outlet = useOutlets();

    const processClickOutlet = async () => {
        if (_isNil(props.outlets?.click)) {
            return;
        }

        await outlet(props.outlets?.click);
    };

    const onClick = () => {
        processClickOutlet();

        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    if (!props.hasBorder) {
        const config = Tools.extractConfig<Config>(props);
        return <Styled.Image onClick={onClick} {...config} src={props.src} />;
    }

    const config = Tools.extractConfig<Config>(props);
    const imgConfig = {
        ...config,
        config: {
            ...config.config,
            styleOverride: {},
        },
    };

    return (
        <Styled.ImageBorder {...config}>
            <Styled.Image onClick={onClick} {...imgConfig} src={props.src} />
        </Styled.ImageBorder>
    );
};
export default Image;
