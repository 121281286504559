import {
    map as _map,
    forEach as _forEach,
    some as _some,
    cloneDeep as _cloneDeep,
    isNil as _isNil,
    get as _get,
    shuffle as _shuffle,
    size as _size,
    find as _find,
} from 'lodash';
import React, { useLayoutEffect, useState } from 'react';
import { useStore, useDispatch } from 'react-redux';

import Block from '../Block';
import * as B from '../../../core/block';
import { Application as ApplicationActions, create as createAction } from '../../../core/action';
import { RootState } from '../../store/root_reducer';
import { shuffleGroup as getShuffleGroup } from '../../store/application/selectors';

export type Props = B.BlockProps & B.Blocks;

const getBlockShuffleGroup = (block: B.Block): string | undefined => _get(block, ['shuffleGroup']);
const isBlockInShuffleGroup = (block: B.Block): boolean => !_isNil(getBlockShuffleGroup(block));
const areSomeBlocksInShuffleGroups = (blocks: B.Block[] | undefined): boolean => _some(blocks, 'shuffleGroup');

const Blocks = (props: Props) => {
    const dispatch = useDispatch();
    const [renderMap, setRenderMap] = useState<number[]>();
    const rootStore = useStore<RootState>();

    const getBlockShuffleGroupNames = (blocks?: B.Block[]): string | undefined => {
        const firstBlokcInShuffleGroup: B.Block | undefined = _find(blocks, (b: B.Block) => isBlockInShuffleGroup(b));
        if (_isNil(firstBlokcInShuffleGroup)) {
            return undefined;
        }

        return getBlockShuffleGroup(firstBlokcInShuffleGroup);
    };

    const generateStraightRenderMap = (blocks?: B.Block[]): number[] => {
        if (_isNil(blocks)) {
            return [];
        }

        return _map(blocks, (b: B.Block, bIdx: number) => bIdx);
    };

    const generateShuffledRenderMap = (blocks?: B.Block[]): number[] => {
        if (_isNil(blocks)) {
            return [];
        }

        // generate straight render map first
        const originalIndexes: number[] = generateStraightRenderMap(blocks);

        // collect indexes of blocks in shuffle groups + generate original
        const blockIndexesInShuffleGroup: number[] = [];
        _forEach(blocks, (b: B.Block, blockIdx: number) => {
            if (isBlockInShuffleGroup(b)) {
                blockIndexesInShuffleGroup.push(blockIdx);
            }
        });

        // shuffle indexes to shuffle
        const shuffledBlockIndexesInShuffleGroup = _shuffle(blockIndexesInShuffleGroup);

        // incorporate shuffled indexes back
        const shuffledIndexes = _cloneDeep(originalIndexes);
        _forEach(blockIndexesInShuffleGroup, (indexBeforeShuffle: number, idx: number) => {
            const indexAfterShuffle = shuffledBlockIndexesInShuffleGroup[idx];
            shuffledIndexes[indexBeforeShuffle] = indexAfterShuffle;
        });

        return shuffledIndexes;
    };

    useLayoutEffect(() => {
        // console.trace('=========== <Blocks /> useLayoutEffect() has been called =======');
        // if blocks are undefined or empty, just use empty render map
        if (_isNil(props.blocks) || _size(props.blocks) === 0) {
            setRenderMap([]);
            return;
        }

        // use just straight render map
        if (!areSomeBlocksInShuffleGroups(props.blocks)) {
            const straightRenderMap = generateStraightRenderMap(props.blocks);
            setRenderMap(straightRenderMap);
        }
        // generate shuffled render map
        else {
            const shuffleGroupName = getBlockShuffleGroupNames(props.blocks);
            // try to get existing render map for this shuffle group
            if (!_isNil(shuffleGroupName)) {
                const sotredRenderMap: number[] | undefined = getShuffleGroup({ groupName: shuffleGroupName })(rootStore.getState());
                // shuffle group is already stored ... use it
                if (!_isNil(sotredRenderMap)) {
                    setRenderMap(sotredRenderMap);
                }
                // there is no shuffle group with this name ... generate it
                else {
                    const shuffledRenderMap = generateShuffledRenderMap(props.blocks);
                    setRenderMap(shuffledRenderMap);
                    // ... and store it
                    dispatch(createAction(ApplicationActions.shuffleGroup.TYPE, { groupName: shuffleGroupName, renderMap: shuffledRenderMap }));
                }
            }
            // generate new render map, but we cannot store it because we don't know the shuffle-group name
            else {
                const shuffledRenderMap = generateShuffledRenderMap(props.blocks);
                setRenderMap(shuffledRenderMap);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.blocks]);

    const getBlockConfigByIndex = (idx: number): B.Block | undefined => _get(props.blocks, [idx]);

    return (
        <>
            {!_isNil(props.blocks) &&
                !_isNil(renderMap) &&
                _size(renderMap) > 0 &&
                _map(renderMap, (blockIdx: number) => {
                    const block: B.Block | undefined = getBlockConfigByIndex(blockIdx);
                    return !_isNil(block) ? <Block key={`${blockIdx}-${block.name}`} {...block} /> : <></>;
                })}
        </>
    );
};
export default Blocks;
