import { isNil as _isNil, find as _find, forEach as _forEach } from 'lodash';

import { Country } from '../interfaces';
import Countries from '../countries';

export default (phoneCodes?: string[]): Country[] => {
    if (_isNil(phoneCodes)) {
        return Countries;
    }

    const filteredCountries: Country[] = [];

    _forEach(phoneCodes, (pc: string) => {
        const country: Country | undefined = _find(Countries, (country: Country) => country.phoneCode === pc);
        if (!_isNil(country)) {
            filteredCountries.push(country);
        }
    });

    return filteredCountries;
};
