import { isNil as _isNil } from 'lodash';
import React, { useState } from 'react';

import { Tools } from '../../../core/block';
import { Menu as MenuConfig, IconsSet } from '../../../core/block/interfaces';
import Block from '../Block';
import { useOnPhone, useOnTablet } from '../../hooks';
import MaterialIcons from '../../../core/block/icon_sets/material';
import * as Styled from './styled';

export type Config = MenuConfig;

export const Menu = (props: Config) => {
    const isMobile = useOnPhone();
    const isTablet = useOnTablet();
    const showMobileMenu = isMobile || isTablet;
    const display = _isNil(props.showOnlyOnMobile) ? true : props.showOnlyOnMobile && showMobileMenu;

    const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);

    const onMobileMenuBtn = () => setIsMenuDrawerOpen(!isMenuDrawerOpen);
    const onDrawerMenuClose = () => setIsMenuDrawerOpen(false);

    if (!display) return null;

    return (
        <>
            <Styled.MenuContainer {...Tools.extractConfig<Config>(props)}>
                {/* mobile menu button */}
                {showMobileMenu && (
                    <Styled.MobileMenuButton onClick={onMobileMenuBtn}>
                        <Styled.MobileMenuButtonIcon name="" icon={MaterialIcons.MENU} set={IconsSet.MATERIAL_OUTLINE} />
                    </Styled.MobileMenuButton>
                )}

                {/* desktop menu items */}
                {!showMobileMenu && (
                    <Styled.MenuItems align={props.align} itemsMargin={props.itemsMargin}>
                        {/* logo */}
                        {!_isNil(props.logo) && (
                            <Styled.MenuLogoWrapper>
                                <Block {...props.logo} />
                            </Styled.MenuLogoWrapper>
                        )}
                        {/* items */}
                        {props.items.map((menuItem) => (
                            <Block key={JSON.stringify(menuItem)} {...menuItem} />
                        ))}

                        {/* additional logo */}
                        {!_isNil(props.additionalLogo) && (
                            <Styled.MenuAdditionalLogoWrapper>
                                <Block {...props.additionalLogo} />
                            </Styled.MenuAdditionalLogoWrapper>
                        )}
                    </Styled.MenuItems>
                )}

                {/* logo on mobile */}
                {!_isNil(props.logo) && showMobileMenu && (
                    <Styled.MenuLogoWrapper>
                        <Block {...props.logo} />
                    </Styled.MenuLogoWrapper>
                )}
            </Styled.MenuContainer>

            {/* drawer */}
            {showMobileMenu && (
                <Styled.MobileMenuDrawer visible={isMenuDrawerOpen} closable={false} onClose={onDrawerMenuClose} placement="left" width={310}>
                    {/* close button */}
                    <Styled.DrawerMenuCloseButtonWrapper>
                        <Styled.DrawerMenuCloseButton onClick={onDrawerMenuClose}>
                            <Styled.DrawerMenuCloseButtonIcon name="" icon={MaterialIcons.CLOSE} set={IconsSet.MATERIAL_OUTLINE} />
                        </Styled.DrawerMenuCloseButton>
                    </Styled.DrawerMenuCloseButtonWrapper>

                    {/* items */}
                    {props.items.map((menuItem) => (
                        <Block key={JSON.stringify(menuItem)} {...menuItem} />
                    ))}
                </Styled.MobileMenuDrawer>
            )}
        </>
    );
};
