import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';

import { Config } from './index';
import { color, animation } from '../../../../stylesheets/shared';
import { cssP } from '../../../../stylesheets/snippets';

export const RadioLabel = styled.label<{ config: Config }>`
    ${cssP};
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    input {
        width: 24px;
        height: 24px;
        margin-right: 12px;

        appearance: none;
        border: 1px solid ${color.grey2};
        border-radius: 50%;
        background-color: ${hexToRgba(color.blue, 0.04)};
        transition: background-color ${animation.timing.interactive} ease-out;

        &:hover {
            background-color: ${hexToRgba(color.blue, 0.24)};
        }

        &:checked {
            background-color: white;
            border: 8px solid ${color.blue};
        }

        &:disabled {
            border-color: #e8e8e8;
            background-color: white;
        }
    }

    /* style override */
    ${({ config }) => config.styleOverride}
`;
