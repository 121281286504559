import { isNil as _isNil } from 'lodash';
import styled, { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { Menu as AntMenu } from 'antd';

import { Config } from './index';
import { color, animation } from '../../../stylesheets/shared';
import { cssDisableUserSelect, cssP } from '../../../stylesheets/snippets';
import { ifHoverIsSupported, onTablet, onPhone } from '../../../stylesheets/tools';
import IconComp from '../../Icon';

export const Menu = styled(AntMenu)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
`;
export const MobileMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 0 16px;
`;

export const ButtonIcon = styled(IconComp)`
    margin-left: 4px;

    ${onTablet(css`
        display: none;
    `)}
`;

const buttonColor = (config: Config): string =>
    !_isNil(config.themeOverride) && !_isNil(config.themeOverride?.color) ? config.themeOverride.color : color.violet;

type ButtonProps = {
    config: Config;
    selected?: boolean;
};

export const Button = styled.button<ButtonProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${cssP};
    font-weight: bold;
    color: ${({ selected }) => (selected ? color.white : color.black)};
    border: none;
    background-color: ${(p: ButtonProps) => (p.selected ? buttonColor(p.config) : 'transparent')};
    cursor: ${(p: ButtonProps) => (p.selected ? 'default' : 'pointer')};
    border-radius: 8px;
    padding: 6px 12px;
    ${cssDisableUserSelect};
    transition: color ${animation.timing.interactive} ease-out, background-color ${animation.timing.interactive} ease-out;

    /* hover */
    ${({ selected }) => {
        if (!selected) {
            return css`
                ${ifHoverIsSupported(css`
                    &:hover {
                        background-color: ${(p: ButtonProps) => hexToRgba(buttonColor(p.config), 0.64)};
                        color: ${color.white};
                    }
                `)}
            `;
        }
    }}

    /* style override */
    ${(p: ButtonProps) => p.config.styleOverride}

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;
