import { Store } from '../interfaces';
import { Poll } from '../../../../core/voting';

const reducer = (state: Store): Store => {
    const currentStep = state.step || '';
    const flow: Poll.Flow = state.config?.flow || { start: '', end: '' };

    return {
        ...state,
        step: Poll.Tools.getNextStep(flow, currentStep),
    };
};

export default reducer;
