import styled, { css } from 'styled-components';
import { Form as AntForm, Input as AntInput } from 'antd';
import hexToRgba from 'hex-to-rgba';
import IconOriginal from '../../Icon';
import { Config } from './index';
import { color } from '../../../stylesheets/shared';
import { cssNoteP, cssInput } from '../../../stylesheets/snippets';

export const DeleteIconPlaceholder = styled.div`
    order: 2;
    width: 18px;
    flex-shrink: 0;
`;

export const MainLayout = styled.div<{ config: Config }>`
    position: inherit;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border: 1px solid ${color.grey3};
    padding: 1px;
    border-radius: 12px;

    ${DeleteIconPlaceholder} {
        display: ${({ config }) => (config.enableDelete ? 'inherit' : 'none')};
    }

    /* style override */
    ${({ config }) => config.styleOverride}
`;

export const LinkIconCommon = css`
    position: absolute;
    text-align: center;
    left: 0;
    top: 0;
    margin: 12px;
    width: 32px;
    height: 32px;
`;

export const LinkIcon = styled(IconOriginal)`
    ${LinkIconCommon}
    margin: 12px 12px 12px 15px;
    z-index: 0;
`;

export const SocialMediaIcon = styled(IconOriginal)`
    ${LinkIconCommon}
    z-index: 1;
`;

export const OpenLinkIcon = styled(IconOriginal)`
    ${LinkIconCommon}
    z-index: 2;
`;
interface LinkIconContainerProps {
    isValidLink: boolean;
    isYouTubeLink: boolean;
}

export const LinkIconContainer = styled.div<LinkIconContainerProps>`
    order: 0;
    flex-shrink: 0;
    width: 56px;

    ${OpenLinkIcon} {
        display: none;
    }
    ${SocialMediaIcon} {
        display: none;
    }

    ${(p: LinkIconContainerProps) => {
        if (p.isValidLink === true) {
            if (p.isYouTubeLink === true) {
                return css`
                    ${SocialMediaIcon} {
                        display: inherit;
                    }
                    ${LinkIcon} {
                        display: none;
                    }
                `;
            }
        } else {
            return css`
                ${SocialMediaIcon} {
                    display: none;
                }
                ${OpenLinkIcon} {
                    display: none;
                }
            `;
        }
    }}

    ${(p: LinkIconContainerProps) => {
        if (p.isValidLink === true) {
            return css`
                &:hover {
                    ${SocialMediaIcon} {
                        display: none;
                    }
                    ${LinkIcon} {
                        display: none;
                    }
                    ${OpenLinkIcon} {
                        cursor: pointer;
                        display: inherit;
                        filter: drop-shadow(0 0 5px ${color.grey2});
                    }
                }
            `;
        }
    }}
`;

export const LinkItemInput = styled(AntInput)``;
interface FormItemInputProps {
    isValidLink?: boolean;
}
export const LinkLayout = styled.div<FormItemInputProps>`
    order: 1;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    flex-shrink: 3;
    flex-grow: 1;
    min-width: 288px;

    ${(p: FormItemInputProps) => {
        if (p.isValidLink === true) {
            return css`
                ${LinkItemInput} {
                    color: blue;
                    text-decoration: underline;
                }
            `;
        }
    }}
`;

export const DeleteIcon = styled(IconOriginal)`
    background: none;
    font-size: 19px;
    border-radius: 50%;
    width: 19px;
    height: 19px;
`;

export const DeleteIconContainer = styled.button`
    position: absolute;
    display: flex;
    width: 26px;
    height: 26px;
    justify-content: center;
    align-items: center;
    right: -8px;
    top: -8px;
    border-radius: 50%;
    border: 1px solid ${color.violet};
    color: black;
    background: white;
    cursor: pointer;

    &:hover {
        color: white;
        background: black;
        border-color: black;
    }
`;

export const FormItem = styled(AntForm.Item)`
    margin: 0;

    .ant-input {
        ${cssInput};
        background-color: ${color.white};
        border: none;
        padding: 0 25px 0 1px;
        appearance: none;

        &:hover {
            border-color: ${hexToRgba(color.blue, 0.48)};
        }
        &:focus {
            border: inherit;
            box-shadow: none;
        }
    }

    .ant-form-item-explain {
        ${cssNoteP};
        color: ${color.red};
        min-height: 0;
    }

    /* error */
    &.ant-form-item-has-error {
        .ant-input {
            border-color: ${color.red};
            border-bottom: 2px solid red;
            &:focus {
                border-color: ${color.red};
            }
        }

        .ant-form-item-explain {
            ${cssNoteP};
            color: ${color.red};
        }
    }
`;
