import { trim as _trim, isNil as _isNil } from 'lodash';
import React, { useEffect } from 'react';

import { Name as VotingName, Results } from '../../../../core/voting';
import { ResultsPage as ResultsPageConfig } from '../../../../core/website';
import { useResultsConfig, useOutlets } from '../../../hooks';
import RedirectTo404 from '../../RedirectTo404';
import StandardResults from '../../Results/Standard';
import Loading from '../../Loading';

const ResultsPage = (props: ResultsPageConfig) => {
    const outlet = useOutlets();
    const [resultsConfig, resultsLoading, resultsError] = useResultsConfig(props.resultsId);

    useEffect(() => {
        if (!_isNil(props.outlets?.enter)) {
            outlet(props.outlets?.enter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isResultsIdValid: boolean = !_isNil(props.resultsId) && _trim(props.resultsId || '') !== '';

    return (
        <>
            {/* some of Id is not valid */}
            {!isResultsIdValid && <RedirectTo404 />}

            {/* download by give Id */}
            {isResultsIdValid && (
                <>
                    {/* loading... */}
                    {resultsLoading && <Loading fullScreen={true} />}
                    {/* error */}
                    {resultsError && <>loading error: {resultsError}</>}
                    {/* does not exist */}
                    {!resultsLoading && !resultsError && _isNil(resultsConfig) && <RedirectTo404 />}
                    {/* exists */}
                    {!_isNil(resultsConfig) && (
                        <>
                            {/* standard */}
                            {resultsConfig.name === VotingName.STANDARD_RESULTS && <StandardResults {...(resultsConfig as Results.Results)} />}
                        </>
                    )}
                </>
            )}
        </>
    );
};
export default ResultsPage;
