import { isNil as _isNil } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useWebsiteConfig } from '../../hooks';
import { Tools } from '../../../core/website';
import RedirectTo404 from '../../components/RedirectTo404';
import StandardWebsite from '../../components/Website/Standard';
import { Name as WebsiteName } from '../../../core/website';
import { stringIsEmpty } from '../../../core/tools';
import Loading from '../../components/Loading';

const WebsitePage = () => {
    const urlParams = useParams();
    const websiteId = Tools.getWebsiteIdFromParams(urlParams);
    const [websiteConfig, websiteLoading, websiteError] = useWebsiteConfig(websiteId);
    const isWebsiteIdValid = !_isNil(websiteId) && !stringIsEmpty(websiteId);

    return (
        <>
            {/* Id is not valid */}
            {!isWebsiteIdValid && <RedirectTo404 />}

            {/* download by give Id */}
            {isWebsiteIdValid && (
                <>
                    {/* loading... */}
                    {websiteLoading && <Loading fullScreen={true} />}
                    {/* error */}
                    {websiteError && <>loading error: {websiteError}</>}
                    {/* does not exist */}
                    {!websiteLoading && !websiteError && _isNil(websiteConfig) && <RedirectTo404 />}
                    {/* exists */}
                    {!_isNil(websiteConfig) && (
                        <>
                            {/* standard */}
                            {websiteConfig.name === WebsiteName.STANDARD_WEBSITE && <StandardWebsite {...websiteConfig} />}
                        </>
                    )}
                </>
            )}
        </>
    );
};
export default WebsitePage;
