import { isNil as _isNil } from 'lodash';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const StyledIcon = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    fill: currentColor;
`;

export const StyledIconWrapper = styled.i`
    position: relative;
    width: 1em;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &:before {
        position: relative;
        content: '';
        display: block;
        padding-top: 100%;
    }
`;

type Props = HTMLAttributes<HTMLElement> & {
    onClick?: () => void;
};

const Icon = (props: Props) => {
    const onClick = () => {
        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <StyledIconWrapper onClick={onClick} className={props.className}>
            <StyledIcon viewBox="0 0 1024 1024">
                <path d="M969.532 0h-915.064c-30.088 0-54.468 24.391-54.468 54.468v915.064c0 30.077 24.38 54.468 54.468 54.468h915.064c30.088 0 54.468-24.391 54.468-54.468v-915.064c0-30.077-24.38-54.468-54.468-54.468zM815.594 576.741c27.909 27.234 57.42 52.878 82.486 82.977 11.090 13.29 21.548 27.016 29.511 42.474 11.34 22.049 1.1 46.265-18.661 47.562l-122.619-0.033c-31.657 2.604-56.865-10.142-78.129-31.788-16.94-17.266-32.692-35.698-49.021-53.531-6.656-7.331-13.715-14.227-22.060-19.674-16.711-10.85-31.232-7.538-40.829 9.902-9.761 17.757-12.016 37.452-12.909 57.246-1.362 28.923-10.077 36.472-39.086 37.812-61.985 2.919-120.821-6.493-175.474-37.725-48.248-27.561-85.558-66.495-118.065-110.527-63.357-85.874-111.877-180.137-155.452-277.101-9.815-21.842-2.636-33.531 21.428-33.977 40.045-0.795 80.079-0.686 120.069-0.044 16.297 0.251 27.071 9.575 33.313 24.935 21.624 53.215 48.161 103.849 81.386 150.8 8.857 12.495 17.898 24.968 30.753 33.803 14.205 9.761 25.034 6.547 31.733-9.303 4.281-10.109 6.133-20.85 7.048-31.657 3.181-36.995 3.562-73.989-1.928-110.843-3.431-23.051-16.417-37.942-39.402-42.3-11.7-2.222-9.989-6.558-4.303-13.236 9.87-11.569 19.173-18.715 37.648-18.715l138.578-0.022c21.853 4.27 26.711 14.075 29.685 36.069l0.131 153.959c-0.229 8.519 4.259 33.748 19.554 39.326 12.234 4.042 20.349-5.774 27.67-13.552 33.204-35.241 56.865-76.865 78.075-119.928 9.336-18.988 17.43-38.661 25.273-58.346 5.806-14.565 14.892-21.733 31.319-21.471l133.403 0.142c3.922 0 7.941 0.044 11.83 0.686 22.463 3.867 28.618 13.541 21.678 35.481-10.937 34.457-32.234 63.172-53.063 91.953-22.256 30.774-46.015 60.536-68.107 91.463-20.24 28.367-18.606 42.616 6.536 67.181z" />
            </StyledIcon>
        </StyledIconWrapper>
    );
};

export default Icon;
