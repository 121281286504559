import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;

    .content {
        width: 100%;
        max-width: 620px;
        box-sizing: border-box;
    }
`;
