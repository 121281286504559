import React from 'react';
import { isNil as _isNil } from 'lodash';
import { Collapse as AntCollapse } from 'antd';
import { BlockProps, Collapse as CollapseConfig, Tools } from '../../../core/block';
import Blocks from '../Blocks';
import * as Styled from './styled';

export type Config = CollapseConfig;
export type Props = BlockProps & Config;

const Collapse = (props: Props) => {
    const { Panel } = AntCollapse;

    return (
        <Styled.Collapse
            {...Tools.extractConfig<Config>(props)}
            defaultActiveKey={!_isNil(props.isExpanded) && props.isExpanded ? '1' : undefined}
            ghost
        >
            <Panel header={props.title} key="1">
                <Blocks blocks={props.blocks} />
            </Panel>
        </Styled.Collapse>
    );
};
export default Collapse;
