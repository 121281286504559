import styled, { CSSObject } from 'styled-components';
import { color } from '../../stylesheets/shared';

export interface ListProps {
    styleOverride?: CSSObject;
}

export const List = styled.ul<ListProps>`
    list-style: none;
    padding: 0;
    width: 100%;
    flex-shrink: 1;

    ${(props) => props.styleOverride}
`;

interface Props {
    isHighlighted?: boolean;
}

function getLeftBorder({ isHighlighted = false }: Props) {
    return isHighlighted ? `3px solid ${color.blue}` : 'none';
}

export const ProposalListItemVisual = styled.li<Props>`
    border: none;
    border-left: ${getLeftBorder};

    width: 100%;
    padding: 12px 8px 24px;
`;
