import { isNil as _isNil } from 'lodash';
import React from 'react';

import { EntityList } from '../../../../../core/admin';
import * as Styled from './styled';

interface Props {
    item: EntityList.Item;
    onCmd?: (cmd: EntityList.Command, item: EntityList.Item) => void;
}

const Item = (props: Props) => {
    const onClick = () => {
        if (!_isNil(props.onCmd)) {
            props.onCmd(EntityList.COMMAND_CLICK, props.item);
        }
    };

    return (
        <Styled.Item onClick={onClick}>
            Id: {props.item.id}
            <br />
            Entity: {props.item.entity}
            <br />
            Title: {props.item.id}
            <br />
            <Styled.PreviewWrapper>
                <Styled.Preview />
            </Styled.PreviewWrapper>
        </Styled.Item>
    );
};
export default Item;
