import styled from 'styled-components';

import { animation } from './stylesheets/shared';

interface AppFrameProps {
    isPopupVisible: boolean;
}
export const AppFrame = styled.div<AppFrameProps>`
    filter: ${({ isPopupVisible }) => (isPopupVisible ? 'blur(8px)' : 'none')};
    transition: filter ${animation.timing.standart} ease-out;
`;
