import styled from 'styled-components';

import { Config } from './index';

export const Item = styled.div``;

export const List = styled.div<{ config: Config }>`
    width: 100%;
    box-sizing: border-box;

    /* style override */
    ${({ config }) => config.styleOverride}
`;
