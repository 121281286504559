// Store
export enum Repetition {
    ONCE = 'once',
    EVERY = 'every',
}
export interface Execution {
    repetition: Repetition;
    ex: any; // for example action description
}
export interface Store {
    plan?: Record<string, Execution>; // trigger -> execution
}
export const initialStore: Store = {};
