import { isNil as _isNil } from 'lodash';
import React, { useState, useEffect } from 'react';

import { BlockProps, ProgressButton as ProgressButtonConfig, Tools, ProgressButtonInlets } from '../../../core/block';
import { useOutlets, useInlets } from '../../hooks';
import * as Styled from './styled';

export type Config = ProgressButtonConfig;
export type Props = BlockProps &
    Config & {
        onClick?: () => void;
    };

const ProgressButton = (props: Props) => {
    const outlets = useOutlets();
    const inlets = useInlets(props.inlets) as ProgressButtonInlets;
    const [percentage, setPercentage] = useState(0);
    const [totalTasksCount, setTotalTasksCount] = useState(0);
    const [doneTasksCount, setDoneTasksCount] = useState(0);
    const [displayInProgress, setDisplayInProgress] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const isInProgress = (): boolean => displayInProgress;

    const processClickOutlet = async () => {
        if (_isNil(props.outlets?.click)) {
            return;
        }

        setDisplayInProgress(true);
        await outlets(props.outlets?.click);
        setDisplayInProgress(false);
    };

    const onClick = () => {
        if (isInProgress()) {
            return;
        }

        processClickOutlet();

        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    useEffect(() => {
        if (!_isNil(inlets.tasks)) {
            setDoneTasksCount(inlets.tasks.doneTasksCount);
            setTotalTasksCount(inlets.tasks.totalTasksCount);
            setPercentage((inlets.tasks.doneTasksCount / inlets.tasks.totalTasksCount) * 100);
        }

        if (!_isNil(inlets.selected)) {
            setIsActive(inlets.selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inlets]);

    const getStatus = () => {
        if (doneTasksCount === totalTasksCount) {
            return 'success';
        }
        return 'active';
    };

    return (
        <Styled.Button {...Tools.extractConfig<Config>(props)} onClick={onClick} isSelected={isActive}>
            <Styled.ProgressChartContainer>
                <Styled.Progress
                    type="circle"
                    status={getStatus()}
                    percent={percentage}
                    width={38}
                    format={percentage === 100 ? undefined : () => `${doneTasksCount}/${totalTasksCount}`}
                />
            </Styled.ProgressChartContainer>
            {props.text}
        </Styled.Button>
    );
};
export default ProgressButton;
