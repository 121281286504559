// import { Proposals as CoreProposalsActions, Action } from '../../../core/action';

import * as mapPins from './map_pin_clicked';
import * as isAddingProposal from './is_adding_proposal';

export const SET_PROPOSALS = 'setProposals';

export interface Payload {
    // projectId: string; // id of the project, under which the proposals belong
    proposals: any[]; // array proposal data
}

export const setProposals = (payload: Payload) => {
    return {
        type: SET_PROPOSALS,
        payload,
    };
};

export const reduceSetProposals = (state: any, payload: Payload) => {
    return {
        ...state,
        proposals: payload.proposals,
    };
};

export interface ProposalsState {
    proposals: Proposal[];
    highlightedProposalId: string;
}

export interface Proposal {
    id: string;
    title: string;
    location?: {
        longitude: number;
        latitude: number;
        label?: string;
    };
}

export const DEFAULT_PROPOSALS_STATE = {
    proposals: [],
};

export function proposalsReducer(state: ProposalsState, action: any) {
    if (!state) {
        return DEFAULT_PROPOSALS_STATE;
    }

    if (action.type === SET_PROPOSALS) {
        return reduceSetProposals(state, action.payload as Payload);
    } else if (action.type === mapPins.PROPOSALS_MAP_PIN_CLICKED) {
        return mapPins.reduceMapPinClicked(state, action.payload as mapPins.Payload);
    } else if (action.type === isAddingProposal.PROPOSALS_IS_ADDING) {
        return isAddingProposal.reduceIsAddingProposal(state, action.payload as isAddingProposal.IsAddingProposalPayload);
    }

    return state;
}
