import { map as _map, isArray as _isArray } from 'lodash';
import React from 'react';

import { Block, BlockProps, TemplateList as TemplateListConfig, TemplateListInlets, Tools } from '../../../core/block';
import { interpolate } from '../../../core/tools';
import { useInlets } from '../../hooks';
import Blocks from '../Blocks';
import * as Styled from './styled';

export type Config = TemplateListConfig;
export type Props = BlockProps & Config & { data?: any[] };

const TemplateList = (props: Props) => {
    const inlets = useInlets(props.inlets) as TemplateListInlets;
    const itemsData = props.data || inlets.data || [];

    // if no items, return empty items container
    const areThereSomeItems = itemsData.length > 0;
    if (!areThereSomeItems) {
        return <Styled.List {...Tools.extractConfig<Config>(props)}></Styled.List>;
    }

    const generateItemBlock = (data: any): Block[] => {
        const interpolatedTemplate = interpolate(props.template, { data }, { skipUnresolvedPlaceholders: true });
        let resultBlocks: Block | Block[] = JSON.parse(interpolatedTemplate);

        // Blocks component can render only array of block definition
        if (!_isArray(resultBlocks)) {
            resultBlocks = [resultBlocks];
        }

        return resultBlocks;
    };

    const isItLastItem = (itemIdx: number) => {
        return itemsData.length - 1 === itemIdx;
    };

    return (
        <Styled.List {...Tools.extractConfig<Config>(props)}>
            {/* first interleaving blocks */}
            {props.interleaving?.useFirst === true && <Blocks blocks={props.interleaving.blocks} />}

            {/* items list */}
            {_map(itemsData, (itemData: any, itemIdx: number) => (
                <Styled.Item key={`${itemIdx}-${JSON.stringify(itemData)}`}>
                    <Blocks blocks={generateItemBlock(itemData)} />

                    {/* interleaving blocks */}
                    {!isItLastItem(itemIdx) && <Blocks blocks={props.interleaving?.blocks} />}
                </Styled.Item>
            ))}

            {/* last interleaving blocks */}
            {props.interleaving?.useLast === true && <Blocks blocks={props.interleaving.blocks} />}
        </Styled.List>
    );
};

export default TemplateList;
