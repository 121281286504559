import styled from 'styled-components';

import { cssP } from '../../../stylesheets/snippets';
import { color, meaningColor, animation } from '../../../stylesheets/shared';
import { Config } from './index';

// title line
export const Title = styled.div`
    flex-shrink: 1;
`;
export const TopLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
`;

// numbers line
export const Percentage = styled.div`
    ${cssP}
    font-weight: bold;
`;
export const Votes = styled.div`
    ${cssP}
    margin-left: 4px;
`;
export const Numbers = styled.div`
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;
export const NumbersLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

// bar line
interface BarProps {
    size: number;
}
export const MinusBar = styled.div<BarProps>`
    position: absolute;
    bottom: 12px;
    left: 50%;
    height: 8px;
    border-radius: 4px;
    background-color: ${meaningColor.minus};
    box-sizing: border-box;
    width: ${(p: BarProps) => (Math.abs(p.size) > 1 ? `${Math.abs(p.size) / 2}%` : '0')};
    transform: rotate(180deg);
    transform-origin: 0% 50%;
    transition: width ${animation.timing.standart} ease-out;
`;
export const PlusBar = styled.div<BarProps>`
    position: absolute;
    bottom: 12px;
    left: 50%;
    height: 8px;
    border-radius: 4px;
    background-color: ${meaningColor.plus};
    box-sizing: border-box;
    width: ${(p: BarProps) => (Math.abs(p.size) > 1 ? `${Math.abs(p.size) / 2}%` : '1px')};
    transition: width ${animation.timing.standart} ease-out;
`;
export const BarLine = styled.div`
    position: relative;
    height: 48px;

    &:before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 1px;
        background-color: ${color.grey3};
    }
`;

export const DoubleSignBarGraph = styled.div<{ config: Config }>`
    /* style override */
    ${({ config }) => config.styleOverride}
`;
