import { isNil as _isNil } from 'lodash';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const StyledIcon = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    fill: currentColor;
`;

export const StyledIconWrapper = styled.i`
    position: relative;
    width: 1em;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &:before {
        position: relative;
        content: '';
        display: block;
        padding-top: 100%;
    }
`;

type Props = HTMLAttributes<HTMLElement> & {
    onClick?: () => void;
};

const Icon = (props: Props) => {
    const onClick = () => {
        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <StyledIconWrapper onClick={onClick} className={props.className}>
            <StyledIcon viewBox="0 0 1024 1024">
                <path d="M511.84 0c-139.008 0-156.438 0.589-211.032 3.080-54.479 2.487-91.687 11.138-124.245 23.792-33.658 13.080-62.202 30.582-90.657 59.036s-45.956 56.999-59.036 90.657c-12.654 32.557-21.305 69.764-23.792 124.243-2.491 54.593-3.078 72.023-3.078 211.032 0 139.006 0.587 156.436 3.078 211.030 2.487 54.479 11.138 91.687 23.792 124.243 13.080 33.658 30.582 62.202 59.036 90.659 28.455 28.455 56.999 45.956 90.657 59.036 32.559 12.652 69.766 21.305 124.245 23.79 54.593 2.491 72.023 3.080 211.032 3.080 139.006 0 156.436-0.589 211.030-3.080 54.479-2.485 91.687-11.138 124.243-23.79 33.658-13.080 62.202-30.582 90.659-59.036 28.455-28.457 45.956-57.001 59.036-90.659 12.652-32.557 21.305-69.764 23.79-124.243 2.491-54.593 3.080-72.023 3.080-211.030 0-139.008-0.589-156.438-3.080-211.032-2.485-54.479-11.138-91.687-23.79-124.243-13.080-33.658-30.582-62.202-59.036-90.657-28.457-28.455-57.001-45.956-90.659-59.036-32.557-12.654-69.764-21.305-124.243-23.792-54.593-2.491-72.023-3.080-211.030-3.080zM511.84 92.225c136.666 0 152.854 0.52 206.826 2.983 49.904 2.278 77.005 10.616 95.041 17.623 23.891 9.285 40.942 20.378 58.852 38.288 17.912 17.91 29.003 34.962 38.288 58.852 7.010 18.036 15.348 45.137 17.623 95.041 2.462 53.971 2.985 70.16 2.985 206.828 0 136.666-0.522 152.854-2.985 206.826-2.276 49.904-10.614 77.005-17.623 95.041-9.285 23.891-20.376 40.942-38.288 58.852-17.91 17.912-34.96 29.003-58.852 38.288-18.036 7.010-45.137 15.348-95.041 17.623-53.963 2.462-70.15 2.985-206.826 2.985-136.678 0-152.863-0.522-206.828-2.985-49.904-2.276-77.005-10.614-95.041-17.623-23.891-9.285-40.942-20.376-58.854-38.288-17.91-17.91-29.003-34.96-38.286-58.852-7.010-18.036-15.348-45.137-17.625-95.041-2.462-53.971-2.983-70.16-2.983-206.826 0-136.668 0.52-152.857 2.983-206.828 2.278-49.904 10.616-77.005 17.625-95.041 9.283-23.889 20.376-40.942 38.286-58.852 17.912-17.91 34.962-29.003 58.854-38.288 18.036-7.007 45.137-15.346 95.041-17.623 53.971-2.462 70.16-2.983 206.828-2.983z" />
                <path d="M511.84 682.452c-94.228 0-170.614-76.386-170.614-170.612 0-94.228 76.386-170.614 170.614-170.614 94.226 0 170.612 76.386 170.612 170.614 0 94.226-76.386 170.612-170.612 170.612zM511.84 249.003c-145.162 0-262.837 117.675-262.837 262.837 0 145.16 117.675 262.835 262.837 262.835 145.16 0 262.835-117.675 262.835-262.835 0-145.162-117.675-262.837-262.835-262.837z" />
                <path d="M846.481 238.619c0 33.922-27.5 61.42-61.422 61.42-33.92 0-61.42-27.498-61.42-61.42s27.5-61.42 61.42-61.42c33.922 0 61.422 27.498 61.422 61.42z" />
            </StyledIcon>
        </StyledIconWrapper>
    );
};

export default Icon;
