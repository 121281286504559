import styled from 'styled-components';

import { Config } from './index';
import { cssTitle } from '../../../stylesheets/snippets';
import { color } from '../../../stylesheets/shared';

export const Title = styled.h1<{ config: Config }>`
    ${cssTitle};
    color: ${color.black};

    /* style override */
    ${({ config }) => config.styleOverride}
`;
