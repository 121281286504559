import { get as _get, isNil as _isNil, min as _min, round as _round } from 'lodash';

import { StoreSelectorFunction } from '../../../../core/selector';
import { Results, Result } from '../../../../core/voting';
import { Voting as VotingCalculator } from '../../../../core/calculator';
import { Meaning } from '../../../../core/voting/choice/interfaces';
import { RootState } from '../../root_reducer';
import { Store, SimpleResultSetup } from '../interfaces';

// get voting store
const getVotingStore = (store: RootState): Store => store.voting;

// current step
export const currentStep = (): StoreSelectorFunction<string | undefined> => (rootStore: RootState): string | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    return store.resultsStep;
};

// current step index
export const currentStepIndex = (): StoreSelectorFunction<number | undefined> => (rootStore: RootState): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    return Results.Tools.stepIndex(store.resultsConfig?.flow, store.resultsStep);
};

// current step config
export const currentStepConfig = (): StoreSelectorFunction<Results.FlowStep | undefined> => (rootStore: RootState): Results.FlowStep | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    if (_isNil(store.resultsStep) || _isNil(store.resultsConfig?.flow.steps)) {
        return undefined;
    }

    return _get(store.resultsConfig?.flow.steps, store.resultsStep);
};

// previous step
export const previousStep = (): StoreSelectorFunction<string | undefined> => (rootStore: RootState): string | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    return Results.Tools.getPreviousStep(store.resultsConfig?.flow, store.resultsStep);
};

// number of steps
export const numberOfSteps = (): StoreSelectorFunction<number | undefined> => (rootStore: RootState): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    return Results.Tools.numberOfSteps(store.resultsConfig?.flow);
};

// config
export const config = (): StoreSelectorFunction<Results.Results | undefined> => (rootStore: RootState): Results.Results | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    return store.resultsConfig;
};

// values
export interface ResultValuesSelectorPayload {
    r: string; // result id
}
export const resultValues = (payload: ResultValuesSelectorPayload): StoreSelectorFunction<Result.Values | undefined> => (
    rootStore: RootState,
): Result.Values | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    const resultConfig: Result.Config | undefined = _get(store.result.config, payload.r);
    if (_isNil(resultConfig)) {
        return undefined;
    }

    return resultConfig.values;
};

// overlap
export interface ResultOverlapSelectorPayload {
    r: string; // result id
}
export const resultOverlap = (payload: ResultOverlapSelectorPayload): StoreSelectorFunction<Record<string, Result.Values> | undefined> => (
    rootStore: RootState,
): Record<string, Result.Values> | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return undefined;
    }

    const resultConfig: Result.Config | undefined = _get(store.result.config, payload.r);
    if (_isNil(resultConfig)) {
        return undefined;
    }

    return resultConfig.overlap;
};

// result respondents
export interface ResultRespondentsSelectorPayload {
    r: string; // result id
}
export const resultRespondents = (payload: ResultRespondentsSelectorPayload): StoreSelectorFunction<number | undefined> => (
    rootStore: RootState,
): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return 0;
    }

    const resultConfig: Result.Config | undefined = _get(store.result.config, payload.r);
    if (_isNil(resultConfig)) {
        return 0;
    }

    return resultConfig.respondents || 0;
};

// results respondents
export const resultsRespondents = (): StoreSelectorFunction<number | undefined> => (rootStore: RootState): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return 0;
    }

    return store.resultsConfig?.respondents || 0;
};

// result respondents in percentage
export interface ResultRespondentsInPercentageSelectorPayload {
    r: string; // result id
    precision?: number; // default is 0
}
export const resultRespondentsInPercentage = (payload: ResultRespondentsInPercentageSelectorPayload): StoreSelectorFunction<number | undefined> => (
    rootStore: RootState,
): number | undefined => {
    const numberOfResultRespondents = resultRespondents({ r: payload.r })(rootStore);
    if (_isNil(numberOfResultRespondents) || numberOfResultRespondents === 0) {
        return 0;
    }

    const numberOfResultsRespondents = resultsRespondents()(rootStore);
    if (_isNil(numberOfResultsRespondents) || numberOfResultsRespondents === 0) {
        return 0;
    }

    const precision: number = payload.precision || 0;

    const calculatedPercentage = _round((numberOfResultRespondents / numberOfResultsRespondents) * 100, precision);
    return _min([100, calculatedPercentage]) || 0;
};

// results plus votes count
export const resultsPlusVotesCount = (): StoreSelectorFunction<number | undefined> => (rootStore: RootState): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return 0;
    }

    return store.resultsConfig?.votes?.[Meaning.PLUS] || 0;
};

// results minus votes count
export const resultsMinusVotesCount = (): StoreSelectorFunction<number | undefined> => (rootStore: RootState): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return 0;
    }

    return store.resultsConfig?.votes?.[Meaning.MINUS] || 0;
};

// results votes count
export const resultsVotesCount = (): StoreSelectorFunction<number | undefined> => (rootStore: RootState): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return 0;
    }

    const plusCount = resultsPlusVotesCount()(rootStore) || 0;
    const minusCount = resultsMinusVotesCount()(rootStore) || 0;

    return plusCount + minusCount;
};

// result plus votes count
export interface ResultPlusVotesCountSelectorPayload {
    r: string; // result id
}
export const resultPlusVotesCount = (payload: ResultPlusVotesCountSelectorPayload): StoreSelectorFunction<number | undefined> => (
    rootStore: RootState,
): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return 0;
    }

    const resultConfig: Result.Config | undefined = _get(store.result.config, payload.r);
    if (_isNil(resultConfig)) {
        return 0;
    }

    return resultConfig.votes?.[Meaning.PLUS] || 0;
};

// result minus votes count
export interface ResultMinusVotesCountSelectorPayload {
    r: string; // result id
}
export const resultMinusVotesCount = (payload: ResultMinusVotesCountSelectorPayload): StoreSelectorFunction<number | undefined> => (
    rootStore: RootState,
): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return 0;
    }

    const resultConfig: Result.Config | undefined = _get(store.result.config, payload.r);
    if (_isNil(resultConfig)) {
        return 0;
    }

    return resultConfig.votes?.[Meaning.MINUS] || 0;
};

// result votes count
export interface ResultVotesCountSelectorPayload {
    r: string; // result id
}
export const resultVotesCount = (payload: ResultVotesCountSelectorPayload): StoreSelectorFunction<number | undefined> => (
    rootStore: RootState,
): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return 0;
    }

    const plusCount = resultPlusVotesCount({ r: payload.r })(rootStore) || 0;
    const minusCount = resultMinusVotesCount({ r: payload.r })(rootStore) || 0;

    return plusCount + minusCount;
};

// result option votes count
export interface ResultOptionVotesCountSelectorPayload {
    r: string; // result id
    o: string; // option id
}
export const resultOptionVotesCount = (payload: ResultOptionVotesCountSelectorPayload): StoreSelectorFunction<number | undefined> => (
    rootStore: RootState,
): number | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return 0;
    }

    const resultConfig: Result.Config | undefined = _get(store.result.config, payload.r);
    if (_isNil(resultConfig)) {
        return 0;
    }

    const plusCount = _get(resultConfig.values, [payload.o, Meaning.PLUS], 0);
    const minusCount = _get(resultConfig.values, [payload.o, Meaning.MINUS], 0);

    return plusCount + minusCount;
};

// result setup - calculation method
export interface ResultSetupCalculationMethodSelectorPayload {
    r: string; // result id
}
export const resultSetupCalculationMethod = (
    payload: ResultSetupCalculationMethodSelectorPayload,
): StoreSelectorFunction<VotingCalculator.Method | undefined> => (rootStore: RootState): VotingCalculator.Method | undefined => {
    const store: Store = getVotingStore(rootStore);
    if (!store) {
        return;
    }

    const resultSetup: SimpleResultSetup | undefined = _get(store.result.setup, payload.r);
    if (_isNil(resultSetup)) {
        return;
    }

    return resultSetup.calculationMethod;
};
