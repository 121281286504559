import { Dispatch } from 'redux';

import { Selector } from '../selector';

export enum Target {
    CLIENT = 'client', // default value
    SERVER = 'server',
    BOTH = 'both',
}

export interface Action<T> {
    type: string;
    payload?: T;
    selectors?: Selector<any>[];
    target?: Target;
}

export type Effect = (dispatch: Dispatch<any>, getState: () => any) => Promise<void>;
export type EffectCreator<T> = (payload: T, ...params: any) => Effect;
