import { get as _get, map as _map } from 'lodash';

import { Store } from '../interfaces';
import { Status as ValidationStatus, Tools as ValidationTools } from '../../../../core/validation';

const reducer = (state: Store, formId: string): Store => {
    let validationStatus: string | boolean = false;

    // get validation of all field
    const allFormFieldStatuses: Record<string, ValidationStatus> = _get(state.fields.validationStatus, formId, {});
    const fieldsValidations: ValidationStatus[] = _map(allFormFieldStatuses, (fvs: ValidationStatus) => fvs);

    // merge all statuses together
    validationStatus = ValidationTools.mergeStatuses(fieldsValidations);

    return {
        ...state,
        form: {
            ...state.form,
            validationStatus: {
                ...state.form.validationStatus,
                [formId]: validationStatus,
            },
        },
    };
};

export default reducer;
