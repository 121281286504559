import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';

import { Results, Urls } from '../../../core/voting';

function useResultsConfig(resultsId: string): [Results.Results | undefined, boolean, any | undefined] {
    const [config, loading, error] = useObjectVal<Results.Results>(firebase.database().ref(Urls.resultsConfig(resultsId)));

    return [config, loading, error];
}

export default useResultsConfig;
