import moment from 'moment';

import { Store } from '../interfaces';

const reducer = (state: Store): Store => {
    const now = moment().toISOString();
    // console.log('now', now);
    const newState: Store = {
        ...state,
        votingEndDatePlanned: state.votingEndDate,
        votingEndDate: now,
    };

    return newState;
};

export default reducer;
