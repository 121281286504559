import { Action } from '../interfaces';
import { Name as EntityName } from '../../entity';

export const TYPE = `${EntityName.ACTION_PREFIX}AUTHENTICATION:check_authentication_redirect_result`;

export interface Payload {
    onSuccess?: Action<any> | Action<any>[];
    onFail?: Action<any> | Action<any>[];
    onNotRedirected?: Action<any> | Action<any>[];
}
