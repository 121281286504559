import React from 'react';

import { BlockProps, VerticalLayout as VerticalLayoutConfig, Tools } from '../../../core/block';
import Blocks from '../Blocks';
import * as Styled from './styled';

export type Config = VerticalLayoutConfig;
export type Props = BlockProps & Config;

const VerticalLayout = (props: Props) => {
    return (
        <Styled.VerticalLayout {...Tools.extractConfig<Config>(props)}>
            <Blocks blocks={props.blocks} />
        </Styled.VerticalLayout>
    );
};
export default VerticalLayout;
