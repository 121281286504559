import {
    isNil as _isNil,
    isString as _isString,
    isArray as _isArray,
    isPlainObject as _isPlainObject,
    keys as _keys,
    replace as _replace,
    cloneDeep as _cloneDeep,
    forEach as _forEach,
    get as _get,
    has as _has,
    includes as _includes,
    map as _map,
    mapValues as _mapValues,
} from 'lodash';

import { Action } from '../../../core/action';

import { findStandardPlaceholders } from '../../tools/interpolation';

const resolveSingleLevel = (action: any, payload: any, valueToResolve?: Record<string, any>): any => {
    // copy original payload
    let p = _cloneDeep(payload);

    if (!_isNil(valueToResolve)) {
        // get value keys
        const valueKeys = _keys(valueToResolve);

        _forEach(valueKeys, (valueKey: string) => {
            const value = _get(valueToResolve, valueKey);
            const templateMark = `{{${valueKey}}}`;
            // if there only the templateMark, use the value with value type ... do NOT convert it to string
            if (p === templateMark) {
                p = value;
            }
            // templateMark is there with toher values -> result is interpolated string
            else {
                while (_includes(p, templateMark)) {
                    p = _replace(p, templateMark, value);
                }
            }
        });
    }

    // process cross payload links
    const placeholders = findStandardPlaceholders(p);
    if (!_isNil(placeholders) && placeholders.length !== 0) {
        _forEach(placeholders, (placeholder: any) => {
            const dataPathPlaceholder = placeholder[0];
            const dataPath = placeholder[2];
            if (_has(action, dataPath)) {
                const resVal = _get(action, dataPath, null);
                if (_isArray(resVal) && p === dataPathPlaceholder) {
                    p = resVal;
                } else {
                    // resolve as string
                    p = _replace(p, dataPathPlaceholder, resVal);
                }
            }
        });
    }

    return p;
};

export default (action: Action<any>, valueToResolve?: Record<string, any>): any => {
    if (_isNil(action.payload)) {
        return action.payload;
    }

    // copy original payload
    let p = _cloneDeep(action.payload);

    // string paylaod
    if (_isString(p)) {
        p = resolveSingleLevel(action, p, valueToResolve);
    }
    // array
    else if (_isArray(p)) {
        p = _map(p, (singleLevel: any) => resolveSingleLevel(action, singleLevel, valueToResolve));
    }
    // plain object
    else if (_isPlainObject(p)) {
        p = _mapValues(p, (singleLevel: any) => resolveSingleLevel(action, singleLevel, valueToResolve));
    }

    return p;
};
