import { useMemo } from 'react';
import { boolean as yupBoolean, object as yupObject, string as yupString, Schema as YupSchema } from 'yup';

import { jsonToYup } from '../../tools';

export interface ValidationResult {
    isValid: boolean;
    antValidationStatus: AntValidationStatus;
    message?: string;
    lastValue?: any;
}

export type AntValidationStatus = 'success' | 'warning' | 'error' | 'validating' | '';
export const VALIDATION_RESULT_UNTOUCHED: ValidationResult = { isValid: true, antValidationStatus: '' };
export const VALIDATION_RESULT_UNTOUCHED_NEGATIVE: ValidationResult = { isValid: false, antValidationStatus: '' };

/** Transforms `ValidationResult` into the form used in the redux form-state tracking */
export function getOutletValidationStatus(validationResult: ValidationResult) {
    return validationResult.isValid || validationResult.message || false;
}

function parseValidationSchema(validationSchemaJson: Record<string, any>, baseSchema: any) {
    return yupObject().shape({
        value: jsonToYup(baseSchema, validationSchemaJson),
    });
}

function getAntdValidationStatus(isValid: boolean, isTouched: boolean): AntValidationStatus {
    if (!isTouched) return '';

    return isValid ? 'success' : 'error';
}

function createValidator(validationSchema: ReturnType<typeof parseValidationSchema>) {
    const validator = (value: any, isTouched = true): Promise<ValidationResult> => {
        return validationSchema
            .validate({ value })
            .then(() => {
                // if the validation passes, the promise resolves with the actual value,
                // but we rather want to return undefined, because the output of our fn
                // is `validationMessage`
                return {
                    isValid: true,
                    antValidationStatus: getAntdValidationStatus(true, isTouched),
                    lastValue: value,
                };
            })
            .catch((validationResult) => {
                const message = validationResult.errors[0];
                return {
                    isValid: false,
                    antValidationStatus: getAntdValidationStatus(false, isTouched),
                    message,
                    lastValue: value,
                };
            });
    };

    return validator;
}

/** Generic validation hook for **non-standard use-cases.** Prefer using hooks for concrete value type - useStringValidation, useBooleanValidation, ...
 *
 *  This hooks needs to receive the base type validator (string, boolean, ...), and is internally used by concrete type validators.
 */
export function useValidation(validationSchemaJson: Record<string, any>, baseSchema: YupSchema<any>) {
    const validator = useMemo(() => {
        const validationSchema = parseValidationSchema(validationSchemaJson, baseSchema);
        return createValidator(validationSchema);
    }, [validationSchemaJson, baseSchema]);

    return validator;
}

/** Validates a **string value** based on the provided validation schema */
export function useStringValidation(validationSchemaJson: Record<string, any>) {
    return useValidation(validationSchemaJson, yupString());
}

/** Validates a **boolean value** based on the provided validation schema */
export function useBooleanValidation(validationSchemaJson: Record<string, any>) {
    return useValidation(validationSchemaJson, yupBoolean());
}
