import { get as _get } from 'lodash';
import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

import Dashboard from '../../components/Admin/Dashboard';
import Editor from '../../components/Admin/Editor';
import RedirectTo404 from '../../components/RedirectTo404';

const AdminPage = () => {
    const routeMatch = useRouteMatch();
    const path = _get(routeMatch, ['path']);
    // TODO
    const adminSignedIn = true;

    return (
        <>
            {/* admin is not signed in */}
            {!adminSignedIn && <RedirectTo404 />}

            {/* admin is signed in */}
            {adminSignedIn && (
                <Switch>
                    {/* dashboard */}
                    <Route path={`${path}`} exact>
                        <Dashboard />
                    </Route>

                    {/* editors */}
                    <Route path={`${path}/:editorId`}>
                        <Editor />
                    </Route>
                </Switch>
            )}
        </>
    );
};
export default AdminPage;
