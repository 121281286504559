import { isNil as _isNil } from 'lodash';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const StyledIcon = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    fill: currentColor;
`;

export const StyledIconWrapper = styled.i`
    position: relative;
    width: 1em;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &:before {
        position: relative;
        content: '';
        display: block;
        padding-top: 100%;
    }
`;

type Props = HTMLAttributes<HTMLElement> & {
    onClick?: () => void;
};

const Icon = (props: Props) => {
    const onClick = () => {
        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <StyledIconWrapper onClick={onClick} className={props.className}>
            <StyledIcon viewBox="0 0 1024 1024">
                <path
                    fill="rgb(216, 0, 39)"
                    d="M1024 512c0 282.77-229.23 512-512 512s-512-229.23-512-512c0-282.77 229.23-512 512-512s512 229.23 512 512z"
                />
                <path
                    fill="rgb(240, 240, 240)"
                    d="M779.13 422.958h-178.086v-178.088h-178.088v178.088h-178.086v178.086h178.086v178.086h178.088v-178.086h178.086z"
                />
            </StyledIcon>
        </StyledIconWrapper>
    );
};

export default Icon;
