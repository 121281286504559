import { defer as _defer, isNil as _isNil, includes as _includes } from 'lodash';
import * as Scroll from 'react-scroll';

import { Effect, Browser as BrowserActions } from '../../../../core/action';

const animScroll = Scroll.animateScroll;
const scroller = Scroll.scroller;

export default function (payload: BrowserActions.scrollWindow.Payload): Effect {
    return async (): Promise<void> => {
        _defer(() => {
            // to?
            if (!_isNil(payload.to)) {
                // viewport height?
                if (_includes(payload.to, 'vh')) {
                    const vh = parseInt(payload.to);
                    animScroll.scrollTo(window.innerHeight * (vh / 100));
                }
                // viewport width?
                else if (_includes(payload.to, 'vw')) {
                    const vw = parseInt(payload.to);
                    animScroll.scrollTo(window.innerWidth * (vw / 100));
                }
            }
            // to anchor
            else if (!_isNil(payload.toAnchor)) {
                scroller.scrollTo(payload.toAnchor, {
                    duration: 1000,
                    smooth: 'easeInOutQuad',
                });
            }
        });
    };
}
