import styled from 'styled-components';

import { Config } from './index';
import { cssP } from '../../../stylesheets/snippets';
import { color } from '../../../stylesheets/shared';

const COLLAPE_BUTTON_HEIGHT_PX = 24;

function getCollapsedHeight(rows: number, lineHeightPx = 22) {
    return rows * lineHeightPx - 0.5 * lineHeightPx + COLLAPE_BUTTON_HEIGHT_PX + 'px';
}

export const Collapsible = styled.div<{ isCollapsed: boolean; visibleRows: number }>`
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: ${({ isCollapsed, visibleRows }) => (isCollapsed ? getCollapsedHeight(visibleRows) : 'auto')};
    box-sizing: content-box;

    div.collapse,
    div.expand {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 0;

        background: ${color.white};
        padding: 4px;

        width: 100%;
        height: ${COLLAPE_BUTTON_HEIGHT_PX}px;

        &:hover {
            color: ${color.blue};
        }

        > div {
            width: 100%;
            height: 100%;
            border-top: 1px solid ${color.grey1};
        }
    }
`;

export const P = styled.p<{ config: Config }>`
    ${cssP};
    color: ${color.black};

    /* style override */
    ${({ config }) => config.styleOverride}
`;

export const MD = styled.div<{ config: Config }>`
    ${cssP};
    color: ${color.black};

    /* style override */
    ${({ config }) => config.styleOverride}
`;
