import { isNil as _isNil } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';

import Loading from '../../Loading';
import RedirectTo404 from '../../RedirectTo404';
import EntityList from '../EntityList';
import { useAdminDashboard } from '../../../hooks';

const AdminDashboard = () => {
    const [list, listLoading, listError] = useAdminDashboard();

    return (
        <>
            <Helmet>
                <title>Admin Dashboard | Decision 21</title>
            </Helmet>

            {/* loading... */}
            {listLoading && <Loading fullScreen={true} />}
            {/* error */}
            {listError && <>loading error: {listError}</>}
            {/* does not exist */}
            {!listLoading && !listError && _isNil(list) && <RedirectTo404 />}
            {/* exists */}
            {!_isNil(list) && <EntityList list={list} />}
        </>
    );
};
export default AdminDashboard;
