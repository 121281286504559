import { Country } from './interfaces';

const UnknownCountry: Country = {
    code: 'unknown',
    languageCode: 'unknown',
    flagIcon: '',
    name: 'unknown',
    localizedName: 'unknown',
    phoneCode: '',
    phoneExample: '',
};

export default UnknownCountry;
