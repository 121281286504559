import { Name as EntityName } from '../../../../core/entity';

const SELECTOR_PREFIX = `${EntityName.SELECTOR_PREFIX}APPLICATION:`;
export const CURRENT_LANGUAGE = `${SELECTOR_PREFIX}language`;
export const POPUP = `${SELECTOR_PREFIX}popup`;
export const SHUFFLE_GROUP = `${SELECTOR_PREFIX}shuffle_group`;
export const ROUTE = `${SELECTOR_PREFIX}route`;
export const ROUTE_MATCH = `${SELECTOR_PREFIX}route_match`;
export const BASE_URL = `${SELECTOR_PREFIX}base_url`;
export const IS_LOADING = `${SELECTOR_PREFIX}is_loading`;
