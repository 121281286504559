import { Name as EntityName } from '../../entity';

export const COMMAND_CLICK = 'cmd_click';

export type Command = typeof COMMAND_CLICK;

export interface Item {
    id: string;
    entity: EntityName.EntityName;
    title: string;
}

export type List = Record<string, Item>; // item id -> item
