import styled, { css } from 'styled-components';

import { Config } from './index';
import IconComp from '../../Icon';
import { cssH1 } from '../../../stylesheets/snippets';
import { color } from '../../../stylesheets/shared';
import { onPhone } from '../../../stylesheets/tools';

export const Icon = styled(IconComp)`
    font-size: 60px;
`;

export const Text = styled.div`
    display: inline;
`;

export const H1 = styled.h1<{ config: Config }>`
    position: relative;
    display: inline;
    ${cssH1};
    color: ${color.black};

    /* style override */
    ${({ config }) => config.styleOverride}

    ${onPhone(css`
        display: inline-block;
    `)}

    ${Text} {
        ${({ config }) =>
            config.breakOnPhone === true &&
            onPhone(css`
                display: block;
            `)}
    }

    ${Icon} {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -76px;

        ${({ config }) =>
            config.breakOnPhone === true &&
            onPhone(css`
                position: relative;
                display: inline-block;
                transform: none;
                left: 0;
                top: 0;
                margin-bottom: 8px;
            `)}
    }
`;

export const Wrapper = styled.div`
    position: relative;
`;
