import React, { useState } from 'react';

import { BlockProps, P as PConfig, PInlets, Tools } from '../../../../core/block';
import { interpolate } from '../../../../core/tools';
import { useInlets } from '../../../hooks';
import getMarkdown from '../../../tools/markdown';
import * as Styled from './styled';

export type Config = PConfig;
export type Props = BlockProps & Config;

const md = getMarkdown();

const P = (props: Props) => {
    const inlets = useInlets(props.inlets) as PInlets;
    const text = interpolate(props.text, { inlets }, { skipUnresolvedPlaceholders: true });

    const [isCollapsed, setIsCollapsed] = useState(true);

    const PlainParagraph = <Styled.P {...Tools.extractConfig<Config>(props)}>{text}</Styled.P>;
    const MarkdownParagraph = <Styled.MD {...Tools.extractConfig<Config>(props)} dangerouslySetInnerHTML={{ __html: md.renderInline(text) }} />;
    const Content = props.useMarkdown ? MarkdownParagraph : PlainParagraph;

    return props.collapsible ? (
        <Styled.Collapsible isCollapsed={isCollapsed} visibleRows={props.collapsible.visibleRows}>
            {Content}

            {isCollapsed && (
                <div className="expand" onClick={() => setIsCollapsed(false)}>
                    <div>{props.collapsible.expandText} ▼</div>
                </div>
            )}

            {!isCollapsed && (
                <div className="collapse" onClick={() => setIsCollapsed(true)}>
                    <div>{props.collapsible.collapseText} ▲</div>
                </div>
            )}
        </Styled.Collapsible>
    ) : (
        Content
    );
};

export default P;
