import { get as _get, isNil as _isNil, map as _map } from 'lodash';

import { Store } from '../interfaces';
import { Answer, Engine, Question } from '../../../../core/voting';
import { Status as ValidationStatus, Tools as ValidationTools } from '../../../../core/validation';

const reducer = (state: Store, questionId: string): Store => {
    const config: Question.Config | undefined = _get(state.question.config, [questionId]);
    if (_isNil(config)) {
        console.error(`Cannot get config for Question with ID:${questionId}.`);
        return state;
    }

    const answer: Answer.Answer | undefined = _get(state.vote, [questionId]);
    let validationStatus: string | boolean = false;

    if (config.type === Question.MethodType.SINGLE_VOTE) {
        validationStatus = Engine.singleVoteValidator(config, answer);
    } else if (config.type === Question.MethodType.MULTI_VOTE) {
        validationStatus = Engine.multiVoteValidator(config, answer);
    } else if (config.type === Question.MethodType.D21) {
        validationStatus = Engine.d21Validator(config, answer);
    } else if (config.type === Question.MethodType.COVER) {
        const c: Question.CoverQuestionConfig = config as Question.CoverQuestionConfig;

        // get validation of all embedded questions
        const embeddedQuestionValidations: ValidationStatus[] = _map(c.embeddedQuestionIds, (qId: string) =>
            _get(state.question.validationStatus, qId, false),
        );

        validationStatus = ValidationTools.mergeStatuses(embeddedQuestionValidations);
    }

    return {
        ...state,
        question: {
            ...state.question,
            validationStatus: {
                ...state.question.validationStatus,
                [questionId]: validationStatus,
            },
        },
    };
};

export default reducer;
