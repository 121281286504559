import { isNil as _isNil, get as _get } from 'lodash';

import { StoreSelectorFunction } from '../../../../core/selector';
import { RootState } from '../../root_reducer';
import { Store, Execution } from '../interfaces';
import getStore from './get_store';

export default (payload: string): StoreSelectorFunction<Execution | undefined> => (rootStore: RootState): Execution | undefined => {
    const store: Store = getStore(rootStore);
    if (_isNil(store)) {
        return;
    }

    return _get(store.plan, payload);
};
