import { Effect, Authentication as AuthenticationActions, create as createAction } from '../../../../core/action';
import { Tools as CoreAuthTools } from '../../../../core/authentication';
import { Streamer, Events } from '../../../../core/stream';
import { Auth as AuthTools } from '../../../tools';

export default function (payload: AuthenticationActions.signInWithPin.Payload): Effect {
    return async (dispatch: any): Promise<any> => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                // 1. if we need to check the whitelist, check it
                if (payload.checkWhitelist === true) {
                    const checkResult: boolean = await Streamer.stream(
                        Events.checkEntityWhitelist(payload.entityName, payload.entityId, payload.pin),
                    );

                    if (checkResult !== true) {
                        dispatch(createAction(AuthenticationActions.signInWithPinError.TYPE));
                        reject({ code: 'Pin is not whitelisted' }); // TODO
                        return;
                    }
                }

                // 2. create custom token
                const uid = CoreAuthTools.uidFromPin(payload.entityName, payload.entityId, payload.pin);
                const token = await Streamer.stream(Events.createCustomToken(uid));

                // 3. sign in with token
                await AuthTools.signInWithCustomToken(token);
                dispatch(createAction(AuthenticationActions.signInWithPinSuccess.TYPE));
                resolve();
            } catch (error) {
                dispatch(createAction(AuthenticationActions.signInWithPinError.TYPE));
                reject(error);
            }
        });
    };
}
