import React from 'react';

import { BlockProps, H1WithStatus as H1WithStatusConfig, Tools } from '../../../../core/block';
import * as Styled from './styled';

export type Config = H1WithStatusConfig;
export type Props = BlockProps & Config;

const H1 = (props: Props) => {
    return (
        <Styled.Wrapper>
            <Styled.H1 {...Tools.extractConfig<Config>(props)}>
                <Styled.Icon {...props.icon} />
                <Styled.Text>{props.text}</Styled.Text>
            </Styled.H1>
        </Styled.Wrapper>
    );
};
export default H1;
