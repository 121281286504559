import styled from 'styled-components';

import { color } from '../../../stylesheets/shared';
import { cssNoteP } from '../../../stylesheets/snippets';

export const ErrorMessage = styled.div`
    ${cssNoteP};
    width: 100%;
    color: ${color.red};
`;
