import { isNil as _isNil, toString as _toString } from 'lodash';
import styled, { css } from 'styled-components';

import { Config } from './index';
import { StandardVotingOptionInlets } from '../../../../core/block';
import { color, animation, meaningColor } from '../../../stylesheets/shared';
import { onPhone } from '../../../stylesheets/tools';
import { cssH3 } from '../../../stylesheets/snippets';
import { Choice } from '../../../../core/voting';

type OptionProps = {
    config: Config;
    usedInlets: StandardVotingOptionInlets;
};

const getSelectionColor = (p: OptionProps, selectedMeaning?: string): string => {
    // it's not selected
    if (_isNil(selectedMeaning)) {
        return 'transparent';
    }

    // theme override ?
    if (!_isNil(p.config.themeOverride)) {
        if (selectedMeaning === Choice.Meaning.PLUS && !_isNil(p.config.themeOverride['+']?.color)) {
            return _toString(p.config.themeOverride['+']?.color);
        }
        if (selectedMeaning === Choice.Meaning.MINUS && !_isNil(p.config.themeOverride['-']?.color)) {
            return _toString(p.config.themeOverride['-']?.color);
        }
    }

    // default color by meaning
    return selectedMeaning === Choice.Meaning.PLUS ? meaningColor.plus : meaningColor.minus;
};

export const Option = styled.div<OptionProps>`
    position: relative;
    padding: 24px;
    color: ${color.black};

    &:before {
        position: absolute;
        left: 0;
        top: 24px;
        bottom: 24px;
        width: 2px;
        content: '';
        display: block;
        transform: ${(p: OptionProps) => (_isNil(p.usedInlets.selectedMeaning) ? 'scaleY(0)' : 'scaleY(1.0)')};
        background-color: ${(p: OptionProps) => getSelectionColor(p, p.usedInlets.selectedMeaning)};
        transition: background-color ${animation.timing.standart} ease-out, transform ${animation.timing.standart} ease-out;
    }

    ${onPhone(css`
        padding: 24px 8px;
    `)}

    /* style override */
    ${(p: OptionProps) => p.config.styleOverride}
`;

export const Name = styled.h3`
    ${cssH3};
    color: ${color.black};
`;
