import { defer as _defer } from 'lodash';

import { Effect } from '../../../../core/action';

export default function (): Effect {
    return async (): Promise<void> => {
        _defer(() => {
            console.log('ʃ FLOW TEST: local async action executed');
        });
    };
}
