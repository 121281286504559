import { isNil as _isNil } from 'lodash';
import moment from 'moment';

const SK_LONG_DATE_OVERRIDE = 'DD. MM. YYYY';

export default (val: string): string => {
    if (_isNil(val)) {
        return val;
    }

    const localeData = moment.localeData();
    let dateFormat = localeData.longDateFormat('L');

    // HACK date format, protože @Dominik :)
    // https://trello.com/c/jm7F9Jta/63-1-a-2-uni-chyb%C3%AD-mezera-v-datech-za-te%C4%8Dkou-v-generovateln%C3%BDch-souborech
    if (moment.locale() === 'sk') {
        dateFormat = SK_LONG_DATE_OVERRIDE;
    }

    return moment(val).format(dateFormat);
};
