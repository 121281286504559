import TagManager from 'react-gtm-module';

import { Action } from '../../core/action';

const middleware = () => (next: any) => (action: Action<any>) => {
    // let next middelware do it's job first
    const result = next(action);

    // log the action to GTM data layer
    TagManager.dataLayer({
        dataLayer: {
            event: 'event_sent',
            category: action.type,
            // ...additionalData, ... TODO
        },
    });

    // just return previously generated result
    return result;
};

export default middleware;
