import { isNil as _isNil } from 'lodash';
import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';

import { stringIsEmpty } from '../../../core/tools';
import { StorageReference } from '../../../core/storage';

function useStorageJSON(ref?: StorageReference): [any | undefined] {
    const [data, setData] = useState<any>();

    useEffect(() => {
        if (_isNil(ref)) {
            setData(undefined);
            return;
        }

        firebase
            .storage()
            .ref(ref.path)
            .getDownloadURL()
            .then((url: string) => {
                // for some reason URL is not defined or empty
                if (_isNil(url) || stringIsEmpty(url)) {
                    setData(undefined);
                    return;
                }

                fetch(url)
                    .then((response) => response.json())
                    .then((data: any) => {
                        setData(data);
                    })
                    .catch(() => {
                        // something is wrong ... let's clear the data
                        setData(undefined);
                    });
            })
            .catch(() => {
                // something is wrong ... let's clear the data
                setData(undefined);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(ref)]);

    return data;
}

export default useStorageJSON;
