import { Voting as VotingActions } from '../../../../core/action';
import { Store } from '../interfaces';

const reducer = (state: Store, payload: VotingActions.questionConfig.Payload): Store => {
    return {
        ...state,
        question: {
            ...state.question,
            config: {
                ...state.question.config,
                [payload.q]: payload.config,
            },
        },
    };
};

export default reducer;
