import styled, { css } from 'styled-components';
import IconOriginal from '../Icon';
import { Config } from './index';
import { color, font, gradient } from '../../stylesheets/shared';
import { ifHoverIsSupported } from '../../stylesheets/tools';

export const DeleteIconPlaceholder = styled.div`
    order: 2;
    width: 18px;
    flex-shrink: 0;
`;

export const LinkIconCommon = css`
    position: absolute;
    text-align: center;
    left: 0;
    top: 0;
    margin: 18px 12px 12px 15px;
    text-align: center;
    width: 32px;
    height: 32px;
    font-size: 32px;
`;

export const LinkIcon = styled(IconOriginal)`
    ${LinkIconCommon}
    z-index: 0;
`;

export const OpenLinkIcon = styled(IconOriginal)`
    ${LinkIconCommon}
    z-index: 1;
    opacity: 0;
`;

export const MainLayout = styled.div<{ config: Config }>`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border: 1px solid ${color.grey3};
    padding: 1px;
    border-radius: 12px;
    width: 100%;

    ${ifHoverIsSupported(css`
        &:hover {
            ${LinkIcon} {
                filter: blur(8px);
                -webkit-filter: blur(8px);
            }
            ${OpenLinkIcon} {
                opacity: 1;
            }
            cursor: pointer;
            background: ${gradient.sweetLight};
        }
    `)}

    ${DeleteIconPlaceholder} {
        display: ${({ config }) => (config.enableDelete ? 'inherit' : 'none')};
    }

    /* style override */
    ${({ config }) => config.styleOverride}
`;

export const LinkIconContainer = styled.div`
    order: 0;
    flex-shrink: 0;
    width: 67px;
`;

export const LabelLayout = styled.div`
    order: 1;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    flex-shrink: 3;
    flex-grow: 1;
    min-height: 65px;
`;

export const Label = styled.div`
    font-family: ${font.family};
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 24px;

    p {
        font-family: ${font.family};
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        line-height: 24px;
        padding: 0;
        margin: 0;
    }
`;

export const DeleteIcon = styled(IconOriginal)`
    background: none;
    font-size: 19px;
    border-radius: 50%;
    width: 19px;
    height: 19px;
`;

export const DeleteIconContainer = styled.button`
    position: absolute;
    display: flex;
    width: 26px;
    height: 26px;
    justify-content: center;
    align-items: center;
    right: -8px;
    top: -8px;
    border-radius: 50%;
    border: 1px solid ${color.violet};
    color: black;
    background: white;
    cursor: pointer;

    &:hover {
        color: white;
        background: black;
        border-color: black;
    }
`;
