import styled, { css } from 'styled-components';

import { Config } from './index';
import { onPhone } from '../../../stylesheets/tools';

// Item
export const Item = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 12px 24px 0 24px;

    ${onPhone(css`
        padding: 12px 8px 0 8px;
    `)}
`;

// List
interface ListProps {
    config: Config;
}
export const List = styled.div<ListProps>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;

    /* style override */
    ${(p: ListProps) => p.config.styleOverride}

    ${onPhone(css`
        padding: 0;
    `)}
`;
