import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';

import { Website, Urls } from '../../../core/website';

function useWebsiteConfig(websiteId: string): [Website | undefined, boolean, any | undefined] {
    const [websiteConfig, websiteLoading, websiteError] = useObjectVal<Website>(firebase.database().ref(Urls.websiteConfig(websiteId)));

    return [websiteConfig, websiteLoading, websiteError];
}

export default useWebsiteConfig;
