import { isNil as _isNil } from 'lodash';
import { StoreSelectorFunction } from '../../../../core/selector';
import { RootState } from '../../root_reducer';
import { Store } from '../interfaces';
import getStore from './get_store';

export default (): StoreSelectorFunction<number | null> => (rootStore: RootState): number | null => {
    const store: Store = getStore(rootStore);

    if (_isNil(store)) {
        return null;
    }

    return store.pinsCount;
};
