import { get as _get } from 'lodash';

import { Store } from '../interfaces';
import { Form as FormActions } from '../../../../core/action';

const reducer = (state: Store, payload: FormActions.fieldChange.Payload): Store => {
    const newState: Store = {
        ...state,
        fields: {
            ...state.fields,
            validationStatus: {
                ...state.fields.validationStatus,
                [payload.f]: {
                    ..._get(state.fields.validationStatus, [payload.f]),
                    [payload.fn]: payload.vs,
                },
            },
            value: {
                ...state.fields.value,
                [payload.f]: {
                    ..._get(state.fields.value, [payload.f]),
                    [payload.fn]: payload.v,
                },
            },
        },
    };

    return newState;
};

export default reducer;
