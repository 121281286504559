import { cloneDeep as _cloneDeep, merge as _merge, forEach as _forEach } from 'lodash';

import { SchoolPb as SchoolPbActions } from '../../../../core/action';
import { Store } from '../interfaces';

const reducer = (state: Store, payload: SchoolPbActions.taskDone.Payload): Store => {
    const taskChange = {
        taskGroup: {
            [payload.taskGroupId]: {
                tasks: {
                    [payload.taskId]: { done: payload.done },
                },
            },
        },
    };

    let newState: Store = _merge(_cloneDeep(state), taskChange);

    const tasks = newState.taskGroup[payload.taskGroupId].tasks;
    let newCount = 0;
    _forEach(tasks, function (value) {
        if (value.done === true) {
            newCount = newCount + 1;
        }
    });

    const progressInfocChange = {
        taskGroup: {
            [payload.taskGroupId]: {
                taskProgressInfo: {
                    doneTasksCount: newCount,
                },
            },
        },
    };

    newState = _merge(newState, progressInfocChange);

    return newState;
};

export default reducer;
