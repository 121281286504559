import { Action } from '../interfaces';
import { Name as EntityName } from '../../entity';

export const TYPE = `${EntityName.ACTION_PREFIX}AUTHENTICATION:generate_pin_set`;

export interface Payload {
    pinCount: number;
    target: {
        type: string;
        id: string;
    };
    pinLength?: number;
    alphabet?: string;
    onDone?: Action<any> | Action<any>[];
    locale?: string;
    // information to be printed as a guidance on the PIN sheet
    authUrl?: string;
    startDate?: string;
    endDate?: string;
}
