import styled from 'styled-components';

import { color, font } from '../../stylesheets/shared';

export const Text = styled.div`
    color: ${color.black};
    font-family: ${font.family};
    font-weight: bold;
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    margin: 0;
    padding: 0;
`;

export const Rank = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: ${color.grey3};
    padding: 5px 7px 4px 7px;
    border-radius: 11px;
`;
