import moment from 'moment';

import { stringIsEmpty } from '../../../tools';

// NOTE ... it compares time inclusively ... it means if NOW is same as "from" or "to" ti returns true

export default (from: string | undefined, to: string | undefined, nowSeed?: string): boolean => {
    // empty timing definition should return true
    if (stringIsEmpty(from || '') && stringIsEmpty(to || '')) {
        return true;
    }

    const now = moment(nowSeed);
    const f = stringIsEmpty(from || '') ? '1918-10-28' : from;
    const t = stringIsEmpty(to || '') ? '2112-12-21' : to;

    return now.isBetween(f, t, undefined, '[]');
};
