import { reduce as _reduce, toString as _toString, isNil as _isNil } from 'lodash';
import React from 'react';
import getMarkdown from '../../../tools/markdown';

import { BlockProps, Accent as AccentConfig, AccentInlets, Tools } from '../../../../core/block';
import { useInlets } from '../../../hooks';
import { replaceSubstringByComponent } from '../../../tools';
import * as Styled from './styled';

export type Config = AccentConfig;
export type Props = BlockProps & Config;

const md = getMarkdown();

const Accent = (props: Props) => {
    const inlets = useInlets(props.inlets) as AccentInlets;

    let text = props.text;

    if (!_isNil(inlets.text)) {
        const textParts: any[] | undefined = replaceSubstringByComponent(text, '{{inlets.text}}', inlets.text);
        text = _reduce(textParts, (result: string, part: any | undefined) => `${result}${_toString(part)}`, '');
    }

    return (
        <>
            {/* Simple */}
            {!props.useMarkdown && <Styled.Accent {...Tools.extractConfig<Config>(props)}>{text}</Styled.Accent>}

            {/* Markdown */}
            {props.useMarkdown && (
                <Styled.Accent {...Tools.extractConfig<Config>(props)} dangerouslySetInnerHTML={{ __html: md.renderInline(text) }} />
            )}
        </>
    );
};
export default Accent;
