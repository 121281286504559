import styled, { css } from 'styled-components';
import { Steps as AntSteps } from 'antd';
import { Config } from './index';
import { isNil as _isNil } from 'lodash';

const ICON_SIZE = 32;
const FONT_SIZE = 16;
const DESCRIPTION_FONT_SIZE = 12;
const DESCRIPTION_PADDING_BOTTOM = 12;
const CONTENT_MIN_HEIGHT = 48;

const TAIL_PADDING_TOP = 38;
const TAIL_PADDING_BOTTOM = 6;
const TAIL_POS_LEFT = 16;

export const Step = styled(AntSteps.Step)<{ config: Config }>`
    &.ant-steps-item {
        .ant-steps-item-container {
            .ant-steps-item-icon {
                width: ${({ config }) => '' + ICON_SIZE * config.scale + 'px'};
                height: ${({ config }) => '' + ICON_SIZE * config.scale + 'px'};
                font-size: ${({ config }) => '' + FONT_SIZE * config.scale + 'px'};
                line-height: ${({ config }) => '' + ICON_SIZE * config.scale + 'px'};
                border-radius: ${({ config }) => '' + (ICON_SIZE * config.scale) / 2 + 'px'};
                ${({ config }) => {
                    if (!_isNil(config.color)) {
                        return css`
                            background: ${config.color};
                            border-color: ${config.color};
                        `;
                    }
                }};
            }

            .ant-steps-item-tail {
                padding: ${({ config }) => '' + TAIL_PADDING_TOP * config.scale + 'px 0 ' + TAIL_PADDING_BOTTOM * config.scale + 'px'};
                left: ${({ config }) => '' + TAIL_POS_LEFT * config.scale + 'px'};
                &:after {
                    ${({ config }) => {
                        if (!_isNil(config.color)) {
                            return css`
                                background: ${config.color};
                            `;
                        }
                    }};
                }
            }

            .ant-steps-item-content {
                min-height: ${({ config }) => '' + CONTENT_MIN_HEIGHT * (config.scale + 1) + 'px'};
                .ant-steps-item-title {
                    font-size: ${({ config }) => '' + FONT_SIZE * config.scale + 'px'};
                    line-height: ${({ config }) => '' + (FONT_SIZE * config.scale + 8) + 'px'};
                    ${({ config }) => {
                        if (!_isNil(config.color)) {
                            return css`
                                color: ${config.color};
                            `;
                        }
                    }};
                    .ant-steps-item-subtitle {
                        display: block;
                        margin-left: 0px;
                        font-weight: 800;
                        font-size: ${({ config }) => '' + FONT_SIZE * config.scale + 'px'};
                        ${({ config }) => {
                            if (!_isNil(config.color)) {
                                return css`
                                    color: ${config.color};
                                `;
                            }
                        }};
                    }
                }
                .ant-steps-item-description {
                    font-size: ${({ config }) => '' + DESCRIPTION_FONT_SIZE * config.scale + 'px'};
                    line-height: ${({ config }) => '' + (DESCRIPTION_FONT_SIZE * config.scale + 8) + 'px'};
                    padding-bottom: ${({ config }) => '' + DESCRIPTION_PADDING_BOTTOM * config.scale + 'px'};
                }
            }
        }
    }

    /* style override */
    ${({ config }) => config.styleOverride}
`;
