import { isNil as _isNil, defer as _defer } from 'lodash';
import React, { useState, useEffect } from 'react';
import * as Styled from './styled';

import { MapSearch as MapSearchConfig, Tools } from '../../../../core/block';
import { useOutlets, ValidationResult, getOutletValidationStatus, useInlets } from '../../../hooks';

// TODO refactor out
// https://usehooks.com/useDebounce/
function useDebounce(value: string, delay: number) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay], // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}

// bingmaps-specific
async function searchAddress(addressObj: Record<string, string>, culture = 'cs-CZ') {
    const addressUrlParams = new URLSearchParams(addressObj);
    const result = await fetch(
        `https://dev.virtualearth.net/REST/v1/Locations?${addressUrlParams.toString()}&culture=${culture}&key=AoJxInPTcJymI_Szi8DB_UDcIFt61XnA8H0xqSl47gCbWJsNvJCxEijKiLBQb4N0`,
    ).then((response) => response.json());

    // console.log('address result', result);

    return result;
}

function extractAddressResult(fullResultObj: any) {
    const results = fullResultObj.resourceSets[0].resources;
    return results;
}

async function autosuggestAddresses(partialAddress: string, culture = 'cs-CZ') {
    const addressParam = encodeURIComponent(partialAddress);
    const result = await fetch(
        `https://dev.virtualearth.net/REST/v1/Autosuggest?query=${addressParam}&culture=${culture}&key=AoJxInPTcJymI_Szi8DB_UDcIFt61XnA8H0xqSl47gCbWJsNvJCxEijKiLBQb4N0`,
    ).then((response) => response.json());

    // console.log('autosuggest result', result);

    return result;
}

function extractAutosuggestResult(fullResultObj: any) {
    const results = fullResultObj.resourceSets[0].resources[0].value;
    return results;
}

async function reverseGeocode(coordinates: any, culture = 'cz-CZ') {
    const coordinatesParam = `${coordinates.latitude},${coordinates.longitude}`;
    const result = await fetch(
        `https://dev.virtualearth.net/REST/v1/Locations/${coordinatesParam}?culture=${culture}&includeEntityTypes=address&key=AoJxInPTcJymI_Szi8DB_UDcIFt61XnA8H0xqSl47gCbWJsNvJCxEijKiLBQb4N0`,
    ).then((response) => response.json());

    // console.log('reverse geocode result', result);

    return result.resourceSets[0]?.resources[0]?.name;
}

export const MapSearchBar: React.FC<MapSearchConfig> = (props: MapSearchConfig) => {
    const [immediateSearchValue, setImmediateSearchValue] = useState('');
    const searchValue = useDebounce(immediateSearchValue, 500);

    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [locationLabel, setLocationLabel] = useState<any>();
    const [lastResolvedCoordinates, setLastResolvedCoordinates] = useState<any>();

    const addressCulture = props.addressFormat?.culture;

    useEffect(
        () => {
            if (searchValue) {
                autosuggestAddresses(searchValue, addressCulture).then((results) => {
                    setSearchResults(extractAutosuggestResult(results));
                });
            } else {
            }
        },
        [searchValue, addressCulture], // Only call effect if debounced search term changes
    );

    const handleChange = (val: string) => {
        setLocationLabel(val);
    };

    const handleSearchInput = (value: string) => {
        setImmediateSearchValue(value);
    };

    const runOutlets = useOutlets();
    const runChangeOutlet = (value: any, validationResult: ValidationResult) => {
        if (!props.outlets?.change) {
            return;
        }

        const outletParameters = {
            value,
            validationStatus: getOutletValidationStatus(validationResult),
        };

        _defer(() => runOutlets(props.outlets?.change, outletParameters));
    };

    const handleItemClick = (val: string, optionItemProps: Record<string, any>) => {
        // this is autosuggest result, which doesn't contain the geo coordinates
        // we now need to perform another query to get it
        searchAddress(optionItemProps.addressobj, addressCulture)
            .then(extractAddressResult)
            .then((result) => {
                if (result.length === 0) {
                    return;
                    // TODO handle this - now we silently let the error die
                }

                setLocationLabel(val);
                const coordinates = {
                    latitude: result[0].point.coordinates[0],
                    longitude: result[0].point.coordinates[1],
                };
                setLastResolvedCoordinates(coordinates);

                const location = {
                    label: val,
                    coordinates,
                };
                runChangeOutlet({ ...location, ...location.coordinates }, { isValid: true, antValidationStatus: 'success' });
            });
    };

    const inlets = useInlets(props.inlets);

    useEffect(() => {
        if (!props.inlets) {
            return;
        }

        if (Tools.isInletResolved(inlets.location)) {
            // console.log('map search: received inlet `location`. Value: ', inlets.location);

            if (inlets.location) {
                const coordinates = (inlets.location as any).coordinates;
                if (coordinates === lastResolvedCoordinates) {
                    return;
                }
                reverseGeocode(coordinates, addressCulture).then((address) => {
                    setLocationLabel(address);
                    setLastResolvedCoordinates(coordinates);
                    runChangeOutlet(
                        {
                            label: address,
                            coordinates,
                            ...coordinates,
                        },
                        { isValid: true, antValidationStatus: 'success' },
                    );
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets)]);

    return (
        <>
            {/* label */}
            {!_isNil(props.text) && <Styled.Label>{props.text}</Styled.Label>}

            <Styled.AutoComplete
                onSearch={handleSearchInput}
                onSelect={handleItemClick}
                value={locationLabel}
                onChange={handleChange}
                placeholder={props.placeholder}
            >
                {searchResults.map((result: any) => (
                    <Styled.AutoCompleteItem
                        key={result.address?.formattedAddress}
                        addressobj={result.address} // cannot be camel case, despite the prop types allowing it
                        value={result.address?.formattedAddress}
                    >
                        {result.address?.formattedAddress}
                    </Styled.AutoCompleteItem>
                ))}
            </Styled.AutoComplete>
        </>
    );
};
