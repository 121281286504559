import { isNil as _isNil, find as _find } from 'lodash';

import { Country } from '../interfaces';
import Countries from '../countries';

export default (phoneCode?: string): string => {
    if (_isNil(phoneCode)) {
        return '';
    }

    const country: Country | undefined = _find(Countries, (c: Country) => c.phoneCode === phoneCode);
    if (_isNil(country)) {
        return '';
    }
    return country.phoneExample;
};
