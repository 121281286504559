import { startsWith as _startsWith, isNil as _isNil } from 'lodash';

import { Meaning } from '../interfaces';

export default (choice?: string): Meaning | undefined => {
    if (_isNil(choice)) {
        return undefined;
    }

    if (_startsWith(choice, Meaning.PLUS)) {
        return Meaning.PLUS;
    }
    if (_startsWith(choice, Meaning.MINUS)) {
        return Meaning.MINUS;
    }

    return undefined;
};
