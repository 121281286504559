import { isNil as _isNil, toString as _toString } from 'lodash';
import styled from 'styled-components';

import { Config } from './index';
import { PortraitVotingOptionInlets } from '../../../../core/block';
import { color, animation, meaningColor } from '../../../stylesheets/shared';
import { cssH3 } from '../../../stylesheets/snippets';
import { Choice } from '../../../../core/voting';

type OptionProps = {
    config: Config;
    usedInlets: PortraitVotingOptionInlets;
};

const getSelectionColor = (p: OptionProps, selectedMeaning?: string): string => {
    // it's not selected
    if (_isNil(selectedMeaning)) {
        return 'transparent';
    }

    // theme override ?
    if (!_isNil(p.config.themeOverride)) {
        if (selectedMeaning === Choice.Meaning.PLUS && !_isNil(p.config.themeOverride['+']?.color)) {
            return _toString(p.config.themeOverride['+']?.color);
        }
        if (selectedMeaning === Choice.Meaning.MINUS && !_isNil(p.config.themeOverride['-']?.color)) {
            return _toString(p.config.themeOverride['-']?.color);
        }
    }

    // default color by meaning
    return selectedMeaning === Choice.Meaning.PLUS ? meaningColor.plus : meaningColor.minus;
};

export const Portrait = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 120px;

    &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color ${animation.timing.interactive} ease-out, transform ${animation.timing.interactive} ease-out;
    }
`;

export const SubPortrait = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    width: 44px;

    &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color ${animation.timing.interactive} ease-out, transform ${animation.timing.interactive} ease-out;
    }
`;

export const Name = styled.h3`
    ${cssH3};
    color: ${color.black};
    text-align: center;
    padding-top: 24px;
`;

export const Option = styled.div<OptionProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    /* style override */
    ${(p: OptionProps) => p.config.styleOverride}

    /* use correct image border based on inlets */
    ${Portrait} {
        &:before {
            transform: scale(1.1);
            background-color: ${(p: OptionProps) => getSelectionColor(p, p.usedInlets.selectedMeaning)};
        }
    }
    ${SubPortrait} {
        &:before {
            transform: scale(1.1);
            background-color: ${(p: OptionProps) => getSelectionColor(p, p.usedInlets.selectedMeaning)};
        }
    }
`;
