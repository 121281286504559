import React from 'react';

import { BlockProps, HorizontalLine as HorizontalLineConfig, Tools } from '../../../core/block';
import * as Styled from './styled';

export type Config = HorizontalLineConfig;
export type Props = BlockProps & Config;

const HorizontalLine = (props: Props) => {
    return <Styled.HorizontalLine {...Tools.extractConfig<Config>(props)} />;
};
export default HorizontalLine;
