import styled from 'styled-components';

import IconOriginal from '../../blocks/Icon';
import { color } from '../../stylesheets/shared';

const CIRCLE_BORDER_THICKNESS = 1;
const CIRCLE_DIAMETER = 32;
export const IconCircle = styled(IconOriginal)`
    color: black;
    background: white;
    width: ${CIRCLE_DIAMETER}px;
    height: ${CIRCLE_DIAMETER}px;
    min-width: ${CIRCLE_DIAMETER}px;
    border: ${CIRCLE_BORDER_THICKNESS}px solid ${color.violet};
    border-radius: 50%;
    font-size: 20px;
    line-height: ${CIRCLE_DIAMETER - 2 * CIRCLE_BORDER_THICKNESS}px;
    text-align: center;
    vertical-align: middle;
`;

const BORDER_THICKNESS = 1;

// generic template for further styling
export const BaseListItem = styled.div`
    border: ${BORDER_THICKNESS}px solid ${color.grey2};
    border-radius: 12px;
    color: ${color.black};
    background-color: ${color.white};
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 270px;
    height: ${62 + 2 * BORDER_THICKNESS}px;
    padding: 8px 12px;
    appearance: none;
    position: relative;
    margin-top: 6px;
    margin-bottom: 6px;
`;

export const ImageThumbnail = styled.img`
    display: block;
    width: 32px;
    max-height: 32px;
`;

export const IconThumbnail = styled(IconOriginal)`
    font-size: 32px;
    display: block;
    width: 32px;
`;

export const FileListItem = styled(BaseListItem)`
    justify-content: flex-start;

    > ${ImageThumbnail}, ${IconThumbnail} {
        margin-right: 8px;
    }
`;

// TODO this is most probably a generic control that is used everywhere in the platform
export const ListItemActionButton = styled.button`
    position: absolute;
    top: -8px;
    right: -8px;
    background: none;
    cursor: pointer;

    ${IconCircle}:hover {
        color: white;
        background: black;
        border-color: black;
    }
`;

export const Link = styled.a`
    color: ${color.blue};

    &:hover {
        text-decoration: underline;
        color: ${color.blue};
    }
`;
