import styled, { css } from 'styled-components';

import { onPhone } from '../../stylesheets/tools';
import { color, constant } from '../../stylesheets/shared';
import { cssA } from '../../stylesheets/snippets';

export const Content = styled.div`
    box-sizing: border-box;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`;

export const Page = styled.div`
    text-align: center;
    position: relative;
    width: 100%;
    max-width: ${constant.page.size.standard}px;
    box-sizing: border-box;
`;

export const TitleWrapper = styled.div`
    padding-top: 96px;

    ${onPhone(css`
        padding-top: 48px;
    `)}

    .grey {
        color: ${color.grey2};
    }
`;

export const ContentWrapper = styled.div`
    padding-top: 48px;

    .linkLike {
        ${cssA};
        cursor: pointer;
    }
`;
