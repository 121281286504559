import { isNil as _isNil, get as _get } from 'lodash';

import { Flow, FlowStep } from '../interfaces';

export default (flow?: Flow, stepId?: string): number => {
    if (_isNil(flow) || _isNil(flow.steps) || _isNil(stepId)) {
        return 0;
    }

    // step id is flow start
    if (flow.start === stepId) {
        return 1;
    }

    let stepIdx = 1;
    let currentStepName: string | undefined = flow.start;
    while (!_isNil(currentStepName) && currentStepName !== stepId) {
        const flowStep: FlowStep = _get(flow.steps, currentStepName);

        if (flowStep.excludeFromStepsCounts !== true) {
            stepIdx++;
        }

        currentStepName = flowStep.to;
    }

    return stepIdx;
};
