import styled, { css } from 'styled-components';

import { Config } from './index';
import { onPhone, onTablet } from '../../stylesheets/tools';

export const GeneralBlock = styled.div<{ config: Config }>`
    /* style override */
    ${({ config }) => config.styleOverride}

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;
