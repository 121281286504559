import styled, { css } from 'styled-components';

import { Config } from './index';
import { color, animation } from '../../stylesheets/shared';
import { cssDisableUserSelect } from '../../stylesheets/snippets';
import { ifHoverIsSupported /*, onPhone */ } from '../../stylesheets/tools';

type ButtonProps = {
    config: Config;
};

export const Button = styled.button<ButtonProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border: none;
    border-radius: 0;
    padding: 8px 32px;
    box-sizing: border-box;
    background-color: ${color.grey3};
    ${cssDisableUserSelect};
    transition: background-color ${animation.timing.interactive} ease-out, border-color ${animation.timing.interactive} ease-out,
        transform ${animation.timing.interactive} ease-out;

    /* style override */
    ${(p: ButtonProps) => p.config.styleOverride}

    ${ifHoverIsSupported(css`
        &:hover {
            /* hover style override */
            ${(p: ButtonProps) => p.config.hoverStyleOverride}
        }
    `)}

    &:active {
        transform: scale(0.97);
    }
`;
