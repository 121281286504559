import React from 'react';

import { BlockProps, CircularImage as CircularImageConfig, Tools } from '../../../core/block';
import * as Styled from './styled';

export type Config = CircularImageConfig;
export type Props = BlockProps & Config;

const Image = (props: Props) => {
    return <Styled.Image {...Tools.extractConfig<Config>(props)} />;
};
export default Image;
