import { isNil as _isNil, forEach as _forEach, indexOf as _indexOf, get as _get, size as _size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';

import { BlockProps, IconsSet } from '../../../../core/block';
import HorizontalSpace from '../../HorizontalSpace';
import Section from '../../Section';
import Loading from '../../../components/Loading';
import * as Styled from './styled';

export type Props = BlockProps;

const SENAT = [
    'brno-mesto_results',
    'ceska_lipa_results',
    'cheb_results',
    'decin_results',
    'frydek-mistek_results',
    'hradec_kralove_results',
    'karvina_results',
    'kladno_results',
    'kolin_results',
    'louny_results',
    'olomouc_results',
    'ostrava-mesto_results',
    'pelhrimov_results',
    'plzen-mesto_results',
    'praha_1_results',
    'praha_5_results',
    'praha_9_results',
    'prerov_results',
    'pribram_results',
    'rychnov_nad_kneznou_results',
    'strakonice_results',
    'trutnov_results',
    'uherske_hradiste_results',
    'vyskov_results',
    'zdar_nad_sazavou_results',
    'zlin_results',
    'znojmo_results',
];
const KRAJ = [
    'jihocesky_kraj_results',
    'jihomoravsky_kraj_results',
    'karlovarsky_kraj_results',
    'kraj_vysocina_results',
    'kralovehradecky_kraj_results',
    'liberecky_kraj_results',
    'moravskoslezsky_kraj_results',
    'olomoucky_kraj_results',
    'pardubicky_kraj_results',
    'plzensky_kraj_results',
    'stredocesky_kraj_results',
    'ustecky_kraj_results',
    'zlinsky_kraj_results',
];

const VolbyJinak2020OverallResults = (props: Props) => {
    const [res, loading, error] = useObjectVal(firebase.database().ref('/results'));
    const [krajRespondents, setKrajRespondents] = useState<number>(0);
    const [krajPlusVotes, setKrajPlusVotes] = useState<number>(0);
    const [krajMinusVotes, setKrajMinusVotes] = useState<number>(0);
    const [senatRespondents, setSenatRespondents] = useState<number>(0);
    const [senatPlusVotes, setSenatPlusVotes] = useState<number>(0);
    const [senatMinusVotes, setSenatMinusVotes] = useState<number>(0);

    const numberWithSpaces = (x: number) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    useEffect(() => {
        if (_isNil(res)) {
            return;
        }

        let _krajRespondents = 0;
        let _krajPlusVotes = 0;
        let _krajMinusVotes = 0;

        let _senatRespondents = 0;
        let _senatPlusVotes = 0;
        let _senatMinusVotes = 0;

        _forEach(res as any, (r: any, rn: string) => {
            const respondents = _get(r, ['__', 'respondents'], 0);
            let plus = 0;
            let minus = 0;

            // kraj
            if (_indexOf(KRAJ, rn) !== -1) {
                _krajRespondents += respondents;
                plus =
                    _get(r, ['result_1', 'config', 'votes', '+'], 0) +
                    _get(r, ['result_2', 'config', 'votes', '+'], 0) +
                    _get(r, ['result_3', 'config', 'votes', '+'], 0) +
                    _get(r, ['result_4', 'config', 'votes', '+'], 0);
                minus =
                    _get(r, ['result_1', 'config', 'votes', '-'], 0) +
                    _get(r, ['result_2', 'config', 'votes', '-'], 0) +
                    _get(r, ['result_3', 'config', 'votes', '-'], 0) +
                    _get(r, ['result_4', 'config', 'votes', '-'], 0);
                _krajPlusVotes += plus;
                _krajMinusVotes += minus;

                console.log('------------------------------------------');
                console.log(`%c ${rn}`, 'background: #bada55; color: #222; font-weight: bold; font-size: 14px');

                console.log(`%c 1. question (single vote):`, 'background: #222; color: #bada55');
                console.log(`     no. of choices -> ${_size(_get(r, ['result_1', 'blocks', '3', 'blocks', '0', 'barTemplates'], {}))}`);
                console.log(`     respondents    -> ${_get(r, ['result_1', 'config', 'respondents'], 0)}`);
                console.log(`     votes          -> +: ${_get(r, ['result_1', 'config', 'votes', '+'], 0)}`);

                console.log(`%c 2. question (D21):`, 'background: #222; color: #bada55');
                console.log(
                    `     no. of choices -> ${_size(_get(r, ['result_2', 'blocks', '3', 'blocks', '0', 'steps', 'd21', '0', 'barTemplates'], {}))}`,
                );
                console.log(`     respondents    -> ${_get(r, ['result_2', 'config', 'respondents'], 0)}`);
                console.log(
                    `     votes          -> +: ${_get(r, ['result_2', 'config', 'votes', '+'], 0)}, -: ${_get(
                        r,
                        ['result_2', 'config', 'votes', '-'],
                        0,
                    )}`,
                );

                console.log(`%c 3. question (single vote):`, 'background: #222; color: #bada55');
                console.log(`     no. of choices -> ${_size(_get(r, ['result_1', 'blocks', '3', 'blocks', '0', 'barTemplates'], {}))}`);
                console.log(`     respondents    -> ${_get(r, ['result_3', 'config', 'respondents'], 0)}`);
                console.log(`     votes          -> +: ${_get(r, ['result_3', 'config', 'votes', '+'], 0)}`);

                console.log(`%c 4. question (D21):`, 'background: #222; color: #bada55');
                console.log(
                    `     no. of choices -> ${_size(_get(r, ['result_2', 'blocks', '3', 'blocks', '0', 'steps', 'd21', '0', 'barTemplates'], {}))}`,
                );
                console.log(`     respondents    -> ${_get(r, ['result_4', 'config', 'respondents'], 0)}`);
                console.log(
                    `     votes          -> +: ${_get(r, ['result_4', 'config', 'votes', '+'], 0)}, -: ${_get(
                        r,
                        ['result_4', 'config', 'votes', '-'],
                        0,
                    )}`,
                );

                console.log(`%c Overall:`, 'background: #222; color: #bada55');
                console.log(`     respondents -> ${respondents}`);
                console.log(`     votes       -> +: ${plus}, -: ${minus}`);
            }
            // senat
            else if (_indexOf(SENAT, rn) !== -1) {
                _senatRespondents += respondents;
                plus = _get(r, ['result_1', 'config', 'votes', '+'], 0) + _get(r, ['result_2', 'config', 'votes', '+'], 0);
                minus = _get(r, ['result_1', 'config', 'votes', '-'], 0) + _get(r, ['result_2', 'config', 'votes', '-'], 0);
                _senatPlusVotes += plus;
                _senatMinusVotes += minus;

                console.log('------------------------------------------');
                console.log(`%c ${rn}`, 'background: #bada55; color: #222; font-weight: bold; font-size: 14px');

                console.log(`%c 1. question (single vote):`, 'background: #222; color: #bada55');
                console.log(`     no. of choices -> ${_size(_get(r, ['result_1', 'blocks', '3', 'blocks', '0', 'barTemplates'], {}))}`);
                console.log(`     respondents    -> ${_get(r, ['result_1', 'config', 'respondents'], 0)}`);
                console.log(`     votes          -> +: ${_get(r, ['result_1', 'config', 'votes', '+'], 0)}`);

                console.log(`%c 2. question (D21):`, 'background: #222; color: #bada55');
                console.log(`     no. of choices -> ${_size(_get(r, ['result_2', 'blocks', '3', 'blocks', '0', 'barTemplates'], {}))}`);
                console.log(`     respondents    -> ${_get(r, ['result_2', 'config', 'respondents'], 0)}`);
                console.log(
                    `     votes          -> +: ${_get(r, ['result_2', 'config', 'votes', '+'], 0)}, -: ${_get(
                        r,
                        ['result_2', 'config', 'votes', '-'],
                        0,
                    )}`,
                );

                console.log(`%c Overall:`, 'background: #222; color: #bada55');
                console.log(`     respondents -> ${respondents}`);
                console.log(`     votes       -> +: ${plus}, -: ${minus}`);
            }

            setKrajRespondents(_krajRespondents);
            setKrajPlusVotes(_krajPlusVotes);
            setKrajMinusVotes(_krajMinusVotes);

            setSenatRespondents(_senatRespondents);
            setSenatPlusVotes(_senatPlusVotes);
            setSenatMinusVotes(_senatMinusVotes);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(res)]);

    const krajskeVysledkyDetails = [
        {
            name: '$LAYOUT:table_like_list',
            alignContent: 'left',
            alignItems: 'top',
            rows: [
                {
                    blocks: [
                        { name: '$TYPOGRAPHY:p', text: 'Počet hlasujících' },
                        { name: '$TYPOGRAPHY:p', text: `**${numberWithSpaces(krajRespondents)}**`, useMarkdown: true },
                    ],
                },
                {
                    blocks: [
                        { name: '$TYPOGRAPHY:p', text: 'Počet hlasů' },
                        {
                            name: '$LAYOUT:horizontal',
                            blocks: [
                                {
                                    name: '$TYPOGRAPHY:p',
                                    text: `**${numberWithSpaces(krajPlusVotes + krajMinusVotes)}** (&nbsp;z toho&nbsp;&nbsp;`,
                                    useMarkdown: true,
                                },
                                { name: '$GRAPHIC:votes_count', count: krajPlusVotes, icon: { icon: 'add' }, meaning: '+' },
                                { name: '$TYPOGRAPHY:p', text: '&nbsp;&nbsp;a&nbsp;&nbsp;', useMarkdown: true },
                                { name: '$GRAPHIC:votes_count', count: krajMinusVotes, icon: { icon: 'remove' }, meaning: '-' },
                                { name: '$TYPOGRAPHY:p', text: '&nbsp;&nbsp;)', useMarkdown: true },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
    const senatniVysledkyDetails = [
        {
            name: '$LAYOUT:table_like_list',
            alignContent: 'left',
            alignItems: 'top',
            rows: [
                {
                    blocks: [
                        { name: '$TYPOGRAPHY:p', text: 'Počet hlasujících' },
                        { name: '$TYPOGRAPHY:p', text: `**${numberWithSpaces(senatRespondents)}**`, useMarkdown: true },
                    ],
                },
                {
                    blocks: [
                        { name: '$TYPOGRAPHY:p', text: 'Počet hlasů' },
                        {
                            name: '$LAYOUT:horizontal',
                            blocks: [
                                {
                                    name: '$TYPOGRAPHY:p',
                                    text: `**${numberWithSpaces(senatPlusVotes + senatMinusVotes)}** (&nbsp;z toho&nbsp;&nbsp;`,
                                    useMarkdown: true,
                                },
                                { name: '$GRAPHIC:votes_count', count: senatPlusVotes, icon: { icon: 'add' }, meaning: '+' },
                                { name: '$TYPOGRAPHY:p', text: '&nbsp;&nbsp;a&nbsp;&nbsp;', useMarkdown: true },
                                { name: '$GRAPHIC:votes_count', count: senatMinusVotes, icon: { icon: 'remove' }, meaning: '-' },
                                { name: '$TYPOGRAPHY:p', text: '&nbsp;&nbsp;)', useMarkdown: true },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
    const celkemVysledkyDetails = [
        {
            name: '$LAYOUT:table_like_list',
            alignContent: 'left',
            alignItems: 'top',
            rows: [
                {
                    blocks: [
                        { name: '$TYPOGRAPHY:p', text: 'Počet hlasujících' },
                        { name: '$TYPOGRAPHY:p', text: `**${numberWithSpaces(krajRespondents + senatRespondents)}**`, useMarkdown: true },
                    ],
                },
                {
                    blocks: [
                        { name: '$TYPOGRAPHY:p', text: 'Počet hlasů' },
                        {
                            name: '$LAYOUT:horizontal',
                            blocks: [
                                {
                                    name: '$TYPOGRAPHY:p',
                                    text: `**${numberWithSpaces(
                                        krajPlusVotes + krajMinusVotes + senatPlusVotes + senatMinusVotes,
                                    )}** (&nbsp;z toho&nbsp;&nbsp;`,
                                    useMarkdown: true,
                                },
                                { name: '$GRAPHIC:votes_count', count: krajPlusVotes + senatPlusVotes, icon: { icon: 'add' }, meaning: '+' },
                                { name: '$TYPOGRAPHY:p', text: '&nbsp;&nbsp;a&nbsp;&nbsp;', useMarkdown: true },
                                { name: '$GRAPHIC:votes_count', count: krajMinusVotes + senatMinusVotes, icon: { icon: 'remove' }, meaning: '-' },
                                { name: '$TYPOGRAPHY:p', text: '&nbsp;&nbsp;)', useMarkdown: true },
                            ],
                        },
                    ],
                },
            ],
        },
    ];

    return (
        <>
            {/* loading... */}
            {loading && <Loading fullScreen={true} />}
            {/* error */}
            {error && <>loading error: {error}</>}
            {/* done */}
            {!_isNil(res) && (
                <Styled.Wrapper {...props}>
                    <HorizontalSpace name="" size="48px" />
                    <Section
                        name=""
                        icon={{ name: '', icon: 'info', set: IconsSet.MATERIAL }}
                        text="Krajské výsledky"
                        blocks={krajskeVysledkyDetails}
                    />
                    <HorizontalSpace name="" size="24px" />
                    <Section
                        name=""
                        icon={{ name: '', icon: 'info', set: IconsSet.MATERIAL }}
                        text="Senátní výsledky"
                        blocks={senatniVysledkyDetails}
                    />
                    <HorizontalSpace name="" size="24px" />
                    <Section name="" icon={{ name: '', icon: 'info', set: IconsSet.MATERIAL }} text="Celkem" blocks={celkemVysledkyDetails} />
                    <HorizontalSpace name="" size="48px" />
                </Styled.Wrapper>
            )}
        </>
    );
};
export default VolbyJinak2020OverallResults;
