import {
    isString as _isString,
    flatten as _flatten,
    map as _map,
    isArray as _isArray,
    isFunction as _isFunction,
    split as _split,
    size as _size,
    isNil as _isNil,
} from 'lodash';

const replaceUsingString = (string: string, pattern: string, newValueOrFn: any) => {
    const index = string.indexOf(pattern);
    // pattern not found
    if (index < 0) {
        return [string];
    }

    const arr = [];
    const endIndex = index + pattern.length;

    if (index > 0) {
        arr.push(string.substring(0, index));
    }

    arr.push(_isFunction(newValueOrFn) ? newValueOrFn(string.substring(index, endIndex), index, string) : newValueOrFn);

    if (endIndex < string.length) {
        arr.push(string.substring(endIndex));
    }

    return arr;
};

const replaceAllUsingString = (string: string, pattern: string, newValueOrFn: any) => {
    const splittedString = _split(string, pattern);
    const numberOfPatternOccurences = _size(splittedString) - 1;
    // not found
    if (numberOfPatternOccurences === 0) {
        return [string];
    }

    const resArr: any[] = [];
    let stringToInspect = string;
    for (let i = 0; i < numberOfPatternOccurences; i++) {
        const replaceRes = replaceUsingString(stringToInspect, pattern, newValueOrFn);
        resArr.push(replaceRes[0]);
        resArr.push(replaceRes[1]);
        stringToInspect = replaceRes[2];
    }
    // push also the last
    if (!_isNil(stringToInspect)) {
        resArr.push(stringToInspect);
    }

    return resArr;
};

const replaceUsingRegexp = (string: string, regexp: any, newValueOrFn: any) => {
    const output = [];

    const storedLastIndex = regexp.lastIndex;
    regexp.lastIndex = 0;

    let result;
    let lastIndex = 0;
    while ((result = regexp.exec(string))) {
        const index = result.index;

        if (result[0] === '') {
            // When the regexp is an empty string
            // we still want to advance our cursor to the next item.
            // This is the behavior of String.replace.
            regexp.lastIndex++;
        }

        if (index !== lastIndex) {
            output.push(string.substring(lastIndex, index));
        }

        const match = result[0];
        lastIndex = index + match.length;

        const out = _isFunction(newValueOrFn) ? newValueOrFn(...result.concat(index, result.input)) : newValueOrFn;
        output.push(out);

        if (!regexp.global) {
            break;
        }
    }

    if (lastIndex < string.length) {
        output.push(string.substring(lastIndex));
    }

    regexp.lastIndex = storedLastIndex;
    return output;
};

const replace = (sourceString: string, regexpOrSubstr: any, newValueOrFn: any) => {
    const fn = _isString(regexpOrSubstr) ? replaceAllUsingString : replaceUsingRegexp;

    return fn(sourceString, regexpOrSubstr, newValueOrFn);
};

const stringReplaceToArray = (source: string | any[], regexpOrSubstr: any, newSubStrOrFn: any) => {
    // string
    if (_isString(source)) {
        return replace(source, regexpOrSubstr, newSubStrOrFn);
    }
    // array
    else if (_isArray(source)) {
        return _flatten(_map(source, (s: any) => (!_isString(s) ? s : replace(s, regexpOrSubstr, newSubStrOrFn))));
    }
};

export default stringReplaceToArray;
