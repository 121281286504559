import styled, { css } from 'styled-components';

import { cssP } from '../../../stylesheets/snippets';
import { color, meaningColor, animation } from '../../../stylesheets/shared';
import { onVerySmallPhone } from '../../../stylesheets/tools';
import { Config } from './index';

const getBarColor = (barSize: number): string => {
    if (barSize >= 0) {
        return meaningColor.plus;
    } else {
        return meaningColor.minus;
    }
};

// title line
export const Title = styled.div`
    flex-shrink: 1;
`;
export const Percentage = styled.div`
    ${cssP}
    font-weight: bold;
`;
// export const NumbersVotesWithLabel = styled.div`
//     ${cssP}
//     margin-left: 4px;

//     ${onVerySmallPhone(css`
//         display: none;
//     `)}
// `;
export const Votes = styled.div`
    ${cssP}
    margin-left: 4px;
    /* display: none; */

    /*${onVerySmallPhone(css`
        display: block;
    `)} */
`;
export const Numbers = styled.div`
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-left: 8px;
`;
export const TopLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

// bar line
interface BarProps {
    size: number;
}
export const Bar = styled.div<BarProps>`
    position: absolute;
    top: 12px;
    left: 50%;
    height: 8px;
    border-radius: 4px;
    background-color: ${(p: BarProps) => getBarColor(p.size)};
    box-sizing: border-box;
    width: ${(p: BarProps) => (Math.abs(p.size) > 1 ? `${Math.abs(p.size) / 2}%` : '1px')};
    transition: width ${animation.timing.standart} ease-out;

    ${(p: BarProps) => {
        if (p.size < 0) {
            return css`
                transform: rotate(180deg);
                transform-origin: 0% 50%;
            `;
        }
    }}
`;
export const BarLine = styled.div`
    position: relative;
    height: 32px;

    &:before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 1px;
        background-color: ${color.grey3};
    }
`;

export const SignBarGraph = styled.div<{ config: Config }>`
    /* style override */
    ${({ config }) => config.styleOverride}
`;
