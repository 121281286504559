import { defer as _defer } from 'lodash';
import { Effect, Browser as BrowserActions } from '../../../../core/action';

export default function (payload: BrowserActions.copyToClipboard.Payload): Effect {
    return async (): Promise<void> => {
        _defer(() => {
            navigator.clipboard.writeText(payload);
        });
    };
}
