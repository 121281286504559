import styled, { css } from 'styled-components';
import { Form as AntForm, Menu as AntMenu } from 'antd';
import hexToRgba from 'hex-to-rgba';

import { Config } from './index';
import { color, shadow, animation } from '../../../stylesheets/shared';
import { cssNoteP, cssP, cssInput, cssDisableUserSelect } from '../../../stylesheets/snippets';
import { ifHoverIsSupported } from '../../../stylesheets/tools';
import IconComp from '../../Icon';

const DEFAULT_TEXT_ALIGN = 'left';

// Form Field
export const FormItemLayout = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
`;

export const FormItem = styled(AntForm.Item)`
    margin: 0;

    .ant-input {
        ${cssInput};
        background-color: ${color.white};
        border-color: ${color.grey2};
        border-left: none;
        border-radius: 0 12px 12px 0;
        padding: 5px 12px;
        appearance: none;
        flex-shrink: 1;

        ${ifHoverIsSupported(css`
            &:hover {
                border-color: ${hexToRgba(color.blue, 0.48)};
            }
        `)}
        &:focus {
            box-shadow: none;
            border-color: ${color.blue};
        }
    }

    &.ant-form-item-has-feedback {
        .ant-form-item-children-icon {
            right: -32px;
        }
    }

    .ant-form-item-explain {
        ${cssNoteP};
        color: ${color.red};
        min-height: 0;
    }

    /* error */
    &.ant-form-item-has-error {
        .ant-input {
            border-color: ${color.red};

            &:focus {
                border-color: ${color.red};
            }
        }

        .ant-form-item-explain {
            ${cssNoteP};
            color: ${color.red};
        }
    }
`;

// Dropdown
export const DropdownMenu = styled(AntMenu)`
    border-radius: 12px;
    border: ${color.grey3} 1px solid;
    padding: 6px 0;
    box-shadow: ${shadow.standard};
`;
export const DropdownMenuItemIcon = styled(IconComp)`
    font-size: 20px;
    margin-right: 12px;
`;
export const DropdownMenuItemText = styled.div`
    ${cssP};
`;
export const DropdownMenuItemPrefix = styled.div`
    ${cssP};
    margin-left: 4px;
`;
export const DropdownMenuItem = styled(AntMenu.Item)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 24px 6px 12px;
    background-color: ${color.white};
    ${cssDisableUserSelect};

    ${ifHoverIsSupported(css`
        &:hover {
            background-color: ${color.grey3};
        }
    `)}
`;
export const DropdownIcon = styled(IconComp)`
    font-size: 24px;
    margin-right: 12px;
`;
export const DropdownPrefix = styled.div`
    ${cssInput};
    margin-right: 8px;
`;
export const DropdownArrow = styled(IconComp)`
    font-size: 24px;
    color: ${color.black};
    transform: rotate(0);
    transition: transform ${animation.timing.interactive} ease-out;
`;
interface DropdownProps {
    active: boolean;
    pasive?: boolean;
}
export const Dropdown = styled.div<DropdownProps>`
    cursor: pointer;
    background-color: ${({ active }) => (active ? color.grey2 : color.grey3)};
    box-sizing: border-box;
    border: ${color.grey2} 1px solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 12px 0 0 12px;
    padding: 0 4px 0 12px;
    transition: background-color ${animation.timing.interactive} ease-out;

    ${({ pasive }) =>
        pasive === true
            ? css`
                  cursor: default;
              `
            : ifHoverIsSupported(css`
                  &:hover {
                      background-color: ${color.grey2};
                  }
              `)}

    ${DropdownArrow} {
        transform: ${({ active }) => (active ? 'rotate(180deg)' : 'rotate(0)')};
    }
`;

// Label
export const Label = styled.div`
    ${cssP};
    font-weight: bold;
    margin-bottom: 2px;
`;

// Phone
export const Phone = styled.div<{ config: Config }>`
    box-sizing: border-box;
    width: 100%;

    /* style override */
    ${({ config }) => config.styleOverride}

    ${Label} {
        text-align: ${({ config }) => (config.align ? config.align : DEFAULT_TEXT_ALIGN)};
    }

    ${FormItem} {
        .ant-input {
            text-align: left;
        }
        .ant-form-item-explain {
            text-align: ${({ config }) => (config.align ? config.align : DEFAULT_TEXT_ALIGN)};
        }
    }
`;
