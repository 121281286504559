import * as Reducers from './reducers';
import { Store, initialStore } from './interfaces';
import { Poll, Results } from '../../../core/voting';
import { Action, Voting as VotingActions } from '../../../core/action';

const reduceChoice = (state: Store, payload: VotingActions.choice.Payload): Store => {
    let newState = Reducers.choice(state, payload);
    newState = Reducers.questionValidation(newState, payload.q);
    newState = Reducers.coverQuestionsValidation(newState, payload.q);
    return newState;
};

const reduceTransferChoice = (state: Store, payload: VotingActions.transferChoice.Payload): Store => {
    let newState = Reducers.transferChoice(state, payload);
    newState = Reducers.questionValidation(newState, payload.to);
    newState = Reducers.coverQuestionsValidation(newState, payload.to);
    return newState;
};

const reduceSetChoices = (state: Store, payload: VotingActions.setChoices.Payload): Store => {
    let newState = Reducers.setChoices(state, payload);
    newState = Reducers.questionValidation(newState, payload.q);
    newState = Reducers.coverQuestionsValidation(newState, payload.q);
    return newState;
};

const reduceQuestionEnter = (state: Store, payload: VotingActions.questionEnter.Payload): Store => {
    let newState = Reducers.questionEnter(state, payload);
    newState = Reducers.questionValidation(newState, payload.question.id);
    newState = Reducers.coverQuestionsValidation(newState, payload.question.id);
    return newState;
};

const reduceQuestionConfig = (state: Store, payload: VotingActions.questionConfig.Payload): Store => {
    let newState = Reducers.questionConfig(state, payload);
    newState = Reducers.questionValidation(newState, payload.q);
    newState = Reducers.coverQuestionsValidation(newState, payload.q);
    return newState;
};

const reducePollEnter = (state: Store, payload: VotingActions.pollEnter.Payload): Store => {
    let newState = Reducers.pollEnter(state, payload);
    const startStepId = Poll.Tools.getFlowStart(payload.poll.flow);
    newState = Reducers.pollStep(newState, startStepId);
    return newState;
};

const reduceResultsEnter = (state: Store, payload: VotingActions.resultsEnter.Payload): Store => {
    let newState = Reducers.resultsEnter(state, payload);
    const startStepId = Results.Tools.getFlowStart(payload.results.flow);
    newState = Reducers.resultsStep(newState, startStepId);
    return newState;
};

export default function (state: Store = initialStore, action: Action<any>) {
    switch (action.type) {
        case VotingActions.choice.TYPE:
            return reduceChoice(state, action.payload as VotingActions.choice.Payload);

        case VotingActions.transferChoice.TYPE:
            return reduceTransferChoice(state, action.payload as VotingActions.transferChoice.Payload);

        case VotingActions.setChoices.TYPE:
            return reduceSetChoices(state, action.payload as VotingActions.setChoices.Payload);

        case VotingActions.questionEnter.TYPE:
            return reduceQuestionEnter(state, action.payload as VotingActions.questionEnter.Payload);

        case VotingActions.questionConfig.TYPE:
            return reduceQuestionConfig(state, action.payload as VotingActions.questionConfig.Payload);

        case VotingActions.pollEnter.TYPE:
            return reducePollEnter(state, action.payload as VotingActions.pollEnter.Payload);

        case VotingActions.pollConfig.TYPE:
            return Reducers.pollConfig(state, action.payload as VotingActions.pollConfig.Payload);

        case VotingActions.pollStep.TYPE:
            const pollStepPayload = action.payload as VotingActions.pollStep.Payload;
            return Reducers.pollStep(state, pollStepPayload.stepId);

        case VotingActions.pollNextStep.TYPE:
            return Reducers.pollNextStep(state);

        case VotingActions.pollPreviousStep.TYPE:
            return Reducers.pollPreviousStep(state);

        case VotingActions.pollFirstStep.TYPE:
            return Reducers.pollFirstStep(state);

        case VotingActions.pollLastStep.TYPE:
            return Reducers.pollLastStep(state);

        case VotingActions.clearPoll.TYPE:
            return Reducers.clearPoll(state);

        // Results
        case VotingActions.resultsEnter.TYPE:
            return reduceResultsEnter(state, action.payload as VotingActions.resultsEnter.Payload);
        case VotingActions.resultsConfig.TYPE:
            return Reducers.resultsConfig(state, action.payload as VotingActions.resultsConfig.Payload);
        case VotingActions.resultsStep.TYPE:
            const resultsStepPayload = action.payload as VotingActions.resultsStep.Payload;
            return Reducers.resultsStep(state, resultsStepPayload.stepId);
        case VotingActions.resultsNextStep.TYPE:
            return Reducers.resultsNextStep(state);
        case VotingActions.resultsPreviousStep.TYPE:
            return Reducers.resultsPreviousStep(state);
        case VotingActions.resultsFirstStep.TYPE:
            return Reducers.resultsFirstStep(state);

        // Result
        case VotingActions.resultEnter.TYPE:
            return Reducers.resultEnter(state, action.payload as VotingActions.resultEnter.Payload);
        case VotingActions.resultConfig.TYPE:
            return Reducers.resultConfig(state, action.payload as VotingActions.resultConfig.Payload);
        case VotingActions.resultSetupCalculationMethod.TYPE:
            return Reducers.resultSetupCalculationMethod(state, action.payload as VotingActions.resultSetupCalculationMethod.Payload);

        default:
            return state;
    }
}
