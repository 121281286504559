import { isNil as _isNil } from 'lodash';
import styled, { css } from 'styled-components';

import { Config } from './index';
import { VerticalAlign, HorizontalAlign } from '../../../core/block';
import { onTablet, onPhone } from '../../stylesheets/tools';

const DEFAULT_ITEMS_ALIGN = 'flex-start';
const DEFAULT_CONTENT_ALIGN = 'flex-start';

const alignItems = (a: VerticalAlign): string => {
    switch (a) {
        case VerticalAlign.CENTER:
            return 'center';
        case VerticalAlign.TOP:
            return 'flex-start';
        case VerticalAlign.BOTTOM:
            return 'flex-end';
        case VerticalAlign.STRETCH:
            return 'stretch';
        case VerticalAlign.BASELINE:
            return 'baseline';
    }
};

const alignContent = (a: HorizontalAlign): string => {
    switch (a) {
        case HorizontalAlign.CENTER:
            return 'center';
        case HorizontalAlign.LEFT:
            return 'flex-start';
        case HorizontalAlign.RIGHT:
            return 'flex-end';
        case HorizontalAlign.STRETCH:
            return 'stretch';
    }
};

export const HorizontalLayout = styled.div<{ config: Config }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* items align */
    align-items: ${({ config }) => (config.alignItems ? alignItems(config.alignItems) : DEFAULT_ITEMS_ALIGN)};
    /* content align */
    justify-content: ${({ config }) => (config.alignContent ? alignContent(config.alignContent) : DEFAULT_CONTENT_ALIGN)};

    /* style override */
    ${({ config }) => config.styleOverride}

    /* all child items */
    ${({ config }) => {
        if (!_isNil(config.itemsMargin)) {
            return css`
                & > * {
                    margin: ${config.itemsMargin};
                }
            `;
        }
    }}

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(
                      css`
                          ${config.onTablet.styleOverride}
                      `,
                  )}
              `
            : ''}



    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;
