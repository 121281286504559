import { isNil as _isNil, toString as _toString } from 'lodash';
import styled, { css } from 'styled-components';

import { Config } from './index';
import { SimpleVotingOptionInlets } from '../../../../core/block';
import { color, animation, meaningColor } from '../../../stylesheets/shared';
import { onPhone } from '../../../stylesheets/tools';
import { cssH3 } from '../../../stylesheets/snippets';
import { Choice } from '../../../../core/voting';

type OptionProps = {
    config: Config;
    numberOfVoteButtons: number;
    usedInlets: SimpleVotingOptionInlets;
};

const getSelectionColor = (p: OptionProps, selectedMeaning?: string): string => {
    // it's not selected
    if (_isNil(selectedMeaning)) {
        return 'transparent';
    }

    // theme override ?
    if (!_isNil(p.config.themeOverride)) {
        if (selectedMeaning === Choice.Meaning.PLUS && !_isNil(p.config.themeOverride['+']?.color)) {
            return _toString(p.config.themeOverride['+']?.color);
        }
        if (selectedMeaning === Choice.Meaning.MINUS && !_isNil(p.config.themeOverride['-']?.color)) {
            return _toString(p.config.themeOverride['-']?.color);
        }
    }

    // default color by meaning
    return selectedMeaning === Choice.Meaning.PLUS ? meaningColor.plus : meaningColor.minus;
};

export const Option = styled.div<OptionProps>`
    position: relative;
    padding: 24px;
    color: ${color.black};
    display: flex;
    flex-direction: row;
    align-items: center;

    ${(p: OptionProps) =>
        p.numberOfVoteButtons > 1
            ? css`
                  ${onPhone(css`
                      flex-direction: column;
                      align-items: stretch;
                  `)}
              `
            : ``}

    &:before {
        position: absolute;
        left: 0;
        top: 24px;
        bottom: 24px;
        width: 2px;
        content: '';
        display: block;
        transform: ${(p: OptionProps) => (_isNil(p.usedInlets.selectedMeaning) ? 'scaleY(0)' : 'scaleY(1.0)')};
        background-color: ${(p: OptionProps) => getSelectionColor(p, p.usedInlets.selectedMeaning)};
        transition: background-color ${animation.timing.standart} ease-out, transform ${animation.timing.standart} ease-out;
    }

    ${onPhone(css`
        padding: 24px 8px;
    `)}

    /* style override */
    ${(p: OptionProps) => p.config.styleOverride}
`;

export const Name = styled.h3`
    ${cssH3};
    color: ${color.black};
    text-align: left;
    flex-grow: 1;
`;

type VoteButtonsProps = {
    numberOfVoteButtons: number;
};
export const VoteButtons = styled.div<VoteButtonsProps>`
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 24px;

    ${(p: VoteButtonsProps) =>
        p.numberOfVoteButtons > 1
            ? css`
                  ${onPhone(css`
                      flex-grow: 1;
                      padding-left: 0;
                      padding-top: 24px;
                      padding-left: 12px;
                      padding-right: 12px;
                      justify-content: space-between;
                  `)}
              `
            : ``}

    & > *:not(:last-child) {
        margin-right: 12px;

        ${onPhone(css`
            margin-right: 0;
        `)}
    }
`;
