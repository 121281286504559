import * as Reducers from './reducers';
import { Store, initialStore } from './interfaces';
import { Action, Form as FormActions } from '../../../core/action';

const reduceFieldChange = (state: Store, payload: FormActions.fieldChange.Payload): Store => {
    let newState = Reducers.fieldChange(state, payload);
    newState = Reducers.formValidation(newState, payload.f);
    return newState;
};

export default function (state: Store = initialStore, action: Action<any>) {
    switch (action.type) {
        case FormActions.fieldChange.TYPE:
            return reduceFieldChange(state, action.payload as FormActions.fieldChange.Payload);

        case FormActions.clearForm.TYPE:
            return Reducers.clearForm(state, action.payload as FormActions.clearForm.Payload);

        default:
            return state;
    }
}
