import { isNil as _isNil } from 'lodash';
import styled, { css } from 'styled-components';

import FullArrowLeftIcon from './custom/FullArrowLeft';
import FullArrowRightIcon from './custom/FullArrowRight';
import LinkIcon from './custom/LinkIcon';
import LinkMarkIcon from './custom/LinkMarkIcon';
import OpenLinkIcon from './custom/OpenLinkRight';
import PentagonIcon from './custom/Pentagon';
import FacebookIcon from './custom/Facebook';
import TwitterIcon from './custom/Twitter';
import YoutubeIcon from './custom/Youtube';
import SpotifyIcon from './custom/Spotify';
import GoogleIcon from './custom/Google';
import GoogleSolidIcon from './custom/GoogleSolid';
import OdnoklassnikiIcon from './custom/Odnoklassniki';
import VKontakteIcon from './custom/VKontakte';
import ViberIcon from './custom/Viber';
import TelegramIcon from './custom/Telegram';
import InstagramIcon from './custom/Instagram';
import FlagCzechRepublicIcon from './custom/FlagCzechRepublic';
import FlagBelarusIcon from './custom/FlagBelarus';
import FlagSwitzerlandIcon from './custom/FlagSwitzerland';
import { Config } from './index';
import { cssMaterialIcon, cssMaterialIconOutline, cssDisableUserSelect } from '../../stylesheets/snippets';
import { onPhone, onTablet } from '../../stylesheets/tools';

const DEFAULT_SIZE = 24;

// common icon style
const commonIconStyle = (config: Config) => {
    return css`
        ${cssDisableUserSelect}
        font-size: ${DEFAULT_SIZE}px;
        cursor: ${() => (_isNil(config.outlets?.click) ? 'default' : 'pointer')};

        ${() => config.styleOverride}

        /* on tablet */
        ${() =>
            config.onTablet
                ? css`
                      ${onTablet(css`
                          ${config.onTablet.styleOverride}
                      `)}
                  `
                : ''}

        /* on phone */
        ${() =>
            config.onPhone
                ? css`
                      ${onPhone(css`
                          ${config.onPhone.styleOverride}
                      `)}
                  `
                : ''}
    `;
};

// Material Icons
export const MaterialIcon = styled.i<{ config: Config }>`
    ${cssMaterialIcon}
    ${({ config }) => commonIconStyle(config)};
`;

// Material Outline Icons
export const MaterialOutlineIcon = styled.i<{ config: Config }>`
    ${cssMaterialIconOutline}
    ${({ config }) => commonIconStyle(config)};
`;

// Social Icons
export const Facebook = styled(FacebookIcon)<{ config: Config }>`
    color: #4267b2;
    ${({ config }) => commonIconStyle(config)};
`;
export const Twitter = styled(TwitterIcon)<{ config: Config }>`
    color: #1da1f2;
    ${({ config }) => commonIconStyle(config)};
`;
export const Youtube = styled(YoutubeIcon)<{ config: Config }>`
    color: #ff0000;
    ${({ config }) => commonIconStyle(config)};
`;
export const Spotify = styled(SpotifyIcon)<{ config: Config }>`
    color: #1dd05e;
    ${({ config }) => commonIconStyle(config)};
`;
export const Google = styled(GoogleIcon)<{ config: Config }>`
    ${({ config }) => commonIconStyle(config)};
`;
export const GoogleSolid = styled(GoogleSolidIcon)<{ config: Config }>`
    color: #000000;
    ${({ config }) => commonIconStyle(config)};
`;
export const Odnoklassniki = styled(OdnoklassnikiIcon)<{ config: Config }>`
    color: #f68220;
    ${({ config }) => commonIconStyle(config)};
`;
export const VKontakte = styled(VKontakteIcon)<{ config: Config }>`
    color: #5182ba;
    ${({ config }) => commonIconStyle(config)};
`;
export const Viber = styled(ViberIcon)<{ config: Config }>`
    color: #7360f2;
    ${({ config }) => commonIconStyle(config)};
`;
export const Telegram = styled(TelegramIcon)<{ config: Config }>`
    color: #33aade;
    ${({ config }) => commonIconStyle(config)};
`;
export const Instagram = styled(InstagramIcon)<{ config: Config }>`
    color: #cf0275;
    ${({ config }) => commonIconStyle(config)};
`;

// Custom Icons
export const FullArrowLeft = styled(FullArrowLeftIcon)<{ config: Config }>`
    ${({ config }) => commonIconStyle(config)};
`;
export const FullArrowRight = styled(FullArrowRightIcon)<{ config: Config }>`
    ${({ config }) => commonIconStyle(config)};
`;
export const Pentagon = styled(PentagonIcon)<{ config: Config }>`
    ${({ config }) => commonIconStyle(config)};
`;
export const Link = styled(LinkIcon)<{ config: Config }>`
    ${({ config }) => commonIconStyle(config)};
`;
export const OpenLink = styled(OpenLinkIcon)<{ config: Config }>`
    ${({ config }) => commonIconStyle(config)};
`;
export const LinkMark = styled(LinkMarkIcon)<{ config: Config }>`
    ${({ config }) => commonIconStyle(config)};
`;

// Flags
export const FlagCzechRepublic = styled(FlagCzechRepublicIcon)<{ config: Config }>`
    ${({ config }) => commonIconStyle(config)};
`;
export const FlagBelarus = styled(FlagBelarusIcon)<{ config: Config }>`
    ${({ config }) => commonIconStyle(config)};
`;
export const FlagSwitzerland = styled(FlagSwitzerlandIcon)<{ config: Config }>`
    ${({ config }) => commonIconStyle(config)};
`;
