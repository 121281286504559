import styled, { css } from 'styled-components';
import { Config } from './index';
import { onTablet, onPhone } from '../../stylesheets/tools';

export const SvgContainer = styled.div<{ config: Config }>`
    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      & > svg {
                          ${config.onTablet.styleOverride}
                      }
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      & > svg {
                          ${config.onPhone.styleOverride}
                      }
                  `)}
              `
            : ''}
`;

export const RegionPath = styled.path`
    color: #000000;
    clip-rule: nonzero;
    display: inline;
    overflow: visible;
    visibility: visible;
    opacity: 1;
    isolation: auto;
    mix-blend-mode: normal;
    color-interpolation: sRGB;
    color-interpolation-filters: linearRGB;
    solid-color: #000000;
    solid-opacity: 1;
    vector-effect: none;
    fill: #e9c6af;
    fill-opacity: 1;
    fill-rule: nonzero;
    stroke: none;
    stroke-width: 3.7795279;
    stroke-linecap: butt;
    stroke-linejoin: round;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
    stroke-dashoffset: 0;
    stroke-opacity: 1;
    marker: none;
    color-rendering: auto;
    image-rendering: auto;
    shape-rendering: auto;
    text-rendering: auto;
    enable-background: accumulate;
`;
