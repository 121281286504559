import { isNil as _isNil } from 'lodash';
import moment from 'moment';

export default (val: string): string => {
    if (_isNil(val)) {
        return val;
    }

    const localeData = moment.localeData();
    const dateFormat = localeData.longDateFormat('LT');
    return moment(val).format(dateFormat);
};
