import { Name as EntityName } from '../../entity';

export const TYPE = `${EntityName.ACTION_PREFIX}AUTHENTICATION:generate_pins`;

export interface Payload {
    numberOfPins: number;
    target: {
        type: string;
        id: string;
    };
    pinLength?: number;
    alphabet?: string;

    // information to be printed as a guidance on the PIN sheet
    authUrl?: string;
    startDate?: string;
    startDateFirestorePath?: string;
}
