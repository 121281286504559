import { isNil as _isNil, template as _template, replace as _replace } from 'lodash';
import React from 'react';
import { BlockProps, Timeline as TimelineConfig, Milestone, MilestoneInlets } from '../../../core/block';
import { useInlets } from '../../hooks';
import { Steps as AntSteps } from 'antd';
import * as Styled from './styled';

export type Config = TimelineConfig;
export type Props = BlockProps & Config;

interface MilestoneItemInterface {
    config: Props;
    milestoneData: Milestone;
}

export const MilestoneItem = (props: MilestoneItemInterface) => {
    const usedInlets = useInlets(props.milestoneData.inlets) as MilestoneInlets;

    const getText = (input: string | undefined): string | undefined => {
        let resText = input;
        if (!_isNil(input)) {
            const template = _template(_replace(input, new RegExp('inlets.', 'g'), ''), { interpolate: /{{([\s\S]+?)}}/g });
            resText = template(usedInlets);
        }
        return resText;
    };

    return (
        <Styled.Step
            config={props.config}
            title={getText(props.milestoneData.title)}
            subTitle={getText(props.milestoneData.subTitle)}
            description={getText(props.milestoneData.description)}
            status={props.config.markAllMilestones}
            key={props.milestoneData.title}
        />
    );
};

export const Timeline: React.FC<Props> = (props: Props) => {
    return (
        <AntSteps direction={props.direction}>
            {props.milestones.length > 0 && props.milestones.map((milestone) => MilestoneItem({ config: props, milestoneData: milestone }))}
        </AntSteps>
    );
};
