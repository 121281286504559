import styled, { css } from 'styled-components';

import { Config } from './index';
import { cssAccent } from '../../../stylesheets/snippets';
import { color } from '../../../stylesheets/shared';
import { ifHoverIsSupported, onPhone, onTablet } from '../../../stylesheets/tools';

export const Accent = styled.p<{ config: Config }>`
    ${cssAccent};
    color: ${color.black};

    a {
        ${cssAccent};
        color: ${color.blue};
        text-decoration: underline;

        ${ifHoverIsSupported(css`
            &:hover {
                text-decoration: none;
            }
        `)}
    }

    /* style override */
    ${({ config }) => config.styleOverride}

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;
