import { isNil as _isNil, mapValues as _mapValues, reduce as _reduce, isEmpty as _isEmpty } from 'lodash';

import { getDescriptor, InletType, SelectorDescriptor } from '../../../app/store/selector_translator';
import { Inlets, Inlet } from '../../block';
import { Selector, Modifier } from '..';
import { Tools } from '../../entity';

export default (selectors: any, store: any, location: any): any => {
    if (_isNil(selectors)) {
        return selectors;
    }
    const applyModifiers = (inlet?: any, modifiers?: Modifier.Modifier[], dataSource?: any): any => {
        if (_isNil(modifiers) || _isEmpty(modifiers)) {
            return inlet;
        }

        return _reduce(
            modifiers,
            (modifiedInlet: any, m: Modifier.Modifier) => {
                return Modifier.apply(modifiedInlet, m, dataSource);
            },
            inlet,
        );
    };

    const processSelector = (selector: Selector<any>): { value: any; dataSource: any } => {
        const selectorDescriptor: SelectorDescriptor = getDescriptor(selector);
        if (_isNil(selectorDescriptor)) {
            throw new Error(`Cannot find selector function`);
        }

        switch (selectorDescriptor.type) {
            case InletType.STORE: {
                return { value: selectorDescriptor.runnable(selector.payload)(store), dataSource: store };
            }
            case InletType.LOCATION: {
                return { value: selectorDescriptor.runnable(selector.payload)(location), dataSource: location };
            }
        }
    };

    return _mapValues(selectors, (inlet: Inlet) => {
        // inlet is Selector
        const selectorInlet: Selector<any> = inlet as Selector<any>;
        if (!_isNil(selectorInlet.type) && Tools.isItSelector(selectorInlet.type)) {
            const inletRes = processSelector(selectorInlet);
            return applyModifiers(inletRes.value, selectorInlet.modifiers, inletRes.dataSource);
        }
        throw new Error(`Cannot recognize type of inlet`);
    }) as Inlets;
};
