import { Name } from '../../core/entity';

// Poll
export const POLL_PREFIX = `${Name.COMPONENT_PREFIX}POLL:`;
export const STANDARD_POLL = `${POLL_PREFIX}standard`;
// Question
export const QUESTION_PREFIX = `${Name.COMPONENT_PREFIX}QUESTION:`;
export const STANDARD_QUESTION = `${QUESTION_PREFIX}standard`;
export const COVER_QUESTION = `${QUESTION_PREFIX}cover`;
export const EMBEDDED_QUESTION = `${QUESTION_PREFIX}embedded`;
// Results
export const RESULTS_PREFIX = `${Name.COMPONENT_PREFIX}RESULTS:`;
export const STANDARD_RESULTS = `${RESULTS_PREFIX}standard`;
// Result
export const RESULT_PREFIX = `${Name.COMPONENT_PREFIX}RESULT:`;
export const STANDARD_RESULT = `${RESULT_PREFIX}standard`;
export const SINGLE_RESULT = `${RESULT_PREFIX}single`;
