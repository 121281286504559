import { Name } from '../../core/entity';

// Website
export const WEBSITE_PREFIX = `${Name.COMPONENT_PREFIX}WEBSITE:`;
export const STANDARD_WEBSITE = `${WEBSITE_PREFIX}standard`;

// Page
export const PAGE_PREFIX = `${Name.COMPONENT_PREFIX}PAGE:`;
export const STANDARD_PAGE = `${PAGE_PREFIX}standard`;
export const POLL_PAGE = `${PAGE_PREFIX}poll`;
export const RESULTS_PAGE = `${PAGE_PREFIX}results`;

// Dialog
export const DIALOG_PREFIX = `${Name.COMPONENT_PREFIX}DIALOG:`;
export const STANDARD_DIALOG = `${DIALOG_PREFIX}standard`;
