import { trim as _trim, isNil as _isNil, get as _get } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
    Tools,
    Name as WebsiteName,
    StandardPage as StandardPageConfig,
    PollPage as PollPageConfig,
    ResultsPage as ResultsPageConfig,
} from '../../../core/website';
import { stringIsEmpty } from '../../../core/tools';
import { usePage } from '../../hooks';
import RedirectTo404 from '../../components/RedirectTo404';
import PollPage from './Poll';
import ResultsPage from './Results';
import StandardPage from './Standard';
import Loading from '../../components/Loading';

const Page = () => {
    const urlParams = useParams();
    const websiteId = Tools.getWebsiteIdFromParams(urlParams);
    const pageId = Tools.getPageIdFromParams(urlParams) || 'index';

    const [pageConfig, pageLoading, pageError] = usePage(websiteId, pageId);

    const isWebsiteIdValid = (): boolean => !_isNil(websiteId) && _trim(websiteId || '') !== '';
    const isPageIdValid = (): boolean => !_isNil(pageId) && _trim(pageId || '') !== '';
    // SEO
    const isSEOTitleValid = !_isNil(pageConfig) && !_isNil(pageConfig.head?.seo?.title) && !stringIsEmpty(pageConfig.head?.seo?.title || '');
    const isSEODescriptionValid =
        !_isNil(pageConfig) && !_isNil(pageConfig.head?.seo?.description) && !stringIsEmpty(pageConfig.head?.seo?.description || '');
    const isSEOKeywordsValid = !_isNil(pageConfig) && !_isNil(pageConfig.head?.seo?.keywords) && !stringIsEmpty(pageConfig.head?.seo?.keywords || '');
    // Sharing
    const isSharingTitleValid =
        !_isNil(pageConfig) && !_isNil(pageConfig.head?.sharing?.title) && !stringIsEmpty(pageConfig.head?.sharing?.title || '');
    const isSharingDescriptionValid =
        !_isNil(pageConfig) && !_isNil(pageConfig.head?.sharing?.description) && !stringIsEmpty(pageConfig.head?.sharing?.description || '');
    const isSharingImageValid =
        !_isNil(pageConfig) && !_isNil(pageConfig.head?.sharing?.image) && !stringIsEmpty(pageConfig.head?.sharing?.image || '');
    // favicon
    const isFaviconValid = !_isNil(pageConfig) && !_isNil(pageConfig.head?.favicon) && !stringIsEmpty(pageConfig.head?.favicon || '');

    // Deprecated ... this is legacy way to show page title
    const legacyPageTitle = _get(pageConfig, 'title');
    const useLegacyPageTitle = !_isNil(pageConfig) && !_isNil(legacyPageTitle);
    const getPageTitle = (): string => {
        if (!_isNil(pageConfig) && !_isNil(legacyPageTitle)) {
            return `${legacyPageTitle} | Decision 21`;
        }

        return `Decision 21`;
    };

    return (
        <>
            {/* some of Id is not valid */}
            {(!isWebsiteIdValid() || !isPageIdValid()) && <RedirectTo404 />}

            {/* download by give Id */}
            {isWebsiteIdValid() && isPageIdValid() && (
                <>
                    {/* loading... */}
                    {pageLoading && <Loading fullScreen={true} />}
                    {/* error */}
                    {pageError && <>loading error: {pageError}</>}
                    {/* does not exist */}
                    {!pageLoading && !pageError && _isNil(pageConfig) && <RedirectTo404 />}
                    {/* exists */}
                    {!_isNil(pageConfig) && (
                        <>
                            {/* Deprecated ... this is legacy way to show page title */}
                            {useLegacyPageTitle && (
                                <Helmet>
                                    <title>{getPageTitle()}</title>
                                </Helmet>
                            )}

                            {/* SEO head */}
                            <Helmet>
                                {isSEOTitleValid && <title>{pageConfig.head?.seo?.title}</title>}
                                {isSEODescriptionValid && <meta name="description" content={pageConfig.head?.seo?.description} />}
                                {isSEOKeywordsValid && <meta name="keywords" content={pageConfig.head?.seo?.keywords} />}
                            </Helmet>

                            {/* Sharing head */}
                            <Helmet>
                                {isSharingTitleValid && <meta property="og:title" content={pageConfig.head?.sharing?.title} />}
                                {isSharingTitleValid && <meta name="twitter:title" content={pageConfig.head?.sharing?.title} />}
                                {isSharingDescriptionValid && <meta property="og:description" content={pageConfig.head?.sharing?.description} />}
                                {isSharingDescriptionValid && <meta name="twitter:description" content={pageConfig.head?.sharing?.description} />}
                                {isSharingImageValid && <meta property="og:image" content={pageConfig.head?.sharing?.image} />}
                            </Helmet>

                            {/* Favicon */}
                            {!_isNil(isFaviconValid) && (
                                <Helmet>
                                    <link rel="icon" type="image/png" href={pageConfig.head?.favicon} />
                                    <link rel="apple-touch-icon" href={pageConfig.head?.favicon} />
                                </Helmet>
                            )}

                            {/* standard */}
                            {pageConfig.name === WebsiteName.STANDARD_PAGE && <StandardPage {...(pageConfig as StandardPageConfig)} />}
                            {/* poll */}
                            {pageConfig.name === WebsiteName.POLL_PAGE && <PollPage {...(pageConfig as PollPageConfig)} />}
                            {/* results */}
                            {pageConfig.name === WebsiteName.RESULTS_PAGE && <ResultsPage {...(pageConfig as ResultsPageConfig)} />}
                        </>
                    )}
                </>
            )}
        </>
    );
};
export default Page;
