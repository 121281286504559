import React from 'react';
import { isNil as _isNil, template as _template, replace as _replace } from 'lodash';
import getMarkdown from '../../../tools/markdown';
import { BlockProps, H1 as H1Config, Tools, TitleInlets } from '../../../../core/block';
import { useInlets } from '../../../hooks';
import * as Styled from './styled';

export type Config = H1Config;
export type Props = BlockProps & Config;

const md = getMarkdown();

const H1 = (props: Props) => {
    const usedInlets = useInlets(props.inlets) as TitleInlets;

    const getText = (input: string): string => {
        let resText = input;
        if (!_isNil(input)) {
            const template = _template(_replace(input, new RegExp('inlets.', 'g'), ''), { interpolate: /{{([\s\S]+?)}}/g });
            resText = template(usedInlets);
        }
        return resText;
    };

    return (
        <>
            {/* Simple */}
            {!props.useMarkdown && <Styled.H1 {...Tools.extractConfig<Config>(props)}>{getText(props.text)}</Styled.H1>}

            {/* Markdown */}
            {props.useMarkdown && (
                <Styled.H1 {...Tools.extractConfig<Config>(props)} dangerouslySetInnerHTML={{ __html: md.renderInline(getText(props.text)) }} />
            )}
        </>
    );
};
export default H1;
