import { default as matchAll } from 'string.prototype.matchall';
// official polyfill, see https://github.com/tc39/proposal-string-matchall

import { isNil as _isNil, get as _get } from 'lodash';

export interface InterpolationOptions {
    /** Handles e.g. multiline strings being interpolated into JSON */
    stringifyStrings?: boolean;
    skipUnresolvedPlaceholders?: boolean;
}

const DEFAULT_OPTIONS: InterpolationOptions = {
    stringifyStrings: true,
};

function interpolatePlaceholder(target: string, placeholder: string, value: any) {
    // const placeholderRegex = new RegExp(placeholder, 'g');
    // const result = target.replace(placeholderRegex, value);

    // TODO - ensure replaceAll (but not with regex, because it breaks on index accessors
    // e.g. prop[0].url
    const result = target.replace(placeholder, value);

    // console.log(`replaced placeholder ${placeholder} with value ${value}`)

    return result;
}

/** Adjusts value to be more suitable for textual interpolation */
export function adjustValueForInterpolation(value: any, options: InterpolationOptions) {
    if (typeof value === 'object') {
        return JSON.stringify(value);
    }

    if (typeof value === 'string') {
        if (options.stringifyStrings) {
            const stringified = JSON.stringify(value);

            // Now we need to strip the enclosing double quotes,
            // because in templates, we need to handle several use-cases,
            // where it's better to add the encloses directly as part of the template
            // rather than to depend on the value to already have it.
            //
            // For example here, we could either use the enclosers that are included in the strigified value (a)
            // or we can strip them and have them explicitly in the template (b)
            // a) "text": {{title}} => "text": "asdf" -- this is ok
            // b) "text": "{{title}}", => "text": "asdf" -- this is ok
            //
            // However, in the following example, only the approach with stripping the enclosers will result in
            // the desired outcome (a),
            // because if we also let the value be interpolated with the enclosers from stringification (b),
            // it would break the quotes pair:
            // a) "text": "Proposal description: {{description}}" => "text": "Proposal description: asdf" -- this is ok
            // b) "text": "Proposal description: {{description}}" => "text": "Proposal description: "asdf"" -- this is NOT OK, because there are unescaped and undesirable redundant quotes

            return stringified.substring(1, stringified.length - 1);
        }
        return value;
    }

    return value;
}

// Regex, that matches all the intepolation placeholders with the following syntax:
// {{ myVar }}
// {{ myVar.someNestedProp }}
// {{ myArray[0] }}
// {{ myArray[0].someNestedProp }}
const standardPlaceholderRegex = /{{(!?) ?(\w+((\.\w+)|(\[\w+\]))*) ?}}/g; // umi i []

/** Finds all interpolation placeholders within the target string.
 *
 * Returns a result of fully exhausted iterator, as per https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/matchAll */
export function findStandardPlaceholders(target: string) {
    const matches = [...matchAll(target, standardPlaceholderRegex)];
    return matches;
}

// Regex, that matches all the intepolation placeholders with the following syntax:
// "{{{ myVar }}}"
// "{{{ myVar.someNestedProp }}}"
// "{{{ myArray[0] }}}"
// "{{{ myArray[0].someNestedProp }}}"
const quotedPlaceholderRegex = /"{{{(!?) ?(\w+((\.\w+)|(\[\w+\]))*) ?}}}"/g;

/** Finds all quoted interpolation placeholders within the target string.
 *
 * This is used in cases, where we also want replace the enclosing quotes.
 * the quotes are undesirable in the final result of interpolation, because
 * the expected value to be interpolated is not a string, but e.g. boolean, array, etc.
 * However, the quotes are neccessary at the design-time, to enclose the placeholder,
 * so that the template definition is a valid JSON.
 *
 * Example:
 * ```
 * // this is NOT a valid JSON
 * {
 *   myProp: {{ myPlaceholder }}
 * }
 *
 * // now, this IS a valid JSON
 * {
 *   myProp: "{{{ myPlaceholder }}}"
 * }
 *
 * // and after the interpolation, we get e.g. this:
 * {
 *   myProp: true
 * }
 * ```
 * Returns a result of fully exhausted iterator, as per https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/matchAll */
export function findQuotedPlaceholders(target: string) {
    const matches: RegExpMatchArray[] = [...matchAll(target, quotedPlaceholderRegex)];
    return matches;
}

// Extracts the actual path from the placeholder,
// which effectively is the first capturing group,
// given the current design of the regex.
function getPlaceHolderContent(regexMatch: RegExpMatchArray) {
    return regexMatch[2];
}

function shouldIgnoreOnce(regexMatch: RegExpExecArray) {
    return regexMatch[1];
}

function getPlaceholderAfterIgnoring(placeholder: string) {
    return placeholder.replace('{!', '{');
}

export function interpolate(template: string, data: Record<string, any>, options: InterpolationOptions = {}) {
    const _options = { ...DEFAULT_OPTIONS, ...options };

    let result = template;

    const placeholders = [...findQuotedPlaceholders(template), ...findStandardPlaceholders(template)];
    for (const placeholderInfo of placeholders) {
        const placeholder = placeholderInfo[0];

        if (shouldIgnoreOnce(placeholderInfo)) {
            const newPlaceholder = getPlaceholderAfterIgnoring(placeholder);
            result = interpolatePlaceholder(result, placeholder, newPlaceholder);
        } else {
            const dataPath = getPlaceHolderContent(placeholderInfo);
            const dataValue = _get(data, dataPath, null);
            if (!_isNil(options.skipUnresolvedPlaceholders) && options.skipUnresolvedPlaceholders === true && _isNil(dataValue)) {
                continue;
            }
            const adjustedDataValue = adjustValueForInterpolation(dataValue, _options);
            result = interpolatePlaceholder(result, placeholder, adjustedDataValue);
        }
    }

    return result;
}
