import { isNil as _isNil, isEqual as _isEqual, get as _get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useInlets } from '../../../hooks';
import { Block, BlockProps, Tools, SwitchInlets, Switch as SwitchConfig } from '../../../../core/block';
import Blocks from '../../Blocks';

export type Config = SwitchConfig;
export type Props = BlockProps & Config;

const Switch = (props: Props) => {
    const inlets = useInlets(props.inlets) as SwitchInlets;
    const [actualStep, setActualStep] = useState('defaultCase');

    useEffect(() => {
        // we are not expecting any inlet
        if (_isNil(props.inlets)) {
            return;
        }

        // is inlet already resolved?
        if (Tools.isInletResolved(inlets.case)) {
            // ... yes value is defined, and it's different then cached value -> use it
            if (!_isNil(inlets.case) && !_isEqual(inlets.case, actualStep)) {
                // use it as cached value ...
                setActualStep(inlets.case);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets.case)]);

    const getBlocks = (): Block[] | undefined => {
        return _get(props.cases, actualStep);
    };

    return (
        <>
            {/* stored step exists */}
            <Blocks blocks={getBlocks()} />
        </>
    );
};
export default Switch;
