import { isNil, isNil as _isNil } from 'lodash';
import React, { useState, SyntheticEvent } from 'react';

import { Input as AntInput } from 'antd';
import Button from '../../../blocks/SecondaryCTA';
import { VERTICAL_SPACE } from '../../../../core/block/name';

import { BlockProps, Tools, FormFieldInputBox as FormFieldInputBoxConfig, SecondaryCTA } from '../../../../core/block';
import { useOutlets, ValidationResult, useStringValidation, VALIDATION_RESULT_UNTOUCHED_NEGATIVE } from '../../../hooks';
import * as Styled from './styled';
// import TextArea from 'antd/lib/input/TextArea';

const AntTextArea = AntInput.TextArea;

export type Config = FormFieldInputBoxConfig;
export type Props = BlockProps &
    Config & {
        onSubmit?: (value: string | undefined, validationResult: ValidationResult) => void;
    };

const InputBoxFormField = (props: Props) => {
    const outlet = useOutlets();
    const [validationResult, setValidationResult] = useState(VALIDATION_RESULT_UNTOUCHED_NEGATIVE);
    const [cachedValue, setCachedValue] = useState(props.input.defaultValue);
    const validate = useStringValidation(props.input.validationScheme || {});

    let mainInput: any;

    const runSubmitOutlet = (value: string | undefined, validationResult: ValidationResult) => {
        if (!props.outlets?.submit || isNil(value)) {
            return;
        }

        const nonEmptyRows = value
            .split('\n')
            .map((v) => v.trim())
            .filter((v) => v.length !== 0);

        for (const rowValue of nonEmptyRows) {
            if (validationResult.isValid) {
                props.onSubmit && props.onSubmit(rowValue, validationResult);
                const outletParameters = {
                    value: rowValue,
                    validationStatus: validationResult,
                };
                outlet(props.outlets?.submit, outletParameters);
            }
        }
    };

    const clearInput = () => {
        setCachedValue(undefined);
        setValidationResult(VALIDATION_RESULT_UNTOUCHED_NEGATIVE);
        // set focus - handles case when button was used for submit
        if (!_isNil(mainInput)) {
            mainInput.setValue('');
            mainInput.focus();
        }
    };

    const processValidation = async (value: string): Promise<ValidationResult> => {
        const validationResult = await validate(value);
        setValidationResult(validationResult);

        return validationResult;
    };

    const handleChange = async (event: SyntheticEvent) => {
        // get value from input
        const value = (event.target as HTMLTextAreaElement).value;

        // validate the value
        processValidation(value).then((validationResult) => {
            setValidationResult(validationResult);
            setCachedValue(value);
        });
    };

    const submit = (value: string | undefined, validationResult: ValidationResult) => {
        if (!_isNil(props.onSubmit)) {
            props.onSubmit(value, validationResult);
        }

        if (!_isNil(value)) {
            runSubmitOutlet(value, validationResult);
        }

        clearInput();
    };

    const handleBtnSubmit = () => {
        submit(cachedValue, validationResult);
    };

    const handleSubmit = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
        submit(cachedValue, validationResult);
        event.preventDefault();
    };

    return (
        <Styled.InputBox {...Tools.extractConfig<Config>(props)}>
            {/* input */}
            <Styled.String {...Tools.extractConfig<Config>(props)} onChange={handleChange}>
                <AntTextArea
                    placeholder={props.input.placeholder}
                    autoFocus={props.input.autoFocus}
                    maxLength={props.input.maxLength}
                    ref={(input) => {
                        mainInput = input;
                    }}
                    onPressEnter={handleSubmit}
                    autoSize={props.input.autoSize}
                    autoComplete="off"
                />
            </Styled.String>

            <Styled.Space name={VERTICAL_SPACE} size="15px" />
            <Button onClick={handleBtnSubmit} isEnabled={validationResult.isValid} {...Tools.extractConfig<SecondaryCTA>(props.button).config} />
        </Styled.InputBox>
    );
};

export default InputBoxFormField;
