import { isNil as _isNil } from 'lodash';
import React, { useEffect } from 'react';

import RedirectTo404 from '../../../components/RedirectTo404';
import Loading from '../../../components/Loading';
import StandardPage from '../../../components/Page/Standard';
import StandardQuestion from '../../../components/Question/Standard';
import CoverQuestion from '../../../components/Question/Cover';
import { usePollStep } from '../../../hooks';
import { Name as VotingName, Question } from '../../../../core/voting';
import { Name as WebsiteName, StandardPage as StandardPageConfig } from '../../../../core/website';

interface Props {
    pollId: string;
    stepId: string;
}

const PollStep = (props: Props) => {
    const [stepData, stepLoading, stepError] = usePollStep(props.pollId, props.stepId);

    // step change
    useEffect(() => {
        // start every step from top
        window.scrollTo(0, 0);
    }, [props.stepId]);

    return (
        <>
            {/* loading... */}
            {stepLoading && <Loading fullScreen={true} />}
            {/* error */}
            {stepError && <>loading error: {stepError}</>}
            {/* does not exist */}
            {!stepLoading && !stepError && _isNil(stepData) && <RedirectTo404 />}
            {/* exists */}
            {!_isNil(stepData) && (
                <>
                    {/* standard page */}
                    {stepData.name === WebsiteName.STANDARD_PAGE && <StandardPage {...(stepData as StandardPageConfig)} />}
                    {/* standard question */}
                    {stepData.name === VotingName.STANDARD_QUESTION && <StandardQuestion {...(stepData as Question.StandardQuestion)} />}
                    {/* cover question */}
                    {stepData.name === VotingName.COVER_QUESTION && <CoverQuestion {...(stepData as Question.CoverQuestion)} />}
                </>
            )}
        </>
    );
};
export default PollStep;
