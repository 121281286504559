import { isNil as _isNil } from 'lodash';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import configuration from '../../configuration/configuration.json';
import middleware from './middleware';
import rootReducer, { RootState } from './root_reducer';
import plannerMiddleware from './planner/middleware';
import applicationMiddleware from './application/middleware';
import gtmMiddleware from './gtm_middleware';
import { getSavedStateSession } from './application/middleware';

let store: any = undefined;

export const configure = () => {
    if (_isNil(store)) {
        // prepare initial redux store state
        const savedStateSession = getSavedStateSession() || {};

        //prepare common middlewares
        const middlewares = [middleware, plannerMiddleware, applicationMiddleware, gtmMiddleware];

        // use Redux Dev Tools
        if (configuration.useReduxDevTools === true) {
            store = createStore(rootReducer, savedStateSession, composeWithDevTools({ trace: true })(applyMiddleware(...middlewares)));
        } else {
            store = createStore(rootReducer, savedStateSession, applyMiddleware(...middlewares));
        }
    }
};

export const getStore = () => {
    return store;
};

export const getState = (): RootState => {
    return store.getState();
};
