import styled from 'styled-components';
import { Affix as AntAffix } from 'antd';
import { Config } from './index';

export const Affix = styled(AntAffix)<{ config: Config }>`
    /* style override */
    ${({ config }) => config.styleOverride}

    @media print {
        ${({ config }) => config.printStyleOverride}
    }
`;
