import { Action } from '../interfaces';
import { Name as EntityName } from '../../entity';

export const TYPE = `${EntityName.ACTION_PREFIX}AUTHENTICATION:sign_in_with_invitation`;

export interface Payload {
    entityName: string;
    entityId: string;
    checkWhitelist?: boolean;
    success?: Action<any> | Action<any>[];
    fail?: Action<any> | Action<any>[];
}
