import { get as _get, isNil as _isNil } from 'lodash';
import React from 'react';
import { Redirect } from 'react-router-dom';

import RedirectTo404 from '../../components/RedirectTo404';

const redirectMap = {
    'prezident21by.org': '/bp2020',
    'dikyhrdinum.cz': '/diky-hrdinum',
    'hlasovani.signalyawards.cz': '/signaly-awards-2020',
    'anketavsenory2020.decision21.org': '/anketavsenory2020',
    'volbyjinak.cz': '/volby-2020',
    'www.volebnitrenazer.cz': '/volby-2020',
    'volebnitrenazer.cz': '/volby-2020',
    'www.cernosickasance.cz': '/cernosicka-sance-2020',
    'cernosickasance.cz': '/cernosicka-sance-2020',
    'www.tvorimejilovsko.cz': '/tvorime-jilovsko-2020',
    'tvorimejilovsko.cz': '/tvorime-jilovsko-2020',
    'napadprobechovice.cz': '/napad-pro-bechovice',
    'www.napadprobechovice.cz': '/napad-pro-bechovice',
    'prekvaptericany.cz': '/prekvapte-ricany-v',
    'www.prekvaptericany.cz': '/prekvapte-ricany-v',
    'www.bezsvobodyslova.cz': '/bez-svobody-slova-nebude',
    'bezsvobodyslova.cz': '/bez-svobody-slova-nebude',
    'tvorime.dobris.cz': '/tvorime-dobris-2021',
};

const Index = () => {
    const getRedirect = (): string | undefined => {
        return _get(redirectMap, window.location.hostname);
    };

    return (
        <>
            {/* redirect */}
            {!_isNil(getRedirect()) && <Redirect to={getRedirect() || ''} />}

            {/* redirect to 404 */}
            {_isNil(getRedirect()) && <RedirectTo404 />}
        </>
    );
};
export default Index;
