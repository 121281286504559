import styled, { keyframes, css } from 'styled-components';

import { gradient } from '../../stylesheets/shared';
import { Props } from './index';

const SIZE = 8;
const DISTANCE = [0.5, 1, 1.5];

export const Wrapper = styled.div<Props>`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const Dots = styled.div`
    position: relative;
    height: ${SIZE}px;
    transform: translateX(-4px);
`;

const cssDot = css`
    position: absolute;
    font-size: ${SIZE / 3}px;
    width: ${SIZE}px;
    height: ${SIZE}px;
    background: ${gradient.sweet};
    border-radius: 50%;
    animation-fill-mode: forwards;
`;

const Dot1Anim = keyframes`
    25% {transform: translateX(-${DISTANCE[0]}rem) scale(0.75)}
    50% {transform: translateX(-${DISTANCE[1]}rem) scale(0.6)}
    75% {transform: translateX(-${DISTANCE[2]}rem) scale(0.5)}
    95% {transform: translateX(0rem) scale(1)}
`;
export const Dot1 = styled.div`
    ${cssDot}
    animation: ${Dot1Anim} 1.5s infinite;
`;

const Dot2Anim = keyframes`
    25% {transform: translateX(-${DISTANCE[0]}rem) scale(0.75)}
    50% {transform: translateX(-${DISTANCE[1]}rem) scale(0.6)}
    75% {transform: translateX(-${DISTANCE[1]}rem) scale(0.6)}
    95% {transform: translateX(0rem) scale(1)}
`;
export const Dot2 = styled.div`
    ${cssDot}
    animation: ${Dot2Anim} 1.5s infinite;
`;

const Dot3Anim = keyframes`
    25% {transform: translateX(-${DISTANCE[0]}rem) scale(0.75)}
    75% {transform: translateX(-${DISTANCE[0]}rem) scale(0.75)}
    95% {transform: translateX(0rem) scale(1)}
`;
export const Dot3 = styled.div`
    ${cssDot}
    animation: ${Dot3Anim} 1.5s infinite;
`;

const Dot4Anim = keyframes`
    25% {transform: translateX(${DISTANCE[0]}rem) scale(0.75)}
    75% {transform: translateX(${DISTANCE[0]}rem) scale(0.75)}
    95% {transform: translateX(0rem) scale(1)}
`;
export const Dot4 = styled.div`
    ${cssDot}
    animation: ${Dot4Anim} 1.5s infinite;
`;

const Dot5Anim = keyframes`
    25% {transform: translateX(${DISTANCE[0]}rem) scale(0.75)}
    50% {transform: translateX(${DISTANCE[1]}rem) scale(0.6)}
    75% {transform: translateX(${DISTANCE[1]}rem) scale(0.6)}
    95% {transform: translateX(0rem) scale(1)}
`;
export const Dot5 = styled.div`
    ${cssDot}
    animation: ${Dot5Anim} 1.5s infinite;
`;

const Dot6Anim = keyframes`
    25% {transform: translateX(${DISTANCE[0]}rem) scale(0.75)}
    50% {transform: translateX(${DISTANCE[1]}rem) scale(0.6)}
    75% {transform: translateX(${DISTANCE[2]}rem) scale(0.5)}
    95% {transform: translateX(0rem) scale(1)}
`;
export const Dot6 = styled.div`
    ${cssDot}
    animation: ${Dot6Anim} 1.5s infinite;
`;
