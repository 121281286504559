import { Store } from '../interfaces';
import { Dialog as DialogActions } from '../../../../core/action';

const reducer = (state: Store, payload: DialogActions.stepperStep.Payload): Store => {
    return {
        ...state,
        stepper: {
            ...state.stepper,
            step: {
                ...state.stepper?.step,
                [payload.s]: payload.sn,
            },
        },
    };
};

export default reducer;
