import { isNil as _isNil } from 'lodash';
import { StoreSelectorFunction } from '../../../../core/selector';
import { RootState } from '../../root_reducer';
import { Store } from '../interfaces';
import getStore from './get_store';

export default (): StoreSelectorFunction<number | undefined> => (rootStore: RootState): number | undefined => {
    const store: Store = getStore(rootStore);

    if (_isNil(store)) {
        return 0;
    }

    return store.proposals.length;
};
