import { isNil as _isNil } from 'lodash';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const StyledIcon = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: currentColor;
`;

export const StyledIconWrapper = styled.i`
    position: relative;
    width: 1em;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &:before {
        position: relative;
        content: '';
        display: block;
        padding-top: 100%;
    }
`;

type Props = HTMLAttributes<HTMLElement> & {
    onClick?: () => void;
};

const Icon = (props: Props) => {
    const onClick = () => {
        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <StyledIconWrapper onClick={onClick} className={props.className}>
            <StyledIcon viewBox="0 0 1365 1024">
                <path d="M464.553 952c442.76 0 685.032-338.665 685.032-631.852 0-9.53 0-19.058-0.468-28.58 46.949-31.18 87.804-70.593 120.201-115.198-43.199 17.754-89.678 29.453-138.512 35.077 49.768-27.284 87.804-71.027 106.115-122.994-46.484 25.548-98.132 43.743-153.064 53.704-44.138-43.311-106.58-70.154-175.599-70.154-132.874 0-240.868 99.607-240.868 222.169 0 17.322 2.345 34.213 6.104 50.672-200.013-9.098-377.496-97.873-496.287-232.129-20.655 32.917-32.397 71.027-32.397 111.733 0 77.083 42.725 145.077 107.054 184.923-39.441-1.296-76.533-11.257-108.928-27.716 0 0.864 0 1.733 0 3.033 0 107.399 83.105 197.479 192.97 217.831-20.19 5.198-41.32 7.793-63.388 7.793-15.492 0-30.518-1.296-45.073-3.896 30.518 88.349 119.727 152.438 224.902 154.175-82.638 59.76-186.401 95.278-299.084 95.278-19.25 0-38.501-0.864-57.283-3.033 106.115 62.361 232.885 99.175 368.577 99.175z" />
            </StyledIcon>
        </StyledIconWrapper>
    );
};

export default Icon;
