import { Name } from '../../core/entity';

// General
export const GENERAL_PREFIX = `${Name.BLOCK_PREFIX}GENERAL:`;
export const GENERAL_BLOCK = `${GENERAL_PREFIX}block`;
export const ANCHOR = `${GENERAL_PREFIX}anchor`;
export const GENERAL_HTML = `${GENERAL_PREFIX}html`;
export const REDIRECT = `${GENERAL_PREFIX}redirect`;
export const REDIRECT_TO_404 = `${GENERAL_PREFIX}redirect_to_404`;
// CTA
export const CTA_PREFIX = `${Name.BLOCK_PREFIX}CTA:`;
export const REPRESENT_PRIMARY_CTA = `${CTA_PREFIX}represent_primary`;
export const PRIMARY_CTA = `${CTA_PREFIX}primary`;
export const SECONDARY_CTA = `${CTA_PREFIX}secondary`;
// Button
export const BUTTON_PREFIX = `${Name.BLOCK_PREFIX}BUTTON:`;
export const REPRESENT_BUTTON = `${BUTTON_PREFIX}represent_button`;
export const BUTTON = `${BUTTON_PREFIX}button`;
export const VOTE_BUTTON = `${BUTTON_PREFIX}vote_button`;
export const LINK_BUTTON = `${BUTTON_PREFIX}link_button`;
export const ICON_BUTTON = `${BUTTON_PREFIX}icon_button`;
export const PROGRESS_BUTTON = `${BUTTON_PREFIX}progress_button`;
export const LINK_ITEM_BUTTON = `${BUTTON_PREFIX}link_item_button`;
// Typography
export const TYPOGRAPHY_PREFIX = `${Name.BLOCK_PREFIX}TYPOGRAPHY:`;
export const TITLE = `${TYPOGRAPHY_PREFIX}title`;
export const H1 = `${TYPOGRAPHY_PREFIX}h1`;
export const H1_WITH_STATUS = `${TYPOGRAPHY_PREFIX}h1_with_status`;
export const H2 = `${TYPOGRAPHY_PREFIX}h2`;
export const H3 = `${TYPOGRAPHY_PREFIX}h3`;
export const P = `${TYPOGRAPHY_PREFIX}p`;
export const NOTE_P = `${TYPOGRAPHY_PREFIX}note_p`;
export const REPRESENT_P = `${TYPOGRAPHY_PREFIX}represent_p`;
export const ACCENT = `${TYPOGRAPHY_PREFIX}accent`;
// Graphic
export const GRAPHIC_PREFIX = `${Name.BLOCK_PREFIX}GRAPHIC:`;
export const ICON = `${GRAPHIC_PREFIX}icon`;
export const IMAGE = `${GRAPHIC_PREFIX}image`;
export const FLEXIBLE_IMAGE = `${GRAPHIC_PREFIX}flexible_image`;
export const CIRCULAR_IMAGE = `${GRAPHIC_PREFIX}circular_image`;
export const VERTICAL_SPACE = `${GRAPHIC_PREFIX}vertical_space`;
export const HORIZONTAL_SPACE = `${GRAPHIC_PREFIX}horizontal_space`;
export const HORIZONTAL_LINE = `${GRAPHIC_PREFIX}horizontal_line`;
export const VOTES_COUNT = `${GRAPHIC_PREFIX}votes_count`;
export const REPRESENT_VOTES_COUNT = `${GRAPHIC_PREFIX}represent_votes_count`;
export const SECTION = `${GRAPHIC_PREFIX}section`;
export const BAR_GRAPH = `${GRAPHIC_PREFIX}bar_graph`;
export const SIGN_BAR_GRAPH = `${GRAPHIC_PREFIX}sign_bar_graph`;
export const DOUBLE_SIGN_BAR_GRAPH = `${GRAPHIC_PREFIX}double_sign_bar_graph`;
export const PORTRAIT_BAR_GRAPH = `${GRAPHIC_PREFIX}portrait_bar_graph`;
export const PORTRAIT_SIGN_BAR_GRAPH = `${GRAPHIC_PREFIX}portrait_sign_bar_graph`;
export const PORTRAIT_DOUBLE_SIGN_BAR_GRAPH = `${GRAPHIC_PREFIX}portrait_double_sign_bar_graph`;
export const YOUTUBE = `${GRAPHIC_PREFIX}youtube`;
export const MP4 = `${GRAPHIC_PREFIX}mp4`;
export const BAR = `${GRAPHIC_PREFIX}bar`;
export const BARS = `${GRAPHIC_PREFIX}bars`;
export const RANK = `${GRAPHIC_PREFIX}rank`;
export const INTERACTIVE_SVG = `${GRAPHIC_PREFIX}interactive_svg`;
export const HARMONOGRAM = `${GRAPHIC_PREFIX}harmonogram`;
// Voting Option
export const VOTING_OPTION_PREFIX = `${Name.BLOCK_PREFIX}VOTING_OPTION:`;
export const SIMPLE_VOTING_OPTION = `${VOTING_OPTION_PREFIX}simple`;
export const STANDARD_VOTING_OPTION = `${VOTING_OPTION_PREFIX}standard`;
export const PORTRAIT_VOTING_OPTION = `${VOTING_OPTION_PREFIX}portrait`;
// Layout
export const LAYOUT_PREFIX = `${Name.BLOCK_PREFIX}LAYOUT:`;
export const VERTICAL_LAYOUT = `${LAYOUT_PREFIX}vertical`;
export const HORIZONTAL_LAYOUT = `${LAYOUT_PREFIX}horizontal`;
export const COLLAPSE = `${LAYOUT_PREFIX}collapse`;
export const COD_SBOM_LAYOUT = `${LAYOUT_PREFIX}cod_sbom`;
export const TABLE_LIKE_LIST = `${LAYOUT_PREFIX}table_like_list`;
export const SPLIT_PANE = `${LAYOUT_PREFIX}split_pane`;
export const AFFIX_LAYOUT = `${LAYOUT_PREFIX}affix`;
// Form
export const FORM_PREFIX = `${Name.BLOCK_PREFIX}FORM:`;
export const FORM_FIELD_STRING = `${FORM_PREFIX}field_string`;
export const FORM_FIELD_STRING_LONG = `${FORM_PREFIX}field_string_long`;
export const FORM_FIELD_PHONE = `${FORM_PREFIX}field_phone`;
export const FORM_FIELD_NUMBER = `${FORM_PREFIX}field_number`;
export const FORM_FIELD_FILE_UPLOAD = `${FORM_PREFIX}field_file_upload`;
export const FORM_FIELD_RADIO_GROUP = `${FORM_PREFIX}field_radio_group`;
export const FORM_FIELD_RADIO = `${FORM_PREFIX}field_radio`;
export const FORM_FIELD_CHECKBOX = `${FORM_PREFIX}field_checkbox`;
export const FORM_FIELD_LINK = `${FORM_PREFIX}field_link`;
export const FORM_FIELD_DROPDOWN = `${FORM_PREFIX}field_dropdown`;
export const FORM_FIELD_INPUT_BOX = `${FORM_PREFIX}field_input_box`;
// Time & Date Pickers
export const TDP_PREFIX = `${Name.BLOCK_PREFIX}TDP:`;
export const DATE_PICKER = `${TDP_PREFIX}date`;
export const TIME_PICKER = `${TDP_PREFIX}time`;
export const TIMELINE = `${TDP_PREFIX}timeline`;
// List
export const LIST_PREFIX = `${Name.BLOCK_PREFIX}LIST:`;
export const TEMPLATE_LIST = `${LIST_PREFIX}template`;
export const UNORDERED_LIST = `${LIST_PREFIX}unordered_list`;
// Item list
export const ITEM_LIST = `${Name.BLOCK_PREFIX}ITEM_LIST`;
// File list
export const FILE_LIST = `${Name.BLOCK_PREFIX}FILE_LIST`;
// Dialog
export const DIALOG_PREFIX = `${Name.BLOCK_PREFIX}DIALOG:`;
export const DIALOG_SMS_AUTHENTICATION = `${DIALOG_PREFIX}sms_authentication`;
export const DIALOG_PIN_AUTHENTICATION = `${DIALOG_PREFIX}pin_authentication`;
export const DIALOG_STEPPER = `${DIALOG_PREFIX}stepper`;
export const DIALOG_SWITCH = `${DIALOG_PREFIX}switch`;
export const DIALOG_TIME_SWITCH = `${DIALOG_PREFIX}time_switch`;
// Proposals
export const PROPOSALS_LIST = `${Name.BLOCK_PREFIX}PROPOSALS_LIST`;
// Maps
export const MAP = `${Name.BLOCK_PREFIX}MAP`;
export const MAP_SEARCH = `${Name.BLOCK_PREFIX}MAP_SEARCH`;
// Results
export const RESULTS_PREFIX = `${Name.BLOCK_PREFIX}RESULTS:`;
export const STANDARD_RESULT_LIST = `${RESULTS_PREFIX}standard_result_list`;
export const PORTRAIT_RESULT_LIST = `${RESULTS_PREFIX}portrait_result_list`;
export const OVERLAP_PORTRAIT_RESULT_LIST = `${RESULTS_PREFIX}overlap_portrait_result_list`;
// Menu
export const MENU = `${Name.BLOCK_PREFIX}MENU`;
export const MENU_ITEM = `${Name.BLOCK_PREFIX}MENU:item`;

// Templating logic
export const LOGIC_PREFIX = `${Name.BLOCK_PREFIX}LOGIC:`;
export const LOGIC_IF = `${LOGIC_PREFIX}if`;
export const LOGIC_FOREACH = `${LOGIC_PREFIX}foreach`;
// Dev
export const DEV_PREFIX = `${Name.BLOCK_PREFIX}DEV:`;
export const DEV_PLAYGROUND = `${DEV_PREFIX}dev_playground`;

// Customized
export const CUSTOMIZED_PREFIX = `${Name.BLOCK_PREFIX}CUSTOMIZED:`;
export const VOLBY_JINAK_2020_OVERALL_RESULTS = `${CUSTOMIZED_PREFIX}volby_jinak_2020_overall_results`;
export const SCHOOL_PB_RESULTS_PANEL = `${CUSTOMIZED_PREFIX}school_pb_results_panel`;
