enum MaterialIcons {
    ROTATION_3D = '3d_rotation',
    AC_UNIT = 'ac_unit',
    ACCESS_ALARM = 'access_alarm',
    ACCESS_ALARMS = 'access_alarms',
    ACCESS_TIME = 'access_time',
    ACCESSIBILITY = 'accessibility',
    ACCESSIBLE = 'accessible',
    ACCOUNT_BALANCE = 'account_balance',
    ACCOUNT_BALANCE_WALLET = 'account_balance_wallet',
    ACCOUNT_BOX = 'account_box',
    ACCOUNT_CIRCLE = 'account_circle',
    ADB = 'adb',
    ADD = 'add',
    ADD_A_PHOTO = 'add_a_photo',
    ADD_ALARM = 'add_alarm',
    ADD_ALERT = 'add_alert',
    ADD_BOX = 'add_box',
    ADD_CIRCLE = 'add_circle',
    ADD_CIRCLE_OUTLINE = 'add_circle_outline',
    ADD_LOCATION = 'add_location',
    ADD_SHOPPING_CART = 'add_shopping_cart',
    ADD_TO_PHOTOS = 'add_to_photos',
    ADD_TO_QUEUE = 'add_to_queue',
    ADJUST = 'adjust',
    AIRLINE_SEAT_FLAT = 'airline_seat_flat',
    AIRLINE_SEAT_FLAT_ANGLED = 'airline_seat_flat_angled',
    AIRLINE_SEAT_INDIVIDUAL_SUITE = 'airline_seat_individual_suite',
    AIRLINE_SEAT_LEGROOM_EXTRA = 'airline_seat_legroom_extra',
    AIRLINE_SEAT_LEGROOM_NORMAL = 'airline_seat_legroom_normal',
    AIRLINE_SEAT_LEGROOM_REDUCED = 'airline_seat_legroom_reduced',
    AIRLINE_SEAT_RECLINE_EXTRA = 'airline_seat_recline_extra',
    AIRLINE_SEAT_RECLINE_NORMAL = 'airline_seat_recline_normal',
    AIRPLANEMODE_ACTIVE = 'airplanemode_active',
    AIRPLANEMODE_INACTIVE = 'airplanemode_inactive',
    AIRPLAY = 'airplay',
    AIRPORT_SHUTTLE = 'airport_shuttle',
    ALARM = 'alarm',
    ALARM_ADD = 'alarm_add',
    ALARM_OFF = 'alarm_off',
    ALARM_ON = 'alarm_on',
    ALBUM = 'album',
    ALL_INCLUSIVE = 'all_inclusive',
    ALL_OUT = 'all_out',
    ANDROID = 'android',
    ANNOUNCEMENT = 'announcement',
    APPS = 'apps',
    ARCHIVE = 'archive',
    ARROW_BACK = 'arrow_back',
    ARROW_DOWNWARD = 'arrow_downward',
    ARROW_DROP_DOWN = 'arrow_drop_down',
    ARROW_DROP_DOWN_CIRCLE = 'arrow_drop_down_circle',
    ARROW_DROP_UP = 'arrow_drop_up',
    ARROW_FORWARD = 'arrow_forward',
    ARROW_UPWARD = 'arrow_upward',
    ART_TRACK = 'art_track',
    ASPECT_RATIO = 'aspect_ratio',
    ASSESSMENT = 'assessment',
    ASSIGNMENT = 'assignment',
    ASSIGNMENT_IND = 'assignment_ind',
    ASSIGNMENT_LATE = 'assignment_late',
    ASSIGNMENT_RETURN = 'assignment_return',
    ASSIGNMENT_RETURNED = 'assignment_returned',
    ASSIGNMENT_TURNED_IN = 'assignment_turned_in',
    ASSISTANT = 'assistant',
    ASSISTANT_PHOTO = 'assistant_photo',
    ATTACH_FILE = 'attach_file',
    ATTACH_MONEY = 'attach_money',
    ATTACHMENT = 'attachment',
    AUDIOTRACK = 'audiotrack',
    AUTORENEW = 'autorenew',
    AV_TIMER = 'av_timer',
    BACKSPACE = 'backspace',
    BACKUP = 'backup',
    BATTERY_ALERT = 'battery_alert',
    BATTERY_CHARGING_FULL = 'battery_charging_full',
    BATTERY_FULL = 'battery_full',
    BATTERY_STD = 'battery_std',
    BATTERY_UNKNOWN = 'battery_unknown',
    BEACH_ACCESS = 'beach_access',
    BEENHERE = 'beenhere',
    BLOCK = 'block',
    BLUETOOTH = 'bluetooth',
    BLUETOOTH_AUDIO = 'bluetooth_audio',
    BLUETOOTH_CONNECTED = 'bluetooth_connected',
    BLUETOOTH_DISABLED = 'bluetooth_disabled',
    BLUETOOTH_SEARCHING = 'bluetooth_searching',
    BLUR_CIRCULAR = 'blur_circular',
    BLUR_LINEAR = 'blur_linear',
    BLUR_OFF = 'blur_off',
    BLUR_ON = 'blur_on',
    BOOK = 'book',
    BOOKMARK = 'bookmark',
    BOOKMARK_BORDER = 'bookmark_border',
    BORDER_ALL = 'border_all',
    BORDER_BOTTOM = 'border_bottom',
    BORDER_CLEAR = 'border_clear',
    BORDER_COLOR = 'border_color',
    BORDER_HORIZONTAL = 'border_horizontal',
    BORDER_INNER = 'border_inner',
    BORDER_LEFT = 'border_left',
    BORDER_OUTER = 'border_outer',
    BORDER_RIGHT = 'border_right',
    BORDER_STYLE = 'border_style',
    BORDER_TOP = 'border_top',
    BORDER_VERTICAL = 'border_vertical',
    BRANDING_WATERMARK = 'branding_watermark',
    BRIGHTNESS_1 = 'brightness_1',
    BRIGHTNESS_2 = 'brightness_2',
    BRIGHTNESS_3 = 'brightness_3',
    BRIGHTNESS_4 = 'brightness_4',
    BRIGHTNESS_5 = 'brightness_5',
    BRIGHTNESS_6 = 'brightness_6',
    BRIGHTNESS_7 = 'brightness_7',
    BRIGHTNESS_AUTO = 'brightness_auto',
    BRIGHTNESS_HIGH = 'brightness_high',
    BRIGHTNESS_LOW = 'brightness_low',
    BRIGHTNESS_MEDIUM = 'brightness_medium',
    BROKEN_IMAGE = 'broken_image',
    BRUSH = 'brush',
    BUBBLE_CHART = 'bubble_chart',
    BUG_REPORT = 'bug_report',
    BUILD = 'build',
    BURST_MODE = 'burst_mode',
    BUSINESS = 'business',
    BUSINESS_CENTER = 'business_center',
    CACHED = 'cached',
    CAKE = 'cake',
    CALL = 'call',
    CALL_END = 'call_end',
    CALL_MADE = 'call_made',
    CALL_MERGE = 'call_merge',
    CALL_MISSED = 'call_missed',
    CALL_MISSED_OUTGOING = 'call_missed_outgoing',
    CALL_RECEIVED = 'call_received',
    CALL_SPLIT = 'call_split',
    CALL_TO_ACTION = 'call_to_action',
    CAMERA = 'camera',
    CAMERA_ALT = 'camera_alt',
    CAMERA_ENHANCE = 'camera_enhance',
    CAMERA_FRONT = 'camera_front',
    CAMERA_REAR = 'camera_rear',
    CAMERA_ROLL = 'camera_roll',
    CANCEL = 'cancel',
    CARD_GIFTCARD = 'card_giftcard',
    CARD_MEMBERSHIP = 'card_membership',
    CARD_TRAVEL = 'card_travel',
    CASINO = 'casino',
    CAST = 'cast',
    CAST_CONNECTED = 'cast_connected',
    CENTER_FOCUS_STRONG = 'center_focus_strong',
    CENTER_FOCUS_WEAK = 'center_focus_weak',
    CHANGE_HISTORY = 'change_history',
    CHAT = 'chat',
    CHAT_BUBBLE = 'chat_bubble',
    CHAT_BUBBLE_OUTLINE = 'chat_bubble_outline',
    CHECK = 'check',
    CHECK_BOX = 'check_box',
    CHECK_BOX_OUTLINE_BLANK = 'check_box_outline_blank',
    CHECK_CIRCLE = 'check_circle',
    CHECK_CIRCLE_OUTLINED = 'check_circle_outlined',
    CHEVRON_LEFT = 'chevron_left',
    CHEVRON_RIGHT = 'chevron_right',
    CHILD_CARE = 'child_care',
    CHILD_FRIENDLY = 'child_friendly',
    CHROME_READER_MODE = 'chrome_reader_mode',
    CLASS = 'class',
    CLEAR = 'clear',
    CLEAR_ALL = 'clear_all',
    CLOSE = 'close',
    CLOSED_CAPTION = 'closed_caption',
    CLOUD = 'cloud',
    CLOUD_CIRCLE = 'cloud_circle',
    CLOUD_DONE = 'cloud_done',
    CLOUD_DOWNLOAD = 'cloud_download',
    CLOUD_OFF = 'cloud_off',
    CLOUD_QUEUE = 'cloud_queue',
    CLOUD_UPLOAD = 'cloud_upload',
    CODE = 'code',
    COLLECTIONS = 'collections',
    COLLECTIONS_BOOKMARK = 'collections_bookmark',
    COLOR_LENS = 'color_lens',
    COLORIZE = 'colorize',
    COMMENT = 'comment',
    COMPARE = 'compare',
    COMPARE_ARROWS = 'compare_arrows',
    COMPUTER = 'computer',
    CONFIRMATION_NUMBER = 'confirmation_number',
    CONTACT_MAIL = 'contact_mail',
    CONTACT_PHONE = 'contact_phone',
    CONTACTS = 'contacts',
    CONTENT_COPY = 'content_copy',
    CONTENT_CUT = 'content_cut',
    CONTENT_PASTE = 'content_paste',
    CONTROL_POINT = 'control_point',
    CONTROL_POINT_DUPLICATE = 'control_point_duplicate',
    COPYRIGHT = 'copyright',
    CREATE = 'create',
    CREATE_NEW_FOLDER = 'create_new_folder',
    CREDIT_CARD = 'credit_card',
    CROP = 'crop',
    CROP_16_9 = 'crop_16_9',
    CROP_3_2 = 'crop_3_2',
    CROP_5_4 = 'crop_5_4',
    CROP_7_5 = 'crop_7_5',
    CROP_DIN = 'crop_din',
    CROP_FREE = 'crop_free',
    CROP_LANDSCAPE = 'crop_landscape',
    CROP_ORIGINAL = 'crop_original',
    CROP_PORTRAIT = 'crop_portrait',
    CROP_ROTATE = 'crop_rotate',
    CROP_SQUARE = 'crop_square',
    DASHBOARD = 'dashboard',
    DATA_USAGE = 'data_usage',
    DATE_RANGE = 'date_range',
    DEHAZE = 'dehaze',
    DELETE = 'delete',
    DELETE_FOREVER = 'delete_forever',
    DELETE_SWEEP = 'delete_sweep',
    DESCRIPTION = 'description',
    DESKTOP_MAC = 'desktop_mac',
    DESKTOP_WINDOWS = 'desktop_windows',
    DETAILS = 'details',
    DEVELOPER_BOARD = 'developer_board',
    DEVELOPER_MODE = 'developer_mode',
    DEVICE_HUB = 'device_hub',
    DEVICES = 'devices',
    DEVICES_OTHER = 'devices_other',
    DIALER_SIP = 'dialer_sip',
    DIALPAD = 'dialpad',
    DIRECTIONS = 'directions',
    DIRECTIONS_BIKE = 'directions_bike',
    DIRECTIONS_BOAT = 'directions_boat',
    DIRECTIONS_BUS = 'directions_bus',
    DIRECTIONS_CAR = 'directions_car',
    DIRECTIONS_RAILWAY = 'directions_railway',
    DIRECTIONS_RUN = 'directions_run',
    DIRECTIONS_SUBWAY = 'directions_subway',
    DIRECTIONS_TRANSIT = 'directions_transit',
    DIRECTIONS_WALK = 'directions_walk',
    DISC_FULL = 'disc_full',
    DNS = 'dns',
    DO_NOT_DISTURB = 'do_not_disturb',
    DO_NOT_DISTURB_ALT = 'do_not_disturb_alt',
    DO_NOT_DISTURB_OFF = 'do_not_disturb_off',
    DO_NOT_DISTURB_ON = 'do_not_disturb_on',
    DOCK = 'dock',
    DOMAIN = 'domain',
    DONE = 'done',
    DONE_ALL = 'done_all',
    DONUT_LARGE = 'donut_large',
    DONUT_SMALL = 'donut_small',
    DRAFTS = 'drafts',
    DRAG_HANDLE = 'drag_handle',
    DRIVE_ETA = 'drive_eta',
    DVR = 'dvr',
    EDIT = 'edit',
    EDIT_LOCATION = 'edit_location',
    EJECT = 'eject',
    EMAIL = 'email',
    ENHANCED_ENCRYPTION = 'enhanced_encryption',
    EQUALIZER = 'equalizer',
    ERROR = 'error',
    ERROR_OUTLINE = 'error_outline',
    EURO_SYMBOL = 'euro_symbol',
    EV_STATION = 'ev_station',
    EVENT = 'event',
    EVENT_AVAILABLE = 'event_available',
    EVENT_BUSY = 'event_busy',
    EVENT_NOTE = 'event_note',
    EVENT_SEAT = 'event_seat',
    EXIT_TO_APP = 'exit_to_app',
    EXPAND_LESS = 'expand_less',
    EXPAND_MORE = 'expand_more',
    EXPLICIT = 'explicit',
    EXPLORE = 'explore',
    EXPOSURE = 'exposure',
    EXPOSURE_NEG_1 = 'exposure_neg_1',
    EXPOSURE_NEG_2 = 'exposure_neg_2',
    EXPOSURE_PLUS_1 = 'exposure_plus_1',
    EXPOSURE_PLUS_2 = 'exposure_plus_2',
    EXPOSURE_ZERO = 'exposure_zero',
    EXTENSION = 'extension',
    FACE = 'face',
    FAST_FORWARD = 'fast_forward',
    FAST_REWIND = 'fast_rewind',
    FAVORITE = 'favorite',
    FAVORITE_BORDER = 'favorite_border',
    FEATURED_PLAY_LIST = 'featured_play_list',
    FEATURED_VIDEO = 'featured_video',
    FEEDBACK = 'feedback',
    FIBER_DVR = 'fiber_dvr',
    FIBER_MANUAL_RECORD = 'fiber_manual_record',
    FIBER_NEW = 'fiber_new',
    FIBER_PIN = 'fiber_pin',
    FIBER_SMART_RECORD = 'fiber_smart_record',
    FILE_DOWNLOAD = 'file_download',
    FILE_UPLOAD = 'file_upload',
    FILTER = 'filter',
    FILTER_1 = 'filter_1',
    FILTER_2 = 'filter_2',
    FILTER_3 = 'filter_3',
    FILTER_4 = 'filter_4',
    FILTER_5 = 'filter_5',
    FILTER_6 = 'filter_6',
    FILTER_7 = 'filter_7',
    FILTER_8 = 'filter_8',
    FILTER_9 = 'filter_9',
    FILTER_9_PLUS = 'filter_9_plus',
    FILTER_B_AND_W = 'filter_b_and_w',
    FILTER_CENTER_FOCUS = 'filter_center_focus',
    FILTER_DRAMA = 'filter_drama',
    FILTER_FRAMES = 'filter_frames',
    FILTER_HDR = 'filter_hdr',
    FILTER_LIST = 'filter_list',
    FILTER_NONE = 'filter_none',
    FILTER_TILT_SHIFT = 'filter_tilt_shift',
    FILTER_VINTAGE = 'filter_vintage',
    FIND_IN_PAGE = 'find_in_page',
    FIND_REPLACE = 'find_replace',
    FINGERPRINT = 'fingerprint',
    FIRST_PAGE = 'first_page',
    FITNESS_CENTER = 'fitness_center',
    FLAG = 'flag',
    FLARE = 'flare',
    FLASH_AUTO = 'flash_auto',
    FLASH_OFF = 'flash_off',
    FLASH_ON = 'flash_on',
    FLIGHT = 'flight',
    FLIGHT_LAND = 'flight_land',
    FLIGHT_TAKEOFF = 'flight_takeoff',
    FLIP = 'flip',
    FLIP_TO_BACK = 'flip_to_back',
    FLIP_TO_FRONT = 'flip_to_front',
    FOLDER = 'folder',
    FOLDER_OPEN = 'folder_open',
    FOLDER_SHARED = 'folder_shared',
    FOLDER_SPECIAL = 'folder_special',
    FONT_DOWNLOAD = 'font_download',
    FORMAT_ALIGN_CENTER = 'format_align_center',
    FORMAT_ALIGN_JUSTIFY = 'format_align_justify',
    FORMAT_ALIGN_LEFT = 'format_align_left',
    FORMAT_ALIGN_RIGHT = 'format_align_right',
    FORMAT_BOLD = 'format_bold',
    FORMAT_CLEAR = 'format_clear',
    FORMAT_COLOR_FILL = 'format_color_fill',
    FORMAT_COLOR_RESET = 'format_color_reset',
    FORMAT_COLOR_TEXT = 'format_color_text',
    FORMAT_INDENT_DECREASE = 'format_indent_decrease',
    FORMAT_INDENT_INCREASE = 'format_indent_increase',
    FORMAT_ITALIC = 'format_italic',
    FORMAT_LINE_SPACING = 'format_line_spacing',
    FORMAT_LIST_BULLETED = 'format_list_bulleted',
    FORMAT_LIST_NUMBERED = 'format_list_numbered',
    FORMAT_PAINT = 'format_paint',
    FORMAT_QUOTE = 'format_quote',
    FORMAT_SHAPES = 'format_shapes',
    FORMAT_SIZE = 'format_size',
    FORMAT_STRIKETHROUGH = 'format_strikethrough',
    FORMAT_TEXTDIRECTION_L_TO_R = 'format_textdirection_l_to_r',
    FORMAT_TEXTDIRECTION_R_TO_L = 'format_textdirection_r_to_l',
    FORMAT_UNDERLINED = 'format_underlined',
    FORUM = 'forum',
    FORWARD = 'forward',
    FORWARD_10 = 'forward_10',
    FORWARD_30 = 'forward_30',
    FORWARD_5 = 'forward_5',
    FREE_BREAKFAST = 'free_breakfast',
    FULLSCREEN = 'fullscreen',
    FULLSCREEN_EXIT = 'fullscreen_exit',
    FUNCTIONS = 'functions',
    G_TRANSLATE = 'g_translate',
    GAMEPAD = 'gamepad',
    GAMES = 'games',
    GAVEL = 'gavel',
    GESTURE = 'gesture',
    GET_APP = 'get_app',
    GIF = 'gif',
    GOLF_COURSE = 'golf_course',
    GPS_FIXED = 'gps_fixed',
    GPS_NOT_FIXED = 'gps_not_fixed',
    GPS_OFF = 'gps_off',
    GRADE = 'grade',
    GRADIENT = 'gradient',
    GRAIN = 'grain',
    GRAPHIC_EQ = 'graphic_eq',
    GRID_OFF = 'grid_off',
    GRID_ON = 'grid_on',
    GROUP = 'group',
    GROUP_ADD = 'group_add',
    GROUP_WORK = 'group_work',
    HD = 'hd',
    HDR_OFF = 'hdr_off',
    HDR_ON = 'hdr_on',
    HDR_STRONG = 'hdr_strong',
    HDR_WEAK = 'hdr_weak',
    HEADSET = 'headset',
    HEADSET_MIC = 'headset_mic',
    HEALING = 'healing',
    HEARING = 'hearing',
    HELP = 'help',
    HELP_OUTLINE = 'help_outline',
    HIGH_QUALITY = 'high_quality',
    HIGHLIGHT = 'highlight',
    HIGHLIGHT_OFF = 'highlight_off',
    HISTORY = 'history',
    HOME = 'home',
    HOT_TUB = 'hot_tub',
    HOTEL = 'hotel',
    HOURGLASS_EMPTY = 'hourglass_empty',
    HOURGLASS_FULL = 'hourglass_full',
    HTTP = 'http',
    HTTPS = 'https',
    IMAGE = 'image',
    IMAGE_ASPECT_RATIO = 'image_aspect_ratio',
    IMPORT_CONTACTS = 'import_contacts',
    IMPORT_EXPORT = 'import_export',
    IMPORTANT_DEVICES = 'important_devices',
    INBOX = 'inbox',
    INDETERMINATE_CHECK_BOX = 'indeterminate_check_box',
    INFO = 'info',
    INFO_OUTLINE = 'info_outline',
    INPUT = 'input',
    INSERT_CHART = 'insert_chart',
    INSERT_COMMENT = 'insert_comment',
    INSERT_DRIVE_FILE = 'insert_drive_file',
    INSERT_EMOTICON = 'insert_emoticon',
    INSERT_INVITATION = 'insert_invitation',
    INSERT_LINK = 'insert_link',
    INSERT_PHOTO = 'insert_photo',
    INVERT_COLORS = 'invert_colors',
    INVERT_COLORS_OFF = 'invert_colors_off',
    ISO = 'iso',
    KEYBOARD = 'keyboard',
    KEYBOARD_ARROW_DOWN = 'keyboard_arrow_down',
    KEYBOARD_ARROW_LEFT = 'keyboard_arrow_left',
    KEYBOARD_ARROW_RIGHT = 'keyboard_arrow_right',
    KEYBOARD_ARROW_UP = 'keyboard_arrow_up',
    KEYBOARD_BACKSPACE = 'keyboard_backspace',
    KEYBOARD_CAPSLOCK = 'keyboard_capslock',
    KEYBOARD_HIDE = 'keyboard_hide',
    KEYBOARD_RETURN = 'keyboard_return',
    KEYBOARD_TAB = 'keyboard_tab',
    KEYBOARD_VOICE = 'keyboard_voice',
    KITCHEN = 'kitchen',
    LABEL = 'label',
    LABEL_OUTLINE = 'label_outline',
    LANDSCAPE = 'landscape',
    LANGUAGE = 'language',
    LAPTOP = 'laptop',
    LAPTOP_CHROMEBOOK = 'laptop_chromebook',
    LAPTOP_MAC = 'laptop_mac',
    LAPTOP_WINDOWS = 'laptop_windows',
    LAST_PAGE = 'last_page',
    LAUNCH = 'launch',
    LAYERS = 'layers',
    LAYERS_CLEAR = 'layers_clear',
    LEAK_ADD = 'leak_add',
    LEAK_REMOVE = 'leak_remove',
    LENS = 'lens',
    LIBRARY_ADD = 'library_add',
    LIBRARY_BOOKS = 'library_books',
    LIBRARY_MUSIC = 'library_music',
    LIGHTBULB_OUTLINE = 'lightbulb_outline',
    LINE_STYLE = 'line_style',
    LINE_WEIGHT = 'line_weight',
    LINEAR_SCALE = 'linear_scale',
    LINK = 'link',
    LINKED_CAMERA = 'linked_camera',
    LIST = 'list',
    LIVE_HELP = 'live_help',
    LIVE_TV = 'live_tv',
    LOCAL_ACTIVITY = 'local_activity',
    LOCAL_AIRPORT = 'local_airport',
    LOCAL_ATM = 'local_atm',
    LOCAL_BAR = 'local_bar',
    LOCAL_CAFE = 'local_cafe',
    LOCAL_CAR_WASH = 'local_car_wash',
    LOCAL_CONVENIENCE_STORE = 'local_convenience_store',
    LOCAL_DINING = 'local_dining',
    LOCAL_DRINK = 'local_drink',
    LOCAL_FLORIST = 'local_florist',
    LOCAL_GAS_STATION = 'local_gas_station',
    LOCAL_GROCERY_STORE = 'local_grocery_store',
    LOCAL_HOSPITAL = 'local_hospital',
    LOCAL_HOTEL = 'local_hotel',
    LOCAL_LAUNDRY_SERVICE = 'local_laundry_service',
    LOCAL_LIBRARY = 'local_library',
    LOCAL_MALL = 'local_mall',
    LOCAL_MOVIES = 'local_movies',
    LOCAL_OFFER = 'local_offer',
    LOCAL_PARKING = 'local_parking',
    LOCAL_PHARMACY = 'local_pharmacy',
    LOCAL_PHONE = 'local_phone',
    LOCAL_PIZZA = 'local_pizza',
    LOCAL_PLAY = 'local_play',
    LOCAL_POST_OFFICE = 'local_post_office',
    LOCAL_PRINTSHOP = 'local_printshop',
    LOCAL_SEE = 'local_see',
    LOCAL_SHIPPING = 'local_shipping',
    LOCAL_TAXI = 'local_taxi',
    LOCATION_CITY = 'location_city',
    LOCATION_DISABLED = 'location_disabled',
    LOCATION_OFF = 'location_off',
    LOCATION_ON = 'location_on',
    LOCATION_SEARCHING = 'location_searching',
    LOCK = 'lock',
    LOCK_OPEN = 'lock_open',
    LOCK_OUTLINE = 'lock_outline',
    LOOKS = 'looks',
    LOOKS_3 = 'looks_3',
    LOOKS_4 = 'looks_4',
    LOOKS_5 = 'looks_5',
    LOOKS_6 = 'looks_6',
    LOOKS_ONE = 'looks_one',
    LOOKS_TWO = 'looks_two',
    LOOP = 'loop',
    LOUPE = 'loupe',
    LOW_PRIORITY = 'low_priority',
    LOYALTY = 'loyalty',
    MAIL = 'mail',
    MAIL_OUTLINE = 'mail_outline',
    MAP = 'map',
    MARKUNREAD = 'markunread',
    MARKUNREAD_MAILBOX = 'markunread_mailbox',
    MEMORY = 'memory',
    MENU = 'menu',
    MERGE_TYPE = 'merge_type',
    MESSAGE = 'message',
    MIC = 'mic',
    MIC_NONE = 'mic_none',
    MIC_OFF = 'mic_off',
    MMS = 'mms',
    MODE_COMMENT = 'mode_comment',
    MODE_EDIT = 'mode_edit',
    MONETIZATION_ON = 'monetization_on',
    MONEY_OFF = 'money_off',
    MONOCHROME_PHOTOS = 'monochrome_photos',
    MOOD = 'mood',
    MOOD_BAD = 'mood_bad',
    MORE = 'more',
    MORE_HORIZ = 'more_horiz',
    MORE_VERT = 'more_vert',
    MOTORCYCLE = 'motorcycle',
    MOUSE = 'mouse',
    MOVE_TO_INBOX = 'move_to_inbox',
    MOVIE = 'movie',
    MOVIE_CREATION = 'movie_creation',
    MOVIE_FILTER = 'movie_filter',
    MULTILINE_CHART = 'multiline_chart',
    MUSIC_NOTE = 'music_note',
    MUSIC_VIDEO = 'music_video',
    MY_LOCATION = 'my_location',
    NATURE = 'nature',
    NATURE_PEOPLE = 'nature_people',
    NAVIGATE_BEFORE = 'navigate_before',
    NAVIGATE_NEXT = 'navigate_next',
    NAVIGATION = 'navigation',
    NEAR_ME = 'near_me',
    NETWORK_CELL = 'network_cell',
    NETWORK_CHECK = 'network_check',
    NETWORK_LOCKED = 'network_locked',
    NETWORK_WIFI = 'network_wifi',
    NEW_RELEASES = 'new_releases',
    NEXT_WEEK = 'next_week',
    NFC = 'nfc',
    NO_ENCRYPTION = 'no_encryption',
    NO_SIM = 'no_sim',
    NOT_INTERESTED = 'not_interested',
    NOTES = 'notes',
    NOTE = 'note',
    NOTE_ADD = 'note_add',
    NOTIFICATIONS = 'notifications',
    NOTIFICATIONS_ACTIVE = 'notifications_active',
    NOTIFICATIONS_NONE = 'notifications_none',
    NOTIFICATIONS_OFF = 'notifications_off',
    NOTIFICATIONS_PAUSED = 'notifications_paused',
    OFFLINE_PIN = 'offline_pin',
    ONDEMAND_VIDEO = 'ondemand_video',
    OPACITY = 'opacity',
    OPEN_IN_BROWSER = 'open_in_browser',
    OPEN_IN_NEW = 'open_in_new',
    OPEN_WITH = 'open_with',
    PAGES = 'pages',
    PAGEVIEW = 'pageview',
    PALETTE = 'palette',
    PAN_TOOL = 'pan_tool',
    PANORAMA = 'panorama',
    PANORAMA_FISH_EYE = 'panorama_fish_eye',
    PANORAMA_HORIZONTAL = 'panorama_horizontal',
    PANORAMA_VERTICAL = 'panorama_vertical',
    PANORAMA_WIDE_ANGLE = 'panorama_wide_angle',
    PARTY_MODE = 'party_mode',
    PAUSE = 'pause',
    PAUSE_CIRCLE_FILLED = 'pause_circle_filled',
    PAUSE_CIRCLE_OUTLINE = 'pause_circle_outline',
    PAYMENT = 'payment',
    PEOPLE = 'people',
    PEOPLE_OUTLINE = 'people_outline',
    PERM_CAMERA_MIC = 'perm_camera_mic',
    PERM_CONTACT_CALENDAR = 'perm_contact_calendar',
    PERM_DATA_SETTING = 'perm_data_setting',
    PERM_DEVICE_INFORMATION = 'perm_device_information',
    PERM_IDENTITY = 'perm_identity',
    PERM_MEDIA = 'perm_media',
    PERM_PHONE_MSG = 'perm_phone_msg',
    PERM_SCAN_WIFI = 'perm_scan_wifi',
    PERSON = 'person',
    PERSON_ADD = 'person_add',
    PERSON_OUTLINE = 'person_outline',
    PERSON_PIN = 'person_pin',
    PERSON_PIN_CIRCLE = 'person_pin_circle',
    PERSONAL_VIDEO = 'personal_video',
    PETS = 'pets',
    PHONE = 'phone',
    PHONE_ANDROID = 'phone_android',
    PHONE_BLUETOOTH_SPEAKER = 'phone_bluetooth_speaker',
    PHONE_FORWARDED = 'phone_forwarded',
    PHONE_IN_TALK = 'phone_in_talk',
    PHONE_IPHONE = 'phone_iphone',
    PHONE_LOCKED = 'phone_locked',
    PHONE_MISSED = 'phone_missed',
    PHONE_PAUSED = 'phone_paused',
    PHONELINK = 'phonelink',
    PHONELINK_ERASE = 'phonelink_erase',
    PHONELINK_LOCK = 'phonelink_lock',
    PHONELINK_OFF = 'phonelink_off',
    PHONELINK_RING = 'phonelink_ring',
    PHONELINK_SETUP = 'phonelink_setup',
    PHOTO = 'photo',
    PHOTO_ALBUM = 'photo_album',
    PHOTO_CAMERA = 'photo_camera',
    PHOTO_FILTER = 'photo_filter',
    PHOTO_LIBRARY = 'photo_library',
    PHOTO_SIZE_SELECT_ACTUAL = 'photo_size_select_actual',
    PHOTO_SIZE_SELECT_LARGE = 'photo_size_select_large',
    PHOTO_SIZE_SELECT_SMALL = 'photo_size_select_small',
    PICTURE_AS_PDF = 'picture_as_pdf',
    PICTURE_IN_PICTURE = 'picture_in_picture',
    PICTURE_IN_PICTURE_ALT = 'picture_in_picture_alt',
    PIE_CHART = 'pie_chart',
    PIE_CHART_OUTLINED = 'pie_chart_outlined',
    PIN_DROP = 'pin_drop',
    PLACE = 'place',
    PLAY_ARROW = 'play_arrow',
    PLAY_CIRCLE_FILLED = 'play_circle_filled',
    PLAY_CIRCLE_OUTLINE = 'play_circle_outline',
    PLAY_FOR_WORK = 'play_for_work',
    PLAYLIST_ADD = 'playlist_add',
    PLAYLIST_ADD_CHECK = 'playlist_add_check',
    PLAYLIST_PLAY = 'playlist_play',
    PLUS_ONE = 'plus_one',
    POLL = 'poll',
    POLYMER = 'polymer',
    POOL = 'pool',
    PORTABLE_WIFI_OFF = 'portable_wifi_off',
    PORTRAIT = 'portrait',
    POWER = 'power',
    POWER_INPUT = 'power_input',
    POWER_SETTINGS_NEW = 'power_settings_new',
    PREGNANT_WOMAN = 'pregnant_woman',
    PRESENT_TO_ALL = 'present_to_all',
    PRINT = 'print',
    PRIORITY_HIGH = 'priority_high',
    PUBLIC = 'public',
    PUBLISH = 'publish',
    QUERY_BUILDER = 'query_builder',
    QUESTION_ANSWER = 'question_answer',
    QUEUE = 'queue',
    QUEUE_MUSIC = 'queue_music',
    QUEUE_PLAY_NEXT = 'queue_play_next',
    RADIO = 'radio',
    RADIO_BUTTON_CHECKED = 'radio_button_checked',
    RADIO_BUTTON_UNCHECKED = 'radio_button_unchecked',
    RATE_REVIEW = 'rate_review',
    RECEIPT = 'receipt',
    RECENT_ACTORS = 'recent_actors',
    RECORD_VOICE_OVER = 'record_voice_over',
    REDEEM = 'redeem',
    REDO = 'redo',
    REFRESH = 'refresh',
    REMOVE = 'remove',
    REMOVE_CIRCLE = 'remove_circle',
    REMOVE_CIRCLE_OUTLINE = 'remove_circle_outline',
    REMOVE_FROM_QUEUE = 'remove_from_queue',
    REMOVE_RED_EYE = 'remove_red_eye',
    REMOVE_SHOPPING_CART = 'remove_shopping_cart',
    REORDER = 'reorder',
    REPEAT = 'repeat',
    REPEAT_ONE = 'repeat_one',
    REPLAY = 'replay',
    REPLAY_10 = 'replay_10',
    REPLAY_30 = 'replay_30',
    REPLAY_5 = 'replay_5',
    REPLY = 'reply',
    REPLY_ALL = 'reply_all',
    REPORT = 'report',
    REPORT_PROBLEM = 'report_problem',
    RESTAURANT = 'restaurant',
    RESTAURANT_MENU = 'restaurant_menu',
    RESTORE = 'restore',
    RESTORE_PAGE = 'restore_page',
    RING_VOLUME = 'ring_volume',
    ROOM = 'room',
    ROOM_SERVICE = 'room_service',
    ROTATE_90_DEGREES_CCW = 'rotate_90_degrees_ccw',
    ROTATE_LEFT = 'rotate_left',
    ROTATE_RIGHT = 'rotate_right',
    ROUNDED_CORNER = 'rounded_corner',
    ROUTER = 'router',
    ROWING = 'rowing',
    RSS_FEED = 'rss_feed',
    RV_HOOKUP = 'rv_hookup',
    SATELLITE = 'satellite',
    SAVE = 'save',
    SAVE_ALT = 'save_alt',
    SCANNER = 'scanner',
    SCHEDULE = 'schedule',
    SCHOOL = 'school',
    SCREEN_LOCK_LANDSCAPE = 'screen_lock_landscape',
    SCREEN_LOCK_PORTRAIT = 'screen_lock_portrait',
    SCREEN_LOCK_ROTATION = 'screen_lock_rotation',
    SCREEN_ROTATION = 'screen_rotation',
    SCREEN_SHARE = 'screen_share',
    SD_CARD = 'sd_card',
    SD_STORAGE = 'sd_storage',
    SEARCH = 'search',
    SECURITY = 'security',
    SELECT_ALL = 'select_all',
    SEND = 'send',
    SENTIMENT_DISSATISFIED = 'sentiment_dissatisfied',
    SENTIMENT_NEUTRAL = 'sentiment_neutral',
    SENTIMENT_SATISFIED = 'sentiment_satisfied',
    SENTIMENT_VERY_DISSATISFIED = 'sentiment_very_dissatisfied',
    SENTIMENT_VERY_SATISFIED = 'sentiment_very_satisfied',
    SETTINGS = 'settings',
    SETTINGS_APPLICATIONS = 'settings_applications',
    SETTINGS_BACKUP_RESTORE = 'settings_backup_restore',
    SETTINGS_BLUETOOTH = 'settings_bluetooth',
    SETTINGS_BRIGHTNESS = 'settings_brightness',
    SETTINGS_CELL = 'settings_cell',
    SETTINGS_ETHERNET = 'settings_ethernet',
    SETTINGS_INPUT_ANTENNA = 'settings_input_antenna',
    SETTINGS_INPUT_COMPONENT = 'settings_input_component',
    SETTINGS_INPUT_COMPOSITE = 'settings_input_composite',
    SETTINGS_INPUT_HDMI = 'settings_input_hdmi',
    SETTINGS_INPUT_SVIDEO = 'settings_input_svideo',
    SETTINGS_OVERSCAN = 'settings_overscan',
    SETTINGS_PHONE = 'settings_phone',
    SETTINGS_POWER = 'settings_power',
    SETTINGS_REMOTE = 'settings_remote',
    SETTINGS_SYSTEM_DAYDREAM = 'settings_system_daydream',
    SETTINGS_VOICE = 'settings_voice',
    SHARE = 'share',
    SHOP = 'shop',
    SHOP_TWO = 'shop_two',
    SHOPPING_BASKET = 'shopping_basket',
    SHOPPING_CART = 'shopping_cart',
    SHORT_TEXT = 'short_text',
    SHOW_CHART = 'show_chart',
    SHUFFLE = 'shuffle',
    SIGNAL_CELLULAR_4_BAR = 'signal_cellular_4_bar',
    SIGNAL_CELLULAR_CONNECTED_NO_INTERNET_4_BAR = 'signal_cellular_connected_no_internet_4_bar',
    SIGNAL_CELLULAR_NO_SIM = 'signal_cellular_no_sim',
    SIGNAL_CELLULAR_NULL = 'signal_cellular_null',
    SIGNAL_CELLULAR_OFF = 'signal_cellular_off',
    SIGNAL_WIFI_4_BAR = 'signal_wifi_4_bar',
    SIGNAL_WIFI_4_BAR_LOCK = 'signal_wifi_4_bar_lock',
    SIGNAL_WIFI_OFF = 'signal_wifi_off',
    SIM_CARD = 'sim_card',
    SIM_CARD_ALERT = 'sim_card_alert',
    SKIP_NEXT = 'skip_next',
    SKIP_PREVIOUS = 'skip_previous',
    SLIDESHOW = 'slideshow',
    SLOW_MOTION_VIDEO = 'slow_motion_video',
    SMARTPHONE = 'smartphone',
    SMOKE_FREE = 'smoke_free',
    SMOKING_ROOMS = 'smoking_rooms',
    SMS = 'sms',
    SMS_FAILED = 'sms_failed',
    SNOOZE = 'snooze',
    SORT = 'sort',
    SORT_BY_ALPHA = 'sort_by_alpha',
    SPA = 'spa',
    SPACE_BAR = 'space_bar',
    SPEAKER = 'speaker',
    SPEAKER_GROUP = 'speaker_group',
    SPEAKER_NOTES = 'speaker_notes',
    SPEAKER_NOTES_OFF = 'speaker_notes_off',
    SPEAKER_PHONE = 'speaker_phone',
    SPELLCHECK = 'spellcheck',
    STAR = 'star',
    STAR_BORDER = 'star_border',
    STAR_HALF = 'star_half',
    STARS = 'stars',
    STAY_CURRENT_LANDSCAPE = 'stay_current_landscape',
    STAY_CURRENT_PORTRAIT = 'stay_current_portrait',
    STAY_PRIMARY_LANDSCAPE = 'stay_primary_landscape',
    STAY_PRIMARY_PORTRAIT = 'stay_primary_portrait',
    STOP = 'stop',
    STOP_SCREEN_SHARE = 'stop_screen_share',
    STORAGE = 'storage',
    STORE = 'store',
    STORE_MALL_DIRECTORY = 'store_mall_directory',
    STRAIGHTEN = 'straighten',
    STREETVIEW = 'streetview',
    STRIKETHROUGH_S = 'strikethrough_s',
    STYLE = 'style',
    SUBDIRECTORY_ARROW_LEFT = 'subdirectory_arrow_left',
    SUBDIRECTORY_ARROW_RIGHT = 'subdirectory_arrow_right',
    SUBJECT = 'subject',
    SUBSCRIPTIONS = 'subscriptions',
    SUBTITLES = 'subtitles',
    SUBWAY = 'subway',
    SUPERVISOR_ACCOUNT = 'supervisor_account',
    SURROUND_SOUND = 'surround_sound',
    SWAP_CALLS = 'swap_calls',
    SWAP_HORIZ = 'swap_horiz',
    SWAP_VERT = 'swap_vert',
    SWAP_VERTICAL_CIRCLE = 'swap_vertical_circle',
    SWITCH_CAMERA = 'switch_camera',
    SWITCH_VIDEO = 'switch_video',
    SYNC = 'sync',
    SYNC_DISABLED = 'sync_disabled',
    SYNC_PROBLEM = 'sync_problem',
    SYSTEM_UPDATE = 'system_update',
    SYSTEM_UPDATE_ALT = 'system_update_alt',
    TAB = 'tab',
    TAB_UNSELECTED = 'tab_unselected',
    TABLET = 'tablet',
    TABLET_ANDROID = 'tablet_android',
    TABLET_MAC = 'tablet_mac',
    TAG_FACES = 'tag_faces',
    TAP_AND_PLAY = 'tap_and_play',
    TERRAIN = 'terrain',
    TEXT_FIELDS = 'text_fields',
    TEXT_FORMAT = 'text_format',
    TEXTSMS = 'textsms',
    TEXTURE = 'texture',
    THEATERS = 'theaters',
    THUMB_DOWN = 'thumb_down',
    THUMB_UP = 'thumb_up',
    THUMBS_UP_DOWN = 'thumbs_up_down',
    TIME_TO_LEAVE = 'time_to_leave',
    TIMELAPSE = 'timelapse',
    TIMELINE = 'timeline',
    TIMER = 'timer',
    TIMER_10 = 'timer_10',
    TIMER_3 = 'timer_3',
    TIMER_OFF = 'timer_off',
    TITLE = 'title',
    TOC = 'toc',
    TODAY = 'today',
    TOLL = 'toll',
    TONALITY = 'tonality',
    TOUCH_APP = 'touch_app',
    TOYS = 'toys',
    TRACK_CHANGES = 'track_changes',
    TRAFFIC = 'traffic',
    TRAIN = 'train',
    TRAM = 'tram',
    TRANSFER_WITHIN_A_STATION = 'transfer_within_a_station',
    TRANSFORM = 'transform',
    TRANSLATE = 'translate',
    TRENDING_DOWN = 'trending_down',
    TRENDING_FLAT = 'trending_flat',
    TRENDING_UP = 'trending_up',
    TUNE = 'tune',
    TURNED_IN = 'turned_in',
    TURNED_IN_NOT = 'turned_in_not',
    TV = 'tv',
    UNARCHIVE = 'unarchive',
    UNDO = 'undo',
    UNFOLD_LESS = 'unfold_less',
    UNFOLD_MORE = 'unfold_more',
    UPDATE = 'update',
    USB = 'usb',
    VERIFIED_USER = 'verified_user',
    VERTICAL_ALIGN_BOTTOM = 'vertical_align_bottom',
    VERTICAL_ALIGN_CENTER = 'vertical_align_center',
    VERTICAL_ALIGN_TOP = 'vertical_align_top',
    VIBRATION = 'vibration',
    VIDEO_CALL = 'video_call',
    VIDEO_LABEL = 'video_label',
    VIDEO_LIBRARY = 'video_library',
    VIDEOCAM = 'videocam',
    VIDEOCAM_OFF = 'videocam_off',
    VIDEOGAME_ASSET = 'videogame_asset',
    VIEW_AGENDA = 'view_agenda',
    VIEW_ARRAY = 'view_array',
    VIEW_CAROUSEL = 'view_carousel',
    VIEW_COLUMN = 'view_column',
    VIEW_COMFY = 'view_comfy',
    VIEW_COMPACT = 'view_compact',
    VIEW_DAY = 'view_day',
    VIEW_HEADLINE = 'view_headline',
    VIEW_LIST = 'view_list',
    VIEW_MODULE = 'view_module',
    VIEW_QUILT = 'view_quilt',
    VIEW_STREAM = 'view_stream',
    VIEW_WEEK = 'view_week',
    VIGNETTE = 'vignette',
    VISIBILITY = 'visibility',
    VISIBILITY_OFF = 'visibility_off',
    VOICE_CHAT = 'voice_chat',
    VOICEMAIL = 'voicemail',
    VOLUME_DOWN = 'volume_down',
    VOLUME_MUTE = 'volume_mute',
    VOLUME_OFF = 'volume_off',
    VOLUME_UP = 'volume_up',
    VPN_KEY = 'vpn_key',
    VPN_LOCK = 'vpn_lock',
    WALLPAPER = 'wallpaper',
    WARNING = 'warning',
    WATCH = 'watch',
    WATCH_LATER = 'watch_later',
    WB_AUTO = 'wb_auto',
    WB_CLOUDY = 'wb_cloudy',
    WB_INCANDESCENT = 'wb_incandescent',
    WB_IRIDESCENT = 'wb_iridescent',
    WB_SUNNY = 'wb_sunny',
    WC = 'wc',
    WEB = 'web',
    WEB_ASSET = 'web_asset',
    WEEKEND = 'weekend',
    WHATSHOT = 'whatshot',
    WIDGETS = 'widgets',
    WIFI = 'wifi',
    WIFI_LOCK = 'wifi_lock',
    WIFI_TETHERING = 'wifi_tethering',
    WORK = 'work',
    WRAP_TEXT = 'wrap_text',
    YOUTUBE_SEARCHED_FOR = 'youtube_searched_for',
    ZOOM_IN = 'zoom_in',
    ZOOM_OUT = 'zoom_out',
    ZOOM_OUT_MAP = 'zoom_out_map',
    CANCEL_SCHEDULE_SEND = 'cancel_schedule_send',
    HOW_TO_VOTE = 'how_to_vote',
}

export default MaterialIcons;
