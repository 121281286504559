import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

import { cssP } from '../../../stylesheets/snippets';
import { color, meaningColor } from '../../../stylesheets/shared';
import { Config } from './index';

// Numbers
export const Percentage = styled.span`
    ${cssP}
    font-weight: bold;
`;
export const Count = styled.span`
    ${cssP}
    display: inline-block;
    margin-left: 4px;
`;

// value line
interface ValueLineProps {
    switched: boolean;
}
const valueLineColor = (switched: boolean): string => (switched ? meaningColor.minus : meaningColor.plus);
export const ValueLine = styled(animated.div)<ValueLineProps>`
    position: absolute;
    top: 8px;
    height: 8px;
    border-radius: 0 4px 4px 0;
    background-color: ${(p: ValueLineProps) => valueLineColor(p.switched)};

    ${({ switched }) => {
        if (switched) {
            return css`
                transform: rotate(180deg);
                transform-origin: 0% 50%;
            `;
        }
    }}
`;
// bar line
interface BarLineLineProps {
    doubleSign?: boolean;
}
export const BarLine = styled.div<BarLineLineProps>`
    position: relative;
    height: 24px;

    &:before {
        position: absolute;
        content: '';
        display: block;
        top: 8px;
        left: 0;
        right: 0;
        height: 8px;
        background-color: #ededed;
        border-radius: ${({ doubleSign }) => (doubleSign === true ? '4px' : '0 4px 4px 0')};
    }
    ${({ doubleSign }) =>
        doubleSign === true
            ? css`
                  &:after {
                      position: absolute;
                      content: '';
                      display: block;
                      top: 0;
                      bottom: 0;
                      background-color: ${color.grey2};
                      width: 1px;
                      left: 50%;
                  }
              `
            : ``}
`;

// top line
export const TopLineBlocks = styled.div``;
export const TopLineNumbers = styled(animated.div)`
    margin-left: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    flex-wrap: nowrap;
    justify-content: flex-end;
`;
export const TopLine = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
`;

// Bar
export const Bar = styled.div<{ config: Config }>`
    /* style override */
    ${({ config }) => config.styleOverride}
`;

// bottom line
export const Numbers = styled(animated.div)`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    flex-wrap: nowrap;
`;
export const BottomLine = styled(animated.div)`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    ${Numbers}:first-child {
        margin-right: 6px;
        justify-content: flex-start;
    }
    ${Numbers}:last-child {
        margin-left: 6px;
        justify-content: flex-end;
    }
`;
