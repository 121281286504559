import firebase from 'firebase/app';
import 'firebase/functions';

import { Event } from './interfaces';
import { isItVotingEvent, isItProjectEvent, isItAdminEvent, isItAuthEvent, isItSchoolPbEvent } from './tools';
import conf from '../../configuration/configuration.json';
import firebaseConf from '../../configuration/firebase.client.json';

const getFunctions = () =>
    conf.useLocalFunctionsEmulator === true ? firebase.app().functions() : firebase.app().functions(firebaseConf.functionsRegion);

const streamVotingEvent = (event: Event<any>): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        const votingStreamFnc = getFunctions().httpsCallable('stream-voting');

        votingStreamFnc(event)
            .then((res: firebase.functions.HttpsCallableResult) => {
                resolve(res.data);
            })
            .catch((error: firebase.functions.HttpsError) => {
                reject();
                throw new Error(`Streaming Voting error: ${error}`);
            });
    });
};

const streamProjectEvent = (event: Event<any>): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        const projectStreamFnc = getFunctions().httpsCallable('stream-project');

        projectStreamFnc(event)
            .then((res: firebase.functions.HttpsCallableResult) => {
                resolve(res.data);
            })
            .catch((error: firebase.functions.HttpsError) => {
                reject();
                throw new Error(`Streaming Project error: ${error}`);
            });
    });
};

const streamAdminEvent = (event: Event<any>): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        const adminStreamFnc = getFunctions().httpsCallable('stream-admin');

        adminStreamFnc(event)
            .then((res: firebase.functions.HttpsCallableResult) => {
                resolve(res.data);
            })
            .catch((error: firebase.functions.HttpsError) => {
                reject();
                throw new Error(`Streaming Admin error: ${error}`);
            });
    });
};

const streamAuthEvent = (event: Event<any>): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        const authStreamFnc = getFunctions().httpsCallable('stream-auth');

        authStreamFnc(event)
            .then((res: firebase.functions.HttpsCallableResult) => {
                resolve(res.data);
            })
            .catch((error: firebase.functions.HttpsError) => {
                reject();
                throw new Error(`Streaming Auth error: ${error}`);
            });
    });
};

const streamSchoolPbEvent = (event: Event<any>): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        const authStreamFnc = getFunctions().httpsCallable('stream-schoolpb');

        authStreamFnc(event)
            .then((res: firebase.functions.HttpsCallableResult) => {
                resolve(res.data);
            })
            .catch((error: firebase.functions.HttpsError) => {
                reject();
                throw new Error(`Streaming School Pb error: ${error}`);
            });
    });
};

export const stream = (event: Event<any>): Promise<any> => {
    // voting
    if (isItVotingEvent(event.type)) {
        return streamVotingEvent(event);
    }
    // project
    else if (isItProjectEvent(event.type)) {
        return streamProjectEvent(event);
    }
    // admin
    else if (isItAdminEvent(event.type)) {
        return streamAdminEvent(event);
    }
    // auth
    else if (isItAuthEvent(event.type)) {
        return streamAuthEvent(event);
    }
    //school pb
    else if (isItSchoolPbEvent(event.type)) {
        return streamSchoolPbEvent(event);
    }

    throw new Error(`Cannot recognize right stream for event: ${event}`);
};
