import { isNil as _isNil, defer as _defer } from 'lodash';
import React, { useState, useEffect } from 'react';
import { TimePicker as AntTimePicker } from 'antd';

import { BlockProps, Tools, TimePicker as TimePickerConfig } from '../../../../core/block';
import { useOutlets, useInlets } from '../../../hooks';
import * as Styled from './styled';
import moment, { Moment } from 'moment';

export type Config = TimePickerConfig;
export type Props = BlockProps &
    Config & {
        onSubmit?: () => void;
    };

const TimePicker = ({ timeFormat = 'HH:mm', ...props }: Props) => {
    const outlet = useOutlets();
    const inlets = useInlets(props.inlets);
    const [time, setTime] = useState(moment(''));

    useEffect(() => {
        if (!props.inlets) {
            return;
        }

        if (Tools.isInletResolved(inlets.value) && !_isNil(inlets.value)) {
            const strMoment = inlets.value as string;
            if (strMoment.length !== 0) {
                const newTime = moment(strMoment);
                setTime(newTime);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets)]);

    const processChangeOutlet = (newDate: Moment) => {
        if (_isNil(props.outlets?.change)) {
            return;
        }

        const outletParameters = {
            value: newDate.toISOString(),
        };

        _defer(() => outlet(props.outlets?.change, outletParameters));
    };

    const setDates = (newTime: Moment | null) => {
        if (!_isNil(newTime)) {
            setTime(newTime);
            processChangeOutlet(newTime);
        }
    };

    return (
        <Styled.Time {...Tools.extractConfig<Config>(props)}>
            {/* label */}
            {!_isNil(props.text) && <Styled.Label>{props.text}</Styled.Label>}

            {/* time picker */}
            <AntTimePicker
                onChange={(value) => {
                    setDates(value);
                }}
                value={time.isValid() ? time : null}
                allowClear={false}
                format={timeFormat}
            />
            <Styled.DropdownStyles />
        </Styled.Time>
    );
};

export default TimePicker;
