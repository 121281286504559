import { isNil as _isNil, defer as _defer, size as _size, isEqual as _isEqual, debounce as _debounce } from 'lodash';
import React, { useState, SyntheticEvent, useEffect, useCallback } from 'react';
import { Input } from 'antd';

import { BlockProps, Tools, FormFieldStringLong as FormFieldStringLongConfig, FormFieldInlets } from '../../../../core/block';
import { useOutlets, useInlets } from '../../../hooks';
import { useStringValidation, ValidationResult, VALIDATION_RESULT_UNTOUCHED, getOutletValidationStatus } from '../../../hooks/useValidation';
import * as Styled from './styled';
import { interpolate } from '../../../../core/tools';

export type Config = FormFieldStringLongConfig;
export type Props = BlockProps &
    Config & {
        onSubmit?: () => void;
        onChange?: (value: string, validationResult: ValidationResult) => void;
    };

const AntTextArea = Input.TextArea;

const StringFormFieldLong = (props: Props) => {
    const outlet = useOutlets();
    const inlets = useInlets(props.inlets) as FormFieldInlets;
    const defaultValueInlet = useInlets(props.defaultValueInlet, { processAsDefaultValueInlet: true, property: 'value' }) as FormFieldInlets;
    // const [value, setValue] = useState<string>();
    // storing value of the textarea, so that the "maxChars" message has access to it
    const validate = useStringValidation(props.validationScheme || {});
    const [validationResult, setValidationResult] = useState(VALIDATION_RESULT_UNTOUCHED);
    const [cachedValue, setCachedValue] = useState(props.defaultValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const processChangeOutlet = useCallback(
        _debounce((value: string, validationResult: ValidationResult) => {
            if (_isNil(props.outlets?.change)) {
                return;
            }

            const outletParameters = {
                value,
                validationStatus: getOutletValidationStatus(validationResult),
            };

            _defer(() => outlet(props.outlets?.change, outletParameters));
        }, 250),
        [],
    );

    // on mount, set form validity state in pristine state of the component
    useEffect(() => {
        (async () => {
            const validationResult = await validate(cachedValue);
            processChangeOutlet(cachedValue || '', validationResult);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const processChange = (value: string, validationResult: ValidationResult) => {
        if (_isNil(props.onChange)) {
            return;
        }

        props.onChange(value, validationResult);
    };

    const processValidation = async (value: string): Promise<ValidationResult> => {
        const validationResult = await validate(value);
        setValidationResult(validationResult);

        return validationResult;
    };

    // TODO possible candidate for refactoring into useInletValue()
    useEffect(() => {
        // we are not expecting any inlet
        if (_isNil(props.inlets)) {
            return;
        }

        // is inlet already resolved?
        if (Tools.isInletResolved(inlets.value)) {
            // ... yes value is defined, and it's different then cached value -> use it
            if (!_isNil(inlets.value) && !_isEqual(inlets.value, cachedValue)) {
                // use it as cached value ...
                setCachedValue(inlets.value);
                // ... and validate the value
                processValidation(inlets.value);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets.value)]);

    // set default value
    useEffect(() => {
        if (_isNil(props.defaultValueInlet)) {
            return;
        }

        // is inlet already resolved?
        if (Tools.isInletResolved(defaultValueInlet.value)) {
            // ... yes value is defined, and it's different then cached value -> use it
            if (!_isNil(defaultValueInlet.value)) {
                // use it as cached value ...
                setCachedValue(defaultValueInlet.value);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(defaultValueInlet.value)]);

    const handleChange = async (event: SyntheticEvent) => {
        // get value from input
        const value = (event.target as HTMLTextAreaElement).value;
        // store it as local value
        setCachedValue(value);

        // validate the value
        processValidation(value).then((validationResult) => {
            // process all changes
            processChangeOutlet(value, validationResult);
            processChange(value, validationResult);
        });
    };

    // TODO - I think it makes no sense without Formik
    // const onSubmit = () => {
    //     if (!_isNil(props.onSubmit)) {
    //         props.onSubmit();
    //     }
    // };

    return (
        <Styled.String {...Tools.extractConfig<Config>(props)} onChange={handleChange}>
            {/* label */}
            {!_isNil(props.text) && <Styled.Label>{props.text}</Styled.Label>}

            {/* input */}
            <Styled.FormItem
                hasFeedback={!_isNil(props.validationScheme)}
                validateStatus={validationResult.antValidationStatus}
                help={validationResult.message}
            >
                <AntTextArea
                    placeholder={props.placeholder}
                    autoFocus={props.autoFocus}
                    rows={props.rows}
                    maxLength={props.maxLength}
                    value={cachedValue}
                    autoComplete="off"
                />
                {props.maxLength && props.maxLengthMessage && (
                    <Styled.MaxCharsMessage>
                        {interpolate(props.maxLengthMessage, {
                            length: _size(cachedValue),
                            maxLength: props.maxLength,
                        })}
                    </Styled.MaxCharsMessage>
                )}
            </Styled.FormItem>
        </Styled.String>
    );
};

export default StringFormFieldLong;
