import { Application as ApplicationActions } from '../../../../core/action';
import { Store } from '../interfaces';

const reducer = (state: Store, payload: ApplicationActions.showPopup.Payload): Store => {
    return {
        ...state,
        popup: {
            websiteId: payload.websiteId,
            dialogId: payload.dialogId,
            blocks: payload.blocks,
            overlayColor: payload.overlayColor,
        },
    };
};

export default reducer;
