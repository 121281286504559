import { isNil as _isNil } from 'lodash';

import { StoreSelectorFunction } from '../../../../core/selector';
import { RootState } from '../../root_reducer';
import { Store } from '../interfaces';
import getStore from './get_store';
import { TaskProgressInfo } from '../../../../core/block';

export interface Payload {
    taskGroupId: string; // task group id
}

export default (payload: Payload): StoreSelectorFunction<TaskProgressInfo> => (rootStore: RootState): TaskProgressInfo => {
    const store: Store = getStore(rootStore);

    const initialVal: TaskProgressInfo = {
        totalTasksCount: 0,
        doneTasksCount: 0,
        totalState: 'normal',
    };

    if (_isNil(store)) {
        return initialVal;
    }

    const group = store.taskGroup[payload.taskGroupId];
    if (!_isNil(group)) {
        return group.taskProgressInfo;
    }

    return initialVal;
};
