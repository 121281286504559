import { css, FlattenSimpleInterpolation, FlattenInterpolation } from 'styled-components';

import { resolution } from './shared';

// resolution
export const onTablet = (styles: FlattenSimpleInterpolation | FlattenInterpolation<any>): FlattenInterpolation<any> => {
    return css`
        @media (max-width: ${resolution.tablet}) {
            ${styles}
        }
    `;
};

export const onPhone = (styles: FlattenSimpleInterpolation | FlattenInterpolation<any>): FlattenInterpolation<any> => {
    return css`
        @media (max-width: ${resolution.phone}) {
            ${styles}
        }
    `;
};

export const onVerySmallPhone = (styles: FlattenSimpleInterpolation | FlattenInterpolation<any>): FlattenInterpolation<any> => {
    return css`
        @media (max-width: ${resolution.verySmallPhone}) {
            ${styles}
        }
    `;
};

// supported features in browser
export const ifHoverIsSupported = (styles: FlattenSimpleInterpolation | FlattenInterpolation<any>): FlattenInterpolation<any> => {
    return css`
        @media (hover: hover) {
            ${styles}
        }
    `;
};
