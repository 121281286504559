import { Status as ValidationStatus } from '../../../core/validation';

export type FormValue = Record<string, FieldValue>;
export type FieldValue = undefined | string | number | boolean;

// Store
export interface Store {
    fields: {
        validationStatus?: Record<string, Record<string, ValidationStatus>>; // form ID -> field name -> validation status
        value?: Record<string, FormValue>; // form ID -> field name -> value
    };
    form: {
        validationStatus?: Record<string, ValidationStatus>; // form ID -> validation status
    };
}
export const initialStore: Store = {
    fields: {},
    form: {},
};
