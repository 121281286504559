import styled, { css } from 'styled-components';

import { ifHoverIsSupported } from '../../../../stylesheets/tools';
import { color, animation } from '../../../../stylesheets/shared';

// preview
export const Preview = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('https://www.howtogeek.com/wp-content/uploads/2010/02/webpagescreenshot01.png');
    transition: transform ${animation.timing.interactive} ease-out;
`;
export const PreviewWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 240px;
    overflow: hidden;
    box-sizing: border-box;
    mask-image: radial-gradient(white, black);
`;

// item
export const Item = styled.div`
    width: 240px;
    height: 320px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    border-color: ${color.grey2};
    background-color: ${color.white};
    transition: transform ${animation.timing.interactive} ease-out;

    ${ifHoverIsSupported(css`
        &:hover {
            transform: scale(1.02);

            ${Preview} {
                transform: scale(1.06);
            }
        }
    `)}
`;
