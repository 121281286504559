import { isNil as _isNil, isEqual as _isEqual, isEmpty as _isEmpty, clone as _clone } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useInlets } from '../../../hooks';
import { Block, BlockProps, TimeSwitchInlets, TimeSwitch as TimeSwitchConfig } from '../../../../core/block';
import Blocks from '../../Blocks';
import moment, { Moment } from 'moment';

export type Config = TimeSwitchConfig;
export type Props = BlockProps & Config;

const TimeSwitch = (props: Props) => {
    const inlets = useInlets(props.inlets) as TimeSwitchInlets;
    const [timerInfo, setTimerInfo] = useState({
        timerId: -1,
        timeout: -1,
        fromTime: moment(),
        strFromTime: '',
        toTime: moment(),
        strToTime: '',
        currentTime: moment(),
        startImmediately: false,
    });

    const getTimeout = (time: Moment | undefined) => {
        let result = -1;

        if (!_isNil(time)) {
            if (timerInfo.fromTime.isValid()) {
                result = timerInfo.fromTime.diff(time, 'milliseconds');
            }

            if (result < 0 && timerInfo.toTime.isValid()) {
                result = timerInfo.toTime.diff(time, 'milliseconds');
            }

            if (result > 0) {
                result += 150;
            }
        }
        return result;
    };

    useEffect(() => {
        // we are not expecting any inlet
        if (!_isNil(props.inlets)) {
            const timerInfoCpy = _clone(timerInfo);
            let switchSettingsChanged = false;

            if (!_isNil(inlets.fromTime) && !_isEmpty(inlets.fromTime) && !_isEqual(inlets.fromTime, timerInfo.strFromTime)) {
                if (inlets.fromTime === 'now') {
                    timerInfoCpy.strFromTime = 'now';
                    timerInfoCpy.fromTime = moment();
                } else {
                    timerInfoCpy.strFromTime = inlets.fromTime;
                    timerInfoCpy.fromTime = moment(inlets.fromTime);
                }
                switchSettingsChanged = true;
            }

            if (!_isNil(inlets.toTime) && !_isEmpty(inlets.toTime) && !_isEqual(inlets.toTime, timerInfo.strToTime)) {
                if (inlets.toTime === 'now') {
                    timerInfoCpy.strToTime = 'now';
                    timerInfoCpy.toTime = moment();
                } else {
                    timerInfoCpy.strToTime = inlets.toTime;
                    timerInfoCpy.toTime = moment(inlets.toTime);
                }
                switchSettingsChanged = true;
            }

            if (!_isNil(inlets.startImmediately) && !_isEqual(inlets.startImmediately, timerInfo.startImmediately)) {
                // use it as cached value ...
                timerInfoCpy.startImmediately = inlets.startImmediately;
                switchSettingsChanged = true;
            }

            if (switchSettingsChanged === true) {
                clearTimeout(timerInfoCpy.timerId);
                timerInfoCpy.currentTime = moment();
                timerInfoCpy.timerId = -1;
                setTimerInfo(timerInfoCpy);
                return;
            }
        }

        if (timerInfo.startImmediately && timerInfo.timerId === -1) {
            const timeout = getTimeout(timerInfo.currentTime);
            if (timeout > 0) {
                const timer = setInterval(() => {
                    const timerInfoCpy = _clone(timerInfo);
                    timerInfoCpy.currentTime = moment();
                    clearTimeout(timerInfo.timerId);
                    timerInfoCpy.timerId = -1;
                    setTimerInfo(timerInfoCpy);
                }, timeout);

                const timerInfoCpy = _clone(timerInfo);
                timerInfoCpy.timerId = timer;
                setTimerInfo(timerInfoCpy);
            } else if (timerInfo.timerId !== -1) {
                clearTimeout(timerInfo.timerId);
            }
        }

        return () => clearTimeout(timerInfo.timerId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets), timerInfo]);

    const getBlocks = (): Block[] | undefined => {
        if (timerInfo.startImmediately === true && timerInfo.fromTime.isValid() && timerInfo.toTime.isValid()) {
            if (timerInfo.currentTime.isBefore(timerInfo.fromTime)) {
                return props.intervals.before;
            } else if (timerInfo.currentTime.isBefore(timerInfo.toTime) && timerInfo.currentTime.isAfter(timerInfo.fromTime)) {
                return props.intervals.within;
            } else {
                if (timerInfo.timerId > 0) {
                    clearTimeout(timerInfo.timerId);
                }
                return props.intervals.after;
            }
        } else {
            return props.intervals.before;
        }
    };

    return (
        <>
            {/* stored step exists */}
            <Blocks blocks={getBlocks()} />
        </>
    );
};
export default TimeSwitch;
