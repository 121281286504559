import { Choice } from '../../voting';
import { Name as EntityName } from '../../entity';

export const TYPE = `${EntityName.ACTION_PREFIX}VOTING:choice`;

export interface Payload {
    q: string; // question Id
    o: string; // option Id
    choice: Choice.Choice;
}
