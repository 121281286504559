import { Dispatch } from 'redux';

import { Effect, Dialog as DialogActions, create as createAction } from '../../../../core/action';
import { Streamer, Events, Event } from '../../../../core/stream';

export default function (payload: DialogActions.storeStepperStep.Payload): Effect {
    return async (dispatch: Dispatch<any>): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const websiteId = payload.w;
                const stepperId = payload.s;
                const stepName = payload.sn;

                const event: Event<Events.StoreStepperStepEventPayload> = Events.storeStepperStep(websiteId, stepperId, stepName);
                const result = await Streamer.stream(event);

                dispatch(createAction(DialogActions.storeStepperStepSuccess.TYPE));
                resolve(result);
            } catch (error) {
                dispatch(createAction(DialogActions.storeStepperStepError.TYPE));
                reject(error);
            }
        });
    };
}
