import { isNil as _isNil } from 'lodash';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const StyledIcon = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: currentColor;
`;

export const StyledIconWithoutColoring = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const StyledIconWrapper = styled.i`
    position: relative;
    width: 1em;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &:before {
        position: relative;
        content: '';
        display: block;
        padding-top: 100%;
    }
`;

type Props = HTMLAttributes<HTMLElement> & {
    onClick?: () => void;
};

const Icon = (props: Props) => {
    const onClick = () => {
        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <StyledIconWrapper onClick={onClick} className={props.className}>
            <StyledIcon viewBox="0 0 1024 1024">
                <path d="M512 0c282.581 0 512 229.419 512 512s-229.419 512-512 512c-282.581 0-512-229.419-512-512s229.419-512 512-512zM426.837 616.149l206.336 152.444c23.548 12.992 40.538 6.263 46.404-21.858l83.989-395.789c8.597-34.475-13.141-50.116-35.669-39.889l-493.184 190.17c-33.664 13.504-33.463 32.286-6.135 40.653l126.562 39.505 293.005-184.853c13.833-8.388 26.53-3.883 16.111 5.367l-237.419 214.251z" />
            </StyledIcon>
            <StyledIconWithoutColoring viewBox="0 0 1024 1024">
                <path
                    fill="rgb(236, 236, 236)"
                    d="M355.123 581.38l0.017 0.004 293.005-184.853c13.833-8.388 26.53-3.883 16.111 5.367l-237.419 214.251 57.83 42.726-66.534 87.791c-16.589 0-13.769-6.263-19.49-22.059l-43.52-143.228z"
                />
                <path
                    fill="rgb(181, 181, 181)"
                    d="M426.846 616.154l78.387 57.916-61.5 59.797c-7.147 6.946-12.8 12.8-25.6 12.8l8.713-130.513zM426.85 616.137l-0.004 0.004v-0.004h0.004z"
                />
            </StyledIconWithoutColoring>
        </StyledIconWrapper>
    );
};

export default Icon;
