import { isNil as _isNil } from 'lodash';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const StyledIcon = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    fill: currentColor;
`;

export const StyledIconWrapper = styled.i`
    position: relative;
    width: 1em;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &:before {
        position: relative;
        content: '';
        display: block;
        padding-top: 100%;
    }
`;

type Props = HTMLAttributes<HTMLElement> & {
    onClick?: () => void;
};

const Facebook = (props: Props) => {
    const onClick = () => {
        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <StyledIconWrapper onClick={onClick} className={props.className}>
            <StyledIcon viewBox="0 0 1024 1024">
                <path d="M952.889 16h-881.778c-30.437 0-55.111 24.674-55.111 55.111v881.778c0 30.437 24.674 55.111 55.111 55.111h474.782v-383.573h-128.822v-150.178h128.822v-110.222c0-128.133 78.258-197.987 192.889-197.987 38.565-0.112 77.106 1.865 115.458 5.924v133.369h-79.084c-62.138 0-74.262 29.622-74.262 73.022v95.618h148.662l-19.289 150.178h-129.373v383.849h251.996c30.437 0 55.111-24.674 55.111-55.111v-881.778c0-30.437-24.674-55.111-55.111-55.111z" />
            </StyledIcon>
        </StyledIconWrapper>
    );
};

export default Facebook;
