import { Name as EntityName } from '../../entity';

export const TYPE = `${EntityName.ACTION_PREFIX}AUTHENTICATION:save_pin_set`;

export interface Payload {
    pinSetId: string;
    pinCountPerPage: number;
    pollName: string;
    locale?: string;
    target: {
        type: string;
        id: string;
    };
}
