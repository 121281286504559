import { isNil as _isNil, forEach as _forEach } from 'lodash';

import { Country } from '../interfaces';
import Countries from '../countries';
import UnknownCountry from '../unknown_country';

export default (languageCode: string): Country => {
    if (_isNil(languageCode)) {
        return UnknownCountry;
    }

    let res: Country = UnknownCountry;

    _forEach(Countries, (country) => {
        if (country.languageCode === languageCode) {
            res = country;
        }
    });
    return res;
};
