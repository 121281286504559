import React from 'react';

import _defer from 'lodash/defer';

import { FileUploader as FileUploaderConfig, Attachment } from '../../../../core/block/interfaces';
import { FileUploader } from '../../../components/FileUploader';
import { useOutlets } from '../../../hooks';

export const FileUploaderBlock: React.FC<FileUploaderConfig> = (props: FileUploaderConfig) => {
    const outlet = useOutlets();

    const handleChange = (attachments: Attachment[]) => {
        if (props.outlets?.change) {
            // update Change outlet ... add "vs" and "v"
            const payload = {
                ...props.outlets?.change.payload,
                vs: true,
                v: attachments,
            };

            // TODO fire change event only if there is some real change

            const extendedOutlet = {
                ...props.outlets?.change,
                payload,
            };

            _defer(() => outlet(extendedOutlet));
        }
    };

    return <FileUploader {...props} onChange={handleChange} />;
};
