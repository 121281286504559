import { map as _map, size as _size } from 'lodash';
import React from 'react';

import { BlockProps, TableLikeList as TableLikeListConfig, TableLikeListRow as TableLikeListRowConfig, Tools } from '../../../core/block';
import Blocks from '../Blocks';
import HorizontalLine from '../HorizontalLine';
import * as Styled from './styled';

export type Config = TableLikeListConfig;
export type Props = BlockProps & Config;

const TableLikeList = (props: Props) => {
    return (
        <>
            {/* section */}
            <Styled.List {...Tools.extractConfig<Config>(props)}>
                {_map(props.rows, (row: TableLikeListRowConfig, rowIdx: number) => (
                    <div key={rowIdx}>
                        {/* row */}
                        <Styled.Row
                            columnsCount={_size(row.blocks)}
                            columsSizes={row.columsSizes || props.columsSizes}
                            alignItems={row.alignItems || props.alignItems}
                            alignContent={row.alignContent || props.alignContent}
                            styleOverride={row.styleOverride}
                        >
                            <Blocks blocks={row.blocks} />
                        </Styled.Row>

                        {/* horizontal line */}
                        {<HorizontalLine name="" />}
                    </div>
                ))}
            </Styled.List>
        </>
    );
};
export default TableLikeList;
