import styled, { css } from 'styled-components';
import { color } from '../../../../stylesheets/shared';

export const Obvody = styled.path`
    fill: ${color.white}; /* #c2c2c2; */
    stroke: ${color.black};
    stroke-width: 3px;
    ${(props: any) => {
        // console.log('===obvody', props);
        const id: string = props['data-custom-id'];
        const activeElements = props['data-active-elements'];
        if (!id || !activeElements) {
            return '';
        }

        if (activeElements[id]?.active) {
            return css`
                fill: #007aa9;
                stroke-width: 10px;
                &:hover {
                    fill: #d2232a;
                }
            `;
        }
    }}
`;

export const ObvodySub = styled.path`
    fill: #c2c2c2;

    fill: ${color.white}; /* #c2c2c2; */
    stroke: ${color.black};
    stroke-width: 10px;
`;

export const Mesta = styled.path`
    fill: #515151;
`;

export const MestaSub = styled.path`
    fill: #515151;
`;

export const Cisla = styled.text`
    font-size: 116px;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-stretch: normal;
    text-align: center;
    line-height: 125%;
    letter-spacing: 0px;
    word-spacing: 0px;
    text-anchor: middle;
    fill: #000000;
    fill-opacity: 1;
    stroke: none;
    font-family: Courier New;
    -inkscape-font-specification: Sans Bold;

    tspan {
        font-size: 72px;
        pointer-events: none;
    }
`;

export const PopisyGroup = styled.g`
    display: inline;

    text {
        font-size: 72px;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        font-stretch: normal;
        text-align: end;
        line-height: 125%;
        letter-spacing: 0px;
        word-spacing: 0px;
        text-anchor: end;
        fill: #000000;
        fill-opacity: 1;
        stroke: none;
        font-family: Courier New;
        -inkscape-font-specification: Courier New;

        tspan {
            font-size: 116px;
            font-weight: bold;
            -inkscape-font-specification: Courier New Bold;
        }
    }
`;
