import { isNil as _isNil, get as _get } from 'lodash';

import { StoreSelectorFunction } from '../../../../core/selector';
import { RootState } from '../../root_reducer';
import { Store } from '../interfaces';
import getStore from './get_store';

export interface Payload {
    f: string; // form id
}

export default (payload: Payload): StoreSelectorFunction<string | boolean> => (rootStore: RootState): string | boolean => {
    const store: Store = getStore(rootStore);
    if (_isNil(store)) {
        return false;
    }

    return _get(store.form.validationStatus, payload.f, false);
};
