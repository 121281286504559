import styled from 'styled-components';
import { color } from '../../stylesheets/shared';

export const MapContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;

export const MarkerPinCircle = styled.div`
    background: white;
    border: 3px solid blue;
    border-radius: 50%;
    width: 21px;
    height: 21px;
`;

export const MarkerPinPointy = styled.div<{ isHighlighted?: boolean }>`
    /* Taken from: https://codepen.io/andreasstorm/pen/ClguF */
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: ${({ isHighlighted }) => (isHighlighted ? color.red : '#89849b')};
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;

    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;

    &:after {
        content: '';
        width: 14px;
        height: 14px;
        margin: 8px 0 0 8px;
        background: #2f2f2f;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
    }
`;
