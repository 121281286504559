import styled from 'styled-components';

import { constant } from '../../stylesheets/shared';

export const Content = styled.div`
    box-sizing: border-box;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`;

export const Page = styled.div`
    text-align: center;
    position: relative;
    width: 100%;
    max-width: ${constant.page.size.standard}px;
    box-sizing: border-box;
`;
