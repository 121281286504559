import { SchoolPb as SchoolPbActions } from '../../../../core/action';
import { Store } from '../interfaces';

const reducer = (state: Store, payload: SchoolPbActions.resultsAnnouncementDate.Payload): Store => {
    const newState: Store = {
        ...state,
        resultsAnnouncementDate: payload.resultsAnnouncementDate,
    };

    return newState;
};

export default reducer;
