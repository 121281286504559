import * as Reducers from './reducers';
import { Store, initialStore } from './interfaces';
import { Application as ApplicationActions, Action } from '../../../core/action';

export default function (state: Store = initialStore, action: Action<any>) {
    switch (action.type) {
        case ApplicationActions.actionFlowTest.TYPE:
            console.log('ʃ FLOW TEST: local sync action executed');
            return state;

        case ApplicationActions.language.TYPE:
            return Reducers.language(state, action.payload as string);

        case ApplicationActions.showPopup.TYPE:
            return Reducers.showPopup(state, action.payload as ApplicationActions.showPopup.Payload);

        case ApplicationActions.hidePopup.TYPE:
            return Reducers.hidePopup(state);

        case ApplicationActions.shuffleGroup.TYPE:
            return Reducers.shuffleGroup(state, action.payload as ApplicationActions.shuffleGroup.Payload);

        case ApplicationActions.showLoading.TYPE:
            return Reducers.showLoading(state);

        case ApplicationActions.hideLoading.TYPE:
            return Reducers.hideLoading(state);

        default:
            return state;
    }
}
