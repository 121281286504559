import { Name as EntityName } from '../../entity';

export const TYPE = `${EntityName.ACTION_PREFIX}SCHOOLPB:add_pin_set`;

export interface Payload {
    pinSet: {
        pinSetId: string;
        pinCount: number;
        creationDate: string;
        title?: string /* will not be stored, it's generated by modifier*/;
    };
}
