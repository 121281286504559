import { Store } from '../interfaces';

const reducer = (state: Store, payload: string): Store => {
    return {
        ...state,
        language: payload,
    };
};

export default reducer;
