import { Country } from './interfaces';
import { IconSets } from '../block';

const countries: Country[] = [
    {
        code: 'by',
        languageCode: 'be',
        flagIcon: IconSets.Custom.FLAG_BELARUS,
        name: 'Belarus',
        localizedName: 'Беларусі',
        phoneCode: '+375',
        phoneExample: '29 123 45 67',
    },
    {
        code: 'cz',
        languageCode: 'cs',
        flagIcon: IconSets.Custom.FLAG_CZECH_REPUBLIC,
        name: 'Czech Republic',
        localizedName: 'Česká republika',
        phoneCode: '+420',
        phoneExample: '602 123 456',
    },
    {
        code: 'ch',
        languageCode: 'de',
        flagIcon: IconSets.Custom.FLAG_SWITZERLAND,
        name: 'Switzerland',
        localizedName: 'Swiss',
        phoneCode: '+41',
        phoneExample: '75 411 12 34',
    },
    {
        code: 'us',
        languageCode: 'en',
        flagIcon: '',
        name: 'United States of America',
        localizedName: 'USA',
        phoneCode: '+1',
        phoneExample: '804 123 4567',
    },
];

export default countries;
