import { Store } from '../interfaces';
import { SchoolPb as SchoolPbActions } from '../../../../core/action';
import { cloneDeep as _cloneDeep } from 'lodash';

const reducer = (state: Store, payload: SchoolPbActions.addPinSet.Payload): Store => {
    const newPinSets = _cloneDeep(state.pinSets);
    newPinSets.push(payload.pinSet);
    const newState: Store = {
        ...state,
        pinSets: newPinSets,
    };
    return newState;
};

export default reducer;
