import { mapValues as _mapValues, isEqual as _isEqual, defer as _defer, isNil as _isNil, get as _get, isEmpty as _empty } from 'lodash';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../store/root_reducer';
import { Inlets } from '../../../core/block';
import { Tools as SelectorTools } from '../../../core/selector';
import { useLocation } from 'react-router-dom';
import { InletType } from '../../store/selector_translator';

function useInlets(blockInlet?: Inlets, settings = { processAsDefaultValueInlet: false, property: '' }): Inlets {
    // create empty map with InletKey and null
    const defaultInletValues = (): Inlets => {
        return _mapValues(blockInlet, () => null); // null means "Inlet wasn't resolved yet"
    };

    const [inlet, setInlet] = useState<Inlets>(defaultInletValues());
    const [alreadyInvoked, setAlreadyInvoked] = useState(false);
    const store = useSelector((rootStore: RootState) => rootStore); // using this here because we want to refresh Store inlets after each state update
    const location = useLocation();

    const checkInlets = (type: InletType) => {
        const newInletState: Inlets = SelectorTools.selectorProcessor(blockInlet, store, location);

        // use new inlets just if they are valid and really different
        if (!_isNil(newInletState) && !_isEqual(newInletState, inlet) && !(settings.processAsDefaultValueInlet && alreadyInvoked)) {
            if (settings.processAsDefaultValueInlet && !_isNil(newInletState)) {
                const propertyVal = _get(newInletState, settings.property, null);
                if (!_isNil(propertyVal) && !_empty(propertyVal)) {
                    setAlreadyInvoked(true);
                }
            }

            switch (type) {
                case InletType.STORE: {
                    _defer(() => {
                        setInlet(newInletState);
                    });
                    break;
                }
                case InletType.LOCATION: {
                    setInlet(newInletState);
                    break;
                }
            }
        }
    };

    useEffect(() => {
        checkInlets(InletType.STORE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store]);

    useEffect(() => {
        checkInlets(InletType.LOCATION);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return inlet;
}

export default useInlets;
