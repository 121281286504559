import React from 'react';

import * as Styled from './styled';

const DashboardPage = () => {
    return (
        <Styled.Wrapper>
            <div className="content">Dashboard</div>
        </Styled.Wrapper>
    );
};
export default DashboardPage;
