import React from 'react';
import { isNil as _isNil } from 'lodash';
import { FileList as FileListConfig, Attachment, IconsSet } from '../../../core/block/interfaces';
import * as Styled from './styled';
import MaterialIcons from '../../../core/block/icon_sets/material';

interface ItemInterface {
    fileItem: Attachment;
    enableDelete?: boolean;
}

export const FileListItem: React.FC<ItemInterface> = (props: ItemInterface) => {
    return (
        <Styled.FileListItem>
            {props.fileItem.thumbnailUrl ? (
                <Styled.ImageThumbnail src={props.fileItem.thumbnailUrl} />
            ) : (
                <Styled.IconThumbnail icon={MaterialIcons.DESCRIPTION} set={IconsSet.MATERIAL_OUTLINE} name="dummy" />
            )}
            <Styled.Link href={props.fileItem.url} target="_blank">
                {props.fileItem.title}
            </Styled.Link>

            {!_isNil(props.enableDelete) && props.enableDelete && (
                <Styled.ListItemActionButton
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                    }}
                >
                    <Styled.IconCircle icon={MaterialIcons.DELETE} set={IconsSet.MATERIAL_OUTLINE} name="dummy_because_of_block_interface" />
                </Styled.ListItemActionButton>
            )}
        </Styled.FileListItem>
    );
};

export const FileList: React.FC<FileListConfig> = ({ files = [], ...props }) => {
    return (
        <div>
            {files.length === 0 && <div>No files here...</div>}
            {files.length > 0 && files.map((file) => <FileListItem {...{ fileItem: file, enableDelete: props.enableDelete }} key={file.url} />)}
        </div>
    );
};
