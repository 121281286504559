import { isNil as _isNil } from 'lodash';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { BlockProps, RedirectTo404 as RedirectTo404Config } from '../../../core/block';
import { stringIsEmpty } from '../../../core/tools';

export type Config = RedirectTo404Config;
export type Props = BlockProps & Config;

const RedirectTo404Block = (props: Props) => {
    const location = useLocation();

    let referer = '';

    // use given referer
    if (!_isNil(props.referer) && !stringIsEmpty(props.referer)) {
        referer = props.referer;
    }
    // use pathname as referer
    else {
        referer = location.pathname;
        // search ?
        if (!_isNil(location.search) && !stringIsEmpty(location.search)) {
            referer = `${location.pathname}${location.search}`;
        }
    }

    return <Redirect to={{ pathname: '/404', state: { referer } }} />;
};
export default RedirectTo404Block;
