import { isNil as _isNil, defer as _defer } from 'lodash';
import React, { useState, SyntheticEvent } from 'react';
import { Input as AntInput } from 'antd';
import { BlockProps, Tools, IconSets, FormFieldLink as FormFieldLinkConfig } from '../../../../core/block';
import { IconsSet } from '../../../../core/block/interfaces';
import MaterialIcons from '../../../../core/block/icon_sets/material';
import { useOutlets, useStringValidation, ValidationResult, VALIDATION_RESULT_UNTOUCHED_NEGATIVE, getOutletValidationStatus } from '../../../hooks';
import * as Styled from './styled';

export type Config = FormFieldLinkConfig;
export type Props = BlockProps &
    Config & {
        onSubmit?: () => void;
    };

const isYoutubeLink = (link: string): boolean => {
    if (_isNil(link)) {
        return false;
    }
    const regex1 = /.youtube./g;
    const regex2 = /.youtu.be\//g;
    const res = link.match(regex1) !== null;
    return res || link.match(regex2) !== null;
};

const LinkFormField = (props: Props) => {
    const outlet = useOutlets();

    const validateLink = useStringValidation(props.linkValidationScheme || {});
    const validateLabel = useStringValidation(props.labelValidationScheme || {});

    const [linkValidationResult, setLinkValidationResult] = useState(VALIDATION_RESULT_UNTOUCHED_NEGATIVE);
    const [labelValidationResult, setLabelValidationResult] = useState(VALIDATION_RESULT_UNTOUCHED_NEGATIVE);
    const isYouTube = isYoutubeLink(linkValidationResult.lastValue);
    const isLink = linkValidationResult.isValid;

    let labelInput: AntInput;
    let linkInput: AntInput;

    const processChangeOutlet = () => {
        if (_isNil(props.outlets?.change)) {
            return;
        }

        let blockValidationResult: ValidationResult = { isValid: true, antValidationStatus: 'success' };
        if (!(linkValidationResult.isValid && labelValidationResult.isValid)) {
            blockValidationResult = linkValidationResult.isValid ? labelValidationResult : linkValidationResult;
        }
        const linkVal = linkValidationResult.lastValue || '';
        const labelVal = labelValidationResult.lastValue || '';
        const value = { linkValue: linkVal, labelValue: labelVal };
        const outletParameters = {
            value,
            validationStatus: getOutletValidationStatus(blockValidationResult),
        };

        _defer(() => {
            outlet(props.outlets?.change, outletParameters);
        });
    };

    /*
        Different aproach to calling outlet process change
    */
    React.useEffect(() => {
        processChangeOutlet();
    });

    const handleLinkChange = (event: SyntheticEvent) => {
        const linkText = (event.target as HTMLInputElement).value;
        return validateLink(linkText).then((validationResult) => {
            setLinkValidationResult(validationResult);
        });
    };

    const handleLabelChange = (event: SyntheticEvent) => {
        const labelText = (event.target as HTMLInputElement).value;
        return validateLabel(labelText).then((validationResult) => {
            setLabelValidationResult(validationResult);
        });
    };

    const handleLinkKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            // switch focus to label input
            labelInput.focus();
        }
    };

    const handleLabelKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            // dismiss focus
            labelInput.blur.apply(labelInput);
        }
    };

    const handleOpenLinkClick = () => {
        if (isLink) {
            window.open(linkInput.state.value, '_blank');
        }
    };

    const handleDeleteClick = () => {
        // TODO: implement this when the wf will be known
        //console.log('delete button clicked');
    };

    return (
        <Styled.MainLayout {...Tools.extractConfig<Config>(props)}>
            {/* link icons */}
            <Styled.LinkIconContainer isValidLink={isLink} isYouTubeLink={isYouTube} onClick={handleOpenLinkClick}>
                <Styled.LinkIcon icon={IconSets.Custom.LINK} set={IconsSet.CUSTOM} name="dummy" />
                <Styled.SocialMediaIcon icon={IconSets.Custom.YOUTUBE} set={IconsSet.CUSTOM} name="dummy" />
                <Styled.OpenLinkIcon icon={IconSets.Custom.OPEN_LINK} set={IconsSet.CUSTOM} name="dummy" />
            </Styled.LinkIconContainer>
            {/* vertical layout for a link and label */}
            <Styled.LinkLayout isValidLink={isLink}>
                <Styled.FormItem hasFeedback={true} validateStatus={linkValidationResult.antValidationStatus} help={linkValidationResult.message}>
                    {/* url input */}
                    <Styled.LinkItemInput
                        type="url"
                        placeholder={props.linkPlaceholder}
                        autoFocus={props.autoFocus}
                        autoComplete="off"
                        ref={(input) => {
                            linkInput = input as AntInput;
                        }}
                        onChange={handleLinkChange}
                        onKeyDown={handleLinkKeyDown}
                    />
                </Styled.FormItem>
                {/* label input */}
                <Styled.FormItem
                    hasFeedback={!_isNil(props.labelValidationScheme)}
                    validateStatus={labelValidationResult.antValidationStatus}
                    help={labelValidationResult.message}
                >
                    <AntInput
                        type="string"
                        placeholder={props.labelPlaceholder}
                        autoComplete="off"
                        ref={(input) => {
                            labelInput = input as AntInput;
                        }}
                        onChange={handleLabelChange}
                        onKeyDown={handleLabelKeyDown}
                    />
                </Styled.FormItem>
            </Styled.LinkLayout>
            {/* delete icon area */}
            <Styled.DeleteIconPlaceholder>
                <Styled.DeleteIconContainer onClick={handleDeleteClick}>
                    <Styled.DeleteIcon icon={MaterialIcons.DELETE} set={IconsSet.MATERIAL_OUTLINE} name="dummy" />
                </Styled.DeleteIconContainer>
            </Styled.DeleteIconPlaceholder>
        </Styled.MainLayout>
    );
};

export default LinkFormField;
