import React, { CSSProperties } from 'react';

import * as Styled from './styled';

export interface Props {
    className?: string;
    style?: CSSProperties;
}

const CompactLoading = (props: Props) => {
    return (
        <Styled.Wrapper {...props}>
            <Styled.Dots>
                <Styled.Dot1 />
                <Styled.Dot2 />
                <Styled.Dot3 />
                <Styled.Dot4 />
                <Styled.Dot5 />
                <Styled.Dot6 />
            </Styled.Dots>
        </Styled.Wrapper>
    );
};
export default CompactLoading;
