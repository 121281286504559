import { isNil as _isNil } from 'lodash';
import React, { useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/min/locales.min';

import { GlobalStyles } from './stylesheets/global';
import { popup, currentLanguage, isLoading } from './store/application/selectors';
import { PopupStore } from './store/application/interfaces';
import Page404 from './pages/404';
import DashboardPage from './pages/dashboard';
import WebsitePage from './pages/website';
import IndexPage from './pages/index';
import AdminPage from './pages/admin';
import RedirectTo404 from './components/RedirectTo404';
import Popup from './components/Popup';
import Loading from './components/Loading';
import history from './application_history';
import * as Styled from './Application.styled';
import catchLinks from 'catch-links';
import * as AuthTools from './tools/authentication';

const Application: React.FC = () => {
    const popupToShow: PopupStore | undefined = useSelector(popup());
    const language: string | undefined = useSelector(currentLanguage());
    const showLoading: boolean | undefined = useSelector(isLoading());

    useEffect(() => {
        // popup is hidden
        if (_isNil(popupToShow)) {
            document.body.classList.remove('disable-scroll');
        }
        // popup is visible
        else {
            document.body.classList.add('disable-scroll');
        }
    });

    useEffect(() => {
        catchLinks(window, (href: string) => {
            history.push(href);
        });
    }, []);

    // reaction on language change
    useEffect(() => {
        // set language code to auth, moment
        AuthTools.setLanguageCode(language);
        moment.locale(language);
    }, [language]);

    return (
        <Router history={history}>
            {/* global */}
            <GlobalStyles />

            {/* general meta tags */}
            <Helmet>
                <title>Decision 21</title>
            </Helmet>

            {/* application frame */}
            <Styled.AppFrame isPopupVisible={!_isNil(popupToShow)}>
                <Switch>
                    {/* 404 */}
                    <Route path="/404">
                        <Page404 />
                    </Route>

                    {/* Dashboard */}
                    <Route path="/dashboard">
                        <DashboardPage />
                    </Route>

                    {/* Admin */}
                    <Route path="/admin">
                        <AdminPage />
                    </Route>

                    {/* Website */}
                    <Route path="/:websiteId">
                        <WebsitePage />
                    </Route>

                    {/* Index */}
                    <Route path="/" exact>
                        <IndexPage />
                    </Route>

                    {/* Redirect to 404 */}
                    <Route>
                        <RedirectTo404 />
                    </Route>
                </Switch>
            </Styled.AppFrame>

            {/* popup */}
            {!_isNil(popupToShow) && <Popup {...popupToShow} />}

            {/* is loading */}
            {showLoading === true && <Loading fullScreen={true} />}
        </Router>
    );
};

export default Application;
