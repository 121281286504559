import { isNil as _isNil, defer as _defer } from 'lodash';
import React from 'react';
import { BlockProps, Tools, IconSets, LinkItemButton as LinkItemButtonConfig } from '../../../core/block';
import { IconsSet } from '../../../core/block/interfaces';
import MarkdownIt from 'markdown-it';
import MaterialIcons from '../../../core/block/icon_sets/material';
import { useOutlets } from '../../hooks';
import * as Styled from './styled';

export type Config = LinkItemButtonConfig;
export type Props = BlockProps &
    Config & {
        onSubmit?: () => void;
    };

const md = new MarkdownIt({
    html: false,
    xhtmlOut: true,
    breaks: true,
    linkify: true,
});

enum OutletType {
    CLICK,
    DELETE,
}

const LinkItemButton = (props: Props) => {
    const outlet = useOutlets();

    const processChangeOutlet = (type: OutletType) => {
        if (type === OutletType.CLICK) {
            if (_isNil(props.outlets?.click)) {
                return;
            }

            const outletParameters = {
                value: props.url,
            };

            _defer(() => {
                outlet(props.outlets?.click, outletParameters);
            });
        } else {
            if (_isNil(props.outlets?.delete)) {
                return;
            }

            const outletParameters = {
                value: props.url,
            };

            _defer(() => {
                outlet(props.outlets?.delete, outletParameters);
            });
        }
    };

    const handleClick = () => {
        processChangeOutlet(OutletType.CLICK);
    };

    const handleDeleteClick = () => {
        processChangeOutlet(OutletType.DELETE);
    };

    const getIconSet = (props: Props): IconsSet => {
        if (_isNil(props.iconSet)) {
            return IconsSet.CUSTOM;
        }

        return props.iconSet;
    };

    const getIcon = (props: Props): string => {
        if (_isNil(props.icon)) {
            return IconSets.Custom.LINK;
        }

        return props.icon;
    };

    return (
        <Styled.MainLayout {...Tools.extractConfig<Config>(props)}>
            {/* link icons */}
            <Styled.LinkIconContainer onClick={props.outlets?.click ? handleClick : undefined}>
                <Styled.LinkIcon icon={getIcon(props)} set={getIconSet(props)} name="dummy" />
                <Styled.OpenLinkIcon icon={IconSets.Custom.OPEN_LINK} set={IconsSet.CUSTOM} name="dummy" />
            </Styled.LinkIconContainer>
            {/* vertical layout for a label */}
            <Styled.LabelLayout onClick={props.outlets?.click ? handleClick : undefined}>
                {/* simle text */}
                {!props.useMarkdown && <Styled.Label>{props.label}</Styled.Label>}
                {/* markdown text */}
                {props.useMarkdown && <Styled.Label dangerouslySetInnerHTML={{ __html: md.render(props.label) }} />}
            </Styled.LabelLayout>
            {/* delete icon area */}
            <Styled.DeleteIconPlaceholder>
                <Styled.DeleteIconContainer onClick={handleDeleteClick}>
                    <Styled.DeleteIcon icon={MaterialIcons.DELETE} set={IconsSet.MATERIAL_OUTLINE} name="dummy" />
                </Styled.DeleteIconContainer>
            </Styled.DeleteIconPlaceholder>
        </Styled.MainLayout>
    );
};

export default LinkItemButton;
