import { isNil as _isNil } from 'lodash';
import React from 'react';

import { BlockProps, SignBarGraph as SignBarGraphConfig, Tools } from '../../../../core/block';
import H3 from '../../Typography/H3';
import * as Styled from './styled';

export type Config = SignBarGraphConfig;
export type Props = BlockProps & Config;

const SignBarGraph = (props: Props) => {
    return (
        <Styled.SignBarGraph {...Tools.extractConfig<Config>(props)}>
            {/* top line */}
            <Styled.TopLine>
                {/* title */}
                <Styled.Title>{!_isNil(props.text) && <H3 name="" text={props.text} />}</Styled.Title>

                {/* number */}
                {(!_isNil(props.data.count) || !_isNil(props.data.percentage)) && (
                    <Styled.Numbers>
                        {!_isNil(props.data.percentage) && <Styled.Percentage>{props.data.percentage} %</Styled.Percentage>}
                        {/*<Styled.NumbersVotesWithLabel>(2 votes)</Styled.NumbersVotesWithLabel>*/}
                        {!_isNil(props.data.count) && <Styled.Votes>({props.data.count})</Styled.Votes>}
                    </Styled.Numbers>
                )}
            </Styled.TopLine>

            {/* bar line */}
            <Styled.BarLine>
                <Styled.Bar size={props.data.barSize} />
            </Styled.BarLine>
        </Styled.SignBarGraph>
    );
};
export default SignBarGraph;
