import React from 'react';

import { StandardDialog } from '../../../../core/website';
import Blocks from '../../../blocks/Blocks';
import * as Styled from './styled';

const Dialog = (props: StandardDialog) => {
    return (
        <Styled.Dialog {...props.layout}>
            <Blocks blocks={props.blocks} />
        </Styled.Dialog>
    );
};
export default Dialog;
