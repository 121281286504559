import styled, { css, CSSProp } from 'styled-components';

import { onPhone, onTablet } from '../../../stylesheets/tools';

export interface WrapperProps {
    padding?: CSSProp;
    paddingOnPhone?: CSSProp;
    paddingOnTablet?: CSSProp;
}

export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: ${({ padding }) => padding || '0 104px'};

    ${(props) =>
        onTablet(css`
            padding: ${props.paddingOnTablet || '0 40px'};
        `)}

    ${(props) =>
        onPhone(css`
            padding: ${props.paddingOnPhone || '0 8px'};
        `)}
`;

export const Page = styled.div`
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;
