import { isNil as _isNil, trim as _trim } from 'lodash';
import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';

function useStepperStep(websiteId: string, stepperId: string, uid: string): [string | undefined, boolean, any | undefined] {
    const [config, loading, error] = useObjectVal<string>(firebase.database().ref(`website/${websiteId}/__stepper/${stepperId}/${uid}`));

    if (_isNil(websiteId) || _isNil(stepperId) || _isNil(uid) || _trim(websiteId) === '' || _trim(stepperId) === '' || _trim(uid) === '') {
        return [undefined, true, undefined];
    }

    return [config, loading, error];
}

export default useStepperStep;
