import { isNil as _isNil } from 'lodash';
import moment from 'moment';

import { Authentication as AuthenticationActions, Tools as ActionTools } from '../../../../core/action';
import { Streamer, Events, Event } from '../../../../core/stream';

export default function (payload: AuthenticationActions.generatePinSet.Payload) {
    return async (dispatch: any) => {
        const event: Event<AuthenticationActions.generatePinSet.Payload> = Events.generatePinSetEvent(payload);
        const response = await Streamer.stream(event);
        const now = moment();
        const result = { pinSetId: response, pinCount: payload.pinCount, creationDate: now.toISOString() };

        if (!_isNil(payload.onDone)) {
            ActionTools.processAction(dispatch, payload.onDone, result);
        }
    };
}
