import styled, { css, createGlobalStyle } from 'styled-components';
import { Select as AntSelect, Form as AntForm } from 'antd';
import hexToRgba from 'hex-to-rgba';

import { Config } from './index';
import IconComp from '../../Icon';
import { color, shadow, animation } from '../../../stylesheets/shared';
import { ifHoverIsSupported } from '../../../stylesheets/tools';
import { cssP, cssNoteP } from '../../../stylesheets/snippets';

const DEFAULT_TEXT_ALIGN = 'left';

export const Select = styled(AntSelect)`
    width: 100%;
    /* increasing selector specificity to beat the antd's selectors */
    .ant-select-selector.ant-select-selector.ant-select-selector {
        cursor: pointer;
        box-sizing: border-box;
        border: ${color.grey2} 1px solid;
        border-radius: 20px;
        width: 100%;
        height: 36px;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 12px;
        transition: border-color ${animation.timing.interactive} ease-out;
        ${ifHoverIsSupported(css`
            &:hover {
                border-color: ${hexToRgba(color.blue, 0.48)};
            }
        `)}
    }
    &.ant-select-focused {
        .ant-select-selector.ant-select-selector.ant-select-selector {
            border-color: ${color.blue};
            box-shadow: none;
        }
    }
    .ant-select-selector.ant-select-selector .ant-select-selection-search-input {
        height: 100%;
    }
    .ant-select-arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 8px;
        width: 24px;
        height: 24px;
    }
`;

/* Need to have it as global style, because it's not a child of dropdown */
export const SelectDropdownStyles = createGlobalStyle`
    .ant-select-dropdown {
        border-radius: 20px;
        border: ${color.grey2} 1px solid;
        padding: 6px 0;
        box-shadow: ${shadow.standard};
        /* item */
        .ant-select-item{
            transition: background-color ${animation.timing.interactive} ease-out;
            /* selected */
            &.ant-select-item-option-selected{
                font-weight: bold;
                &:not(.ant-select-item-option-disabled):not(.ant-select-item-option-active){
                    background-color: transparent;
                }
            }
            /* hover */
            &.ant-select-item-option-active {
                background-color: ${color.grey3};
            }
        }
    }
`;

export const FormItem = styled(AntForm.Item)`
    margin: 0;
    /* explanation */
    .ant-form-item-explain {
        ${cssNoteP};
        color: ${color.red};
        min-height: 0;
    }
    /* feedback */
    &.ant-form-item-has-feedback {
        .ant-form-item-children-icon {
            right: -32px;
        }
    }
    /* error */
    &.ant-form-item-has-error {
        .ant-input {
            border-color: ${color.red};
            &:focus {
                border-color: ${color.red};
            }
        }
        .ant-form-item-explain {
            ${cssNoteP};
            color: ${color.red};
        }
    }
`;

export const Label = styled.div`
    ${cssP};
    font-weight: bold;
    margin-bottom: 2px;
`;

interface CustomIcon {
    rotate?: boolean;
}
export const Icon = styled(IconComp)<CustomIcon>`
    color: ${color.black};
    transform: ${(p: CustomIcon) => (p.rotate ? 'rotate(180deg)' : 'rotate(0)')};
    transition: transform ${animation.timing.interactive} ease-out;
`;

export const Dropdown = styled.div<{ config: Config }>`
    box-sizing: border-box;
    width: 100%;

    ${Label} {
        text-align: ${({ config }) => (config.align ? config.align : DEFAULT_TEXT_ALIGN)};
    }

    ${FormItem} {
        .ant-select-selection-search-input {
            text-align: ${({ config }) => (config.align ? config.align : DEFAULT_TEXT_ALIGN)};
        }
        .ant-form-item-explain {
            text-align: ${({ config }) => (config.align ? config.align : DEFAULT_TEXT_ALIGN)};
        }
    }

    /* style override */
    ${({ config }) => config.styleOverride}
`;
