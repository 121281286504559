import { Name as EntityName } from '../../../../core/entity';

const SELECTOR_PREFIX = `${EntityName.SELECTOR_PREFIX}SCHOOLPB:`;
export const TASKS_CHANGE = `${SELECTOR_PREFIX}tasks_change`;
export const TASK_VALUE_CHANGE = `${SELECTOR_PREFIX}task_value_change`;
export const CAMPAIGN_END_DATE = `${SELECTOR_PREFIX}campaign_end_date`;
export const CAMPAIGN_START_DATE = `${SELECTOR_PREFIX}campaign_start_date`;
export const COORDINATOR_EMAIL = `${SELECTOR_PREFIX}coordinator_email`;
export const COORDINATOR_NAME = `${SELECTOR_PREFIX}coordinator_name`;
export const MAX_PARTICULAR_BUDGET = `${SELECTOR_PREFIX}max_particular_budget`;
export const TOTAL_BUDGET = `${SELECTOR_PREFIX}total_budget`;
export const PROPOSALS_APPROVING_START_DATE = `${SELECTOR_PREFIX}proposals_approving_start_date`;
export const PROPOSALS_APPROVING_END_DATE = `${SELECTOR_PREFIX}proposals_approving_end_date`;
export const RESULTS_ANNOUNCEMENT_DATE = `${SELECTOR_PREFIX}results_announcement_date`;
export const VOTING_START_DATE = `${SELECTOR_PREFIX}voting_start_date`;
export const VOTING_END_DATE = `${SELECTOR_PREFIX}voting_end_date`;
export const WORKSHOP_START_DATE = `${SELECTOR_PREFIX}workshop_start_date`;
export const WORKSHOP_START_TIME = `${SELECTOR_PREFIX}workshop_start_time`;
export const WORKSHOP_VENUE = `${SELECTOR_PREFIX}workshop_venue`;
export const PROPOSALS = `${SELECTOR_PREFIX}proposals`;
export const PROPOSAL_COUNT = `${SELECTOR_PREFIX}proposal_count`;
export const PIN_SETS = `${SELECTOR_PREFIX}pin_sets`;
export const PIN_COUNT_PER_CLASS = `${SELECTOR_PREFIX}pin_count_per_class`;
export const PIN_COUNT = `${SELECTOR_PREFIX}pin_count`;
export const CLASS_COUNT = `${SELECTOR_PREFIX}class_count`;
export const VOTING_LAUNCHED = `${SELECTOR_PREFIX}voting_launched`;
export const FOREWORD = `${SELECTOR_PREFIX}foreword`;
export const NAME = `${SELECTOR_PREFIX}name`;
export const IS_PROPOSAL_WINNER = `${SELECTOR_PREFIX}is_proposal_winner`;
export const WINNERS = `${SELECTOR_PREFIX}winners`;
