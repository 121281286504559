import { omit as _omit } from 'lodash';

import { Block, BlockProps } from '../interfaces';

export default <T extends Block>(block: Block & BlockProps): BlockProps & { config: T } => {
    return {
        className: block.className,
        style: block.style,
        config: _omit(block, ['className', 'style']) as T,
    };
};
