import React from 'react';

import { BlockProps, RepresentP as RepresentPConfig, RepresentPInlets, Tools } from '../../../../core/block';
import { interpolate } from '../../../../core/tools';
import { useInlets } from '../../../hooks';
import getMarkdown from '../../../tools/markdown';
import * as Styled from './styled';

export type Config = RepresentPConfig;
export type Props = BlockProps & Config;

const md = getMarkdown();

const RepresentP = (props: Props) => {
    const inlets = useInlets(props.inlets) as RepresentPInlets;
    const text = interpolate(props.text, { inlets }, { skipUnresolvedPlaceholders: true });

    return (
        <>
            {/* Simple */}
            {!props.useMarkdown && <Styled.P {...Tools.extractConfig<Config>(props)}>{text}</Styled.P>}

            {/* Markdown */}
            {props.useMarkdown && <Styled.MD {...Tools.extractConfig<Config>(props)} dangerouslySetInnerHTML={{ __html: md.renderInline(text) }} />}
        </>
    );
};
export default RepresentP;
