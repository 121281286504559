import { isNil as _isNil } from 'lodash';
import React, { useState } from 'react';
import MarkdownIt from 'markdown-it';

import { BlockProps, Button as ButtonConfig, Tools } from '../../../core/block';
import { useOutlets } from '../../hooks';
import Loading from '../../components/Loading';
import * as Styled from './styled';

export type Config = ButtonConfig;
export type Props = BlockProps &
    Config & {
        inProgress?: boolean;
        onClick?: () => void;
    };

const md = new MarkdownIt({
    html: false,
    xhtmlOut: true,
    breaks: true,
    linkify: true,
});

const Button = (props: Props) => {
    const outlet = useOutlets();
    const [displayInProgress, setDisplayInProgress] = useState<boolean>(false);

    const isInProgress = (): boolean => displayInProgress === true || props.inProgress === true;

    const processClickOutlet = async () => {
        if (_isNil(props.outlets?.click)) {
            return;
        }

        setDisplayInProgress(true);
        await outlet(props.outlets?.click);
        setDisplayInProgress(false);
    };

    const onClick = () => {
        if (isInProgress()) {
            return;
        }

        processClickOutlet();

        if (!_isNil(props.onClick)) {
            props.onClick();
        }
    };

    return (
        <Styled.Button {...Tools.extractConfig<Config>(props)} inProgress={props.inProgress} onClick={onClick}>
            {/* icon */}
            {props.icon && <Styled.Icon {...props.icon} />}

            {/* simle text */}
            {!props.useMarkdown && <Styled.Label>{props.text}</Styled.Label>}
            {/* markdown text */}
            {props.useMarkdown && <Styled.Label dangerouslySetInnerHTML={{ __html: md.render(props.text) }} />}

            {/* loading */}
            {isInProgress() && (
                <Styled.LoadingWrapper>
                    <Loading />
                </Styled.LoadingWrapper>
            )}
        </Styled.Button>
    );
};
export default Button;
