// https://github.com/mapbox/mapbox-gl-js/issues/5785#issuecomment-357374166
// this gets rid of the warning in the console, as per the issue,
// however, it doesn't make the map colorful
// Also, it removes the warning even if I don't include the styles explicitly.
// This seems to be legit behavior, see _Or embed it in your app_
// https://visgl.github.io/react-map-gl/docs/get-started/get-started#styling
import 'mapbox-gl/dist/mapbox-gl.css';

import React, { useState, useEffect } from 'react';
import ReactMapGL, { Marker, ViewportProps, NavigationControl } from 'react-map-gl';
import _defer from 'lodash/defer';

import { Map as MapConfig, Tools } from '../../../core/block';
import * as Styled from './styled';
import { useInlets, useOutlets, ValidationResult, getOutletValidationStatus } from '../../hooks';
import { useListItems } from '../../hooks/useListItems';

// TODO mode out to ENV
const TMP_TOKEN = 'pk.eyJ1IjoiZGV2MjEtbWUiLCJhIjoiY2p4NjNkamoxMDZ5NzQ0bDh5amY1Y2NsdiJ9.2dOmHocWicmQhk-8Uaqsgg';
const DEFAULT_MAPSTYLE = 'mapbox://styles/mapbox/streets-v11';

interface Coordinates {
    longitude: number;
    latitude: number;
}

const locations: Record<string, Coordinates> = {
    zapova18: {
        latitude: 50.0743489638,
        longitude: 14.3905895053,
    },
    zapova17: {
        latitude: 50.07431,
        longitude: 14.3903,
    },
};

export const DEFAULT_CONFIG = {
    width: 400,
    height: 400,
    zoom: 16,
    ...locations.zapova18,
};

/** This is basically a useState hook, with state overridable by the "master prop".
 *
 *  The use-case for this hook is to have a internal component state,
 *  which is overwritten every time a "master prop" updates. (usually coming from component's props)
 *  after the overwrite happens, the component continues to use the state as internal again,
 *  and is able to write new values to it.
 *
 *  Usage:
 *  ```typescript
 *  const [myState, setMyState] = useStateWithMasterProp(masterProp);
 *  ```
 */
export function useStateWithMasterProp<TVal>(propValue: TVal): [TVal, React.Dispatch<React.SetStateAction<TVal>>] {
    const [state, setState] = useState(propValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setState(propValue), [JSON.stringify(propValue)]);

    return [state, setState];
}

export const Map: React.FC<MapConfig> = ({ pins = [], interactive = true, canAddPin: canAddPinProp = true, ...props }) => {
    // ######## INITAL SETUP

    const _config = {
        ...DEFAULT_CONFIG,
        ...props,
    } as any;

    const [mapConfig, setMapConfig] = useState<Partial<ViewportProps>>(_config);
    const [movableMarkerPosition, setMovableMarkerPosition] = useState<Coordinates | undefined>();

    // ensures that the map updates if props change
    useEffect(() => {
        setMapConfig(_config);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(_config)]);

    const [_pins, _setPins] = useState(pins);
    const [canAddPin, setCanAddPin] = useStateWithMasterProp(canAddPinProp);

    // ######## INLETS

    const inlets = useInlets(props.inlets);

    const centerMapOnCoordinates = (coordinates: Coordinates) => {
        setMapConfig((current) => {
            return {
                ...current,
                ...coordinates,
            };
        });
    };

    useEffect(() => {
        if (!props.inlets) {
            return;
        }

        if (Tools.isInletResolved(inlets.canAddPin) && typeof inlets.canAddPin === 'boolean') {
            setCanAddPin(inlets.canAddPin);
        }

        if (Tools.isInletResolved(inlets.location)) {
            // console.log('map: received inlet `location`. Value: ', inlets.location, 'current position is ', movableMarkerPosition);

            if (inlets.location) {
                const coord = (inlets.location as any).coordinates;
                if (coord === movableMarkerPosition) {
                    // console.log('Received coordinates are the same as the current movable marker position. Skipping');
                } else {
                    setMovableMarkerPosition(coord);
                    centerMapOnCoordinates(coord);
                }
            }

            //     const rawValue = mapValueBack(inlets.value, props.valueMapping);
            //     setIsChecked(rawValue);
        }

        if (Tools.isInletResolved(inlets.proposals)) {
            // console.log('map: received inlet `proposals`. Value: ', inlets.proposals);

            if (inlets.proposals) {
                const listItems = (inlets.proposals as unknown) as any[];
                const pinData = listItems.filter((item) => item.attrs?.location !== undefined).map((item) => ({ ...item.attrs.location, item }));
                if (pinData.length !== listItems.length) {
                    console.warn('Some of the proposals are missing location attribute, and are therefore omitted from the map');
                }
                // console.log('Map is using items provided via inlets.proposals');
                _setPins(pinData);
            }

            //     const rawValue = mapValueBack(inlets.value, props.valueMapping);
            //     setIsChecked(rawValue);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inlets)]);

    // Pull proposals directly from database instead of relying on <ProposalsList /> to put them into the state.
    // TODO this makes the Map event more coupled with the proposals logic,
    // but let's see if we ever encounter another use-case, and only then we refactor this
    // const itemsPath = '/proposals-snapshots/pbnyc-propose/list-items';
    const [items] = useListItems<any>(props.listItemsPath);
    if (items && items.length) {
        console.log('Map is using items provided via listItemsPath');
        const itemsWithLocation = items.filter((item) => item.attrs?.location !== undefined).map((item) => ({ ...item.attrs.location, item }));
        if (JSON.stringify(itemsWithLocation) !== JSON.stringify(_pins)) {
            _setPins(itemsWithLocation);
        }
    }

    // ######## OULETS

    const runOutlets = useOutlets();
    const runChangeOutlet = (value: any, validationResult: ValidationResult) => {
        if (!props.outlets?.change) {
            return;
        }

        const outletParameters = {
            value,
            validationStatus: getOutletValidationStatus(validationResult),
        };

        _defer(() => runOutlets(props.outlets?.change, outletParameters));
    };

    // ######## MAP MOVEMENT
    const handleViewportChange = (props: ViewportProps) => {
        if (!interactive) return;

        setMapConfig(props);
    };

    // ######## PINS
    const updateMarker = (coordinates: Coordinates) => {
        setMovableMarkerPosition(coordinates);
        runChangeOutlet({ source: 'MAP UPDATE MARKER', coordinates, ...coordinates }, { isValid: true, antValidationStatus: 'success' });
    };

    const handleMovableMarkerDragEnd = ({ lngLat /*, ...params */ }: any) => {
        const coordinates = {
            latitude: lngLat[1],
            longitude: lngLat[0],
        };

        updateMarker(coordinates);
    };

    const handleMapClick = (params: any) => {
        const coordinates = {
            latitude: params.lngLat[1],
            longitude: params.lngLat[0],
        };

        if (canAddPin) {
            updateMarker(coordinates);
        }
    };

    const runPinClickedOutlet = (pin: any) => {
        if (!props.outlets?.pinClicked) {
            return;
        }

        const outletParameters = {
            pin,
        };

        _defer(() => runOutlets(props.outlets?.pinClicked, outletParameters));
    };

    const handlePinClick = (pin: any) => {
        runPinClickedOutlet(pin);
    };

    return (
        <>
            <ReactMapGL
                {...(mapConfig as ViewportProps)}
                mapStyle={DEFAULT_MAPSTYLE}
                onViewportChange={handleViewportChange}
                onClick={handleMapClick}
                mapboxApiAccessToken={TMP_TOKEN}
            >
                <div style={{ position: 'absolute', top: '12px', left: '12px' }}>
                    <NavigationControl showCompass={false} />
                </div>

                {movableMarkerPosition && (
                    <Marker {...movableMarkerPosition} draggable onDragEnd={handleMovableMarkerDragEnd}>
                        <Styled.MarkerPinPointy />
                    </Marker>
                )}

                {_pins.map((pin) => (
                    <Marker {...pin} key={`${pin.longitude},${pin.latitude}`}>
                        <Styled.MarkerPinPointy onClick={() => handlePinClick(pin)} />
                    </Marker>
                ))}
            </ReactMapGL>
        </>
    );
};
