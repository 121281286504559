import { get as _get, isNil as _isNil, unset as _unset, set as _set, isEmpty as _isEmpty, keys as _keys, size as _size, head as _head } from 'lodash';

import { Store } from '../interfaces';
import { Answer, Engine, Question, Choice } from '../../../../core/voting';
import { Voting as VotingActions } from '../../../../core/action';

const reducer = (state: Store, payload: VotingActions.transferChoice.Payload): Store => {
    const targetQuestionconfig: Question.StandardQuestionConfig | undefined = _get(state.question.config, [payload.to]);
    if (_isNil(targetQuestionconfig)) {
        console.error(`Cannot get config for target Question with ID:${payload.to}.`);
        return state;
    }

    // copy the state
    const newState: Store = {
        ...state,
    };

    const currentSourceAnswer: Answer.Answer | undefined = _get(state.vote, [payload.from]);
    // if source question has no choice, we have nothing to transfer
    if (_isNil(currentSourceAnswer)) {
        return newState;
    }

    // find first choice from source answer to transfer
    const sourceAnswerOptionIDs = _keys(currentSourceAnswer);
    // if source question has no choice, we have nothing to transfer
    if (_size(sourceAnswerOptionIDs) === 0) {
        return newState;
    }

    // get choice to transfer
    const optionId: string | undefined = _head(sourceAnswerOptionIDs);
    // strange state ... just return the new state
    if (_isNil(optionId)) {
        return newState;
    }
    const choice: Choice.Choice | undefined = _get(currentSourceAnswer, [optionId]);
    // strange state ... just return the new state
    if (_isNil(choice)) {
        return newState;
    }

    const currentTargetAnswer: Answer.Answer | undefined = _get(state.vote, [payload.to]);
    let newTargetAnswer: Answer.Answer | undefined;

    // we need to make sure that choice in Single-Vote question will be really selected even if it's already chosen (because of auto-fix)
    // and also if autoFix is on other option will be removed
    if (targetQuestionconfig.type === Question.MethodType.SINGLE_VOTE) {
        newTargetAnswer = Engine.singleVoteSolver(optionId, choice, currentTargetAnswer, targetQuestionconfig.autoFix);
        newTargetAnswer = {
            ...newTargetAnswer,
            optionId: choice,
        };
        // in other question types we can just set the choice
    } else {
        newTargetAnswer = {
            ...currentTargetAnswer,
            [optionId]: choice,
        };
    }

    if (_isNil(newTargetAnswer)) {
        _unset(newState, ['vote', payload.to]);
    } else {
        _set(newState, ['vote', payload.to], newTargetAnswer);
    }

    // clear Vote store?
    if (_isNil(newState.vote) || _isEmpty(newState.vote)) {
        _unset(newState, ['vote']);
    }

    return newState;
};

export default reducer;
