import { isNil as _isNil } from 'lodash';
import styled, { css } from 'styled-components';

import { Config } from './index';
import { onPhone, onTablet } from '../../../stylesheets/tools';

type MP4Props = {
    config: Config;
};
export const MP4 = styled.video<MP4Props>`
    /* style override */
    ${({ config }) => config.styleOverride}

    /* on tablet */
    ${(p: MP4Props) => {
        if (!_isNil(p.config.onTablet)) {
            return onTablet(css`
                /* style override */
                ${(p: MP4Props) => p.config.onTablet?.styleOverride};
            `);
        }
    }}

    /* on phone */
    ${(p: MP4Props) => {
        if (!_isNil(p.config.onPhone)) {
            return onPhone(css`
                /* style override */
                ${(p: MP4Props) => p.config.onPhone?.styleOverride};
            `);
        }
    }}
`;
