import { isNil as _isNil } from 'lodash';
import React, { useEffect } from 'react';

import RedirectTo404 from '../../RedirectTo404';
import Loading from '../../Loading';
import StandardPage from '../../Page/Standard';
import StandardResult from '../../Result/Standard';
import SingleResult from '../../Result/Single';
import { useResultsStep } from '../../../hooks';
import { Name as WebsiteName, StandardPage as StandardPageConfig } from '../../../../core/website';
import { Name as VotingName, Result } from '../../../../core/voting';

interface Props {
    resultsId: string;
    stepId: string;
}

const ResultsStep = (props: Props) => {
    const [stepData, stepLoading, stepError] = useResultsStep(props.resultsId, props.stepId);

    // step change
    useEffect(() => {
        // start every step from top
        window.scrollTo(0, 0);
    }, [props.stepId]);

    return (
        <>
            {/* loading... */}
            {stepLoading && <Loading fullScreen={true} />}
            {/* error */}
            {stepError && <>loading error: {stepError}</>}
            {/* does not exist */}
            {!stepLoading && !stepError && _isNil(stepData) && <RedirectTo404 />}
            {/* exists */}
            {!_isNil(stepData) && (
                <>
                    {/* standard page */}
                    {stepData.name === WebsiteName.STANDARD_PAGE && <StandardPage {...(stepData as StandardPageConfig)} />}
                    {/* standard result */}
                    {stepData.name === VotingName.STANDARD_RESULT && <StandardResult {...(stepData as Result.StandardResult)} />}
                    {/* simple result */}
                    {stepData.name === VotingName.SINGLE_RESULT && <SingleResult {...(stepData as Result.SingleResult)} />}
                </>
            )}
        </>
    );
};
export default ResultsStep;
