import React from 'react';

import Blocks from '../Blocks';
import { BlockProps, CodSBomLayout as CodSBomLayoutConfig, Tools } from '../../../core/block';
import * as Styled from './styled';

export type Config = CodSBomLayoutConfig;
export type Props = BlockProps & Config;

const CodSBomLayout = (props: Props) => {
    return (
        <Styled.Layout {...Tools.extractConfig<Config>(props)}>
            <Blocks blocks={props.blocks} />
        </Styled.Layout>
    );
};
export default CodSBomLayout;
