import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';

import { Url, EntityList } from '../../../core/admin';

function useAdminDashboard(): [EntityList.List | undefined, boolean, any | undefined] {
    const [list, loading, error] = useObjectVal<EntityList.List>(firebase.database().ref(Url.dashboard()));

    return [list, loading, error];
}

export default useAdminDashboard;
