import styled, { css } from 'styled-components';

import { cssP } from '../../../stylesheets/snippets';
import { animation, meaningColor } from '../../../stylesheets/shared';
import { onVerySmallPhone } from '../../../stylesheets/tools';
import { Config } from './index';

// title line
export const Title = styled.div`
    flex-shrink: 1;
    text-align: left;
`;
export const Percentage = styled.div`
    ${cssP}
    font-weight: bold;
`;
// export const NumbersVotesWithLabel = styled.div`
//     ${cssP}
//     margin-left: 4px;

//     ${onVerySmallPhone(css`
//         display: none;
//     `)}
// `;
export const Votes = styled.div`
    ${cssP}
    margin-left: 4px;
    /* display: none; */

    /*${onVerySmallPhone(css`
        display: block;
    `)} */
`;
export const Numbers = styled.div`
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-left: 8px;
`;
export const TopLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

// bar line
interface BarProps {
    size: number;
    colorOverride?: string;
}
export const Bar = styled.div<BarProps>`
    position: absolute;
    top: 12px;
    left: 0;
    height: 8px;
    border-radius: 4px;
    background-color: ${(p: BarProps) => p.colorOverride || meaningColor.plus};
    box-sizing: border-box;
    width: ${(p: BarProps) => (p.size > 1 ? `${p.size}%` : '1px')};
    transition: width ${animation.timing.standart} ease-out;
`;
export const BarLine = styled.div`
    position: relative;
    height: 32px;
`;

export const BarGraph = styled.div<{ config: Config }>`
    /* style override */
    ${({ config }) => config.styleOverride}
`;
