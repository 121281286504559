import { get as _get, isNil as _isNil } from 'lodash';
import firebase from 'firebase/app';
import 'firebase/functions';

import conf from '../../configuration/configuration.json';
import firebaseConf from '../../configuration/firebase.client.json';
import { Action, Tools as ActionTools, Effect, EffectCreator } from '../../core/action';
import effectsMapping from './effects_mapping';

// TODO ... move this to separate library
const getFunctions = () =>
    conf.useLocalFunctionsEmulator === true ? firebase.app().functions() : firebase.app().functions(firebaseConf.functionsRegion);

// dispatch action on server
const dispatchOnServer = (action: Action<any>): Promise<void> => {
    if (ActionTools.isItForServer(action.target)) {
        return new Promise<any>((resolve, reject) => {
            const dispatchFnc = getFunctions().httpsCallable('store-dispatch');

            dispatchFnc(action)
                .then(() => {
                    resolve();
                })
                .catch((error: firebase.functions.HttpsError) => {
                    reject();
                    throw new Error(`Online Store Dispatch error: ${error}`);
                });
        });
    }

    return Promise.resolve();
};

// dispatch action on client
const dispatchOnClient = (action: Action<any>, store: any): Promise<void> => {
    if (ActionTools.isItForClient(action.target)) {
        const effectCreator: EffectCreator<any> | undefined = _get(effectsMapping, action.type);
        // cannot find the async action creator
        if (_isNil(effectCreator)) {
            return Promise.resolve();
        }

        const effect: Effect = effectCreator(action.payload);

        return effect(store.dispatch, store.getState);
    }

    return Promise.resolve();
};

const middleware = (store: any) => (next: any) => (action: Action<any>) => {
    // let next middlewares to process the action
    // the last middleware should be the original "dispatch" ... so action it self should be processes by local reducers automatically (if it's local)
    next(action);

    // dispatch action on server and on client in parallel ... if it's setuped to run there
    return Promise.all([dispatchOnClient(action, store), dispatchOnServer(action)]);
};

export default middleware;
