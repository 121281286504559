import { isNil as _isNil } from 'lodash';
import { Locale } from 'antd/es/locale-provider';

import csCZ from 'antd/es/locale/cs_CZ';
import enUS from 'antd/es/locale/en_US';
import ruRu from 'antd/es/locale/ru_RU';
import deDE from 'antd/es/locale/de_DE';
import skSk from 'antd/es/locale/sk_SK';

export const Locales: Locale[] = [csCZ, enUS, ruRu, deDE];

const localeByLanguageCode = (languageCode: string): Locale => {
    if (_isNil(languageCode)) {
        return enUS;
    }

    switch (languageCode) {
        case 'en': {
            return enUS;
        }
        case 'cs': {
            return csCZ;
        }
        case 'ru': {
            return ruRu;
        }
        case 'de': {
            return deDE;
        }
        case 'sk': {
            return skSk;
        }
        default: {
            return enUS;
        }
    }
};

export default localeByLanguageCode;
