import { SchoolPb as SchoolPbActions } from '../../../../core/action';
import { Store } from '../interfaces';

const reducer = (state: Store, payload: SchoolPbActions.coordinatorName.Payload): Store => {
    const newState: Store = {
        ...state,
        coordinatorName: payload.coordinatorName,
    };

    return newState;
};

export default reducer;
