import styled, { css } from 'styled-components';

import { Config } from './index';
import { cssRepresentP } from '../../../stylesheets/snippets';
import { color } from '../../../stylesheets/shared';
import { ifHoverIsSupported, onPhone, onTablet } from '../../../stylesheets/tools';

export const P = styled.p<{ config: Config }>`
    ${cssRepresentP};
    color: ${color.black};

    /* style override */
    ${({ config }) => config.styleOverride}

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;

export const MD = styled.div<{ config: Config }>`
    ${cssRepresentP};
    color: ${color.black};

    p,
    li {
        ${cssRepresentP};
        color: ${color.black};
    }

    strong {
        ${cssRepresentP};
        color: ${color.black};
        font-weight: bold;
    }

    a {
        ${cssRepresentP};
        color: ${color.blue};
        text-decoration: underline;

        ${ifHoverIsSupported(css`
            &:hover {
                text-decoration: none;
            }
        `)}
    }

    /* style override */
    ${({ config }) => config.styleOverride}

    /* on tablet */
    ${({ config }) =>
        config.onTablet
            ? css`
                  ${onTablet(css`
                      ${config.onTablet.styleOverride}
                  `)}
              `
            : ''}

    /* on phone */
    ${({ config }) =>
        config.onPhone
            ? css`
                  ${onPhone(css`
                      ${config.onPhone.styleOverride}
                  `)}
              `
            : ''}
`;
