import {
    EffectCreator,
    Application as ApplicationActions,
    Authentication as AuthenticationActions,
    SchoolPb as SchoolPbActions,
    Dialog as DialogActions,
    Form as FormActions,
    Browser as BrowserActions,
    Voting as VotingActions,
} from '../../core/action';
import * as ApplicationEffects from './application/effects';
import * as VotingEffects from './voting/effects';
import * as BrowserEffects from './browser/effects';
import * as FormEffects from './form/effects';
import * as DialogEffects from './dialog/effects';
import * as AuthenticationEffects from './authentication/effects';
import * as SchoolPbEffects from './school_pb/effects';
import { updatePoll, UPDATE_POLL_ACTION } from './school_pb/vote_manager';
import { updateResults, UPDATE_RESULTS_ACTION } from './school_pb/results_manager';
import { updateProposals, UPDATE_PROPOSALS_ACTION } from './school_pb/proposals_manager';

const effects: Record<string, EffectCreator<any>> = {
    // application
    [ApplicationActions.actionFlowTest.TYPE]: ApplicationEffects.actionFlowTest,
    // voting
    [VotingActions.sendVote.TYPE]: VotingEffects.sendVote,
    [VotingActions.markVoter.TYPE]: VotingEffects.markVoter,
    // browser
    [BrowserActions.href.TYPE]: BrowserEffects.href,
    [BrowserActions.copyToClipboard.TYPE]: BrowserEffects.copyToClipboard,
    [BrowserActions.hrefInNewWindow.TYPE]: BrowserEffects.hrefInNewWindow,
    [BrowserActions.route.TYPE]: BrowserEffects.route,
    [BrowserActions.redirectTo404.TYPE]: BrowserEffects.redirectTo404,
    [BrowserActions.alert.TYPE]: BrowserEffects.alert,
    [BrowserActions.shareOnFacebook.TYPE]: BrowserEffects.shareOnFacebook,
    [BrowserActions.shareOnTwitter.TYPE]: BrowserEffects.shareOnTwitter,
    [BrowserActions.shareOnOdnoklassniki.TYPE]: BrowserEffects.shareOnOdnoklassniki,
    [BrowserActions.scrollWindow.TYPE]: BrowserEffects.scrollWindow,
    [BrowserActions.printPreview.TYPE]: BrowserEffects.printPreview,
    // form
    [FormActions.sendForm.TYPE]: FormEffects.sendForm,
    // dialog
    [DialogActions.storeStepperStep.TYPE]: DialogEffects.storeStepperStep,
    // authentication
    [AuthenticationActions.signOut.TYPE]: AuthenticationEffects.signOut,
    [AuthenticationActions.signInWithGoogle.TYPE]: AuthenticationEffects.signInWithGoogle,
    [AuthenticationActions.signInWithFacebook.TYPE]: AuthenticationEffects.signInWithFacebook,
    [AuthenticationActions.signInWithTwitter.TYPE]: AuthenticationEffects.signInWithTwitter,
    [AuthenticationActions.signInWithPin.TYPE]: AuthenticationEffects.signInWithPin,
    [AuthenticationActions.signInWithInvitation.TYPE]: AuthenticationEffects.signInWithInvitation,
    [AuthenticationActions.checkAuthenticationRedirectResult.TYPE]: AuthenticationEffects.checkAuthenticationRedirectResult,
    [AuthenticationActions.generatePins.TYPE]: AuthenticationEffects.generatePins,
    [AuthenticationActions.generatePinSet.TYPE]: AuthenticationEffects.generatePinSet,
    [AuthenticationActions.savePinSet.TYPE]: AuthenticationEffects.savePinSet,
    // school pb
    [SchoolPbActions.storedState.TYPE]: SchoolPbEffects.getStoredState,
    [UPDATE_POLL_ACTION]: updatePoll,
    [UPDATE_RESULTS_ACTION]: updateResults,
    [UPDATE_PROPOSALS_ACTION]: updateProposals,
};

export default effects;
