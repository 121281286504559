import { isNil as _isNil, size as _size, map as _map } from 'lodash';
import React, { useState } from 'react';
import { Dropdown as AntDropdown } from 'antd';

import { BlockProps, Section as SectionConfig, Tools, IconSets, Block as BlockConfig } from '../../../core/block';
import Blocks from '../Blocks';
import Block from '../Block';
import * as Styled from './styled';

export type Config = SectionConfig;
export type Props = BlockProps & Config;

const Section = (props: Props) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const onMenuVisibleChange = (visible: boolean) => {
        setIsDropdownOpen(visible);
    };

    const onItemClick = () => {
        setIsDropdownOpen(true);
    };

    const menu = (
        <Styled.DropdownMenu onClick={onItemClick}>
            {_map(props.menu, (block: BlockConfig, blockIdx: number) => (
                <Styled.DropdownMenuItem key={blockIdx}>
                    <Block {...block} />
                </Styled.DropdownMenuItem>
            ))}
        </Styled.DropdownMenu>
    );

    return (
        <>
            {/* section */}
            <Styled.Section {...Tools.extractConfig<Config>(props)}>
                {/* label */}
                <Styled.Label>
                    {props.icon && <Styled.Icon {...props.icon} />}
                    {props.text && <Styled.Text>{props.text}</Styled.Text>}
                </Styled.Label>

                {/* menu button */}
                {!_isNil(props.menu) && _size(props.menu) && (
                    <Styled.Menu>
                        <AntDropdown
                            overlay={menu}
                            visible={isDropdownOpen}
                            trigger={['click']}
                            placement={'bottomRight'}
                            onVisibleChange={onMenuVisibleChange}
                        >
                            <Styled.MenuButton isActive={isDropdownOpen}>
                                <Styled.MenuButtonIcon name="" icon={isDropdownOpen ? IconSets.Material.CLOSE : IconSets.Material.MORE_HORIZ} />
                            </Styled.MenuButton>
                        </AntDropdown>
                    </Styled.Menu>
                )}
            </Styled.Section>

            {/* content blocks */}
            {!_isNil(props.blocks) && <Blocks blocks={props.blocks} />}
        </>
    );
};
export default Section;
