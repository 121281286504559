import styled, { css } from 'styled-components';

import { Config } from './index';
import IconComp from '../Icon';
import { color, gradient, shadow } from '../../stylesheets/shared';
import { cssH3, cssDisableUserSelect } from '../../stylesheets/snippets';
import { onPhone, ifHoverIsSupported } from '../../stylesheets/tools';

type SectionProps = {
    config: Config;
};

// Dropdown Menu
export const DropdownMenuItem = styled.div`
    text-align: left;
    padding: 6px 48px 6px 24px;
    background-color: ${color.white};
    ${cssDisableUserSelect};

    ${ifHoverIsSupported(css`
        &:hover {
            background-color: ${color.white};
        }
    `)}
`;
export const DropdownMenu = styled.div`
    border-radius: 12px;
    border: ${color.grey3} 1px solid;
    padding: 8px 0;
    box-shadow: ${shadow.standard};
    background-color: ${color.white};
`;

// Label
export const Icon = styled(IconComp)`
    font-size: 24px;
    margin-right: 8px;
    color: ${color.black};
`;
export const Text = styled.h3`
    ${cssH3};
    color: ${color.black};
`;
export const Label = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

// Menu
export const MenuButtonIcon = styled(IconComp)`
    font-size: 20px;
    color: ${color.black};
`;
interface MenuButtonProps {
    isActive: boolean;
}
export const MenuButton = styled.button<MenuButtonProps>`
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-width: 2px;
    border-style: solid;
    border-color: ${color.violet};
    background: ${color.white};
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    ${cssDisableUserSelect};

    /* enable hover only if buttons is not active */
    ${(p: MenuButtonProps) =>
        p.isActive === false &&
        css`
            ${ifHoverIsSupported(css`
                &:hover {
                    background: ${gradient.sweetLight};
                }
            `)}
        `}

    /* active */
    ${(p: MenuButtonProps) =>
        p.isActive === true &&
        css`
            background: ${color.black};
            border-color: ${color.black};
            transform: scale(0.97);

            ${MenuButtonIcon} {
                color: ${color.white};
            }
        `}

    &:active {
        transform: scale(0.96);
    }
`;
export const Menu = styled.div`
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
`;

export const Section = styled.div<SectionProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;
    width: 100%;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: ${color.grey3};

    /* style override */
    ${(p: SectionProps) => p.config.styleOverride}

    ${onPhone(css`
        border-radius: 0;
        margin-left: calc(50% - 50vw);
        width: 100vw;
        padding: 8px;
    `)}
`;
