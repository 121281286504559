import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';

import { Descriptors } from '../../../core/descriptor';

function useAdmin(): [Descriptors | undefined, boolean, any | undefined] {
    const [descriptors, loading, error] = useObjectVal<Descriptors>(firebase.database().ref('/admin/'));

    return [descriptors, loading, error];
}

export default useAdmin;
