import { isNil as _isNil } from 'lodash';
import React from 'react';

import { BlockProps, PortraitSignBarGraph as PortraitSignBarGraphConfig, Tools } from '../../../../core/block';
import H3 from '../../Typography/H3';
import * as Styled from './styled';

export type Config = PortraitSignBarGraphConfig;
export type Props = BlockProps & Config;

const PortraitSignBarGraph = (props: Props) => {
    return (
        <Styled.PortraitSignBarGraph {...Tools.extractConfig<Config>(props)}>
            {/* portrait */}
            <Styled.PortraitWrapper>
                <Styled.Portrait name="" src={props.portraitSrc} />
                {/* sub-portrait */}
                {!_isNil(props.subPortraitSrc) && <Styled.SubPortrait name="" src={props.subPortraitSrc} />}
            </Styled.PortraitWrapper>

            {/* title */}
            <Styled.Title>{!_isNil(props.text) && <H3 name="" text={props.text} />}</Styled.Title>

            {/* spacer */}
            <Styled.Spacer />

            {/* bar line */}
            <Styled.BarLine>
                <Styled.BarZeroSign />
                <Styled.Bar size={props.data.barSize} />
            </Styled.BarLine>

            {/* number */}
            {(!_isNil(props.data.count) || !_isNil(props.data.percentage)) && (
                <Styled.Numbers>
                    {!_isNil(props.data.percentage) && <Styled.Percentage>{props.data.percentage} %</Styled.Percentage>}
                    {!_isNil(props.data.count) && <Styled.Votes>({props.data.count})</Styled.Votes>}
                </Styled.Numbers>
            )}
        </Styled.PortraitSignBarGraph>
    );
};
export default PortraitSignBarGraph;
