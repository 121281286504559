import { isNil as _isNil, trim as _trim } from 'lodash';
import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';

import { Page } from '../../../core/website';
import { Urls } from '../../../core/voting';

function useResultsStep(resultsId: string, stepId: string): [Page | undefined, boolean, any | undefined] {
    const [config, loading, error] = useObjectVal<Page>(firebase.database().ref(Urls.resultsStep(resultsId, stepId)));

    if (_isNil(resultsId) || _isNil(stepId) || _trim(resultsId) === '' || _trim(stepId) === '') {
        return [undefined, true, undefined];
    }

    return [config, loading, error];
}

export default useResultsStep;
